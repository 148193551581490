@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-info-btn.lia-info-btn {
  display: inline-flex;
  column-gap: 5px;
  align-items: center;
  border-radius: var(--lia-border-radius-50);
  cursor: help !important;
  @include default-focus-outline();

  &.lia-with-label {
    border-radius: var(--lia-bs-border-radius);
  }
  .lia-label {
    white-space: pre-wrap;
    line-height: 1;
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-local-icon-color, var(--lia-bs-body-color));
  }
}
