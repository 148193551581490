.LoadingDot_lia-dot-wrap__ez7NW {
  display: flex;
  align-items: center;
  gap: 0.1875rem;
}
.LoadingDot_lia-dot__EomGo {
  border-radius: var(--lia-border-radius-50);
  animation: LoadingDot_lia-dot-animate__8soyD 1s ease infinite 200ms;
  transform: scale(0);
  transform-origin: center;
}
.LoadingDot_lia-dot__EomGo:nth-child(2) {
    animation-delay: 400ms;
  }
.LoadingDot_lia-dot__EomGo:nth-child(3) {
    animation-delay: 600ms;
  }
.LoadingDot_lia-dot-dark__EsPuE .LoadingDot_lia-dot__EomGo {
    background-color: var(--lia-load-dot-dark-color);
  }
.LoadingDot_lia-dot-light__3bU1S .LoadingDot_lia-dot__EomGo {
    background-color: var(--lia-load-dot-light-color);
  }
.LoadingDot_lia-dot-size-sm__TkiSA .LoadingDot_lia-dot__EomGo {
    width: 0.5rem;
    height: 0.5rem;
  }
.LoadingDot_lia-dot-size-lg__pvt8O .LoadingDot_lia-dot__EomGo {
    width: 0.875rem;
    height: 0.875rem;
  }
.LoadingDot_lia-dot-spacing-sm__K9eDW {
    min-height: 1.3125rem;
  }
.LoadingDot_lia-dot-spacing-md__neK1L {
    min-height: 4rem;
  }
.LoadingDot_lia-dot-spacing-lg__1oa5i {
    min-height: 8rem;
  }
.LoadingDot_lia-dot-spacing-xl__pySiy {
    min-height: 16rem;
  }
/* Animation */
@keyframes LoadingDot_lia-dot-animate__8soyD {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.LoadingLinear_lia-loading-bar-wrap__ZuYP4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--lia-font-size-xs) * 1.4);
}

.LoadingLinear_lia-loading-bar__L2RfG {
  overflow: hidden;
  height: 0.375rem;
  box-shadow: var(--lia-box-shadow-xs);
  border-radius: var(--lia-bs-border-radius-sm);
}

.LoadingLinear_lia-loading-bar-light__PDix6 {
    background-color: var(--lia-load-bar-light-color);
  }

.LoadingLinear_lia-loading-bar-dark__0P_CS {
    background-color: var(--lia-load-bar-dark-color);
  }

.LoadingLinear_lia-loading-bar-light__PDix6 > [role='progressbar'] {
      height: inherit;
      background-color: var(--lia-bs-white);
      background-size: 12px 12px;
      transition: var(--lia-timing-normal) linear;
    }

.LoadingLinear_lia-loading-bar-dark__0P_CS > [role='progressbar'] {
      height: inherit;
      background-color: var(--lia-bs-gray-700);
      background-size: 12px 12px;
      transition: var(--lia-timing-normal) linear;
    }

/* Media breakpoints */
/* Breakpoint Down */
/* Breakpoint Up */
/* Panels */
/* Admin */
/* Search */
/* Indents */
/* Quilts */
/*
 * Default Bootstrap toast styling is designed for use in a stack.
 * To apply properly to a queue, flex-direction column-reverse is applied,
 * and the :not(:last-child) margin style for the toast is reversed.
 */
.ToastContext_lia-container-banner__uaGII,
  .ToastContext_lia-container-flyout__64cKi {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    z-index: 9999;
  }
.ToastContext_lia-container-banner__uaGII {
    align-items: center;
    right: 50%;
    bottom: 1.5625rem;
    left: 50%;
  }
@media (max-width: 767.98px) {
.ToastContext_lia-container-banner__uaGII {
      margin: 0 0.3125rem;
      right: 0;
      left: 0
  }
    }
.ToastContext_lia-container-flyout__64cKi {
    top: 1.5625rem;
    right: 1.5625rem;
  }
@media (max-width: 767.98px) {
.ToastContext_lia-container-flyout__64cKi {
      margin: 0 1.25rem;
      right: 0
  }
    }
.ToastContext_lia-toast__j20kG + .ToastContext_lia-toast__j20kG {
  margin-bottom: 0.625rem;
}

/*
 Entry point for global styles used in Aurora. Includes
 our custom variables, mixins, and third party libraries. These "global"
 styles are still passed through the CSS Module system and require
 a class name scoping, they cannot be "pure" (or "unscoped") CSS rules
 that apply styles directly to elements (such as div, span, h1, etc).

 Changes to pure/unscoped elements should be done in `unscoped.scss`
 and should be added sparingly.

 @group bootstrap
 @author Adam Ayres
*/

/* Custom variables and overrides for variables used by other libraries */

/* Media breakpoints */

/* Breakpoint Down */

/* Breakpoint Up */

/* Panels */

/* Admin */

/* Search */

/* Indents */

/* Quilts */

/**
 * Custom build of Bootstrap that maps some SCSS variables to
 * CSS Variables. This is done to allow for theming via CSS
 * Variables without relying on a SCSS compile.
 *
 * This custom build also separates out any CSS rules that are done
 * at root level, or rather done directly on elements with no selectors.
 * We call this distinction "scoped" and "unscoped", where the "scoped" CSS
 * is CSS that applies to a class or ID and the "unscoped" CSS is CSS that applies
 * to root elements. This is done to allow for the "scoped" Bootstrap CSS classes
 * to work with CSS Modules where the class names are ultimately transformed
 * and will only work within the scope of our components. The unscoped Bootstrap
 * styles are applied globally and will apply any element/component on the page
 * that they are applicable to.
 *
 * Additional reading:
 *
 * https://blog.wick.technology/runtime-dynamic-bootstrap-theme/
 * https://gist.github.com/johanlef/518a511b2b2f6b96c4f429b3af2f169a
 * https://github.com/twbs/bootstrap/issues/26596
 *
 * @author Adam Ayres
 */

/* Mixin overrides */

/* Provides function overrides to allow the SCSS to CSS Variable conversion */

/**
  Taken from https://gist.github.com/johanlef/518a511b2b2f6b96c4f429b3af2f169a
 */

/**
 * Contains the mappings from SCSS to CSS Variables as well as changes to
 * Bootstrap SCSS variables that may not be exposed as CSS Variables.
 */

/* Gray Scale */

/* Main Colors */

/* Link Color */

/* Body Background */

/* Gutters */

/* Font Family */

/* Font Weight */

/* Line Height */

/* Font Size */

/* Headings */

/* Header Font Size */

/* Lead */

/* Text Muted */

/* Blockquote Font Size */

/* Border */

/* Box Shadows */

/* List Group */

/* Grids Breakpoints */

/* Container Max Widths */

/* Modals */

/* Popover */

/* Inputs */

/* Button Sizes */

/* Button Other */

/* Toasts */

/* Dropdowns */

/* Pagination */

/* Card */

/* List Groups */

/* Nav */

/* Progress Bars */

/* Close */

/* Table */

/* Overridden to break apart the unscoped and scoped styles */

.styles_h1__vgkYd,
.styles_h2__MqqWa,
.styles_h3__PfINH,
.styles_h4__wlzOi,
.styles_h5__q_74t,
.styles_h6__Cz6EH {
  margin-bottom: 0.5rem;
  font-family: var(--lia-bs-headings-font-family);
  font-weight: var(--lia-bs-headings-font-weight);
  line-height: var(--lia-bs-headings-line-height);
  font-style: var(--lia-headings-font-style);
  color: var(--lia-bs-headings-color);
}

.styles_h1__vgkYd {
  font-size: var(--lia-bs-h1-font-size);
  letter-spacing: var(--lia-h1-letter-spacing);
  font-weight: var(--lia-h1-font-weight);
}

.styles_h2__MqqWa {
  font-size: var(--lia-bs-h2-font-size);
  letter-spacing: var(--lia-h2-letter-spacing);
  font-weight: var(--lia-h2-font-weight);
}

.styles_h3__PfINH {
  font-size: var(--lia-bs-h3-font-size);
  letter-spacing: var(--lia-h3-letter-spacing);
  font-weight: var(--lia-h3-font-weight);
}

.styles_h4__wlzOi {
  font-size: var(--lia-bs-h4-font-size);
  letter-spacing: var(--lia-h4-letter-spacing);
  font-weight: var(--lia-h4-font-weight);
}

.styles_h5__q_74t {
  font-size: var(--lia-bs-h5-font-size);
  letter-spacing: var(--lia-h5-letter-spacing);
  font-weight: var(--lia-h5-font-weight);
}

.styles_h6__Cz6EH {
  font-size: var(--lia-bs-h6-font-size);
  letter-spacing: var(--lia-h6-letter-spacing);
  font-weight: var(--lia-h6-font-weight);
}

.styles_lead__5rtHo {
  font-size: calc(var(--lia-bs-font-size-base) * 1.25);
  font-weight: 300;
}

.styles_display-1__7Mz1b {
  font-size: 6rem;
  font-weight: 300;
  line-height: var(--lia-bs-headings-line-height);
}

@media (max-width: 1200px) {
  .styles_display-1__7Mz1b {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.styles_display-2__Zd1W_ {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: var(--lia-bs-headings-line-height);
}

@media (max-width: 1200px) {
  .styles_display-2__Zd1W_ {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.styles_display-3__OIoht {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: var(--lia-bs-headings-line-height);
}

@media (max-width: 1200px) {
  .styles_display-3__OIoht {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.styles_display-4__nJAtB {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: var(--lia-bs-headings-line-height);
}

@media (max-width: 1200px) {
  .styles_display-4__nJAtB {
    font-size: calc(1.475rem + 2.7vw);
  }
}

.styles_small__seeYj {
  font-size: var(--lia-bs-small-font-size);
  font-weight: var(--lia-bs-font-weight-base);
  letter-spacing: var(--lia-letter-spacing-sm);
}

.styles_mark__8tI59 {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.styles_list-unstyled__4L_2i {
  padding-left: 0;
  list-style: none;
}

.styles_list-inline__2N4Tg {
  padding-left: 0;
  list-style: none;
}

.styles_list-inline-item__8jK4c {
  display: inline-block;
}

.styles_list-inline-item__8jK4c:not(:last-child) {
  margin-right: 0.5rem;
}

.styles_initialism__Njy32 {
  font-size: 90%;
  text-transform: uppercase;
}

.styles_blockquote__IEeWd {
  margin-bottom: 1rem;
  font-size: calc(var(--lia-bs-font-size-base) * 1.25);
}

.styles_blockquote-footer__dmyKF {
  display: block;
  font-size: var(--lia-bs-small-font-size);
  color: var(--lia-bs-gray-600);
}

.styles_blockquote-footer__dmyKF::before {
  content: '\2014\00A0';
}

.styles_img-fluid__mYKBQ {
  max-width: 100%;
  height: auto;
}

.styles_img-thumbnail__NmIby {
  padding: 0.25rem;
  background-color: var(--lia-bs-body-bg);
  border: 1px solid var(--lia-bs-gray-300);
  border-radius: var(--lia-bs-border-radius);
  max-width: 100%;
  height: auto;
}

.styles_figure___XIPu {
  display: inline-block;
}

.styles_figure-img__Usz_a {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.styles_figure-caption__YTPMS {
  font-size: 90%;
  color: var(--lia-bs-gray-600);
}

/* Overridden to break apart the unscoped and scoped styles */

.styles_pre-scrollable__VVTa_ {
  max-height: 21.25rem;
  overflow-y: scroll;
}

.styles_container__6wTQq,
.styles_container-fluid__AMlCa,
.styles_container-sm__4LIGQ,
.styles_container-md__5Qksv,
.styles_container-lg__YRzUP,
.styles_container-xl__iu8bu {
  width: 100%;
  padding-right: calc(var(--lia-bs-grid-gutter-width) * 0.5);
  padding-left: calc(var(--lia-bs-grid-gutter-width) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .styles_container__6wTQq,
  .styles_container-sm__4LIGQ {
    max-width: 33.75rem;
  }
}

@media (min-width: 768px) {
  .styles_container__6wTQq,
  .styles_container-sm__4LIGQ,
  .styles_container-md__5Qksv {
    max-width: 45rem;
  }
}

@media (min-width: 992px) {
  .styles_container__6wTQq,
  .styles_container-sm__4LIGQ,
  .styles_container-md__5Qksv,
  .styles_container-lg__YRzUP {
    max-width: 60rem;
  }
}

@media (min-width: 1260px) {
  .styles_container__6wTQq,
  .styles_container-sm__4LIGQ,
  .styles_container-md__5Qksv,
  .styles_container-lg__YRzUP,
  .styles_container-xl__iu8bu {
    max-width: 75rem;
  }
}

.styles_row__sjnST {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--lia-bs-grid-gutter-width) * -0.5);
  margin-left: calc(var(--lia-bs-grid-gutter-width) * -0.5);
}

.styles_no-gutters__AQ_uI {
  margin-right: 0;
  margin-left: 0;
}

.styles_no-gutters__AQ_uI > .styles_col__fuduo,
.styles_no-gutters__AQ_uI > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.styles_col-1__X9b3T,
.styles_col-2__8xcDS,
.styles_col-3__HWjoC,
.styles_col-4__UJ5qo,
.styles_col-5__YF4QN,
.styles_col-6__A_P4W,
.styles_col-7__C2bRc,
.styles_col-8__axLF2,
.styles_col-9__9D9sa,
.styles_col-10__W4uYt,
.styles_col-11__QYS_r,
.styles_col-12__cxGyI,
.styles_col__fuduo,
.styles_col-auto__V0o3N,
.styles_col-sm-1___Yfk0,
.styles_col-sm-2__gXHpq,
.styles_col-sm-3__UR1Gz,
.styles_col-sm-4__BYHb0,
.styles_col-sm-5__f6BXV,
.styles_col-sm-6__n_zH3,
.styles_col-sm-7__r_HJL,
.styles_col-sm-8__gJQQB,
.styles_col-sm-9__3yYs0,
.styles_col-sm-10__n57XT,
.styles_col-sm-11__0mSHt,
.styles_col-sm-12__N6qZE,
.styles_col-sm__1nSW_,
.styles_col-sm-auto__fNcrI,
.styles_col-md-1__HwG8y,
.styles_col-md-2__2yFaM,
.styles_col-md-3__CEfFT,
.styles_col-md-4__iuBD9,
.styles_col-md-5__C73KG,
.styles_col-md-6__O0Dyu,
.styles_col-md-7__6COyV,
.styles_col-md-8__6_AMA,
.styles_col-md-9__GB_Ww,
.styles_col-md-10__keFkc,
.styles_col-md-11__mXp_D,
.styles_col-md-12__5IKJ8,
.styles_col-md__hE1ZT,
.styles_col-md-auto__hX1d3,
.styles_col-lg-1__e7KkO,
.styles_col-lg-2__sER7D,
.styles_col-lg-3__9OqHf,
.styles_col-lg-4__6NfeT,
.styles_col-lg-5__I7dSS,
.styles_col-lg-6__QPfhp,
.styles_col-lg-7__Fe69d,
.styles_col-lg-8__V2wNr,
.styles_col-lg-9__nK4bV,
.styles_col-lg-10__8JPjB,
.styles_col-lg-11___E4OT,
.styles_col-lg-12__u20eR,
.styles_col-lg__vfM3T,
.styles_col-lg-auto__AfKAh,
.styles_col-xl-1__LLM2t,
.styles_col-xl-2__EwCKr,
.styles_col-xl-3__1YgIz,
.styles_col-xl-4__VPLH0,
.styles_col-xl-5__8JipE,
.styles_col-xl-6__z3ADJ,
.styles_col-xl-7__PI5B8,
.styles_col-xl-8__9knew,
.styles_col-xl-9__LyMpg,
.styles_col-xl-10__NiytH,
.styles_col-xl-11__YyNPX,
.styles_col-xl-12__nZPu_,
.styles_col-xl__ErlQE,
.styles_col-xl-auto__yidUh {
  position: relative;
  width: 100%;
  padding-right: calc(var(--lia-bs-grid-gutter-width) * 0.5);
  padding-left: calc(var(--lia-bs-grid-gutter-width) * 0.5);
}

.styles_col__fuduo {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.styles_row-cols-1__6uSC5 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.styles_row-cols-2__CWZGo > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.styles_row-cols-3__JeG22 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.styles_row-cols-4__UnUS6 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.styles_row-cols-5__nRfyC > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.styles_row-cols-6__UMACY > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.styles_col-auto__V0o3N {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.styles_col-1__X9b3T {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.styles_col-2__8xcDS {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.styles_col-3__HWjoC {
  flex: 0 0 25%;
  max-width: 25%;
}

.styles_col-4__UJ5qo {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.styles_col-5__YF4QN {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.styles_col-6__A_P4W {
  flex: 0 0 50%;
  max-width: 50%;
}

.styles_col-7__C2bRc {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.styles_col-8__axLF2 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.styles_col-9__9D9sa {
  flex: 0 0 75%;
  max-width: 75%;
}

.styles_col-10__W4uYt {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.styles_col-11__QYS_r {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.styles_col-12__cxGyI {
  flex: 0 0 100%;
  max-width: 100%;
}

.styles_order-first__N1TGK {
  order: -1;
}

.styles_order-last__NV953 {
  order: 13;
}

.styles_order-0__pZrMy {
  order: 0;
}

.styles_order-1__5TNWJ {
  order: 1;
}

.styles_order-2__fjHOl {
  order: 2;
}

.styles_order-3__EjagL {
  order: 3;
}

.styles_order-4__UHz76 {
  order: 4;
}

.styles_order-5__h2nDK {
  order: 5;
}

.styles_order-6__hBjc_ {
  order: 6;
}

.styles_order-7__e7tiU {
  order: 7;
}

.styles_order-8__1bHMq {
  order: 8;
}

.styles_order-9__41u98 {
  order: 9;
}

.styles_order-10__T_4FA {
  order: 10;
}

.styles_order-11__y0ihX {
  order: 11;
}

.styles_order-12__YiIZD {
  order: 12;
}

.styles_offset-1__PeI6_ {
  margin-left: 8.33333%;
}

.styles_offset-2__oIsxi {
  margin-left: 16.66667%;
}

.styles_offset-3__SZBcZ {
  margin-left: 25%;
}

.styles_offset-4__X4EBn {
  margin-left: 33.33333%;
}

.styles_offset-5__RZsj3 {
  margin-left: 41.66667%;
}

.styles_offset-6__I0Ybm {
  margin-left: 50%;
}

.styles_offset-7__xKHN5 {
  margin-left: 58.33333%;
}

.styles_offset-8__6q43U {
  margin-left: 66.66667%;
}

.styles_offset-9__AjC0L {
  margin-left: 75%;
}

.styles_offset-10__8NY6q {
  margin-left: 83.33333%;
}

.styles_offset-11__mES04 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .styles_col-sm__1nSW_ {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .styles_row-cols-sm-1__i1EzU > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_row-cols-sm-2__KoO5O > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_row-cols-sm-3__jcsPA > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_row-cols-sm-4__vgHn3 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_row-cols-sm-5__meG2H > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .styles_row-cols-sm-6__GV_e9 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-sm-auto__fNcrI {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .styles_col-sm-1___Yfk0 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .styles_col-sm-2__gXHpq {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-sm-3__UR1Gz {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_col-sm-4__BYHb0 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_col-sm-5__f6BXV {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .styles_col-sm-6__n_zH3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_col-sm-7__r_HJL {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .styles_col-sm-8__gJQQB {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .styles_col-sm-9__3yYs0 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .styles_col-sm-10__n57XT {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .styles_col-sm-11__0mSHt {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .styles_col-sm-12__N6qZE {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_order-sm-first__GzLCp {
    order: -1;
  }
  .styles_order-sm-last__Y0IOp {
    order: 13;
  }
  .styles_order-sm-0__xgslp {
    order: 0;
  }
  .styles_order-sm-1__F6oYl {
    order: 1;
  }
  .styles_order-sm-2__2kMrH {
    order: 2;
  }
  .styles_order-sm-3__YuNrZ {
    order: 3;
  }
  .styles_order-sm-4__FeM1I {
    order: 4;
  }
  .styles_order-sm-5__mXSUd {
    order: 5;
  }
  .styles_order-sm-6__LoJbI {
    order: 6;
  }
  .styles_order-sm-7__NFRHh {
    order: 7;
  }
  .styles_order-sm-8__BVc4i {
    order: 8;
  }
  .styles_order-sm-9__R9soQ {
    order: 9;
  }
  .styles_order-sm-10__hgZ3K {
    order: 10;
  }
  .styles_order-sm-11__xMVHJ {
    order: 11;
  }
  .styles_order-sm-12__4anbK {
    order: 12;
  }
  .styles_offset-sm-0__C8o_8 {
    margin-left: 0;
  }
  .styles_offset-sm-1__1dqKd {
    margin-left: 8.33333%;
  }
  .styles_offset-sm-2__92Y2W {
    margin-left: 16.66667%;
  }
  .styles_offset-sm-3__nmUZR {
    margin-left: 25%;
  }
  .styles_offset-sm-4__T6jUO {
    margin-left: 33.33333%;
  }
  .styles_offset-sm-5__f02Bz {
    margin-left: 41.66667%;
  }
  .styles_offset-sm-6__J5skS {
    margin-left: 50%;
  }
  .styles_offset-sm-7__uiTlY {
    margin-left: 58.33333%;
  }
  .styles_offset-sm-8__unTt6 {
    margin-left: 66.66667%;
  }
  .styles_offset-sm-9__HLW63 {
    margin-left: 75%;
  }
  .styles_offset-sm-10__67qYM {
    margin-left: 83.33333%;
  }
  .styles_offset-sm-11__zxyr5 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .styles_col-md__hE1ZT {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .styles_row-cols-md-1__7NjX8 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_row-cols-md-2__7XE16 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_row-cols-md-3__UtW1H > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_row-cols-md-4__1LK6H > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_row-cols-md-5__aXi9d > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .styles_row-cols-md-6__Amc6z > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-md-auto__hX1d3 {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .styles_col-md-1__HwG8y {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .styles_col-md-2__2yFaM {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-md-3__CEfFT {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_col-md-4__iuBD9 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_col-md-5__C73KG {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .styles_col-md-6__O0Dyu {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_col-md-7__6COyV {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .styles_col-md-8__6_AMA {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .styles_col-md-9__GB_Ww {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .styles_col-md-10__keFkc {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .styles_col-md-11__mXp_D {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .styles_col-md-12__5IKJ8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_order-md-first__T_72e {
    order: -1;
  }
  .styles_order-md-last__nwCDw {
    order: 13;
  }
  .styles_order-md-0__oyNMJ {
    order: 0;
  }
  .styles_order-md-1__ye9GF {
    order: 1;
  }
  .styles_order-md-2__8_K4I {
    order: 2;
  }
  .styles_order-md-3__VH5q1 {
    order: 3;
  }
  .styles_order-md-4__ZqOj6 {
    order: 4;
  }
  .styles_order-md-5__x78Ee {
    order: 5;
  }
  .styles_order-md-6__uc0Bt {
    order: 6;
  }
  .styles_order-md-7__01NVb {
    order: 7;
  }
  .styles_order-md-8__GlKjG {
    order: 8;
  }
  .styles_order-md-9__zMDhs {
    order: 9;
  }
  .styles_order-md-10__im4SU {
    order: 10;
  }
  .styles_order-md-11__zAPY_ {
    order: 11;
  }
  .styles_order-md-12__qzZyJ {
    order: 12;
  }
  .styles_offset-md-0__xAvIr {
    margin-left: 0;
  }
  .styles_offset-md-1__quHxj {
    margin-left: 8.33333%;
  }
  .styles_offset-md-2__wEak6 {
    margin-left: 16.66667%;
  }
  .styles_offset-md-3__KR2lH {
    margin-left: 25%;
  }
  .styles_offset-md-4__ByJzO {
    margin-left: 33.33333%;
  }
  .styles_offset-md-5__fQcjp {
    margin-left: 41.66667%;
  }
  .styles_offset-md-6__d_hce {
    margin-left: 50%;
  }
  .styles_offset-md-7__pboJc {
    margin-left: 58.33333%;
  }
  .styles_offset-md-8__s9Fmn {
    margin-left: 66.66667%;
  }
  .styles_offset-md-9__WQAzw {
    margin-left: 75%;
  }
  .styles_offset-md-10__FTEyu {
    margin-left: 83.33333%;
  }
  .styles_offset-md-11__ptEL0 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .styles_col-lg__vfM3T {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .styles_row-cols-lg-1__6c_z7 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_row-cols-lg-2__miYVk > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_row-cols-lg-3__gfsxx > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_row-cols-lg-4__Lq6pu > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_row-cols-lg-5__d0xNT > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .styles_row-cols-lg-6__HQCXm > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-lg-auto__AfKAh {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .styles_col-lg-1__e7KkO {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .styles_col-lg-2__sER7D {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-lg-3__9OqHf {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_col-lg-4__6NfeT {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_col-lg-5__I7dSS {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .styles_col-lg-6__QPfhp {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_col-lg-7__Fe69d {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .styles_col-lg-8__V2wNr {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .styles_col-lg-9__nK4bV {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .styles_col-lg-10__8JPjB {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .styles_col-lg-11___E4OT {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .styles_col-lg-12__u20eR {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_order-lg-first__xwXS8 {
    order: -1;
  }
  .styles_order-lg-last__VCPEH {
    order: 13;
  }
  .styles_order-lg-0__srU4K {
    order: 0;
  }
  .styles_order-lg-1__ukA8Q {
    order: 1;
  }
  .styles_order-lg-2__t3d2D {
    order: 2;
  }
  .styles_order-lg-3__6Lch5 {
    order: 3;
  }
  .styles_order-lg-4__q2jlf {
    order: 4;
  }
  .styles_order-lg-5__uFfKp {
    order: 5;
  }
  .styles_order-lg-6__1kRcz {
    order: 6;
  }
  .styles_order-lg-7__i1JBX {
    order: 7;
  }
  .styles_order-lg-8__MazSI {
    order: 8;
  }
  .styles_order-lg-9__hb40c {
    order: 9;
  }
  .styles_order-lg-10__jmMJq {
    order: 10;
  }
  .styles_order-lg-11__krX0T {
    order: 11;
  }
  .styles_order-lg-12__5JSrC {
    order: 12;
  }
  .styles_offset-lg-0__qfsdp {
    margin-left: 0;
  }
  .styles_offset-lg-1__mDHYB {
    margin-left: 8.33333%;
  }
  .styles_offset-lg-2__G9cHJ {
    margin-left: 16.66667%;
  }
  .styles_offset-lg-3__QOD9_ {
    margin-left: 25%;
  }
  .styles_offset-lg-4__zAK4M {
    margin-left: 33.33333%;
  }
  .styles_offset-lg-5__Yc8dm {
    margin-left: 41.66667%;
  }
  .styles_offset-lg-6__JRqmC {
    margin-left: 50%;
  }
  .styles_offset-lg-7__D3M3c {
    margin-left: 58.33333%;
  }
  .styles_offset-lg-8__ISVfX {
    margin-left: 66.66667%;
  }
  .styles_offset-lg-9__cFzSH {
    margin-left: 75%;
  }
  .styles_offset-lg-10__KWSS9 {
    margin-left: 83.33333%;
  }
  .styles_offset-lg-11__1oUgz {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1260px) {
  .styles_col-xl__ErlQE {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .styles_row-cols-xl-1__8DVL_ > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_row-cols-xl-2__lEgmc > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_row-cols-xl-3__9uDYz > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_row-cols-xl-4__hFMIt > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_row-cols-xl-5__e6ypo > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .styles_row-cols-xl-6__r7fgV > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-xl-auto__yidUh {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .styles_col-xl-1__LLM2t {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .styles_col-xl-2__EwCKr {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .styles_col-xl-3__1YgIz {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .styles_col-xl-4__VPLH0 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .styles_col-xl-5__8JipE {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .styles_col-xl-6__z3ADJ {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .styles_col-xl-7__PI5B8 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .styles_col-xl-8__9knew {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .styles_col-xl-9__LyMpg {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .styles_col-xl-10__NiytH {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .styles_col-xl-11__YyNPX {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .styles_col-xl-12__nZPu_ {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .styles_order-xl-first__Pi5w8 {
    order: -1;
  }
  .styles_order-xl-last__Nl1BQ {
    order: 13;
  }
  .styles_order-xl-0__VyvDH {
    order: 0;
  }
  .styles_order-xl-1__OvNTA {
    order: 1;
  }
  .styles_order-xl-2__ogKpx {
    order: 2;
  }
  .styles_order-xl-3__pvDTp {
    order: 3;
  }
  .styles_order-xl-4__aJ9SN {
    order: 4;
  }
  .styles_order-xl-5__JrJo4 {
    order: 5;
  }
  .styles_order-xl-6___vv80 {
    order: 6;
  }
  .styles_order-xl-7__pqB4a {
    order: 7;
  }
  .styles_order-xl-8__yph1t {
    order: 8;
  }
  .styles_order-xl-9__GZEJl {
    order: 9;
  }
  .styles_order-xl-10__5MqOs {
    order: 10;
  }
  .styles_order-xl-11__KB9DQ {
    order: 11;
  }
  .styles_order-xl-12__iDhec {
    order: 12;
  }
  .styles_offset-xl-0___q9og {
    margin-left: 0;
  }
  .styles_offset-xl-1__85pC9 {
    margin-left: 8.33333%;
  }
  .styles_offset-xl-2__q0RPP {
    margin-left: 16.66667%;
  }
  .styles_offset-xl-3__vJxCr {
    margin-left: 25%;
  }
  .styles_offset-xl-4__nyYor {
    margin-left: 33.33333%;
  }
  .styles_offset-xl-5__NAbKl {
    margin-left: 41.66667%;
  }
  .styles_offset-xl-6__7w_Ay {
    margin-left: 50%;
  }
  .styles_offset-xl-7__5s8K3 {
    margin-left: 58.33333%;
  }
  .styles_offset-xl-8__3IgqL {
    margin-left: 66.66667%;
  }
  .styles_offset-xl-9__BPFv6 {
    margin-left: 75%;
  }
  .styles_offset-xl-10__ZNsCU {
    margin-left: 83.33333%;
  }
  .styles_offset-xl-11__JPJ3h {
    margin-left: 91.66667%;
  }
}

/* Overridden for color functions */

.styles_table__jNBXc {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--lia-bs-body-color);
}

.styles_table__jNBXc th,
.styles_table__jNBXc td {
  padding: 0.625rem;
  vertical-align: top;
  border-top: 1px solid var(--lia-bs-border-color);
}

.styles_table__jNBXc thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--lia-bs-border-color);
}

.styles_table__jNBXc tbody + tbody {
  border-top: 2px solid var(--lia-bs-border-color);
}

.styles_table-sm__C0QAH th,
.styles_table-sm__C0QAH td {
  padding: 0.3125rem;
}

.styles_table-bordered__W5qJx {
  border: 1px solid var(--lia-bs-border-color);
}

.styles_table-bordered__W5qJx th,
.styles_table-bordered__W5qJx td {
  border: 1px solid var(--lia-bs-border-color);
}

.styles_table-bordered__W5qJx thead th,
.styles_table-bordered__W5qJx thead td {
  border-bottom-width: 0.125rem;
}

.styles_table-borderless__JDFwH th,
.styles_table-borderless__JDFwH td,
.styles_table-borderless__JDFwH thead th,
.styles_table-borderless__JDFwH tbody + tbody {
  border: 0;
}

.styles_table-striped__fqpL8 tbody tr:nth-of-type(odd) {
  background-color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.05);
}

.styles_table-hover__8_Kui tbody tr:hover {
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-gray-200);
}

.styles_table-primary__vbKMe,
.styles_table-primary__vbKMe > th,
.styles_table-primary__vbKMe > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
}

.styles_table-primary__vbKMe th,
.styles_table-primary__vbKMe td,
.styles_table-primary__vbKMe thead th,
.styles_table-primary__vbKMe tbody + tbody {
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_table-hover__8_Kui .styles_table-primary__vbKMe:hover {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.7
  );
}

.styles_table-hover__8_Kui .styles_table-primary__vbKMe:hover > td,
.styles_table-hover__8_Kui .styles_table-primary__vbKMe:hover > th {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.7
  );
}

.styles_table-secondary__4Q_ND,
.styles_table-secondary__4Q_ND > th,
.styles_table-secondary__4Q_ND > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
}

.styles_table-secondary__4Q_ND th,
.styles_table-secondary__4Q_ND td,
.styles_table-secondary__4Q_ND thead th,
.styles_table-secondary__4Q_ND tbody + tbody {
  border-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.5
  );
}

.styles_table-hover__8_Kui .styles_table-secondary__4Q_ND:hover {
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.7
  );
}

.styles_table-hover__8_Kui .styles_table-secondary__4Q_ND:hover > td,
.styles_table-hover__8_Kui .styles_table-secondary__4Q_ND:hover > th {
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.7
  );
}

.styles_table-success__4W9A1,
.styles_table-success__4W9A1 > th,
.styles_table-success__4W9A1 > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
}

.styles_table-success__4W9A1 th,
.styles_table-success__4W9A1 td,
.styles_table-success__4W9A1 thead th,
.styles_table-success__4W9A1 tbody + tbody {
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.5
  );
}

.styles_table-hover__8_Kui .styles_table-success__4W9A1:hover {
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.7
  );
}

.styles_table-hover__8_Kui .styles_table-success__4W9A1:hover > td,
.styles_table-hover__8_Kui .styles_table-success__4W9A1:hover > th {
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.7
  );
}

.styles_table-info__10Fp2,
.styles_table-info__10Fp2 > th,
.styles_table-info__10Fp2 > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
}

.styles_table-info__10Fp2 th,
.styles_table-info__10Fp2 td,
.styles_table-info__10Fp2 thead th,
.styles_table-info__10Fp2 tbody + tbody {
  border-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.5);
}

.styles_table-hover__8_Kui .styles_table-info__10Fp2:hover {
  background-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.7);
}

.styles_table-hover__8_Kui .styles_table-info__10Fp2:hover > td,
.styles_table-hover__8_Kui .styles_table-info__10Fp2:hover > th {
  background-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.7);
}

.styles_table-warning__NlJBa,
.styles_table-warning__NlJBa > th,
.styles_table-warning__NlJBa > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
}

.styles_table-warning__NlJBa th,
.styles_table-warning__NlJBa td,
.styles_table-warning__NlJBa thead th,
.styles_table-warning__NlJBa tbody + tbody {
  border-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.5
  );
}

.styles_table-hover__8_Kui .styles_table-warning__NlJBa:hover {
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.7
  );
}

.styles_table-hover__8_Kui .styles_table-warning__NlJBa:hover > td,
.styles_table-hover__8_Kui .styles_table-warning__NlJBa:hover > th {
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.7
  );
}

.styles_table-danger__DsT8d,
.styles_table-danger__DsT8d > th,
.styles_table-danger__DsT8d > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
}

.styles_table-danger__DsT8d th,
.styles_table-danger__DsT8d td,
.styles_table-danger__DsT8d thead th,
.styles_table-danger__DsT8d tbody + tbody {
  border-color: hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.5);
}

.styles_table-hover__8_Kui .styles_table-danger__DsT8d:hover {
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    var(--lia-bs-danger-l),
    0.7
  );
}

.styles_table-hover__8_Kui .styles_table-danger__DsT8d:hover > td,
.styles_table-hover__8_Kui .styles_table-danger__DsT8d:hover > th {
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    var(--lia-bs-danger-l),
    0.7
  );
}

.styles_table-light__3YUf3,
.styles_table-light__3YUf3 > th,
.styles_table-light__3YUf3 > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
}

.styles_table-light__3YUf3 th,
.styles_table-light__3YUf3 td,
.styles_table-light__3YUf3 thead th,
.styles_table-light__3YUf3 tbody + tbody {
  border-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.5);
}

.styles_table-hover__8_Kui .styles_table-light__3YUf3:hover {
  background-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.7);
}

.styles_table-hover__8_Kui .styles_table-light__3YUf3:hover > td,
.styles_table-hover__8_Kui .styles_table-light__3YUf3:hover > th {
  background-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.7);
}

.styles_table-dark__UB7ie,
.styles_table-dark__UB7ie > th,
.styles_table-dark__UB7ie > td {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
}

.styles_table-dark__UB7ie th,
.styles_table-dark__UB7ie td,
.styles_table-dark__UB7ie thead th,
.styles_table-dark__UB7ie tbody + tbody {
  border-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.5);
}

.styles_table-hover__8_Kui .styles_table-dark__UB7ie:hover {
  background-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.7);
}

.styles_table-hover__8_Kui .styles_table-dark__UB7ie:hover > td,
.styles_table-hover__8_Kui .styles_table-dark__UB7ie:hover > th {
  background-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.7);
}

.styles_table-active__xsptA,
.styles_table-active__xsptA > th,
.styles_table-active__xsptA > td {
  background-color: var(--lia-bs-gray-200);
}

.styles_table-hover__8_Kui .styles_table-active__xsptA:hover {
  background-color: hsla(
    var(--lia-bs-gray-200-h),
    var(--lia-bs-gray-200-s),
    calc(var(--lia-bs-gray-200-l) + -5%),
    var(--lia-bs-gray-200-a, 1)
  );
}

.styles_table-hover__8_Kui .styles_table-active__xsptA:hover > td,
.styles_table-hover__8_Kui .styles_table-active__xsptA:hover > th {
  background-color: hsla(
    var(--lia-bs-gray-200-h),
    var(--lia-bs-gray-200-s),
    calc(var(--lia-bs-gray-200-l) + -5%),
    var(--lia-bs-gray-200-a, 1)
  );
}

.styles_table__jNBXc .styles_thead-dark__8y83q th {
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-gray-800);
  border-color: hsla(
    var(--lia-bs-gray-800-h),
    var(--lia-bs-gray-800-s),
    calc(var(--lia-bs-gray-800-l) + 7.5%),
    var(--lia-bs-gray-800-a, 1)
  );
}

.styles_table__jNBXc .styles_thead-light__xuuK5 th {
  color: var(--lia-bs-gray-700);
  background-color: var(--lia-bs-gray-200);
  border-color: var(--lia-bs-border-color);
}

.styles_table-dark__UB7ie {
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-gray-800);
}

.styles_table-dark__UB7ie th,
.styles_table-dark__UB7ie td,
.styles_table-dark__UB7ie thead th {
  border-color: hsla(
    var(--lia-bs-gray-800-h),
    var(--lia-bs-gray-800-s),
    calc(var(--lia-bs-gray-800-l) + 7.5%),
    var(--lia-bs-gray-800-a, 1)
  );
}

.styles_table-dark__UB7ie.styles_table-bordered__W5qJx {
  border: 0;
}

.styles_table-dark__UB7ie.styles_table-striped__fqpL8 tbody tr:nth-of-type(odd) {
  background-color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.05);
}

.styles_table-dark__UB7ie.styles_table-hover__8_Kui tbody tr:hover {
  color: var(--lia-bs-white);
  background-color: hsla(
    var(--lia-bs-white-h),
    var(--lia-bs-white-s),
    var(--lia-bs-white-l),
    0.075
  );
}

@media (max-width: 575.98px) {
  .styles_table-responsive-sm__SWTXk {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .styles_table-responsive-sm__SWTXk > .styles_table-bordered__W5qJx {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .styles_table-responsive-md__RsrFW {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .styles_table-responsive-md__RsrFW > .styles_table-bordered__W5qJx {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .styles_table-responsive-lg__vT8vq {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .styles_table-responsive-lg__vT8vq > .styles_table-bordered__W5qJx {
    border: 0;
  }
}

@media (max-width: 1259.98px) {
  .styles_table-responsive-xl__lRm0N {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .styles_table-responsive-xl__lRm0N > .styles_table-bordered__W5qJx {
    border: 0;
  }
}

.styles_table-responsive__MW0lN {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.styles_table-responsive__MW0lN > .styles_table-bordered__W5qJx {
  border: 0;
}

.styles_form-control__h8E13 {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.4375rem 0.625rem;
  font-size: var(--lia-bs-input-btn-font-size);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: 1.71429;
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
  background-clip: padding-box;
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-bs-input-border-radius);
  transition: border-color var(--lia-timing-fast) var(--lia-timing-function),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);
}

@media (prefers-reduced-motion: reduce) {
  .styles_form-control__h8E13 {
    transition: none;
  }
}

.styles_form-control__h8E13::-ms-expand {
  background-color: transparent;
  border: 0;
}

.styles_form-control__h8E13:focus {
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
  border-color: var(--lia-bs-input-focus-border-color);
  outline: 0;
  box-shadow: var(--lia-bs-input-focus-box-shadow);
}

.styles_form-control__h8E13::-moz-placeholder {
  color: var(--lia-bs-gray-700);
  opacity: 1;
}

.styles_form-control__h8E13::placeholder {
  color: var(--lia-bs-gray-700);
  opacity: 1;
}

.styles_form-control__h8E13:disabled,
.styles_form-control__h8E13[readonly] {
  background-color: var(--lia-bs-gray-200);
  opacity: 1;
}

input[type='date'].styles_form-control__h8E13,
input[type='time'].styles_form-control__h8E13,
input[type='datetime-local'].styles_form-control__h8E13,
input[type='month'].styles_form-control__h8E13 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

select.styles_form-control__h8E13:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--lia-bs-body-color);
}

select.styles_form-control__h8E13:focus::-ms-value {
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
}

.styles_form-control-file__XNq74,
.styles_form-control-range__T18W4 {
  display: block;
  width: 100%;
}

.styles_col-form-label__CMvTF {
  padding-top: calc(0.4375rem + 0.0625rem);
  padding-bottom: calc(0.4375rem + 0.0625rem);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.71429;
}

.styles_col-form-label-lg__SDduH {
  padding-top: calc(0.5rem + 0.0625rem);
  padding-bottom: calc(0.5rem + 0.0625rem);
  font-size: var(--lia-bs-font-size-lg);
  line-height: 1.8;
}

.styles_col-form-label-sm__EqrAV {
  padding-top: calc(0.25rem + 0.0625rem);
  padding-bottom: calc(0.25rem + 0.0625rem);
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.2;
}

.styles_form-control-plaintext__dmCU4 {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: var(--lia-bs-input-btn-font-size);
  line-height: 1.71429;
  color: var(--lia-bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}

.styles_form-control-plaintext__dmCU4.styles_form-control-sm__9rM42,
.styles_form-control-plaintext__dmCU4.styles_form-control-lg__2exhg {
  padding-right: 0;
  padding-left: 0;
}

.styles_form-control-sm__9rM42 {
  height: calc(1.2em + 0.5rem + 0.125rem);
  padding: 0.25rem 0.5rem;
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.2;
  border-radius: var(--lia-bs-input-border-radius-sm);
}

.styles_form-control-lg__2exhg {
  height: calc(1.8em + 1rem + 0.125rem);
  padding: 0.5rem 1rem;
  font-size: var(--lia-bs-font-size-lg);
  line-height: 1.8;
  border-radius: var(--lia-bs-input-border-radius-lg);
}

select.styles_form-control__h8E13[size],
select.styles_form-control__h8E13[multiple] {
  height: auto;
}

textarea.styles_form-control__h8E13 {
  height: auto;
}

.styles_form-group__SBBeT {
  margin-bottom: 1.25rem;
}

.styles_form-text__IaYLU {
  display: block;
  margin-top: var(--lia-bs-form-text-margin-top);
}

.styles_form-row__S6_bA {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

.styles_form-row__S6_bA > .styles_col__fuduo,
.styles_form-row__S6_bA > [class*='col-'] {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

.styles_form-check__K9Uz9 {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.styles_form-check-input__ACRJO {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.styles_form-check-input__ACRJO[disabled] ~ .styles_form-check-label__6JMbk,
.styles_form-check-input__ACRJO:disabled ~ .styles_form-check-label__6JMbk {
  color: var(--lia-bs-text-muted);
}

.styles_form-check-label__6JMbk {
  margin-bottom: 0;
}

.styles_form-check-inline__PbQ1x {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.styles_form-check-inline__PbQ1x .styles_form-check-input__ACRJO {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.styles_valid-feedback__ZxWb6 {
  display: none;
  width: 100%;
  margin-top: var(--lia-bs-form-text-margin-top);
  font-size: var(--lia-bs-small-font-size);
  color: var(--lia-bs-success);
}

.styles_valid-tooltip__I8Esv {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: var(--lia-bs-font-size-sm);
  line-height: var(--lia-bs-line-height-base);
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.9
  );
  border-radius: var(--lia-bs-border-radius);
}

.styles_form-row__S6_bA > .styles_col__fuduo > .styles_valid-tooltip__I8Esv,
.styles_form-row__S6_bA > [class*='col-'] > .styles_valid-tooltip__I8Esv {
  left: 0.3125rem;
}

.styles_was-validated__KBckY :valid ~ .styles_valid-feedback__ZxWb6,
.styles_was-validated__KBckY :valid ~ .styles_valid-tooltip__I8Esv,
.styles_is-valid__Y2StV ~ .styles_valid-feedback__ZxWb6,
.styles_is-valid__Y2StV ~ .styles_valid-tooltip__I8Esv {
  display: block;
}

.styles_was-validated__KBckY .styles_form-control__h8E13:valid,
.styles_form-control__h8E13.styles_is-valid__Y2StV {
  border-color: var(--lia-bs-success);
  padding-right: calc(1.71429em + 0.875rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='var%28--lia-bs-success%29' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.42857em + 0.21875rem) center;
  background-size: calc(0.85714em + 0.4375rem) calc(0.85714em + 0.4375rem);
}

.styles_was-validated__KBckY .styles_form-control__h8E13:valid:focus,
.styles_form-control__h8E13.styles_is-valid__Y2StV:focus {
  border-color: var(--lia-bs-success);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.25);
}

.styles_was-validated__KBckY select.styles_form-control__h8E13:valid,
select.styles_form-control__h8E13.styles_is-valid__Y2StV {
  padding-right: 2.5rem !important;
  background-position: right 1.25rem center;
}

.styles_was-validated__KBckY textarea.styles_form-control__h8E13:valid,
textarea.styles_form-control__h8E13.styles_is-valid__Y2StV {
  padding-right: calc(1.71429em + 0.875rem);
  background-position: top calc(0.42857em + 0.21875rem) right calc(0.42857em + 0.21875rem);
}

.styles_was-validated__KBckY .styles_custom-select__QYOVz:valid,
.styles_custom-select__QYOVz.styles_is-valid__Y2StV {
  border-color: var(--lia-bs-success);
  padding-right: calc(0.75em + 2.28125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='var%28--lia-bs-gray-800%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.625rem center/8px 10px no-repeat,
    var(--lia-bs-white)
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='var%28--lia-bs-success%29' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      center right 1.625rem / calc(0.85714em + 0.4375rem) calc(0.85714em + 0.4375rem) no-repeat;
}

.styles_was-validated__KBckY .styles_custom-select__QYOVz:valid:focus,
.styles_custom-select__QYOVz.styles_is-valid__Y2StV:focus {
  border-color: var(--lia-bs-success);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.25);
}

.styles_was-validated__KBckY .styles_form-check-input__ACRJO:valid ~ .styles_form-check-label__6JMbk,
.styles_form-check-input__ACRJO.styles_is-valid__Y2StV ~ .styles_form-check-label__6JMbk {
  color: var(--lia-bs-success);
}

.styles_was-validated__KBckY .styles_form-check-input__ACRJO:valid ~ .styles_valid-feedback__ZxWb6,
.styles_was-validated__KBckY .styles_form-check-input__ACRJO:valid ~ .styles_valid-tooltip__I8Esv,
.styles_form-check-input__ACRJO.styles_is-valid__Y2StV ~ .styles_valid-feedback__ZxWb6,
.styles_form-check-input__ACRJO.styles_is-valid__Y2StV ~ .styles_valid-tooltip__I8Esv {
  display: block;
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:valid ~ .styles_custom-control-label__98j17,
.styles_custom-control-input___8VFl.styles_is-valid__Y2StV ~ .styles_custom-control-label__98j17 {
  color: var(--lia-bs-success);
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:valid ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-valid__Y2StV ~ .styles_custom-control-label__98j17::before {
  border-color: var(--lia-bs-success);
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:valid:checked ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-valid__Y2StV:checked ~ .styles_custom-control-label__98j17::before {
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + 10%),
    var(--lia-bs-success-a, 1)
  );
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + 10%),
    var(--lia-bs-success-a, 1)
  );
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:valid:focus ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-valid__Y2StV:focus ~ .styles_custom-control-label__98j17::before {
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.25);
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:valid:focus:not(:checked) ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-valid__Y2StV:focus:not(:checked) ~ .styles_custom-control-label__98j17::before {
  border-color: var(--lia-bs-success);
}

.styles_was-validated__KBckY .styles_custom-file-input__jsy3I:valid ~ .styles_custom-file-label__yrR5T,
.styles_custom-file-input__jsy3I.styles_is-valid__Y2StV ~ .styles_custom-file-label__yrR5T {
  border-color: var(--lia-bs-success);
}

.styles_was-validated__KBckY .styles_custom-file-input__jsy3I:valid:focus ~ .styles_custom-file-label__yrR5T,
.styles_custom-file-input__jsy3I.styles_is-valid__Y2StV:focus ~ .styles_custom-file-label__yrR5T {
  border-color: var(--lia-bs-success);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.25);
}

.styles_invalid-feedback___GAOx {
  display: none;
  width: 100%;
  margin-top: var(--lia-bs-form-text-margin-top);
  font-size: var(--lia-bs-small-font-size);
  color: var(--lia-bs-danger);
}

.styles_invalid-tooltip__mLFfQ {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: var(--lia-bs-font-size-sm);
  line-height: var(--lia-bs-line-height-base);
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    var(--lia-bs-danger-l),
    0.9
  );
  border-radius: var(--lia-bs-border-radius);
}

.styles_form-row__S6_bA > .styles_col__fuduo > .styles_invalid-tooltip__mLFfQ,
.styles_form-row__S6_bA > [class*='col-'] > .styles_invalid-tooltip__mLFfQ {
  left: 0.3125rem;
}

.styles_was-validated__KBckY :invalid ~ .styles_invalid-feedback___GAOx,
.styles_was-validated__KBckY :invalid ~ .styles_invalid-tooltip__mLFfQ,
.styles_is-invalid__03Gbr ~ .styles_invalid-feedback___GAOx,
.styles_is-invalid__03Gbr ~ .styles_invalid-tooltip__mLFfQ {
  display: block;
}

.styles_was-validated__KBckY .styles_form-control__h8E13:invalid,
.styles_form-control__h8E13.styles_is-invalid__03Gbr {
  border-color: var(--lia-bs-danger);
  padding-right: calc(1.71429em + 0.875rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='var%28--lia-bs-danger%29' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='var%28--lia-bs-danger%29' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.42857em + 0.21875rem) center;
  background-size: calc(0.85714em + 0.4375rem) calc(0.85714em + 0.4375rem);
}

.styles_was-validated__KBckY .styles_form-control__h8E13:invalid:focus,
.styles_form-control__h8E13.styles_is-invalid__03Gbr:focus {
  border-color: var(--lia-bs-danger);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.25);
}

.styles_was-validated__KBckY select.styles_form-control__h8E13:invalid,
select.styles_form-control__h8E13.styles_is-invalid__03Gbr {
  padding-right: 2.5rem !important;
  background-position: right 1.25rem center;
}

.styles_was-validated__KBckY textarea.styles_form-control__h8E13:invalid,
textarea.styles_form-control__h8E13.styles_is-invalid__03Gbr {
  padding-right: calc(1.71429em + 0.875rem);
  background-position: top calc(0.42857em + 0.21875rem) right calc(0.42857em + 0.21875rem);
}

.styles_was-validated__KBckY .styles_custom-select__QYOVz:invalid,
.styles_custom-select__QYOVz.styles_is-invalid__03Gbr {
  border-color: var(--lia-bs-danger);
  padding-right: calc(0.75em + 2.28125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='var%28--lia-bs-gray-800%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.625rem center/8px 10px no-repeat,
    var(--lia-bs-white)
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='var%28--lia-bs-danger%29' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='var%28--lia-bs-danger%29' stroke='none'/%3e%3c/svg%3e")
      center right 1.625rem / calc(0.85714em + 0.4375rem) calc(0.85714em + 0.4375rem) no-repeat;
}

.styles_was-validated__KBckY .styles_custom-select__QYOVz:invalid:focus,
.styles_custom-select__QYOVz.styles_is-invalid__03Gbr:focus {
  border-color: var(--lia-bs-danger);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.25);
}

.styles_was-validated__KBckY .styles_form-check-input__ACRJO:invalid ~ .styles_form-check-label__6JMbk,
.styles_form-check-input__ACRJO.styles_is-invalid__03Gbr ~ .styles_form-check-label__6JMbk {
  color: var(--lia-bs-danger);
}

.styles_was-validated__KBckY .styles_form-check-input__ACRJO:invalid ~ .styles_invalid-feedback___GAOx,
.styles_was-validated__KBckY .styles_form-check-input__ACRJO:invalid ~ .styles_invalid-tooltip__mLFfQ,
.styles_form-check-input__ACRJO.styles_is-invalid__03Gbr ~ .styles_invalid-feedback___GAOx,
.styles_form-check-input__ACRJO.styles_is-invalid__03Gbr ~ .styles_invalid-tooltip__mLFfQ {
  display: block;
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:invalid ~ .styles_custom-control-label__98j17,
.styles_custom-control-input___8VFl.styles_is-invalid__03Gbr ~ .styles_custom-control-label__98j17 {
  color: var(--lia-bs-danger);
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:invalid ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-invalid__03Gbr ~ .styles_custom-control-label__98j17::before {
  border-color: var(--lia-bs-danger);
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:invalid:checked ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-invalid__03Gbr:checked ~ .styles_custom-control-label__98j17::before {
  border-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + 10%),
    var(--lia-bs-danger-a, 1)
  );
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + 10%),
    var(--lia-bs-danger-a, 1)
  );
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:invalid:focus ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-invalid__03Gbr:focus ~ .styles_custom-control-label__98j17::before {
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.25);
}

.styles_was-validated__KBckY .styles_custom-control-input___8VFl:invalid:focus:not(:checked) ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl.styles_is-invalid__03Gbr:focus:not(:checked) ~ .styles_custom-control-label__98j17::before {
  border-color: var(--lia-bs-danger);
}

.styles_was-validated__KBckY .styles_custom-file-input__jsy3I:invalid ~ .styles_custom-file-label__yrR5T,
.styles_custom-file-input__jsy3I.styles_is-invalid__03Gbr ~ .styles_custom-file-label__yrR5T {
  border-color: var(--lia-bs-danger);
}

.styles_was-validated__KBckY .styles_custom-file-input__jsy3I:invalid:focus ~ .styles_custom-file-label__yrR5T,
.styles_custom-file-input__jsy3I.styles_is-invalid__03Gbr:focus ~ .styles_custom-file-label__yrR5T {
  border-color: var(--lia-bs-danger);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.25);
}

.styles_form-inline__nNfNd {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.styles_form-inline__nNfNd .styles_form-check__K9Uz9 {
  width: 100%;
}

@media (min-width: 576px) {
  .styles_form-inline__nNfNd label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .styles_form-inline__nNfNd .styles_form-group__SBBeT {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .styles_form-inline__nNfNd .styles_form-control__h8E13 {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .styles_form-inline__nNfNd .styles_form-control-plaintext__dmCU4 {
    display: inline-block;
  }
  .styles_form-inline__nNfNd .styles_input-group__9JmKA,
  .styles_form-inline__nNfNd .styles_custom-select__QYOVz {
    width: auto;
  }
  .styles_form-inline__nNfNd .styles_form-check__K9Uz9 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .styles_form-inline__nNfNd .styles_form-check-input__ACRJO {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .styles_form-inline__nNfNd .styles_custom-control___ybyE {
    align-items: center;
    justify-content: center;
  }
  .styles_form-inline__nNfNd .styles_custom-control-label__98j17 {
    margin-bottom: 0;
  }
}

.styles_btn__1HFm0 {
  display: inline-block;
  font-weight: var(--lia-bs-btn-font-weight);
  color: var(--lia-bs-body-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: var(--lia-bs-btn-padding-y) var(--lia-bs-btn-padding-x);
  font-size: var(--lia-bs-font-size-sm);
  line-height: var(--lia-bs-line-height-base);
  border-radius: var(--lia-bs-btn-border-radius);
  transition: color var(--lia-timing-normal) var(--lia-timing-function),
    background-color var(--lia-timing-normal) var(--lia-timing-function),
    border-color var(--lia-timing-normal) var(--lia-timing-function),
    box-shadow var(--lia-timing-normal) var(--lia-timing-function);
}

@media (prefers-reduced-motion: reduce) {
  .styles_btn__1HFm0 {
    transition: none;
  }
}

.styles_btn__1HFm0:hover {
  color: var(--lia-bs-body-color);
  text-decoration: none;
}

.styles_btn__1HFm0:focus,
.styles_btn__1HFm0.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: var(--lia-bs-input-focus-box-shadow);
}

.styles_btn__1HFm0.styles_disabled__lqbPt,
.styles_btn__1HFm0:disabled {
  opacity: var(--lia-bs-btn-disabled-opacity);
}

.styles_btn__1HFm0:not(:disabled):not(.styles_disabled__lqbPt) {
  cursor: pointer;
}

a.styles_btn__1HFm0.styles_disabled__lqbPt,
fieldset:disabled a.styles_btn__1HFm0 {
  pointer-events: none;
}

.styles_btn-primary__UevYT {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_btn-primary__UevYT:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-primary-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -7.5%),
    var(--lia-bs-primary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -10%),
    var(--lia-bs-primary-a, 1)
  );
}

.styles_btn-primary__UevYT:focus,
.styles_btn-primary__UevYT.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-primary-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -7.5%),
    var(--lia-bs-primary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -10%),
    var(--lia-bs-primary-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-primary-h)) / 2),
      calc((0% + var(--lia-bs-primary-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)) + var(--lia-bs-primary-l)) / 2),
      0.5
    );
}

.styles_btn-primary__UevYT.styles_disabled__lqbPt,
.styles_btn-primary__UevYT:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-primary__UevYT.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-primary-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -10%),
    var(--lia-bs-primary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -12.5%),
    var(--lia-bs-primary-a, 1)
  );
}

.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-primary__UevYT.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-primary-h)) / 2),
      calc((0% + var(--lia-bs-primary-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)) + var(--lia-bs-primary-l)) / 2),
      0.5
    );
}

.styles_btn-secondary__Ust3k {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
  border-color: var(--lia-bs-secondary);
}

.styles_btn-secondary__Ust3k:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-secondary-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -7.5%),
    var(--lia-bs-secondary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -10%),
    var(--lia-bs-secondary-a, 1)
  );
}

.styles_btn-secondary__Ust3k:focus,
.styles_btn-secondary__Ust3k.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-secondary-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -7.5%),
    var(--lia-bs-secondary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -10%),
    var(--lia-bs-secondary-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-secondary-h)) / 2),
      calc((0% + var(--lia-bs-secondary-s)) / 2),
      calc(
        (calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)) + var(--lia-bs-secondary-l)) / 2
      ),
      0.5
    );
}

.styles_btn-secondary__Ust3k.styles_disabled__lqbPt,
.styles_btn-secondary__Ust3k:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
  border-color: var(--lia-bs-secondary);
}

.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-secondary__Ust3k.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-secondary-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -10%),
    var(--lia-bs-secondary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -12.5%),
    var(--lia-bs-secondary-a, 1)
  );
}

.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-secondary__Ust3k.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-secondary-h)) / 2),
      calc((0% + var(--lia-bs-secondary-s)) / 2),
      calc(
        (calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)) + var(--lia-bs-secondary-l)) / 2
      ),
      0.5
    );
}

.styles_btn-success__G9i1k {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
  border-color: var(--lia-bs-success);
}

.styles_btn-success__G9i1k:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-success-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -7.5%),
    var(--lia-bs-success-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -10%),
    var(--lia-bs-success-a, 1)
  );
}

.styles_btn-success__G9i1k:focus,
.styles_btn-success__G9i1k.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-success-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -7.5%),
    var(--lia-bs-success-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -10%),
    var(--lia-bs-success-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-success-h)) / 2),
      calc((0% + var(--lia-bs-success-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)) + var(--lia-bs-success-l)) / 2),
      0.5
    );
}

.styles_btn-success__G9i1k.styles_disabled__lqbPt,
.styles_btn-success__G9i1k:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
  border-color: var(--lia-bs-success);
}

.styles_btn-success__G9i1k:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-success__G9i1k:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-success__G9i1k.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-success-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -10%),
    var(--lia-bs-success-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -12.5%),
    var(--lia-bs-success-a, 1)
  );
}

.styles_btn-success__G9i1k:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-success__G9i1k:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-success__G9i1k.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-success-h)) / 2),
      calc((0% + var(--lia-bs-success-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)) + var(--lia-bs-success-l)) / 2),
      0.5
    );
}

.styles_btn-info__gCL7V {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
  border-color: var(--lia-bs-info);
}

.styles_btn-info__gCL7V:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-info-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -7.5%),
    var(--lia-bs-info-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -10%),
    var(--lia-bs-info-a, 1)
  );
}

.styles_btn-info__gCL7V:focus,
.styles_btn-info__gCL7V.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-info-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -7.5%),
    var(--lia-bs-info-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -10%),
    var(--lia-bs-info-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-info-h)) / 2),
      calc((0% + var(--lia-bs-info-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)) + var(--lia-bs-info-l)) / 2),
      0.5
    );
}

.styles_btn-info__gCL7V.styles_disabled__lqbPt,
.styles_btn-info__gCL7V:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
  border-color: var(--lia-bs-info);
}

.styles_btn-info__gCL7V:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-info__gCL7V:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-info__gCL7V.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-info-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -10%),
    var(--lia-bs-info-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -12.5%),
    var(--lia-bs-info-a, 1)
  );
}

.styles_btn-info__gCL7V:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-info__gCL7V:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-info__gCL7V.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-info-h)) / 2),
      calc((0% + var(--lia-bs-info-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)) + var(--lia-bs-info-l)) / 2),
      0.5
    );
}

.styles_btn-warning__gOSte {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
  border-color: var(--lia-bs-warning);
}

.styles_btn-warning__gOSte:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-warning-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -7.5%),
    var(--lia-bs-warning-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -10%),
    var(--lia-bs-warning-a, 1)
  );
}

.styles_btn-warning__gOSte:focus,
.styles_btn-warning__gOSte.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-warning-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -7.5%),
    var(--lia-bs-warning-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -10%),
    var(--lia-bs-warning-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-warning-h)) / 2),
      calc((0% + var(--lia-bs-warning-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)) + var(--lia-bs-warning-l)) / 2),
      0.5
    );
}

.styles_btn-warning__gOSte.styles_disabled__lqbPt,
.styles_btn-warning__gOSte:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
  border-color: var(--lia-bs-warning);
}

.styles_btn-warning__gOSte:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-warning__gOSte:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-warning__gOSte.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-warning-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -10%),
    var(--lia-bs-warning-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -12.5%),
    var(--lia-bs-warning-a, 1)
  );
}

.styles_btn-warning__gOSte:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-warning__gOSte:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-warning__gOSte.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-warning-h)) / 2),
      calc((0% + var(--lia-bs-warning-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)) + var(--lia-bs-warning-l)) / 2),
      0.5
    );
}

.styles_btn-danger__gUe8d {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
  border-color: var(--lia-bs-danger);
}

.styles_btn-danger__gUe8d:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-danger-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -7.5%),
    var(--lia-bs-danger-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -10%),
    var(--lia-bs-danger-a, 1)
  );
}

.styles_btn-danger__gUe8d:focus,
.styles_btn-danger__gUe8d.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-danger-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -7.5%),
    var(--lia-bs-danger-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -10%),
    var(--lia-bs-danger-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-danger-h)) / 2),
      calc((0% + var(--lia-bs-danger-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)) + var(--lia-bs-danger-l)) / 2),
      0.5
    );
}

.styles_btn-danger__gUe8d.styles_disabled__lqbPt,
.styles_btn-danger__gUe8d:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
  border-color: var(--lia-bs-danger);
}

.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-danger__gUe8d.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-danger-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -10%),
    var(--lia-bs-danger-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -12.5%),
    var(--lia-bs-danger-a, 1)
  );
}

.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-danger__gUe8d.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-danger-h)) / 2),
      calc((0% + var(--lia-bs-danger-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)) + var(--lia-bs-danger-l)) / 2),
      0.5
    );
}

.styles_btn-light__fMj7i {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
  border-color: var(--lia-bs-light);
}

.styles_btn-light__fMj7i:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-light-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -7.5%),
    var(--lia-bs-light-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -10%),
    var(--lia-bs-light-a, 1)
  );
}

.styles_btn-light__fMj7i:focus,
.styles_btn-light__fMj7i.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-light-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -7.5%),
    var(--lia-bs-light-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -10%),
    var(--lia-bs-light-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-light-h)) / 2),
      calc((0% + var(--lia-bs-light-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)) + var(--lia-bs-light-l)) / 2),
      0.5
    );
}

.styles_btn-light__fMj7i.styles_disabled__lqbPt,
.styles_btn-light__fMj7i:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
  border-color: var(--lia-bs-light);
}

.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-light__fMj7i.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-light-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -10%),
    var(--lia-bs-light-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -12.5%),
    var(--lia-bs-light-a, 1)
  );
}

.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-light__fMj7i.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-light-h)) / 2),
      calc((0% + var(--lia-bs-light-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)) + var(--lia-bs-light-l)) / 2),
      0.5
    );
}

.styles_btn-dark__7OhvO {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
  border-color: var(--lia-bs-dark);
}

.styles_btn-dark__7OhvO:hover {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-dark-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -7.5%),
    var(--lia-bs-dark-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -10%),
    var(--lia-bs-dark-a, 1)
  );
}

.styles_btn-dark__7OhvO:focus,
.styles_btn-dark__7OhvO.styles_focus__Ydoo3 {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-dark-l) + -7.5%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -7.5%),
    var(--lia-bs-dark-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -10%),
    var(--lia-bs-dark-a, 1)
  );
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-dark-h)) / 2),
      calc((0% + var(--lia-bs-dark-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)) + var(--lia-bs-dark-l)) / 2),
      0.5
    );
}

.styles_btn-dark__7OhvO.styles_disabled__lqbPt,
.styles_btn-dark__7OhvO:disabled {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
  border-color: var(--lia-bs-dark);
}

.styles_btn-dark__7OhvO:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-dark__7OhvO:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-dark__7OhvO.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(calc(var(--lia-bs-dark-l) + -10%) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -10%),
    var(--lia-bs-dark-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -12.5%),
    var(--lia-bs-dark-a, 1)
  );
}

.styles_btn-dark__7OhvO:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-dark__7OhvO:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-dark__7OhvO.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(
      calc((0 + var(--lia-bs-dark-h)) / 2),
      calc((0% + var(--lia-bs-dark-s)) / 2),
      calc((calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)) + var(--lia-bs-dark-l)) / 2),
      0.5
    );
}

.styles_btn-outline-primary__69puA {
  color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_btn-outline-primary__69puA:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_btn-outline-primary__69puA:focus,
.styles_btn-outline-primary__69puA.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.5);
}

.styles_btn-outline-primary__69puA.styles_disabled__lqbPt,
.styles_btn-outline-primary__69puA:disabled {
  color: var(--lia-bs-primary);
  background-color: transparent;
}

.styles_btn-outline-primary__69puA:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-primary__69puA:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-primary__69puA.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_btn-outline-primary__69puA:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-primary__69puA:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-primary__69puA.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.5);
}

.styles_btn-outline-secondary__sNhOA {
  color: var(--lia-bs-secondary);
  border-color: var(--lia-bs-secondary);
}

.styles_btn-outline-secondary__sNhOA:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
  border-color: var(--lia-bs-secondary);
}

.styles_btn-outline-secondary__sNhOA:focus,
.styles_btn-outline-secondary__sNhOA.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-secondary-h), var(--lia-bs-secondary-s), var(--lia-bs-secondary-l), 0.5);
}

.styles_btn-outline-secondary__sNhOA.styles_disabled__lqbPt,
.styles_btn-outline-secondary__sNhOA:disabled {
  color: var(--lia-bs-secondary);
  background-color: transparent;
}

.styles_btn-outline-secondary__sNhOA:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-secondary__sNhOA:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-secondary__sNhOA.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
  border-color: var(--lia-bs-secondary);
}

.styles_btn-outline-secondary__sNhOA:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-secondary__sNhOA:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-secondary__sNhOA.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-secondary-h), var(--lia-bs-secondary-s), var(--lia-bs-secondary-l), 0.5);
}

.styles_btn-outline-success__6avDe {
  color: var(--lia-bs-success);
  border-color: var(--lia-bs-success);
}

.styles_btn-outline-success__6avDe:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
  border-color: var(--lia-bs-success);
}

.styles_btn-outline-success__6avDe:focus,
.styles_btn-outline-success__6avDe.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.5);
}

.styles_btn-outline-success__6avDe.styles_disabled__lqbPt,
.styles_btn-outline-success__6avDe:disabled {
  color: var(--lia-bs-success);
  background-color: transparent;
}

.styles_btn-outline-success__6avDe:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-success__6avDe:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-success__6avDe.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
  border-color: var(--lia-bs-success);
}

.styles_btn-outline-success__6avDe:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-success__6avDe:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-success__6avDe.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.5);
}

.styles_btn-outline-info__ZLyJj {
  color: var(--lia-bs-info);
  border-color: var(--lia-bs-info);
}

.styles_btn-outline-info__ZLyJj:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
  border-color: var(--lia-bs-info);
}

.styles_btn-outline-info__ZLyJj:focus,
.styles_btn-outline-info__ZLyJj.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.5);
}

.styles_btn-outline-info__ZLyJj.styles_disabled__lqbPt,
.styles_btn-outline-info__ZLyJj:disabled {
  color: var(--lia-bs-info);
  background-color: transparent;
}

.styles_btn-outline-info__ZLyJj:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-info__ZLyJj:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-info__ZLyJj.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
  border-color: var(--lia-bs-info);
}

.styles_btn-outline-info__ZLyJj:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-info__ZLyJj:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-info__ZLyJj.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.5);
}

.styles_btn-outline-warning__i4ekZ {
  color: var(--lia-bs-warning);
  border-color: var(--lia-bs-warning);
}

.styles_btn-outline-warning__i4ekZ:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
  border-color: var(--lia-bs-warning);
}

.styles_btn-outline-warning__i4ekZ:focus,
.styles_btn-outline-warning__i4ekZ.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-warning-h), var(--lia-bs-warning-s), var(--lia-bs-warning-l), 0.5);
}

.styles_btn-outline-warning__i4ekZ.styles_disabled__lqbPt,
.styles_btn-outline-warning__i4ekZ:disabled {
  color: var(--lia-bs-warning);
  background-color: transparent;
}

.styles_btn-outline-warning__i4ekZ:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-warning__i4ekZ:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-warning__i4ekZ.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
  border-color: var(--lia-bs-warning);
}

.styles_btn-outline-warning__i4ekZ:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-warning__i4ekZ:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-warning__i4ekZ.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-warning-h), var(--lia-bs-warning-s), var(--lia-bs-warning-l), 0.5);
}

.styles_btn-outline-danger__vF_c6 {
  color: var(--lia-bs-danger);
  border-color: var(--lia-bs-danger);
}

.styles_btn-outline-danger__vF_c6:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
  border-color: var(--lia-bs-danger);
}

.styles_btn-outline-danger__vF_c6:focus,
.styles_btn-outline-danger__vF_c6.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.5);
}

.styles_btn-outline-danger__vF_c6.styles_disabled__lqbPt,
.styles_btn-outline-danger__vF_c6:disabled {
  color: var(--lia-bs-danger);
  background-color: transparent;
}

.styles_btn-outline-danger__vF_c6:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-danger__vF_c6:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-danger__vF_c6.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
  border-color: var(--lia-bs-danger);
}

.styles_btn-outline-danger__vF_c6:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-danger__vF_c6:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-danger__vF_c6.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.5);
}

.styles_btn-outline-light__7TUYc {
  color: var(--lia-bs-light);
  border-color: var(--lia-bs-light);
}

.styles_btn-outline-light__7TUYc:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
  border-color: var(--lia-bs-light);
}

.styles_btn-outline-light__7TUYc:focus,
.styles_btn-outline-light__7TUYc.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.5);
}

.styles_btn-outline-light__7TUYc.styles_disabled__lqbPt,
.styles_btn-outline-light__7TUYc:disabled {
  color: var(--lia-bs-light);
  background-color: transparent;
}

.styles_btn-outline-light__7TUYc:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-light__7TUYc:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-light__7TUYc.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
  border-color: var(--lia-bs-light);
}

.styles_btn-outline-light__7TUYc:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-light__7TUYc:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-light__7TUYc.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.5);
}

.styles_btn-outline-dark__aq9mM {
  color: var(--lia-bs-dark);
  border-color: var(--lia-bs-dark);
}

.styles_btn-outline-dark__aq9mM:hover {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
  border-color: var(--lia-bs-dark);
}

.styles_btn-outline-dark__aq9mM:focus,
.styles_btn-outline-dark__aq9mM.styles_focus__Ydoo3 {
  box-shadow: 0 0 0 3px hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.5);
}

.styles_btn-outline-dark__aq9mM.styles_disabled__lqbPt,
.styles_btn-outline-dark__aq9mM:disabled {
  color: var(--lia-bs-dark);
  background-color: transparent;
}

.styles_btn-outline-dark__aq9mM:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-outline-dark__aq9mM:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-outline-dark__aq9mM.styles_dropdown-toggle__lyIUd {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
  border-color: var(--lia-bs-dark);
}

.styles_btn-outline-dark__aq9mM:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-outline-dark__aq9mM:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-outline-dark__aq9mM.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: 0 0 0 3px hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.5);
}

.styles_btn-link__Qn59s {
  font-weight: var(--lia-bs-font-weight-normal);
  color: var(--lia-bs-link-color);
  -webkit-text-decoration: var(--lia-bs-link-decoration);
          text-decoration: var(--lia-bs-link-decoration);
}

.styles_btn-link__Qn59s:hover {
  color: var(--lia-bs-link-hover-color);
  -webkit-text-decoration: var(--lia-bs-link-hover-decoration);
          text-decoration: var(--lia-bs-link-hover-decoration);
}

.styles_btn-link__Qn59s:focus,
.styles_btn-link__Qn59s.styles_focus__Ydoo3 {
  -webkit-text-decoration: var(--lia-bs-link-hover-decoration);
          text-decoration: var(--lia-bs-link-hover-decoration);
}

.styles_btn-link__Qn59s:disabled,
.styles_btn-link__Qn59s.styles_disabled__lqbPt {
  color: var(--lia-bs-gray-600);
  pointer-events: none;
}

.styles_btn-lg__MJujA,
.styles_btn-group-lg__xeuJb > .styles_btn__1HFm0 {
  padding: var(--lia-bs-btn-padding-y-lg) var(--lia-bs-btn-padding-x-lg);
  font-size: var(--lia-bs-font-size-base);
  line-height: var(--lia-bs-line-height-base);
  border-radius: var(--lia-bs-btn-border-radius-lg);
}

.styles_btn-sm__Km_GX,
.styles_btn-group-sm__KlFjM > .styles_btn__1HFm0 {
  padding: 0.25rem 0.5rem;
  font-size: var(--lia-font-size-xs);
  line-height: 1.2;
  border-radius: var(--lia-bs-btn-border-radius-sm);
}

.styles_btn-block__mGIfa {
  display: block;
  width: 100%;
}

.styles_btn-block__mGIfa + .styles_btn-block__mGIfa {
  margin-top: 0.5rem;
}

input[type='submit'].styles_btn-block__mGIfa,
input[type='reset'].styles_btn-block__mGIfa,
input[type='button'].styles_btn-block__mGIfa {
  width: 100%;
}

.styles_fade__R026M {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .styles_fade__R026M {
    transition: none;
  }
}

.styles_fade__R026M:not(.styles_show__uuON9) {
  opacity: 0;
}

.styles_collapse__ukvwL:not(.styles_show__uuON9) {
  display: none;
}

.styles_collapsing__srxY0 {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .styles_collapsing__srxY0 {
    transition: none;
  }
}

.styles_dropup__yVkJZ,
.styles_dropright__VsiYI,
.styles_dropdown__gtmlA,
.styles_dropleft__YB64Z {
  position: relative;
}

.styles_dropdown-toggle__lyIUd {
  white-space: nowrap;
}

.styles_dropdown-menu__gMSix {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: var(--lia-bs-dropdown-font-size);
  color: var(--lia-bs-body-color);
  text-align: left;
  list-style: none;
  background-color: var(--lia-bs-white);
  background-clip: padding-box;
  border: 1px solid var(--lia-bs-dropdown-border-color);
  border-radius: var(--lia-bs-dropdown-border-radius);
}

.styles_dropdown-menu-left__A9RPD {
  right: auto;
  left: 0;
}

.styles_dropdown-menu-right__Mt30H {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .styles_dropdown-menu-sm-left__UK8Vt {
    right: auto;
    left: 0;
  }
  .styles_dropdown-menu-sm-right__iTYD4 {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .styles_dropdown-menu-md-left___LimD {
    right: auto;
    left: 0;
  }
  .styles_dropdown-menu-md-right__gfooO {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .styles_dropdown-menu-lg-left__iZbC_ {
    right: auto;
    left: 0;
  }
  .styles_dropdown-menu-lg-right__S1w9D {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1260px) {
  .styles_dropdown-menu-xl-left__zqTj4 {
    right: auto;
    left: 0;
  }
  .styles_dropdown-menu-xl-right__JHz_W {
    right: 0;
    left: auto;
  }
}

.styles_dropup__yVkJZ .styles_dropdown-menu__gMSix {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.styles_dropright__VsiYI .styles_dropdown-menu__gMSix {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.styles_dropright__VsiYI .styles_dropdown-toggle__lyIUd::after {
  vertical-align: 0;
}

.styles_dropleft__YB64Z .styles_dropdown-menu__gMSix {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.styles_dropleft__YB64Z .styles_dropdown-toggle__lyIUd::before {
  vertical-align: 0;
}

.styles_dropdown-menu__gMSix[x-placement^='top'],
.styles_dropdown-menu__gMSix[x-placement^='right'],
.styles_dropdown-menu__gMSix[x-placement^='bottom'],
.styles_dropdown-menu__gMSix[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.styles_dropdown-divider__nF3c9 {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--lia-bs-dropdown-divider-bg);
}

.styles_dropdown-item__s_Yb9 {
  display: block;
  width: 100%;
  padding: var(--lia-bs-dropdown-item-padding-y) var(--lia-bs-dropdown-item-padding-x);
  clear: both;
  font-weight: var(--lia-bs-font-weight-normal);
  color: var(--lia-bs-gray-900);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.styles_dropdown-item__s_Yb9:hover,
.styles_dropdown-item__s_Yb9:focus {
  color: inherit;
  text-decoration: none;
  background-color: var(--lia-bs-gray-200);
}

.styles_dropdown-item__s_Yb9.styles_active__EuuVN,
.styles_dropdown-item__s_Yb9:active {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

.styles_dropdown-item__s_Yb9.styles_disabled__lqbPt,
.styles_dropdown-item__s_Yb9:disabled {
  color: var(--lia-bs-gray-500);
  pointer-events: none;
  background-color: transparent;
}

.styles_dropdown-menu__gMSix.styles_show__uuON9 {
  display: block;
}

.styles_dropdown-header__9Jtdy {
  display: block;
  padding: 0.5rem var(--lia-bs-dropdown-item-padding-x);
  margin-bottom: 0;
  font-size: var(--lia-bs-font-size-sm);
  color: var(--lia-bs-dropdown-header-color);
  white-space: nowrap;
}

.styles_dropdown-item-text__mE96h {
  display: block;
  padding: var(--lia-bs-dropdown-item-padding-y) var(--lia-bs-dropdown-item-padding-x);
  color: var(--lia-bs-gray-900);
}

/* Overridden for button padding */

.styles_btn-group__dKgfL,
.styles_btn-group-vertical__b4_xx {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.styles_btn-group__dKgfL > .styles_btn__1HFm0,
.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0 {
  position: relative;
  flex: 1 1 auto;
}

.styles_btn-group__dKgfL > .styles_btn__1HFm0:hover,
.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0:hover {
  z-index: 1;
}

.styles_btn-group__dKgfL > .styles_btn__1HFm0:focus,
.styles_btn-group__dKgfL > .styles_btn__1HFm0:active,
.styles_btn-group__dKgfL > .styles_btn__1HFm0.styles_active__EuuVN,
.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0:focus,
.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0:active,
.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0.styles_active__EuuVN {
  z-index: 1;
}

.styles_btn-toolbar___jHaS {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.styles_btn-toolbar___jHaS .styles_input-group__9JmKA {
  width: auto;
}

.styles_btn-group__dKgfL > .styles_btn__1HFm0:not(:first-child),
.styles_btn-group__dKgfL > .styles_btn-group__dKgfL:not(:first-child) {
  margin-left: -0.0625rem;
}

.styles_btn-group__dKgfL > .styles_btn__1HFm0:not(:last-child):not(.styles_dropdown-toggle__lyIUd),
.styles_btn-group__dKgfL > .styles_btn-group__dKgfL:not(:last-child) > .styles_btn__1HFm0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_btn-group__dKgfL > .styles_btn__1HFm0:not(:first-child),
.styles_btn-group__dKgfL > .styles_btn-group__dKgfL:not(:first-child) > .styles_btn__1HFm0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.styles_dropdown-toggle-split__9wsCD {
  padding-right: calc(var(--lia-bs-btn-padding-x) * 0.75);
  padding-left: calc(var(--lia-bs-btn-padding-x) * 0.75);
}

.styles_dropdown-toggle-split__9wsCD::after,
.styles_dropup__yVkJZ .styles_dropdown-toggle-split__9wsCD::after,
.styles_dropright__VsiYI .styles_dropdown-toggle-split__9wsCD::after {
  margin-left: 0;
}

.styles_dropleft__YB64Z .styles_dropdown-toggle-split__9wsCD::before {
  margin-right: 0;
}

.styles_btn-sm__Km_GX + .styles_dropdown-toggle-split__9wsCD,
.styles_btn-group-sm__KlFjM > .styles_btn__1HFm0 + .styles_dropdown-toggle-split__9wsCD {
  padding-right: calc(0.5rem * 0.75);
  padding-left: calc(0.5rem * 0.75);
}

.styles_btn-lg__MJujA + .styles_dropdown-toggle-split__9wsCD,
.styles_btn-group-lg__xeuJb > .styles_btn__1HFm0 + .styles_dropdown-toggle-split__9wsCD {
  padding-right: calc(var(--lia-bs-btn-padding-x-lg) * 0.75);
  padding-left: calc(var(--lia-bs-btn-padding-x-lg) * 0.75);
}

.styles_btn-group-vertical__b4_xx {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0,
.styles_btn-group-vertical__b4_xx > .styles_btn-group__dKgfL {
  width: 100%;
}

.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0:not(:first-child),
.styles_btn-group-vertical__b4_xx > .styles_btn-group__dKgfL:not(:first-child) {
  margin-top: -0.0625rem;
}

.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0:not(:last-child):not(.styles_dropdown-toggle__lyIUd),
.styles_btn-group-vertical__b4_xx > .styles_btn-group__dKgfL:not(:last-child) > .styles_btn__1HFm0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.styles_btn-group-vertical__b4_xx > .styles_btn__1HFm0:not(:first-child),
.styles_btn-group-vertical__b4_xx > .styles_btn-group__dKgfL:not(:first-child) > .styles_btn__1HFm0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.styles_btn-group-toggle__y6g7z > .styles_btn__1HFm0,
.styles_btn-group-toggle__y6g7z > .styles_btn-group__dKgfL > .styles_btn__1HFm0 {
  margin-bottom: 0;
}

.styles_btn-group-toggle__y6g7z > .styles_btn__1HFm0 input[type='radio'],
.styles_btn-group-toggle__y6g7z > .styles_btn__1HFm0 input[type='checkbox'],
.styles_btn-group-toggle__y6g7z > .styles_btn-group__dKgfL > .styles_btn__1HFm0 input[type='radio'],
.styles_btn-group-toggle__y6g7z > .styles_btn-group__dKgfL > .styles_btn__1HFm0 input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.styles_input-group__9JmKA {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.styles_input-group__9JmKA > .styles_form-control__h8E13,
.styles_input-group__9JmKA > .styles_form-control-plaintext__dmCU4,
.styles_input-group__9JmKA > .styles_custom-select__QYOVz,
.styles_input-group__9JmKA > .styles_custom-file__sgTM_ {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.styles_input-group__9JmKA > .styles_form-control__h8E13 + .styles_form-control__h8E13,
.styles_input-group__9JmKA > .styles_form-control__h8E13 + .styles_custom-select__QYOVz,
.styles_input-group__9JmKA > .styles_form-control__h8E13 + .styles_custom-file__sgTM_,
.styles_input-group__9JmKA > .styles_form-control-plaintext__dmCU4 + .styles_form-control__h8E13,
.styles_input-group__9JmKA > .styles_form-control-plaintext__dmCU4 + .styles_custom-select__QYOVz,
.styles_input-group__9JmKA > .styles_form-control-plaintext__dmCU4 + .styles_custom-file__sgTM_,
.styles_input-group__9JmKA > .styles_custom-select__QYOVz + .styles_form-control__h8E13,
.styles_input-group__9JmKA > .styles_custom-select__QYOVz + .styles_custom-select__QYOVz,
.styles_input-group__9JmKA > .styles_custom-select__QYOVz + .styles_custom-file__sgTM_,
.styles_input-group__9JmKA > .styles_custom-file__sgTM_ + .styles_form-control__h8E13,
.styles_input-group__9JmKA > .styles_custom-file__sgTM_ + .styles_custom-select__QYOVz,
.styles_input-group__9JmKA > .styles_custom-file__sgTM_ + .styles_custom-file__sgTM_ {
  margin-left: -0.0625rem;
}

.styles_input-group__9JmKA > .styles_form-control__h8E13:focus,
.styles_input-group__9JmKA > .styles_custom-select__QYOVz:focus,
.styles_input-group__9JmKA > .styles_custom-file__sgTM_ .styles_custom-file-input__jsy3I:focus ~ .styles_custom-file-label__yrR5T {
  z-index: 3;
}

.styles_input-group__9JmKA > .styles_custom-file__sgTM_ .styles_custom-file-input__jsy3I:focus {
  z-index: 4;
}

.styles_input-group__9JmKA > .styles_form-control__h8E13:not(:first-child),
.styles_input-group__9JmKA > .styles_custom-select__QYOVz:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.styles_input-group__9JmKA > .styles_custom-file__sgTM_ {
  display: flex;
  align-items: center;
}

.styles_input-group__9JmKA > .styles_custom-file__sgTM_:not(:last-child) .styles_custom-file-label__yrR5T,
.styles_input-group__9JmKA > .styles_custom-file__sgTM_:not(:last-child) .styles_custom-file-label__yrR5T::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_input-group__9JmKA > .styles_custom-file__sgTM_:not(:first-child) .styles_custom-file-label__yrR5T {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.styles_input-group__9JmKA:not(.styles_has-validation__JXR_d) > .styles_form-control__h8E13:not(:last-child),
.styles_input-group__9JmKA:not(.styles_has-validation__JXR_d) > .styles_custom-select__QYOVz:not(:last-child),
.styles_input-group__9JmKA:not(.styles_has-validation__JXR_d) > .styles_custom-file__sgTM_:not(:last-child) .styles_custom-file-label__yrR5T,
.styles_input-group__9JmKA:not(.styles_has-validation__JXR_d) > .styles_custom-file__sgTM_:not(:last-child) .styles_custom-file-label__yrR5T::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_input-group__9JmKA.styles_has-validation__JXR_d > .styles_form-control__h8E13:nth-last-child(n + 3),
.styles_input-group__9JmKA.styles_has-validation__JXR_d > .styles_custom-select__QYOVz:nth-last-child(n + 3),
.styles_input-group__9JmKA.styles_has-validation__JXR_d > .styles_custom-file__sgTM_:nth-last-child(n + 3) .styles_custom-file-label__yrR5T,
.styles_input-group__9JmKA.styles_has-validation__JXR_d > .styles_custom-file__sgTM_:nth-last-child(n + 3) .styles_custom-file-label__yrR5T::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_input-group-prepend__cmSLp,
.styles_input-group-append__bXTqL {
  display: flex;
}

.styles_input-group-prepend__cmSLp .styles_btn__1HFm0,
.styles_input-group-append__bXTqL .styles_btn__1HFm0 {
  position: relative;
  z-index: 2;
}

.styles_input-group-prepend__cmSLp .styles_btn__1HFm0:focus,
.styles_input-group-append__bXTqL .styles_btn__1HFm0:focus {
  z-index: 3;
}

.styles_input-group-prepend__cmSLp .styles_btn__1HFm0 + .styles_btn__1HFm0,
.styles_input-group-prepend__cmSLp .styles_btn__1HFm0 + .styles_input-group-text__wpeNk,
.styles_input-group-prepend__cmSLp .styles_input-group-text__wpeNk + .styles_input-group-text__wpeNk,
.styles_input-group-prepend__cmSLp .styles_input-group-text__wpeNk + .styles_btn__1HFm0,
.styles_input-group-append__bXTqL .styles_btn__1HFm0 + .styles_btn__1HFm0,
.styles_input-group-append__bXTqL .styles_btn__1HFm0 + .styles_input-group-text__wpeNk,
.styles_input-group-append__bXTqL .styles_input-group-text__wpeNk + .styles_input-group-text__wpeNk,
.styles_input-group-append__bXTqL .styles_input-group-text__wpeNk + .styles_btn__1HFm0 {
  margin-left: -0.0625rem;
}

.styles_input-group-prepend__cmSLp {
  margin-right: -0.0625rem;
}

.styles_input-group-append__bXTqL {
  margin-left: -0.0625rem;
}

.styles_input-group-text__wpeNk {
  display: flex;
  align-items: center;
  padding: 0.4375rem 0.625rem;
  margin-bottom: 0;
  font-size: var(--lia-bs-input-btn-font-size);
  font-weight: var(--lia-bs-font-weight-normal);
  line-height: 1.71429;
  color: var(--lia-bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--lia-bs-gray-200);
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-bs-input-border-radius);
}

.styles_input-group-text__wpeNk input[type='radio'],
.styles_input-group-text__wpeNk input[type='checkbox'] {
  margin-top: 0;
}

.styles_input-group-lg__k_Imv > .styles_form-control__h8E13:not(textarea),
.styles_input-group-lg__k_Imv > .styles_custom-select__QYOVz {
  height: calc(1.8em + 1rem + 0.125rem);
}

.styles_input-group-lg__k_Imv > .styles_form-control__h8E13,
.styles_input-group-lg__k_Imv > .styles_custom-select__QYOVz,
.styles_input-group-lg__k_Imv > .styles_input-group-prepend__cmSLp > .styles_input-group-text__wpeNk,
.styles_input-group-lg__k_Imv > .styles_input-group-append__bXTqL > .styles_input-group-text__wpeNk,
.styles_input-group-lg__k_Imv > .styles_input-group-prepend__cmSLp > .styles_btn__1HFm0,
.styles_input-group-lg__k_Imv > .styles_input-group-append__bXTqL > .styles_btn__1HFm0 {
  padding: 0.5rem 1rem;
  font-size: var(--lia-bs-font-size-lg);
  line-height: 1.8;
  border-radius: var(--lia-bs-input-border-radius-lg);
}

.styles_input-group-sm__1uSt5 > .styles_form-control__h8E13:not(textarea),
.styles_input-group-sm__1uSt5 > .styles_custom-select__QYOVz {
  height: calc(1.2em + 0.5rem + 0.125rem);
}

.styles_input-group-sm__1uSt5 > .styles_form-control__h8E13,
.styles_input-group-sm__1uSt5 > .styles_custom-select__QYOVz,
.styles_input-group-sm__1uSt5 > .styles_input-group-prepend__cmSLp > .styles_input-group-text__wpeNk,
.styles_input-group-sm__1uSt5 > .styles_input-group-append__bXTqL > .styles_input-group-text__wpeNk,
.styles_input-group-sm__1uSt5 > .styles_input-group-prepend__cmSLp > .styles_btn__1HFm0,
.styles_input-group-sm__1uSt5 > .styles_input-group-append__bXTqL > .styles_btn__1HFm0 {
  padding: 0.25rem 0.5rem;
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.2;
  border-radius: var(--lia-bs-input-border-radius-sm);
}

.styles_input-group-lg__k_Imv > .styles_custom-select__QYOVz,
.styles_input-group-sm__1uSt5 > .styles_custom-select__QYOVz {
  padding-right: 1.625rem;
}

.styles_input-group__9JmKA > .styles_input-group-prepend__cmSLp > .styles_btn__1HFm0,
.styles_input-group__9JmKA > .styles_input-group-prepend__cmSLp > .styles_input-group-text__wpeNk,
.styles_input-group__9JmKA:not(.styles_has-validation__JXR_d) > .styles_input-group-append__bXTqL:not(:last-child) > .styles_btn__1HFm0,
.styles_input-group__9JmKA:not(.styles_has-validation__JXR_d) > .styles_input-group-append__bXTqL:not(:last-child) > .styles_input-group-text__wpeNk,
.styles_input-group__9JmKA.styles_has-validation__JXR_d > .styles_input-group-append__bXTqL:nth-last-child(n + 3) > .styles_btn__1HFm0,
.styles_input-group__9JmKA.styles_has-validation__JXR_d > .styles_input-group-append__bXTqL:nth-last-child(n + 3) > .styles_input-group-text__wpeNk,
.styles_input-group__9JmKA > .styles_input-group-append__bXTqL:last-child > .styles_btn__1HFm0:not(:last-child):not(.styles_dropdown-toggle__lyIUd),
.styles_input-group__9JmKA > .styles_input-group-append__bXTqL:last-child > .styles_input-group-text__wpeNk:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_input-group__9JmKA > .styles_input-group-append__bXTqL > .styles_btn__1HFm0,
.styles_input-group__9JmKA > .styles_input-group-append__bXTqL > .styles_input-group-text__wpeNk,
.styles_input-group__9JmKA > .styles_input-group-prepend__cmSLp:not(:first-child) > .styles_btn__1HFm0,
.styles_input-group__9JmKA > .styles_input-group-prepend__cmSLp:not(:first-child) > .styles_input-group-text__wpeNk,
.styles_input-group__9JmKA > .styles_input-group-prepend__cmSLp:first-child > .styles_btn__1HFm0:not(:first-child),
.styles_input-group__9JmKA > .styles_input-group-prepend__cmSLp:first-child > .styles_input-group-text__wpeNk:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Overridden to break apart the unscoped and scoped styles */

.styles_custom-control___ybyE {
  position: relative;
  z-index: 1;
  display: block;
  min-height: calc(var(--lia-bs-font-size-base) * var(--lia-bs-line-height-base));
  padding-left: 1.5rem;
}

.styles_custom-control-inline__7ayyk {
  display: inline-flex;
  margin-right: 1rem;
}

.styles_custom-control-input___8VFl {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: calc((var(--lia-bs-font-size-base) * var(--lia-bs-line-height-base) + 1rem) / 2);
  opacity: 0;
}

.styles_custom-control-input___8VFl:checked ~ .styles_custom-control-label__98j17::before {
  color: var(--lia-bs-white);
  border-color: var(--lia-bs-primary);
  background-color: var(--lia-bs-primary);
}

.styles_custom-control-input___8VFl:focus ~ .styles_custom-control-label__98j17::before {
  box-shadow: var(--lia-bs-input-focus-box-shadow);
}

.styles_custom-control-input___8VFl:focus:not(:checked) ~ .styles_custom-control-label__98j17::before {
  border-color: var(--lia-bs-input-focus-border-color);
}

.styles_custom-control-input___8VFl:not(:disabled):active ~ .styles_custom-control-label__98j17::before {
  color: var(--lia-bs-white);
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + 35%),
    var(--lia-bs-primary-a, 1)
  );
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + 35%),
    var(--lia-bs-primary-a, 1)
  );
}

.styles_custom-control-input___8VFl[disabled] ~ .styles_custom-control-label__98j17,
.styles_custom-control-input___8VFl:disabled ~ .styles_custom-control-label__98j17 {
  color: var(--lia-bs-gray-600);
}

.styles_custom-control-input___8VFl[disabled] ~ .styles_custom-control-label__98j17::before,
.styles_custom-control-input___8VFl:disabled ~ .styles_custom-control-label__98j17::before {
  background-color: var(--lia-bs-gray-200);
}

.styles_custom-control-label__98j17 {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.styles_custom-control-label__98j17::before {
  position: absolute;
  top: calc((var(--lia-bs-font-size-base) * calc(var(--lia-bs-line-height-base) - 1)) / 2);
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: var(--lia-bs-white);
  border: var(--lia-bs-gray-500) solid 1px;
}

.styles_custom-control-label__98j17::after {
  position: absolute;
  top: calc((var(--lia-bs-font-size-base) * (var(--lia-bs-line-height-base) - 1rem)) / 2);
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.styles_custom-checkbox__9PIaE .styles_custom-control-label__98j17::before {
  border-radius: var(--lia-bs-border-radius);
}

.styles_custom-checkbox__9PIaE .styles_custom-control-input___8VFl:checked ~ .styles_custom-control-label__98j17::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='var%28--lia-bs-white%29' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.styles_custom-checkbox__9PIaE .styles_custom-control-input___8VFl:indeterminate ~ .styles_custom-control-label__98j17::before {
  border-color: var(--lia-bs-primary);
  background-color: var(--lia-bs-primary);
}

.styles_custom-checkbox__9PIaE .styles_custom-control-input___8VFl:indeterminate ~ .styles_custom-control-label__98j17::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='var%28--lia-bs-white%29' d='M0 2h4'/%3e%3c/svg%3e");
}

.styles_custom-checkbox__9PIaE .styles_custom-control-input___8VFl:disabled:checked ~ .styles_custom-control-label__98j17::before {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_custom-checkbox__9PIaE .styles_custom-control-input___8VFl:disabled:indeterminate ~ .styles_custom-control-label__98j17::before {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_custom-radio__yYqws .styles_custom-control-label__98j17::before {
  border-radius: 50%;
}

.styles_custom-radio__yYqws .styles_custom-control-input___8VFl:checked ~ .styles_custom-control-label__98j17::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--lia-bs-white%29'/%3e%3c/svg%3e");
}

.styles_custom-radio__yYqws .styles_custom-control-input___8VFl:disabled:checked ~ .styles_custom-control-label__98j17::before {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_custom-switch__LRKoq {
  padding-left: 2.25rem;
}

.styles_custom-switch__LRKoq .styles_custom-control-label__98j17::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.styles_custom-switch__LRKoq .styles_custom-control-label__98j17::after {
  top: calc(calc((var(--lia-bs-font-size-base) * var(--lia-bs-line-height-base) - 1rem) / 2) + 0.125rem);
  left: calc(-2.25rem + 0.125rem);
  width: calc(1rem - 0.25rem);
  height: calc(1rem - 0.25rem);
  background-color: var(--lia-bs-gray-500);
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .styles_custom-switch__LRKoq .styles_custom-control-label__98j17::after {
    transition: none;
  }
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:checked ~ .styles_custom-control-label__98j17::after {
  background-color: var(--lia-bs-white);
  transform: translateX(0.75rem);
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:disabled:checked ~ .styles_custom-control-label__98j17::before {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_custom-select__QYOVz {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 0.4375rem 1.625rem 0.4375rem 0.625rem;
  font-size: var(--lia-bs-input-btn-font-size);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: 1.71429;
  color: var(--lia-bs-body-color);
  vertical-align: middle;
  background: var(--lia-bs-white)
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='var%28--lia-bs-gray-800%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.625rem center/8px 10px no-repeat;
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-bs-border-radius);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.styles_custom-select__QYOVz:focus {
  border-color: var(--lia-bs-input-focus-border-color);
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.25);
}

.styles_custom-select__QYOVz:focus::-ms-value {
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
}

.styles_custom-select__QYOVz[multiple],
.styles_custom-select__QYOVz[size]:not([size='1']) {
  height: auto;
  padding-right: 0.625rem;
  background-image: none;
}

.styles_custom-select__QYOVz:disabled {
  color: var(--lia-bs-gray-600);
  background-color: var(--lia-bs-gray-200);
}

.styles_custom-select__QYOVz::-ms-expand {
  display: none;
}

.styles_custom-select__QYOVz:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--lia-bs-body-color);
}

.styles_custom-select-sm__AcgV0 {
  height: calc(1.2em + 0.5rem + 0.125rem);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: var(--lia-bs-font-size-sm);
}

.styles_custom-select-lg__2K2N6 {
  height: calc(1.8em + 1rem + 0.125rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: var(--lia-bs-font-size-lg);
}

.styles_custom-file__sgTM_ {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 0;
}

.styles_custom-file-input__jsy3I {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  margin: 0;
  opacity: 0;
}

.styles_custom-file-input__jsy3I:focus ~ .styles_custom-file-label__yrR5T {
  border-color: var(--lia-bs-input-focus-border-color);
  box-shadow: var(--lia-bs-input-focus-box-shadow);
}

.styles_custom-file-input__jsy3I[disabled] ~ .styles_custom-file-label__yrR5T,
.styles_custom-file-input__jsy3I:disabled ~ .styles_custom-file-label__yrR5T {
  background-color: var(--lia-bs-gray-200);
}

.styles_custom-file-input__jsy3I:lang(en) ~ .styles_custom-file-label__yrR5T::after {
  content: 'Browse';
}

.styles_custom-file-input__jsy3I ~ .styles_custom-file-label__yrR5T[data-browse]::after {
  content: attr(data-browse);
}

.styles_custom-file-label__yrR5T {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 0.4375rem 0.625rem;
  font-weight: var(--lia-bs-font-weight-base);
  line-height: 1.71429;
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-bs-input-border-radius);
}

.styles_custom-file-label__yrR5T::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.71429em + 0.875rem);
  padding: 0.4375rem 0.625rem;
  line-height: 1.71429;
  color: var(--lia-bs-body-color);
  content: 'Browse';
  background-color: var(--lia-bs-gray-200);
  border-left: inherit;
  border-radius: 0 var(--lia-bs-input-border-radius) var(--lia-bs-input-border-radius) 0;
}

.styles_custom-range__N21Hh {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.styles_custom-range__N21Hh:focus {
  outline: none;
}

.styles_custom-range__N21Hh:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px var(--lia-bs-body-bg), var(--lia-bs-input-focus-box-shadow);
}

.styles_custom-range__N21Hh:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px var(--lia-bs-body-bg), var(--lia-bs-input-focus-box-shadow);
}

.styles_custom-range__N21Hh:focus::-ms-thumb {
  box-shadow: 0 0 0 1px var(--lia-bs-body-bg), var(--lia-bs-input-focus-box-shadow);
}

.styles_custom-range__N21Hh::-moz-focus-outer {
  border: 0;
}

.styles_custom-range__N21Hh::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--lia-bs-primary);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .styles_custom-range__N21Hh::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.styles_custom-range__N21Hh::-webkit-slider-thumb:active {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + 35%),
    var(--lia-bs-primary-a, 1)
  );
}

.styles_custom-range__N21Hh::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--lia-bs-gray-300);
  border-color: transparent;
  border-radius: 1rem;
}

.styles_custom-range__N21Hh::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--lia-bs-primary);
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .styles_custom-range__N21Hh::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.styles_custom-range__N21Hh::-moz-range-thumb:active {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + 35%),
    var(--lia-bs-primary-a, 1)
  );
}

.styles_custom-range__N21Hh::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--lia-bs-gray-300);
  border-color: transparent;
  border-radius: 1rem;
}

.styles_custom-range__N21Hh::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: var(--lia-bs-primary);
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .styles_custom-range__N21Hh::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.styles_custom-range__N21Hh::-ms-thumb:active {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + 35%),
    var(--lia-bs-primary-a, 1)
  );
}

.styles_custom-range__N21Hh::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.styles_custom-range__N21Hh::-ms-fill-lower {
  background-color: var(--lia-bs-gray-300);
  border-radius: 1rem;
}

.styles_custom-range__N21Hh::-ms-fill-upper {
  margin-right: 0.9375rem;
  background-color: var(--lia-bs-gray-300);
  border-radius: 1rem;
}

.styles_custom-range__N21Hh:disabled::-webkit-slider-thumb {
  background-color: var(--lia-bs-gray-500);
}

.styles_custom-range__N21Hh:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.styles_custom-range__N21Hh:disabled::-moz-range-thumb {
  background-color: var(--lia-bs-gray-500);
}

.styles_custom-range__N21Hh:disabled::-moz-range-track {
  cursor: default;
}

.styles_custom-range__N21Hh:disabled::-ms-thumb {
  background-color: var(--lia-bs-gray-500);
}

.styles_custom-control-label__98j17::before,
.styles_custom-file-label__yrR5T,
.styles_custom-select__QYOVz {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .styles_custom-control-label__98j17::before,
  .styles_custom-file-label__yrR5T,
  .styles_custom-select__QYOVz {
    transition: none;
  }
}

.styles_nav__ZeLHd {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.styles_nav-link__Mv2ls {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.styles_nav-link__Mv2ls:hover,
.styles_nav-link__Mv2ls:focus {
  text-decoration: none;
}

.styles_nav-link__Mv2ls.styles_disabled__lqbPt {
  color: var(--lia-bs-gray-600);
  pointer-events: none;
  cursor: default;
}

.styles_nav-tabs__CL8SY {
  border-bottom: 1px solid var(--lia-bs-gray-300);
}

.styles_nav-tabs__CL8SY .styles_nav-link__Mv2ls {
  margin-bottom: -0.0625rem;
  border: 1px solid transparent;
  border-top-left-radius: var(--lia-bs-border-radius);
  border-top-right-radius: var(--lia-bs-border-radius);
}

.styles_nav-tabs__CL8SY .styles_nav-link__Mv2ls:hover,
.styles_nav-tabs__CL8SY .styles_nav-link__Mv2ls:focus {
  border-color: var(--lia-bs-gray-200) var(--lia-bs-gray-200) var(--lia-bs-gray-300);
}

.styles_nav-tabs__CL8SY .styles_nav-link__Mv2ls.styles_disabled__lqbPt {
  color: var(--lia-bs-gray-600);
  background-color: transparent;
  border-color: transparent;
}

.styles_nav-tabs__CL8SY .styles_nav-link__Mv2ls.styles_active__EuuVN,
.styles_nav-tabs__CL8SY .styles_nav-item__BJ_DA.styles_show__uuON9 .styles_nav-link__Mv2ls {
  color: var(--lia-bs-gray-700);
  background-color: var(--lia-bs-body-bg);
  border-color: var(--lia-bs-gray-300) var(--lia-bs-gray-300) var(--lia-bs-body-bg);
}

.styles_nav-tabs__CL8SY .styles_dropdown-menu__gMSix {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.styles_nav-pills__h9bWJ .styles_nav-link__Mv2ls {
  border-radius: var(--lia-bs-border-radius);
}

.styles_nav-pills__h9bWJ .styles_nav-link__Mv2ls.styles_active__EuuVN,
.styles_nav-pills__h9bWJ .styles_show__uuON9 > .styles_nav-link__Mv2ls {
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-primary);
}

.styles_nav-fill__2qFus > .styles_nav-link__Mv2ls,
.styles_nav-fill__2qFus .styles_nav-item__BJ_DA {
  flex: 1 1 auto;
  text-align: center;
}

.styles_nav-justified__WQd5X > .styles_nav-link__Mv2ls,
.styles_nav-justified__WQd5X .styles_nav-item__BJ_DA {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.styles_tab-content__sQBLg > .styles_tab-pane__mn84c {
  display: none;
}

.styles_tab-content__sQBLg > .styles_active__EuuVN {
  display: block;
}

.styles_navbar__aRcgS {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.styles_navbar__aRcgS .styles_container__6wTQq,
.styles_navbar__aRcgS .styles_container-fluid__AMlCa,
.styles_navbar__aRcgS .styles_container-sm__4LIGQ,
.styles_navbar__aRcgS .styles_container-md__5Qksv,
.styles_navbar__aRcgS .styles_container-lg__YRzUP,
.styles_navbar__aRcgS .styles_container-xl__iu8bu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.styles_navbar-brand__XazPB {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: var(--lia-bs-font-size-lg);
  line-height: inherit;
  white-space: nowrap;
}

.styles_navbar-brand__XazPB:hover,
.styles_navbar-brand__XazPB:focus {
  text-decoration: none;
}

.styles_navbar-nav__ZTUPi {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
  padding-right: 0;
  padding-left: 0;
}

.styles_navbar-nav__ZTUPi .styles_dropdown-menu__gMSix {
  position: static;
  float: none;
}

.styles_navbar-text__BYIjx {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.styles_navbar-collapse__cBjF_ {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.styles_navbar-toggler__4Zg_1 {
  padding: 0.25rem 0.75rem;
  font-size: var(--lia-bs-font-size-lg);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: var(--lia-bs-btn-border-radius);
}

.styles_navbar-toggler__4Zg_1:hover,
.styles_navbar-toggler__4Zg_1:focus {
  text-decoration: none;
}

.styles_navbar-toggler-icon__5xLEH {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: 50% / 100% 100% no-repeat;
}

.styles_navbar-nav-scroll__qunEr {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .styles_navbar-expand-sm__SK_wZ > .styles_container__6wTQq,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-md__5Qksv,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-lg__YRzUP,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-xl__iu8bu {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .styles_navbar-expand-sm__SK_wZ {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .styles_navbar-expand-sm__SK_wZ .styles_navbar-nav__ZTUPi {
    flex-direction: row;
  }
  .styles_navbar-expand-sm__SK_wZ .styles_navbar-nav__ZTUPi .styles_dropdown-menu__gMSix {
    position: absolute;
  }
  .styles_navbar-expand-sm__SK_wZ .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .styles_navbar-expand-sm__SK_wZ > .styles_container__6wTQq,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-md__5Qksv,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-lg__YRzUP,
  .styles_navbar-expand-sm__SK_wZ > .styles_container-xl__iu8bu {
    flex-wrap: nowrap;
  }
  .styles_navbar-expand-sm__SK_wZ .styles_navbar-nav-scroll__qunEr {
    overflow: visible;
  }
  .styles_navbar-expand-sm__SK_wZ .styles_navbar-collapse__cBjF_ {
    display: flex !important;
    flex-basis: auto;
  }
  .styles_navbar-expand-sm__SK_wZ .styles_navbar-toggler__4Zg_1 {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .styles_navbar-expand-md__v4LX5 > .styles_container__6wTQq,
  .styles_navbar-expand-md__v4LX5 > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-md__v4LX5 > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-md__v4LX5 > .styles_container-md__5Qksv,
  .styles_navbar-expand-md__v4LX5 > .styles_container-lg__YRzUP,
  .styles_navbar-expand-md__v4LX5 > .styles_container-xl__iu8bu {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .styles_navbar-expand-md__v4LX5 {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .styles_navbar-expand-md__v4LX5 .styles_navbar-nav__ZTUPi {
    flex-direction: row;
  }
  .styles_navbar-expand-md__v4LX5 .styles_navbar-nav__ZTUPi .styles_dropdown-menu__gMSix {
    position: absolute;
  }
  .styles_navbar-expand-md__v4LX5 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .styles_navbar-expand-md__v4LX5 > .styles_container__6wTQq,
  .styles_navbar-expand-md__v4LX5 > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-md__v4LX5 > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-md__v4LX5 > .styles_container-md__5Qksv,
  .styles_navbar-expand-md__v4LX5 > .styles_container-lg__YRzUP,
  .styles_navbar-expand-md__v4LX5 > .styles_container-xl__iu8bu {
    flex-wrap: nowrap;
  }
  .styles_navbar-expand-md__v4LX5 .styles_navbar-nav-scroll__qunEr {
    overflow: visible;
  }
  .styles_navbar-expand-md__v4LX5 .styles_navbar-collapse__cBjF_ {
    display: flex !important;
    flex-basis: auto;
  }
  .styles_navbar-expand-md__v4LX5 .styles_navbar-toggler__4Zg_1 {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .styles_navbar-expand-lg__xqlPx > .styles_container__6wTQq,
  .styles_navbar-expand-lg__xqlPx > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-lg__xqlPx > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-lg__xqlPx > .styles_container-md__5Qksv,
  .styles_navbar-expand-lg__xqlPx > .styles_container-lg__YRzUP,
  .styles_navbar-expand-lg__xqlPx > .styles_container-xl__iu8bu {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .styles_navbar-expand-lg__xqlPx {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .styles_navbar-expand-lg__xqlPx .styles_navbar-nav__ZTUPi {
    flex-direction: row;
  }
  .styles_navbar-expand-lg__xqlPx .styles_navbar-nav__ZTUPi .styles_dropdown-menu__gMSix {
    position: absolute;
  }
  .styles_navbar-expand-lg__xqlPx .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .styles_navbar-expand-lg__xqlPx > .styles_container__6wTQq,
  .styles_navbar-expand-lg__xqlPx > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-lg__xqlPx > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-lg__xqlPx > .styles_container-md__5Qksv,
  .styles_navbar-expand-lg__xqlPx > .styles_container-lg__YRzUP,
  .styles_navbar-expand-lg__xqlPx > .styles_container-xl__iu8bu {
    flex-wrap: nowrap;
  }
  .styles_navbar-expand-lg__xqlPx .styles_navbar-nav-scroll__qunEr {
    overflow: visible;
  }
  .styles_navbar-expand-lg__xqlPx .styles_navbar-collapse__cBjF_ {
    display: flex !important;
    flex-basis: auto;
  }
  .styles_navbar-expand-lg__xqlPx .styles_navbar-toggler__4Zg_1 {
    display: none;
  }
}

@media (max-width: 1259.98px) {
  .styles_navbar-expand-xl__v4DMj > .styles_container__6wTQq,
  .styles_navbar-expand-xl__v4DMj > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-xl__v4DMj > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-xl__v4DMj > .styles_container-md__5Qksv,
  .styles_navbar-expand-xl__v4DMj > .styles_container-lg__YRzUP,
  .styles_navbar-expand-xl__v4DMj > .styles_container-xl__iu8bu {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1260px) {
  .styles_navbar-expand-xl__v4DMj {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .styles_navbar-expand-xl__v4DMj .styles_navbar-nav__ZTUPi {
    flex-direction: row;
  }
  .styles_navbar-expand-xl__v4DMj .styles_navbar-nav__ZTUPi .styles_dropdown-menu__gMSix {
    position: absolute;
  }
  .styles_navbar-expand-xl__v4DMj .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .styles_navbar-expand-xl__v4DMj > .styles_container__6wTQq,
  .styles_navbar-expand-xl__v4DMj > .styles_container-fluid__AMlCa,
  .styles_navbar-expand-xl__v4DMj > .styles_container-sm__4LIGQ,
  .styles_navbar-expand-xl__v4DMj > .styles_container-md__5Qksv,
  .styles_navbar-expand-xl__v4DMj > .styles_container-lg__YRzUP,
  .styles_navbar-expand-xl__v4DMj > .styles_container-xl__iu8bu {
    flex-wrap: nowrap;
  }
  .styles_navbar-expand-xl__v4DMj .styles_navbar-nav-scroll__qunEr {
    overflow: visible;
  }
  .styles_navbar-expand-xl__v4DMj .styles_navbar-collapse__cBjF_ {
    display: flex !important;
    flex-basis: auto;
  }
  .styles_navbar-expand-xl__v4DMj .styles_navbar-toggler__4Zg_1 {
    display: none;
  }
}

.styles_navbar-expand__d5TZ1 {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.styles_navbar-expand__d5TZ1 > .styles_container__6wTQq,
.styles_navbar-expand__d5TZ1 > .styles_container-fluid__AMlCa,
.styles_navbar-expand__d5TZ1 > .styles_container-sm__4LIGQ,
.styles_navbar-expand__d5TZ1 > .styles_container-md__5Qksv,
.styles_navbar-expand__d5TZ1 > .styles_container-lg__YRzUP,
.styles_navbar-expand__d5TZ1 > .styles_container-xl__iu8bu {
  padding-right: 0;
  padding-left: 0;
}

.styles_navbar-expand__d5TZ1 .styles_navbar-nav__ZTUPi {
  flex-direction: row;
}

.styles_navbar-expand__d5TZ1 .styles_navbar-nav__ZTUPi .styles_dropdown-menu__gMSix {
  position: absolute;
}

.styles_navbar-expand__d5TZ1 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.styles_navbar-expand__d5TZ1 > .styles_container__6wTQq,
.styles_navbar-expand__d5TZ1 > .styles_container-fluid__AMlCa,
.styles_navbar-expand__d5TZ1 > .styles_container-sm__4LIGQ,
.styles_navbar-expand__d5TZ1 > .styles_container-md__5Qksv,
.styles_navbar-expand__d5TZ1 > .styles_container-lg__YRzUP,
.styles_navbar-expand__d5TZ1 > .styles_container-xl__iu8bu {
  flex-wrap: nowrap;
}

.styles_navbar-expand__d5TZ1 .styles_navbar-nav-scroll__qunEr {
  overflow: visible;
}

.styles_navbar-expand__d5TZ1 .styles_navbar-collapse__cBjF_ {
  display: flex !important;
  flex-basis: auto;
}

.styles_navbar-expand__d5TZ1 .styles_navbar-toggler__4Zg_1 {
  display: none;
}

.styles_navbar-light__Trg86 .styles_navbar-brand__XazPB {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.9);
}

.styles_navbar-light__Trg86 .styles_navbar-brand__XazPB:hover,
.styles_navbar-light__Trg86 .styles_navbar-brand__XazPB:focus {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.9);
}

.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.5);
}

.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls:hover,
.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls:focus {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.7);
}

.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls.styles_disabled__lqbPt {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.3);
}

.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_show__uuON9 > .styles_nav-link__Mv2ls,
.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_active__EuuVN > .styles_nav-link__Mv2ls,
.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls.styles_show__uuON9,
.styles_navbar-light__Trg86 .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls.styles_active__EuuVN {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.9);
}

.styles_navbar-light__Trg86 .styles_navbar-toggler__4Zg_1 {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.5);
  border-color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.1);
}

.styles_navbar-light__Trg86 .styles_navbar-toggler-icon__5xLEH {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='hsla%28var%28--lia-bs-black-h%29, var%28--lia-bs-black-s%29, var%28--lia-bs-black-l%29, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.styles_navbar-light__Trg86 .styles_navbar-text__BYIjx {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.5);
}

.styles_navbar-light__Trg86 .styles_navbar-text__BYIjx a {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.9);
}

.styles_navbar-light__Trg86 .styles_navbar-text__BYIjx a:hover,
.styles_navbar-light__Trg86 .styles_navbar-text__BYIjx a:focus {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.9);
}

.styles_navbar-dark__3CPKa .styles_navbar-brand__XazPB {
  color: var(--lia-bs-white);
}

.styles_navbar-dark__3CPKa .styles_navbar-brand__XazPB:hover,
.styles_navbar-dark__3CPKa .styles_navbar-brand__XazPB:focus {
  color: var(--lia-bs-white);
}

.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls {
  color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.5);
}

.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls:hover,
.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls:focus {
  color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.75);
}

.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls.styles_disabled__lqbPt {
  color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.25);
}

.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_show__uuON9 > .styles_nav-link__Mv2ls,
.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_active__EuuVN > .styles_nav-link__Mv2ls,
.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls.styles_show__uuON9,
.styles_navbar-dark__3CPKa .styles_navbar-nav__ZTUPi .styles_nav-link__Mv2ls.styles_active__EuuVN {
  color: var(--lia-bs-white);
}

.styles_navbar-dark__3CPKa .styles_navbar-toggler__4Zg_1 {
  color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.5);
  border-color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.1);
}

.styles_navbar-dark__3CPKa .styles_navbar-toggler-icon__5xLEH {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='hsla%28var%28--lia-bs-white-h%29, var%28--lia-bs-white-s%29, var%28--lia-bs-white-l%29, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.styles_navbar-dark__3CPKa .styles_navbar-text__BYIjx {
  color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.5);
}

.styles_navbar-dark__3CPKa .styles_navbar-text__BYIjx a {
  color: var(--lia-bs-white);
}

.styles_navbar-dark__3CPKa .styles_navbar-text__BYIjx a:hover,
.styles_navbar-dark__3CPKa .styles_navbar-text__BYIjx a:focus {
  color: var(--lia-bs-white);
}

.styles_card__fKF_e {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--lia-bs-white);
  background-clip: border-box;
  border: 1px solid hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.125);
  border-radius: var(--lia-bs-border-radius);
}

.styles_card__fKF_e > hr {
  margin-right: 0;
  margin-left: 0;
}

.styles_card__fKF_e > .styles_list-group__JHN_Z {
  border-top: inherit;
  border-bottom: inherit;
}

.styles_card__fKF_e > .styles_list-group__JHN_Z:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(var(--lia-bs-border-radius) - 1px);
  border-top-right-radius: calc(var(--lia-bs-border-radius) - 1px);
}

.styles_card__fKF_e > .styles_list-group__JHN_Z:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(var(--lia-bs-border-radius) - 1px);
  border-bottom-left-radius: calc(var(--lia-bs-border-radius) - 1px);
}

.styles_card__fKF_e > .styles_card-header__wr_Y9 + .styles_list-group__JHN_Z,
.styles_card__fKF_e > .styles_list-group__JHN_Z + .styles_card-footer__Qc_c0 {
  border-top: 0;
}

.styles_card-body__CrO4U {
  flex: 1 1 auto;
  min-height: 0.0625rem;
  padding: 1.25rem;
}

.styles_card-title__VTb_W {
  margin-bottom: 0.75rem;
}

.styles_card-subtitle__kYurq {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.styles_card-text__okvCu:last-child {
  margin-bottom: 0;
}

.styles_card-link__7p978:hover {
  text-decoration: none;
}

.styles_card-link__7p978 + .styles_card-link__7p978 {
  margin-left: 1.25rem;
}

.styles_card-header__wr_Y9 {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.03);
  border-bottom: 1px solid
    hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.125);
}

.styles_card-header__wr_Y9:first-child {
  border-radius: calc(var(--lia-bs-border-radius) - 0.0625rem) calc(var(--lia-bs-border-radius) - 0.0625rem) 0 0;
}

.styles_card-footer__Qc_c0 {
  padding: 0.75rem 1.25rem;
  background-color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.03);
  border-top: 1px solid
    hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.125);
}

.styles_card-footer__Qc_c0:last-child {
  border-radius: 0 0 calc(var(--lia-bs-border-radius) - 0.0625rem) calc(var(--lia-bs-border-radius) - 0.0625rem);
}

.styles_card-header-tabs__OtGUl {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.styles_card-header-pills__z1iqp {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.styles_card-img-overlay__NbZ_D {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(var(--lia-bs-border-radius) - 0.0625rem);
}

.styles_card-img__mVK8G,
.styles_card-img-top__B1BEx,
.styles_card-img-bottom__gIW25 {
  flex-shrink: 0;
  width: 100%;
}

.styles_card-img__mVK8G,
.styles_card-img-top__B1BEx {
  border-top-left-radius: calc(var(--lia-bs-border-radius) - 1px);
  border-top-right-radius: calc(var(--lia-bs-border-radius) - 1px);
}

.styles_card-img__mVK8G,
.styles_card-img-bottom__gIW25 {
  border-bottom-right-radius: calc(var(--lia-bs-border-radius) - 1px);
  border-bottom-left-radius: calc(var(--lia-bs-border-radius) - 1px);
}

.styles_card-deck__PrFEB .styles_card__fKF_e {
  margin-bottom: calc(var(--lia-bs-grid-gutter-width) / 2);
}

@media (min-width: 576px) {
  .styles_card-deck__PrFEB {
    display: flex;
    flex-flow: row wrap;
    margin-right: -calc(var(--lia-bs-grid-gutter-width) / 2);
    margin-left: -calc(var(--lia-bs-grid-gutter-width) / 2);
  }
  .styles_card-deck__PrFEB .styles_card__fKF_e {
    flex: 1 0 0%;
    margin-right: calc(var(--lia-bs-grid-gutter-width) / 2);
    margin-bottom: 0;
    margin-left: calc(var(--lia-bs-grid-gutter-width) / 2);
  }
}

.styles_card-group__UB0Xm > .styles_card__fKF_e {
  margin-bottom: calc(var(--lia-bs-grid-gutter-width) / 2);
}

@media (min-width: 576px) {
  .styles_card-group__UB0Xm {
    display: flex;
    flex-flow: row wrap;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e + .styles_card__fKF_e {
    margin-left: 0;
    border-left: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:last-child) .styles_card-img-top__B1BEx,
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:last-child) .styles_card-header__wr_Y9 {
    border-top-right-radius: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:last-child) .styles_card-img-bottom__gIW25,
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:last-child) .styles_card-footer__Qc_c0 {
    border-bottom-right-radius: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:first-child) .styles_card-img-top__B1BEx,
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:first-child) .styles_card-header__wr_Y9 {
    border-top-left-radius: 0;
  }
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:first-child) .styles_card-img-bottom__gIW25,
  .styles_card-group__UB0Xm > .styles_card__fKF_e:not(:first-child) .styles_card-footer__Qc_c0 {
    border-bottom-left-radius: 0;
  }
}

.styles_card-columns__uBIdY .styles_card__fKF_e {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .styles_card-columns__uBIdY {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .styles_card-columns__uBIdY .styles_card__fKF_e {
    display: inline-block;
    width: 100%;
  }
}

.styles_accordion__bd6xH {
  overflow-anchor: none;
}

.styles_accordion__bd6xH > .styles_card__fKF_e {
  overflow: hidden;
}

.styles_accordion__bd6xH > .styles_card__fKF_e:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.styles_accordion__bd6xH > .styles_card__fKF_e:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.styles_accordion__bd6xH > .styles_card__fKF_e > .styles_card-header__wr_Y9 {
  border-radius: 0;
  margin-bottom: -0.0625rem;
}

.styles_breadcrumb__N1B2L {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: var(--lia-bs-gray-200);
  border-radius: var(--lia-bs-border-radius);
}

.styles_breadcrumb-item__51iW_ + .styles_breadcrumb-item__51iW_ {
  padding-left: 0.5rem;
}

.styles_breadcrumb-item__51iW_ + .styles_breadcrumb-item__51iW_::before {
  float: left;
  padding-right: 0.5rem;
  color: var(--lia-bs-gray-600);
  content: '/';
}

.styles_breadcrumb-item__51iW_ + .styles_breadcrumb-item__51iW_:hover::before {
  text-decoration: underline;
}

.styles_breadcrumb-item__51iW_ + .styles_breadcrumb-item__51iW_:hover::before {
  text-decoration: none;
}

.styles_breadcrumb-item__51iW_.styles_active__EuuVN {
  color: var(--lia-bs-gray-600);
}

.styles_pagination__zfkl1 {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: var(--lia-bs-border-radius);
}

.styles_page-link__wCtCZ {
  position: relative;
  display: block;
  padding: 0.6875rem 1rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: var(--lia-bs-body-color);
  text-decoration: none;
  background-color: var(--lia-bs-white);
  border: 1px solid var(--lia-bs-gray-300);
}

.styles_page-link__wCtCZ:hover {
  z-index: 2;
  color: var(--lia-bs-link-hover-color);
  text-decoration: none;
  background-color: var(--lia-bs-gray-300);
  border-color: var(--lia-bs-gray-300);
}

.styles_page-link__wCtCZ:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--lia-bs-input-focus-box-shadow);
}

.styles_page-item__rQMne:first-child .styles_page-link__wCtCZ {
  margin-left: 0;
  border-top-left-radius: var(--lia-bs-border-radius);
  border-bottom-left-radius: var(--lia-bs-border-radius);
}

.styles_page-item__rQMne:last-child .styles_page-link__wCtCZ {
  border-top-right-radius: var(--lia-bs-border-radius);
  border-bottom-right-radius: var(--lia-bs-border-radius);
}

.styles_page-item__rQMne.styles_active__EuuVN .styles_page-link__wCtCZ {
  z-index: 3;
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_page-item__rQMne.styles_disabled__lqbPt .styles_page-link__wCtCZ {
  color: var(--lia-bs-gray-600);
  pointer-events: none;
  cursor: auto;
  background-color: var(--lia-bs-white);
  border-color: var(--lia-bs-gray-300);
}

.styles_pagination-lg__mR2js .styles_page-link__wCtCZ {
  padding: 0.75rem 1.5rem;
  font-size: var(--lia-bs-font-size-lg);
  line-height: 1.8;
}

.styles_pagination-lg__mR2js .styles_page-item__rQMne:first-child .styles_page-link__wCtCZ {
  border-top-left-radius: var(--lia-bs-border-radius-lg);
  border-bottom-left-radius: var(--lia-bs-border-radius-lg);
}

.styles_pagination-lg__mR2js .styles_page-item__rQMne:last-child .styles_page-link__wCtCZ {
  border-top-right-radius: var(--lia-bs-border-radius-lg);
  border-bottom-right-radius: var(--lia-bs-border-radius-lg);
}

.styles_pagination-sm__5ryfR .styles_page-link__wCtCZ {
  padding: 0.25rem 0.5rem;
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.2;
}

.styles_pagination-sm__5ryfR .styles_page-item__rQMne:first-child .styles_page-link__wCtCZ {
  border-top-left-radius: var(--lia-bs-border-radius-sm);
  border-bottom-left-radius: var(--lia-bs-border-radius-sm);
}

.styles_pagination-sm__5ryfR .styles_page-item__rQMne:last-child .styles_page-link__wCtCZ {
  border-top-right-radius: var(--lia-bs-border-radius-sm);
  border-bottom-right-radius: var(--lia-bs-border-radius-sm);
}

.styles_badge__ReL9K {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: var(--lia-bs-font-weight-bold);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--lia-bs-border-radius);
  transition: color var(--lia-timing-normal) var(--lia-timing-function),
    background-color var(--lia-timing-normal) var(--lia-timing-function),
    border-color var(--lia-timing-normal) var(--lia-timing-function),
    box-shadow var(--lia-timing-normal) var(--lia-timing-function);
}

@media (prefers-reduced-motion: reduce) {
  .styles_badge__ReL9K {
    transition: none;
  }
}

a.styles_badge__ReL9K:hover,
a.styles_badge__ReL9K:focus {
  text-decoration: none;
}

.styles_badge__ReL9K:empty {
  display: none;
}

.styles_btn__1HFm0 .styles_badge__ReL9K {
  position: relative;
  top: -0.0625rem;
}

.styles_badge-pill__YFx73 {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.styles_badge-primary__BbMLt {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
}

a.styles_badge-primary__BbMLt:hover,
a.styles_badge-primary__BbMLt:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -10%),
    var(--lia-bs-primary-a, 1)
  );
}

a.styles_badge-primary__BbMLt:focus,
a.styles_badge-primary__BbMLt.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.5);
}

.styles_badge-secondary__dItKg {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
}

a.styles_badge-secondary__dItKg:hover,
a.styles_badge-secondary__dItKg:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -10%),
    var(--lia-bs-secondary-a, 1)
  );
}

a.styles_badge-secondary__dItKg:focus,
a.styles_badge-secondary__dItKg.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-secondary-h), var(--lia-bs-secondary-s), var(--lia-bs-secondary-l), 0.5);
}

.styles_badge-success__i2XZW {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
}

a.styles_badge-success__i2XZW:hover,
a.styles_badge-success__i2XZW:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -10%),
    var(--lia-bs-success-a, 1)
  );
}

a.styles_badge-success__i2XZW:focus,
a.styles_badge-success__i2XZW.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-success-h), var(--lia-bs-success-s), var(--lia-bs-success-l), 0.5);
}

.styles_badge-info__OwEOX {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
}

a.styles_badge-info__OwEOX:hover,
a.styles_badge-info__OwEOX:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -10%),
    var(--lia-bs-info-a, 1)
  );
}

a.styles_badge-info__OwEOX:focus,
a.styles_badge-info__OwEOX.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.5);
}

.styles_badge-warning__0eIOQ {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
}

a.styles_badge-warning__0eIOQ:hover,
a.styles_badge-warning__0eIOQ:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -10%),
    var(--lia-bs-warning-a, 1)
  );
}

a.styles_badge-warning__0eIOQ:focus,
a.styles_badge-warning__0eIOQ.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-warning-h), var(--lia-bs-warning-s), var(--lia-bs-warning-l), 0.5);
}

.styles_badge-danger__3xNTr {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
}

a.styles_badge-danger__3xNTr:hover,
a.styles_badge-danger__3xNTr:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -10%),
    var(--lia-bs-danger-a, 1)
  );
}

a.styles_badge-danger__3xNTr:focus,
a.styles_badge-danger__3xNTr.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.5);
}

.styles_badge-light__iVJI5 {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
}

a.styles_badge-light__iVJI5:hover,
a.styles_badge-light__iVJI5:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -10%),
    var(--lia-bs-light-a, 1)
  );
}

a.styles_badge-light__iVJI5:focus,
a.styles_badge-light__iVJI5.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.5);
}

.styles_badge-dark__Eq78d {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
}

a.styles_badge-dark__Eq78d:hover,
a.styles_badge-dark__Eq78d:focus {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -10%),
    var(--lia-bs-dark-a, 1)
  );
}

a.styles_badge-dark__Eq78d:focus,
a.styles_badge-dark__Eq78d.styles_focus__Ydoo3 {
  outline: 0;
  box-shadow: 0 0 0 0.2rem
    hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.5);
}

.styles_jumbotron__OlyhR {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: var(--lia-bs-gray-200);
  border-radius: var(--lia-bs-border-radius-lg);
}

@media (min-width: 576px) {
  .styles_jumbotron__OlyhR {
    padding: 4rem 2rem;
  }
}

.styles_jumbotron-fluid__Fm6Vp {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

/* Overridden for color functions */

.styles_alert__uJYHn {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: var(--lia-bs-border-radius);
}

.styles_alert-heading__thiME {
  color: inherit;
}

.styles_alert-link__v7z6Z {
  font-weight: var(--lia-bs-font-weight-bold);
}

.styles_alert-dismissible__1TP70 {
  padding-right: calc(var(--lia-bs-font-size-base) * 1.5) 2.5rem;
}

.styles_alert-dismissible__1TP70 .styles_close__bRXON {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.styles_alert-primary__iqXlN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_alert-primary__iqXlN hr {
  border-top-color: hsl(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) - var(--lia-bs-primary-mod))
  );
}

.styles_alert-primary__iqXlN .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-secondary__3fOS_ {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
  border-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.5
  );
}

.styles_alert-secondary__3fOS_ hr {
  border-top-color: hsl(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) - var(--lia-bs-secondary-mod))
  );
}

.styles_alert-secondary__3fOS_ .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-success__C27Xk {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.5
  );
}

.styles_alert-success__C27Xk hr {
  border-top-color: hsl(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) - var(--lia-bs-success-mod))
  );
}

.styles_alert-success__C27Xk .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-info__o_EEG {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
  border-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.5);
}

.styles_alert-info__o_EEG hr {
  border-top-color: hsl(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) - var(--lia-bs-info-mod))
  );
}

.styles_alert-info__o_EEG .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-warning__rlk_a {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
  border-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.5
  );
}

.styles_alert-warning__rlk_a hr {
  border-top-color: hsl(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) - var(--lia-bs-warning-mod))
  );
}

.styles_alert-warning__rlk_a .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-danger__W_yaM {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
  border-color: hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.5);
}

.styles_alert-danger__W_yaM hr {
  border-top-color: hsl(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) - var(--lia-bs-danger-mod))
  );
}

.styles_alert-danger__W_yaM .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-light__MNVhJ {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
  border-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.5);
}

.styles_alert-light__MNVhJ hr {
  border-top-color: hsl(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) - var(--lia-bs-light-mod))
  );
}

.styles_alert-light__MNVhJ .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

.styles_alert-dark__lR8ZQ {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
  border-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.5);
}

.styles_alert-dark__lR8ZQ hr {
  border-top-color: hsl(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) - var(--lia-bs-dark-mod))
  );
}

.styles_alert-dark__lR8ZQ .styles_alert-link__v7z6Z {
  text-decoration: underline;
}

@keyframes styles_progress-bar-stripes__ACTqj {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.styles_progress__Iqomt {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: calc(var(--lia-bs-font-size-base) * 0.75);
  background-color: var(--lia-bs-gray-200);
  border-radius: var(--lia-bs-border-radius);
}

.styles_progress-bar__xesUX {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--lia-bs-white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--lia-bs-primary);
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .styles_progress-bar__xesUX {
    transition: none;
  }
}

.styles_progress-bar-striped__Vl4V9 {
  background-image: linear-gradient(
    45deg,
    hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.15) 25%,
    transparent 25%,
    transparent 50%,
    hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.15) 50%,
    hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.styles_progress-bar-animated__FQyso {
  animation: styles_progress-bar-stripes__ACTqj 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .styles_progress-bar-animated__FQyso {
    animation: none;
  }
}

.styles_media__F3tVQ {
  display: flex;
  align-items: flex-start;
}

.styles_media-body__HpglW {
  flex: 1;
}

/* Overridden for color functions */

.styles_list-group__JHN_Z {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--lia-bs-border-radius);
}

.styles_list-group-item-action__w9Cm_ {
  width: 100%;
  color: var(--lia-bs-gray-700);
  text-align: inherit;
}

.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-action__w9Cm_:focus {
  z-index: 1;
  color: var(--lia-bs-gray-700);
  text-decoration: none;
  background-color: var(--lia-bs-gray-100);
}

.styles_list-group-item-action__w9Cm_:active {
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-gray-200);
}

.styles_list-group-item__c_btC {
  position: relative;
  display: block;
  padding: var(--lia-bs-list-group-item-padding-y) var(--lia-bs-list-group-item-padding-x);
  text-decoration: none;
  background-color: var(--lia-bs-white);
  border: 1px solid var(--lia-bs-list-group-border-color);
}

.styles_list-group-item__c_btC:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.styles_list-group-item__c_btC:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.styles_list-group-item__c_btC.styles_disabled__lqbPt,
.styles_list-group-item__c_btC:disabled {
  color: var(--lia-bs-gray-600);
  pointer-events: none;
  background-color: var(--lia-bs-white);
}

.styles_list-group-item__c_btC.styles_active__EuuVN {
  z-index: 2;
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-primary);
  border-color: var(--lia-bs-primary);
}

.styles_list-group-item__c_btC + .styles_list-group-item__c_btC {
  border-top-width: 0;
}

.styles_list-group-item__c_btC + .styles_list-group-item__c_btC.styles_active__EuuVN {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}

.styles_list-group-horizontal__DRDoH {
  flex-direction: row;
}

.styles_list-group-horizontal__DRDoH > .styles_list-group-item__c_btC:first-child {
  border-bottom-left-radius: var(--lia-bs-border-radius);
  border-top-right-radius: 0;
}

.styles_list-group-horizontal__DRDoH > .styles_list-group-item__c_btC:last-child {
  border-top-right-radius: var(--lia-bs-border-radius);
  border-bottom-left-radius: 0;
}

.styles_list-group-horizontal__DRDoH > .styles_list-group-item__c_btC.styles_active__EuuVN {
  margin-top: 0;
}

.styles_list-group-horizontal__DRDoH > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC {
  border-top-width: 0.0625rem;
  border-left-width: 0;
}

.styles_list-group-horizontal__DRDoH > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC.styles_active__EuuVN {
  margin-left: -0.0625rem;
  border-left-width: 0.0625rem;
}

@media (min-width: 576px) {
  .styles_list-group-horizontal-sm__Pk7YU {
    flex-direction: row;
  }
  .styles_list-group-horizontal-sm__Pk7YU > .styles_list-group-item__c_btC:first-child {
    border-bottom-left-radius: var(--lia-bs-border-radius);
    border-top-right-radius: 0;
  }
  .styles_list-group-horizontal-sm__Pk7YU > .styles_list-group-item__c_btC:last-child {
    border-top-right-radius: var(--lia-bs-border-radius);
    border-bottom-left-radius: 0;
  }
  .styles_list-group-horizontal-sm__Pk7YU > .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-top: 0;
  }
  .styles_list-group-horizontal-sm__Pk7YU > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .styles_list-group-horizontal-sm__Pk7YU > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 768px) {
  .styles_list-group-horizontal-md__b7Sii {
    flex-direction: row;
  }
  .styles_list-group-horizontal-md__b7Sii > .styles_list-group-item__c_btC:first-child {
    border-bottom-left-radius: var(--lia-bs-border-radius);
    border-top-right-radius: 0;
  }
  .styles_list-group-horizontal-md__b7Sii > .styles_list-group-item__c_btC:last-child {
    border-top-right-radius: var(--lia-bs-border-radius);
    border-bottom-left-radius: 0;
  }
  .styles_list-group-horizontal-md__b7Sii > .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-top: 0;
  }
  .styles_list-group-horizontal-md__b7Sii > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .styles_list-group-horizontal-md__b7Sii > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 992px) {
  .styles_list-group-horizontal-lg__uVI_Z {
    flex-direction: row;
  }
  .styles_list-group-horizontal-lg__uVI_Z > .styles_list-group-item__c_btC:first-child {
    border-bottom-left-radius: var(--lia-bs-border-radius);
    border-top-right-radius: 0;
  }
  .styles_list-group-horizontal-lg__uVI_Z > .styles_list-group-item__c_btC:last-child {
    border-top-right-radius: var(--lia-bs-border-radius);
    border-bottom-left-radius: 0;
  }
  .styles_list-group-horizontal-lg__uVI_Z > .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-top: 0;
  }
  .styles_list-group-horizontal-lg__uVI_Z > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .styles_list-group-horizontal-lg__uVI_Z > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1260px) {
  .styles_list-group-horizontal-xl__l6V8H {
    flex-direction: row;
  }
  .styles_list-group-horizontal-xl__l6V8H > .styles_list-group-item__c_btC:first-child {
    border-bottom-left-radius: var(--lia-bs-border-radius);
    border-top-right-radius: 0;
  }
  .styles_list-group-horizontal-xl__l6V8H > .styles_list-group-item__c_btC:last-child {
    border-top-right-radius: var(--lia-bs-border-radius);
    border-bottom-left-radius: 0;
  }
  .styles_list-group-horizontal-xl__l6V8H > .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-top: 0;
  }
  .styles_list-group-horizontal-xl__l6V8H > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .styles_list-group-horizontal-xl__l6V8H > .styles_list-group-item__c_btC + .styles_list-group-item__c_btC.styles_active__EuuVN {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

.styles_list-group-flush__FCocl {
  border-radius: 0;
}

.styles_list-group-flush__FCocl > .styles_list-group-item__c_btC {
  border-width: 0 0 0.0625rem;
}

.styles_list-group-flush__FCocl > .styles_list-group-item__c_btC:last-child {
  border-bottom-width: 0;
}

.styles_list-group-item-primary__60bEB {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-primary);
}

.styles_list-group-item-primary__60bEB.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-primary__60bEB.styles_list-group-item-action__w9Cm_:focus {
  color: 'primary';
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.7
  );
}

.styles_list-group-item-primary__60bEB.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-primary-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.85
  );
  border-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    var(--lia-bs-primary-l),
    0.5
  );
}

.styles_list-group-item-secondary__Y5y_U {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-secondary);
}

.styles_list-group-item-secondary__Y5y_U.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-secondary__Y5y_U.styles_list-group-item-action__w9Cm_:focus {
  color: 'secondary';
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.7
  );
}

.styles_list-group-item-secondary__Y5y_U.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-secondary-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.85
  );
  border-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    var(--lia-bs-secondary-l),
    0.5
  );
}

.styles_list-group-item-success__FDLSA {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-success);
}

.styles_list-group-item-success__FDLSA.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-success__FDLSA.styles_list-group-item-action__w9Cm_:focus {
  color: 'success';
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.7
  );
}

.styles_list-group-item-success__FDLSA.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-success-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.85
  );
  border-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    var(--lia-bs-success-l),
    0.5
  );
}

.styles_list-group-item-info__kqa9x {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-info);
}

.styles_list-group-item-info__kqa9x.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-info__kqa9x.styles_list-group-item-action__w9Cm_:focus {
  color: 'info';
  background-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.7);
}

.styles_list-group-item-info__kqa9x.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-info-l) - 58.59375%)), 1);
  background-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.85);
  border-color: hsla(var(--lia-bs-info-h), var(--lia-bs-info-s), var(--lia-bs-info-l), 0.5);
}

.styles_list-group-item-warning__v9D4v {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-warning);
}

.styles_list-group-item-warning__v9D4v.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-warning__v9D4v.styles_list-group-item-action__w9Cm_:focus {
  color: 'warning';
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.7
  );
}

.styles_list-group-item-warning__v9D4v.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-warning-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.85
  );
  border-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    var(--lia-bs-warning-l),
    0.5
  );
}

.styles_list-group-item-danger__Eh3KW {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-danger);
}

.styles_list-group-item-danger__Eh3KW.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-danger__Eh3KW.styles_list-group-item-action__w9Cm_:focus {
  color: 'danger';
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    var(--lia-bs-danger-l),
    0.7
  );
}

.styles_list-group-item-danger__Eh3KW.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-danger-l) - 58.59375%)), 1);
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    var(--lia-bs-danger-l),
    0.85
  );
  border-color: hsla(var(--lia-bs-danger-h), var(--lia-bs-danger-s), var(--lia-bs-danger-l), 0.5);
}

.styles_list-group-item-light__gNGBJ {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-light);
}

.styles_list-group-item-light__gNGBJ.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-light__gNGBJ.styles_list-group-item-action__w9Cm_:focus {
  color: 'light';
  background-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.7);
}

.styles_list-group-item-light__gNGBJ.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-light-l) - 58.59375%)), 1);
  background-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.85);
  border-color: hsla(var(--lia-bs-light-h), var(--lia-bs-light-s), var(--lia-bs-light-l), 0.5);
}

.styles_list-group-item-dark__RWhZt {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: var(--lia-bs-dark);
}

.styles_list-group-item-dark__RWhZt.styles_list-group-item-action__w9Cm_:hover,
.styles_list-group-item-dark__RWhZt.styles_list-group-item-action__w9Cm_:focus {
  color: 'dark';
  background-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.7);
}

.styles_list-group-item-dark__RWhZt.styles_list-group-item-action__w9Cm_.styles_active__EuuVN {
  color: hsla(0, 0%, calc(-100 * calc(var(--lia-bs-dark-l) - 58.59375%)), 1);
  background-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.85);
  border-color: hsla(var(--lia-bs-dark-h), var(--lia-bs-dark-s), var(--lia-bs-dark-l), 0.5);
}

.styles_close__bRXON {
  float: right;
  font-size: calc(var(--lia-bs-font-size-base) * 1.5);
  font-weight: var(--lia-bs-font-weight-bold);
  line-height: 1;
  color: var(--lia-bs-black);
  text-shadow: 0 1px 0 var(--lia-bs-white);
  opacity: 0.5;
}

.styles_close__bRXON:hover {
  color: var(--lia-bs-black);
  text-decoration: none;
}

.styles_close__bRXON:not(:disabled):not(.styles_disabled__lqbPt):hover,
.styles_close__bRXON:not(:disabled):not(.styles_disabled__lqbPt):focus {
  opacity: 0.75;
}

button.styles_close__bRXON {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.styles_close__bRXON.styles_disabled__lqbPt {
  pointer-events: none;
}

.styles_toast__B4c5_ {
  flex-basis: 350px;
  max-width: 21.875rem;
  font-size: 0.875rem;
  background-color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem
    hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.1);
  opacity: 0;
  border-radius: var(--lia-bs-toast-border-radius);
}

.styles_toast__B4c5_:not(:last-child) {
  margin-bottom: var(--lia-bs-toast-padding-x);
}

.styles_toast__B4c5_.styles_showing__UCp4d {
  opacity: 1;
}

.styles_toast__B4c5_.styles_show__uuON9 {
  display: block;
  opacity: 1;
}

.styles_toast__B4c5_.styles_hide__1IFYc {
  display: none;
}

.styles_toast-header__mXkBW {
  display: flex;
  align-items: center;
  padding: 0.25rem var(--lia-bs-toast-padding-x);
  color: var(--lia-bs-gray-600);
  background-color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(var(--lia-bs-toast-border-radius) - 1px);
  border-top-right-radius: calc(var(--lia-bs-toast-border-radius) - 1px);
}

.styles_toast-body__c5mIe {
  padding: var(--lia-bs-toast-padding-x);
}

.styles_modal-open__kdsv5 {
  overflow: hidden;
}

.styles_modal-open__kdsv5 .styles_modal__U0hZl {
  overflow-x: hidden;
  overflow-y: auto;
}

.styles_modal__U0hZl {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.styles_modal-dialog__uTuhd {
  position: relative;
  width: auto;
  margin: 0.3125rem;
  pointer-events: none;
}

.styles_modal__U0hZl.styles_fade__R026M .styles_modal-dialog__uTuhd {
  transition: 250ms cubic-bezier(0.24, 0.94, 0.62, 1);
  transform: translateY(-0.3125rem) scale(0.97);
}

@media (prefers-reduced-motion: reduce) {
  .styles_modal__U0hZl.styles_fade__R026M .styles_modal-dialog__uTuhd {
    transition: none;
  }
}

.styles_modal__U0hZl.styles_show__uuON9 .styles_modal-dialog__uTuhd {
  transform: none;
}

.styles_modal__U0hZl.styles_modal-static__qfXXr .styles_modal-dialog__uTuhd {
  transform: scale(1.02);
}

.styles_modal-dialog-scrollable__oS5LP {
  display: flex;
  max-height: calc(100% - 0.625rem);
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-content__73xuK {
  max-height: calc(100vh - 0.625rem);
  overflow: hidden;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-header__rgSBQ,
.styles_modal-dialog-scrollable__oS5LP .styles_modal-footer__XSxmz {
  flex-shrink: 0;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal {
  overflow-y: auto;
}

.styles_modal-dialog-centered__bjxua {
  display: flex;
  align-items: center;
  min-height: calc(100% - 0.625rem);
}

.styles_modal-dialog-centered__bjxua::before {
  display: block;
  height: calc(100vh - 0.625rem);
  height: -moz-min-content;
  height: min-content;
  content: '';
}

.styles_modal-dialog-centered__bjxua.styles_modal-dialog-scrollable__oS5LP {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.styles_modal-dialog-centered__bjxua.styles_modal-dialog-scrollable__oS5LP .styles_modal-content__73xuK {
  max-height: none;
}

.styles_modal-dialog-centered__bjxua.styles_modal-dialog-scrollable__oS5LP::before {
  content: none;
}

.styles_modal-content__73xuK {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--lia-bs-modal-content-color);
  pointer-events: auto;
  background-color: var(--lia-bs-modal-content-bg);
  background-clip: padding-box;
  border: 0 solid hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.2);
  border-radius: var(--lia-bs-border-radius-lg);
  outline: 0;
}

.styles_modal-backdrop__2O4AS {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--lia-bs-modal-backdrop-bg);
}

.styles_modal-backdrop__2O4AS.styles_fade__R026M {
  opacity: 0;
}

.styles_modal-backdrop__2O4AS.styles_show__uuON9 {
  opacity: var(--lia-bs-modal-backdrop-opacity);
}

.styles_modal-header__rgSBQ {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem 1.875rem;
  border-bottom: 0 solid var(--lia-bs-border-color);
  border-top-left-radius: calc(var(--lia-bs-border-radius-lg) - 0);
  border-top-right-radius: calc(var(--lia-bs-border-radius-lg) - 0);
}

.styles_modal-header__rgSBQ .styles_close__bRXON {
  padding: 1.25rem 1.875rem;
  margin: -1rem -1rem -1rem auto;
}

.styles_modal-title__5BmJZ {
  margin-bottom: 0;
  line-height: var(--lia-bs-line-height-base);
}

.styles_modal-body__0fyal {
  position: relative;
  flex: 1 1 auto;
  padding: 0 1.875rem 1.875rem;
}

.styles_modal-footer__XSxmz {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.875rem 1.875rem-0.25rem;
  border-top: 0 solid var(--lia-bs-border-color);
  border-bottom-right-radius: calc(var(--lia-bs-border-radius-lg) - 0);
  border-bottom-left-radius: calc(var(--lia-bs-border-radius-lg) - 0);
}

.styles_modal-footer__XSxmz > * {
  margin: 0.25rem;
}

.styles_modal-scrollbar-measure__C_urs {
  position: absolute;
  top: -624.9375rem;
  width: 3.125rem;
  height: 3.125rem;
  overflow: scroll;
}

@media (min-width: 576px) {
  .styles_modal-dialog__uTuhd {
    max-width: var(--lia-bs-modal-md);
    margin: 1.5625rem auto;
  }
  .styles_modal-dialog-scrollable__oS5LP {
    max-height: calc(100% - 3.125rem);
  }
  .styles_modal-dialog-scrollable__oS5LP .styles_modal-content__73xuK {
    max-height: calc(100vh - 3.125rem);
  }
  .styles_modal-dialog-centered__bjxua {
    min-height: calc(100% - 3.125rem);
  }
  .styles_modal-dialog-centered__bjxua::before {
    height: calc(100vh - 3.125rem);
    height: -moz-min-content;
    height: min-content;
  }
  .styles_modal-sm__35pm2 {
    max-width: var(--lia-bs-modal-sm);
  }
}

@media (min-width: 992px) {
  .styles_modal-lg__KVfEj,
  .styles_modal-xl__WJuHU {
    max-width: var(--lia-bs-modal-lg);
  }
}

@media (min-width: 1260px) {
  .styles_modal-xl__WJuHU {
    max-width: 100%;
  }
}

.styles_tooltip___oYJL {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--lia-bs-font-family-base);
  font-style: normal;
  font-weight: var(--lia-bs-font-weight-normal);
  line-height: var(--lia-bs-line-height-base);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: var(--lia-bs-font-size-sm);
  word-wrap: break-word;
  opacity: 0;
}

.styles_tooltip___oYJL.styles_show__uuON9 {
  opacity: 0.9;
}

.styles_tooltip___oYJL .styles_arrow__iHXnr {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.styles_tooltip___oYJL .styles_arrow__iHXnr::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.styles_bs-tooltip-top__B8Ywe,
.styles_bs-tooltip-auto__R5atD[x-placement^='top'] {
  padding: 0.4rem 0;
}

.styles_bs-tooltip-top__B8Ywe .styles_arrow__iHXnr,
.styles_bs-tooltip-auto__R5atD[x-placement^='top'] .styles_arrow__iHXnr {
  bottom: 0;
}

.styles_bs-tooltip-top__B8Ywe .styles_arrow__iHXnr::before,
.styles_bs-tooltip-auto__R5atD[x-placement^='top'] .styles_arrow__iHXnr::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--lia-bs-black);
}

.styles_bs-tooltip-right__Pxbua,
.styles_bs-tooltip-auto__R5atD[x-placement^='right'] {
  padding: 0 0.4rem;
}

.styles_bs-tooltip-right__Pxbua .styles_arrow__iHXnr,
.styles_bs-tooltip-auto__R5atD[x-placement^='right'] .styles_arrow__iHXnr {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.styles_bs-tooltip-right__Pxbua .styles_arrow__iHXnr::before,
.styles_bs-tooltip-auto__R5atD[x-placement^='right'] .styles_arrow__iHXnr::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--lia-bs-black);
}

.styles_bs-tooltip-bottom__W1EXH,
.styles_bs-tooltip-auto__R5atD[x-placement^='bottom'] {
  padding: 0.4rem 0;
}

.styles_bs-tooltip-bottom__W1EXH .styles_arrow__iHXnr,
.styles_bs-tooltip-auto__R5atD[x-placement^='bottom'] .styles_arrow__iHXnr {
  top: 0;
}

.styles_bs-tooltip-bottom__W1EXH .styles_arrow__iHXnr::before,
.styles_bs-tooltip-auto__R5atD[x-placement^='bottom'] .styles_arrow__iHXnr::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--lia-bs-black);
}

.styles_bs-tooltip-left__QCGI3,
.styles_bs-tooltip-auto__R5atD[x-placement^='left'] {
  padding: 0 0.4rem;
}

.styles_bs-tooltip-left__QCGI3 .styles_arrow__iHXnr,
.styles_bs-tooltip-auto__R5atD[x-placement^='left'] .styles_arrow__iHXnr {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.styles_bs-tooltip-left__QCGI3 .styles_arrow__iHXnr::before,
.styles_bs-tooltip-auto__R5atD[x-placement^='left'] .styles_arrow__iHXnr::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--lia-bs-black);
}

.styles_tooltip-inner__mk_WS {
  max-width: 12.5rem;
  padding: 0.25rem 0.5rem;
  color: var(--lia-bs-white);
  text-align: center;
  background-color: var(--lia-bs-black);
  border-radius: var(--lia-bs-border-radius);
}

/* Overridden for calc functions */

.styles_popover__01_WA {
  min-height: -moz-min-content;
  min-height: min-content;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: var(--lia-bs-popover-max-width);
  font-family: var(--lia-bs-font-family-base);
  font-style: normal;
  font-weight: var(--lia-bs-font-weight-normal);
  line-height: var(--lia-bs-line-height-base);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: var(--lia-bs-font-size-sm);
  word-wrap: break-word;
  background-color: var(--lia-bs-white);
  background-clip: padding-box;
  border: 1px solid var(--lia-bs-popover-border-color);
  border-radius: var(--lia-bs-popover-border-radius);
}

.styles_popover__01_WA .styles_arrow__iHXnr {
  position: absolute;
  display: block;
  width: var(--lia-bs-popover-arrow-width);
  height: var(--lia-bs-popover-arrow-height);
  margin: 0 var(--lia-bs-popover-border-radius);
}

.styles_popover__01_WA .styles_arrow__iHXnr::before,
.styles_popover__01_WA .styles_arrow__iHXnr::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.styles_bs-popover-top__u59kA > .styles_arrow__iHXnr,
.styles_bs-popover-auto__AG2j1[x-placement^='top'] > .styles_arrow__iHXnr {
  bottom: calc(-1 * (var(--lia-bs-popover-arrow-height) + 0.0625rem));
}

.styles_bs-popover-top__u59kA > .styles_arrow__iHXnr::before,
.styles_bs-popover-auto__AG2j1[x-placement^='top'] > .styles_arrow__iHXnr::before {
  bottom: 0;
  border-width: var(--lia-bs-popover-arrow-height) calc(var(--lia-bs-popover-arrow-width) / 2) 0;
  border-top-color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.25);
}

.styles_bs-popover-top__u59kA > .styles_arrow__iHXnr::after,
.styles_bs-popover-auto__AG2j1[x-placement^='top'] > .styles_arrow__iHXnr::after {
  bottom: 0.0625rem;
  border-width: var(--lia-bs-popover-arrow-height) calc(var(--lia-bs-popover-arrow-width) / 2) 0;
  border-top-color: var(--lia-bs-white);
}

.styles_bs-popover-right__LYBkG > .styles_arrow__iHXnr,
.styles_bs-popover-auto__AG2j1[x-placement^='right'] > .styles_arrow__iHXnr {
  left: calc(-1 * (var(--lia-bs-popover-arrow-height) + 0.0625rem));
  width: var(--lia-bs-popover-arrow-height);
  height: var(--lia-bs-popover-arrow-width);
  margin: var(--lia-bs-popover-border-radius) 0;
}

.styles_bs-popover-right__LYBkG > .styles_arrow__iHXnr::before,
.styles_bs-popover-auto__AG2j1[x-placement^='right'] > .styles_arrow__iHXnr::before {
  left: 0;
  border-width: calc(var(--lia-bs-popover-arrow-width) / 2) var(--lia-bs-popover-arrow-height)
    calc(var(--lia-bs-popover-arrow-width) / 2) 0;
  border-right-color: hsla(
    var(--lia-bs-black-h),
    var(--lia-bs-black-s),
    var(--lia-bs-black-l),
    0.25
  );
}

.styles_bs-popover-right__LYBkG > .styles_arrow__iHXnr::after,
.styles_bs-popover-auto__AG2j1[x-placement^='right'] > .styles_arrow__iHXnr::after {
  left: 0.0625rem;
  border-width: calc(var(--lia-bs-popover-arrow-width) / 2) var(--lia-bs-popover-arrow-height)
    calc(var(--lia-bs-popover-arrow-width) / 2) 0;
  border-right-color: var(--lia-bs-white);
}

.styles_bs-popover-bottom__Fn92k > .styles_arrow__iHXnr,
.styles_bs-popover-auto__AG2j1[x-placement^='bottom'] > .styles_arrow__iHXnr {
  top: calc(-1 * (var(--lia-bs-popover-arrow-height) + 0.0625rem));
}

.styles_bs-popover-bottom__Fn92k > .styles_arrow__iHXnr::before,
.styles_bs-popover-auto__AG2j1[x-placement^='bottom'] > .styles_arrow__iHXnr::before {
  top: 0;
  border-width: 0 calc(var(--lia-bs-popover-arrow-width) / 2) var(--lia-bs-popover-arrow-height)
    calc(var(--lia-bs-popover-arrow-width) / 2);
  border-bottom-color: hsla(
    var(--lia-bs-black-h),
    var(--lia-bs-black-s),
    var(--lia-bs-black-l),
    0.25
  );
}

.styles_bs-popover-bottom__Fn92k > .styles_arrow__iHXnr::after,
.styles_bs-popover-auto__AG2j1[x-placement^='bottom'] > .styles_arrow__iHXnr::after {
  top: 0.0625rem;
  border-width: 0 calc(var(--lia-bs-popover-arrow-width) / 2) var(--lia-bs-popover-arrow-height)
    calc(var(--lia-bs-popover-arrow-width) / 2);
  border-bottom-color: var(--lia-bs-white);
}

.styles_bs-popover-bottom__Fn92k .styles_popover-header__2H7nK::before,
.styles_bs-popover-auto__AG2j1[x-placement^='bottom'] .styles_popover-header__2H7nK::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--lia-bs-popover-arrow-width);
  margin-left: -var(--lia-bs-popover-arrow-width) / 2;
  content: '';
  border-bottom: 1px solid var(--lia-bs-popover-header-bg);
}

.styles_bs-popover-left__ANXbi > .styles_arrow__iHXnr,
.styles_bs-popover-auto__AG2j1[x-placement^='left'] > .styles_arrow__iHXnr {
  right: calc(-1 * (var(--lia-bs-popover-arrow-height) + 0.0625rem));
  width: var(--lia-bs-popover-arrow-height);
  height: var(--lia-bs-popover-arrow-width);
  margin: var(--lia-bs-popover-border-radius) 0;
}

.styles_bs-popover-left__ANXbi > .styles_arrow__iHXnr::before,
.styles_bs-popover-auto__AG2j1[x-placement^='left'] > .styles_arrow__iHXnr::before {
  right: 0;
  border-width: calc(var(--lia-bs-popover-arrow-width) / 2) 0
    calc(var(--lia-bs-popover-arrow-width) / 2) var(--lia-bs-popover-arrow-height);
  border-left-color: hsla(
    var(--lia-bs-black-h),
    var(--lia-bs-black-s),
    var(--lia-bs-black-l),
    0.25
  );
}

.styles_bs-popover-left__ANXbi > .styles_arrow__iHXnr::after,
.styles_bs-popover-auto__AG2j1[x-placement^='left'] > .styles_arrow__iHXnr::after {
  right: 0.0625rem;
  border-width: calc(var(--lia-bs-popover-arrow-width) / 2) 0
    calc(var(--lia-bs-popover-arrow-width) / 2) var(--lia-bs-popover-arrow-height);
  border-left-color: var(--lia-bs-white);
}

.styles_popover-header__2H7nK {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: var(--lia-bs-font-size-base);
  color: var(--lia-bs-headings-color);
  background-color: var(--lia-bs-popover-header-bg);
  border-bottom: 1px solid
    hsla(
      var(--lia-bs-popover-header-bg-h),
      var(--lia-bs-popover-header-bg-s),
      calc(var(--lia-bs-popover-header-bg-l) + -5%),
      var(--lia-bs-popover-header-bg-a, 1)
    );
  border-top-left-radius: calc(var(--lia-bs-popover-border-radius) - 1px);
  border-top-right-radius: calc(var(--lia-bs-popover-border-radius) - 1px);
}

.styles_popover-header__2H7nK:empty {
  display: none;
}

.styles_popover-body__yYylT {
  padding: 0 0;
  color: var(--lia-bs-body-color);
}

.styles_carousel__CvDXE {
  position: relative;
}

.styles_carousel__CvDXE.styles_pointer-event__enWKV {
  touch-action: pan-y;
}

.styles_carousel-inner__HBWSt {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.styles_carousel-inner__HBWSt::after {
  display: block;
  clear: both;
  content: '';
}

.styles_carousel-item__40BGa {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .styles_carousel-item__40BGa {
    transition: none;
  }
}

.styles_carousel-item__40BGa.styles_active__EuuVN,
.styles_carousel-item-next__OV4u4,
.styles_carousel-item-prev__5NYGQ {
  display: block;
}

.styles_carousel-item-next__OV4u4:not(.styles_carousel-item-left__kKDgs),
.styles_active__EuuVN.styles_carousel-item-right__8Ua94 {
  transform: translateX(100%);
}

.styles_carousel-item-prev__5NYGQ:not(.styles_carousel-item-right__8Ua94),
.styles_active__EuuVN.styles_carousel-item-left__kKDgs {
  transform: translateX(-100%);
}

.styles_carousel-fade__assRC .styles_carousel-item__40BGa {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.styles_carousel-fade__assRC .styles_carousel-item__40BGa.styles_active__EuuVN,
.styles_carousel-fade__assRC .styles_carousel-item-next__OV4u4.styles_carousel-item-left__kKDgs,
.styles_carousel-fade__assRC .styles_carousel-item-prev__5NYGQ.styles_carousel-item-right__8Ua94 {
  z-index: 1;
  opacity: 1;
}

.styles_carousel-fade__assRC .styles_active__EuuVN.styles_carousel-item-left__kKDgs,
.styles_carousel-fade__assRC .styles_active__EuuVN.styles_carousel-item-right__8Ua94 {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .styles_carousel-fade__assRC .styles_active__EuuVN.styles_carousel-item-left__kKDgs,
  .styles_carousel-fade__assRC .styles_active__EuuVN.styles_carousel-item-right__8Ua94 {
    transition: none;
  }
}

.styles_carousel-control-prev__sjNaL,
.styles_carousel-control-next__HCrKU {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: var(--lia-bs-white);
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .styles_carousel-control-prev__sjNaL,
  .styles_carousel-control-next__HCrKU {
    transition: none;
  }
}

.styles_carousel-control-prev__sjNaL:hover,
.styles_carousel-control-prev__sjNaL:focus,
.styles_carousel-control-next__HCrKU:hover,
.styles_carousel-control-next__HCrKU:focus {
  color: var(--lia-bs-white);
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.styles_carousel-control-prev__sjNaL {
  left: 0;
}

.styles_carousel-control-next__HCrKU {
  right: 0;
}

.styles_carousel-control-prev-icon__Bgnem,
.styles_carousel-control-next-icon__5e8xV {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background: 50% / 100% 100% no-repeat;
}

.styles_carousel-control-prev-icon__Bgnem {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='var%28--lia-bs-white%29' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.styles_carousel-control-next-icon__5e8xV {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='var%28--lia-bs-white%29' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.styles_carousel-indicators__EqwVF {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.styles_carousel-indicators__EqwVF li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 1.875rem;
  height: 0.1875rem;
  margin-right: 0.1875rem;
  margin-left: 0.1875rem;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--lia-bs-white);
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .styles_carousel-indicators__EqwVF li {
    transition: none;
  }
}

.styles_carousel-indicators__EqwVF .styles_active__EuuVN {
  opacity: 1;
}

.styles_carousel-caption__uih0v {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  z-index: 10;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--lia-bs-white);
  text-align: center;
}

/* Overridden for animation functions */

@keyframes styles_spinner-border__uq5hH {
  to {
    transform: rotate(360deg);
  }
}

.styles_spinner-border__uq5hH {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: styles_spinner-border__uq5hH 0.75s linear infinite;
}

.styles_spinner-border-sm__HSB6L {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes styles_spinner-grow__S7FS_ {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.styles_spinner-grow__S7FS_ {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: styles_spinner-grow__S7FS_ 0.75s linear infinite;
}

.styles_spinner-grow-sm__0VH8Q {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .styles_spinner-border__uq5hH,
  .styles_spinner-grow__S7FS_ {
    animation-duration: 1.5s;
  }
}

.styles_align-baseline__QOm_p {
  vertical-align: baseline !important;
}

.styles_align-top__eFSii {
  vertical-align: top !important;
}

.styles_align-middle__j2WHc {
  vertical-align: middle !important;
}

.styles_align-bottom__Yzhl0 {
  vertical-align: bottom !important;
}

.styles_align-text-bottom__VxBaG {
  vertical-align: text-bottom !important;
}

.styles_align-text-top__sSX4u {
  vertical-align: text-top !important;
}

.styles_bg-primary__a5ugD {
  background-color: var(--lia-bs-primary) !important;
}

a.styles_bg-primary__a5ugD:hover,
a.styles_bg-primary__a5ugD:focus,
button.styles_bg-primary__a5ugD:hover,
button.styles_bg-primary__a5ugD:focus {
  background-color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -10%),
    var(--lia-bs-primary-a, 1)
  ) !important;
}

.styles_bg-secondary__6e1D7 {
  background-color: var(--lia-bs-secondary) !important;
}

a.styles_bg-secondary__6e1D7:hover,
a.styles_bg-secondary__6e1D7:focus,
button.styles_bg-secondary__6e1D7:hover,
button.styles_bg-secondary__6e1D7:focus {
  background-color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -10%),
    var(--lia-bs-secondary-a, 1)
  ) !important;
}

.styles_bg-success__7YoFu {
  background-color: var(--lia-bs-success) !important;
}

a.styles_bg-success__7YoFu:hover,
a.styles_bg-success__7YoFu:focus,
button.styles_bg-success__7YoFu:hover,
button.styles_bg-success__7YoFu:focus {
  background-color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -10%),
    var(--lia-bs-success-a, 1)
  ) !important;
}

.styles_bg-info__DPFkh {
  background-color: var(--lia-bs-info) !important;
}

a.styles_bg-info__DPFkh:hover,
a.styles_bg-info__DPFkh:focus,
button.styles_bg-info__DPFkh:hover,
button.styles_bg-info__DPFkh:focus {
  background-color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -10%),
    var(--lia-bs-info-a, 1)
  ) !important;
}

.styles_bg-warning__1lBhU {
  background-color: var(--lia-bs-warning) !important;
}

a.styles_bg-warning__1lBhU:hover,
a.styles_bg-warning__1lBhU:focus,
button.styles_bg-warning__1lBhU:hover,
button.styles_bg-warning__1lBhU:focus {
  background-color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -10%),
    var(--lia-bs-warning-a, 1)
  ) !important;
}

.styles_bg-danger___9fVq {
  background-color: var(--lia-bs-danger) !important;
}

a.styles_bg-danger___9fVq:hover,
a.styles_bg-danger___9fVq:focus,
button.styles_bg-danger___9fVq:hover,
button.styles_bg-danger___9fVq:focus {
  background-color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -10%),
    var(--lia-bs-danger-a, 1)
  ) !important;
}

.styles_bg-light__P7NSS {
  background-color: var(--lia-bs-light) !important;
}

a.styles_bg-light__P7NSS:hover,
a.styles_bg-light__P7NSS:focus,
button.styles_bg-light__P7NSS:hover,
button.styles_bg-light__P7NSS:focus {
  background-color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -10%),
    var(--lia-bs-light-a, 1)
  ) !important;
}

.styles_bg-dark__ZfccU {
  background-color: var(--lia-bs-dark) !important;
}

a.styles_bg-dark__ZfccU:hover,
a.styles_bg-dark__ZfccU:focus,
button.styles_bg-dark__ZfccU:hover,
button.styles_bg-dark__ZfccU:focus {
  background-color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -10%),
    var(--lia-bs-dark-a, 1)
  ) !important;
}

.styles_bg-white__0DGAA {
  background-color: var(--lia-bs-white) !important;
}

.styles_bg-transparent__NrLtq {
  background-color: transparent !important;
}

.styles_border__BAltG {
  border: 1px solid var(--lia-bs-border-color) !important;
}

.styles_border-top__eYcZc {
  border-top: 1px solid var(--lia-bs-border-color) !important;
}

.styles_border-right__3dcAt {
  border-right: 1px solid var(--lia-bs-border-color) !important;
}

.styles_border-bottom__rm8pu {
  border-bottom: 1px solid var(--lia-bs-border-color) !important;
}

.styles_border-left__y5IIF {
  border-left: 1px solid var(--lia-bs-border-color) !important;
}

.styles_border-0__bE5Su {
  border: 0 !important;
}

.styles_border-top-0__oCNN0 {
  border-top: 0 !important;
}

.styles_border-right-0__0icu3 {
  border-right: 0 !important;
}

.styles_border-bottom-0__FE8jB {
  border-bottom: 0 !important;
}

.styles_border-left-0__qsBak {
  border-left: 0 !important;
}

.styles_border-primary__H82zH {
  border-color: var(--lia-bs-primary) !important;
}

.styles_border-secondary__0CTsf {
  border-color: var(--lia-bs-secondary) !important;
}

.styles_border-success__7Jl5m {
  border-color: var(--lia-bs-success) !important;
}

.styles_border-info__RyRkz {
  border-color: var(--lia-bs-info) !important;
}

.styles_border-warning__U5cKs {
  border-color: var(--lia-bs-warning) !important;
}

.styles_border-danger__Uaq1h {
  border-color: var(--lia-bs-danger) !important;
}

.styles_border-light__sWVLX {
  border-color: var(--lia-bs-light) !important;
}

.styles_border-dark__kCWEn {
  border-color: var(--lia-bs-dark) !important;
}

.styles_border-white__zLE0h {
  border-color: var(--lia-bs-white) !important;
}

.styles_rounded-sm__gAfAD {
  border-radius: var(--lia-bs-border-radius-sm) !important;
}

.styles_rounded__bNYHw {
  border-radius: var(--lia-bs-border-radius) !important;
}

.styles_rounded-top__1_ysg {
  border-top-left-radius: var(--lia-bs-border-radius) !important;
  border-top-right-radius: var(--lia-bs-border-radius) !important;
}

.styles_rounded-right__Y7OOC {
  border-top-right-radius: var(--lia-bs-border-radius) !important;
  border-bottom-right-radius: var(--lia-bs-border-radius) !important;
}

.styles_rounded-bottom__bAeGF {
  border-bottom-right-radius: var(--lia-bs-border-radius) !important;
  border-bottom-left-radius: var(--lia-bs-border-radius) !important;
}

.styles_rounded-left__yt3Ha {
  border-top-left-radius: var(--lia-bs-border-radius) !important;
  border-bottom-left-radius: var(--lia-bs-border-radius) !important;
}

.styles_rounded-lg__Jb89t {
  border-radius: var(--lia-bs-border-radius-lg) !important;
}

.styles_rounded-circle__Sl6qD {
  border-radius: 50% !important;
}

.styles_rounded-pill__PxaCc {
  border-radius: 50rem !important;
}

.styles_rounded-0__xjK5M {
  border-radius: 0 !important;
}

.styles_clearfix__xFEoC::after {
  display: block;
  clear: both;
  content: '';
}

.styles_d-none__wDjqp {
  display: none !important;
}

.styles_d-inline__Y5Xih {
  display: inline !important;
}

.styles_d-inline-block__phCtl {
  display: inline-block !important;
}

.styles_d-block__btBet {
  display: block !important;
}

.styles_d-table__CjX6F {
  display: table !important;
}

.styles_d-table-row__O0jBr {
  display: table-row !important;
}

.styles_d-table-cell__Xkdjs {
  display: table-cell !important;
}

.styles_d-flex__7__Y_ {
  display: flex !important;
}

.styles_d-inline-flex__K8X9v {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .styles_d-sm-none__vJtxZ {
    display: none !important;
  }
  .styles_d-sm-inline__Vqbio {
    display: inline !important;
  }
  .styles_d-sm-inline-block__0FKHg {
    display: inline-block !important;
  }
  .styles_d-sm-block__dctBw {
    display: block !important;
  }
  .styles_d-sm-table__DoUIG {
    display: table !important;
  }
  .styles_d-sm-table-row__UNrb4 {
    display: table-row !important;
  }
  .styles_d-sm-table-cell__qLdLb {
    display: table-cell !important;
  }
  .styles_d-sm-flex__YC3Uq {
    display: flex !important;
  }
  .styles_d-sm-inline-flex__JC653 {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .styles_d-md-none__I1rBU {
    display: none !important;
  }
  .styles_d-md-inline__lPo_u {
    display: inline !important;
  }
  .styles_d-md-inline-block__CfoG8 {
    display: inline-block !important;
  }
  .styles_d-md-block__GBk_W {
    display: block !important;
  }
  .styles_d-md-table__0dDp9 {
    display: table !important;
  }
  .styles_d-md-table-row__HjCM_ {
    display: table-row !important;
  }
  .styles_d-md-table-cell__0aUYt {
    display: table-cell !important;
  }
  .styles_d-md-flex__2MBPd {
    display: flex !important;
  }
  .styles_d-md-inline-flex__SRm_c {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .styles_d-lg-none__T_G0U {
    display: none !important;
  }
  .styles_d-lg-inline__NVxxv {
    display: inline !important;
  }
  .styles_d-lg-inline-block__6lFAa {
    display: inline-block !important;
  }
  .styles_d-lg-block__COAl7 {
    display: block !important;
  }
  .styles_d-lg-table__IqVgO {
    display: table !important;
  }
  .styles_d-lg-table-row__x3lfP {
    display: table-row !important;
  }
  .styles_d-lg-table-cell__p1Aud {
    display: table-cell !important;
  }
  .styles_d-lg-flex__iGrxh {
    display: flex !important;
  }
  .styles_d-lg-inline-flex__4l0z9 {
    display: inline-flex !important;
  }
}

@media (min-width: 1260px) {
  .styles_d-xl-none__7CYkY {
    display: none !important;
  }
  .styles_d-xl-inline__ldtCy {
    display: inline !important;
  }
  .styles_d-xl-inline-block__w1xua {
    display: inline-block !important;
  }
  .styles_d-xl-block__Cdkax {
    display: block !important;
  }
  .styles_d-xl-table__ctH8J {
    display: table !important;
  }
  .styles_d-xl-table-row__umJUm {
    display: table-row !important;
  }
  .styles_d-xl-table-cell__HvAUb {
    display: table-cell !important;
  }
  .styles_d-xl-flex__h15lA {
    display: flex !important;
  }
  .styles_d-xl-inline-flex__7ifMG {
    display: inline-flex !important;
  }
}

@media print {
  .styles_d-print-none__svmVb {
    display: none !important;
  }
  .styles_d-print-inline__qURiu {
    display: inline !important;
  }
  .styles_d-print-inline-block__XkcD5 {
    display: inline-block !important;
  }
  .styles_d-print-block__kHU_B {
    display: block !important;
  }
  .styles_d-print-table___Zk_I {
    display: table !important;
  }
  .styles_d-print-table-row__vFpBG {
    display: table-row !important;
  }
  .styles_d-print-table-cell__dQD7l {
    display: table-cell !important;
  }
  .styles_d-print-flex__PxH_2 {
    display: flex !important;
  }
  .styles_d-print-inline-flex__PMS_t {
    display: inline-flex !important;
  }
}

.styles_embed-responsive__Yl_49 {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.styles_embed-responsive__Yl_49::before {
  display: block;
  content: '';
}

.styles_embed-responsive__Yl_49 .styles_embed-responsive-item___DOyu,
.styles_embed-responsive__Yl_49 iframe,
.styles_embed-responsive__Yl_49 embed,
.styles_embed-responsive__Yl_49 object,
.styles_embed-responsive__Yl_49 video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.styles_embed-responsive-21by9__9PeWB::before {
  padding-top: 42.85714%;
}

.styles_embed-responsive-16by9__KUDfg::before {
  padding-top: 56.25%;
}

.styles_embed-responsive-4by3__4xCLh::before {
  padding-top: 75%;
}

.styles_embed-responsive-1by1__EDSI8::before {
  padding-top: 100%;
}

.styles_flex-row__JMdBm {
  flex-direction: row !important;
}

.styles_flex-column__Vl0ML {
  flex-direction: column !important;
}

.styles_flex-row-reverse__o2BNt {
  flex-direction: row-reverse !important;
}

.styles_flex-column-reverse__f88_T {
  flex-direction: column-reverse !important;
}

.styles_flex-wrap__Fh60q {
  flex-wrap: wrap !important;
}

.styles_flex-nowrap__hm_6f {
  flex-wrap: nowrap !important;
}

.styles_flex-wrap-reverse__IFXC9 {
  flex-wrap: wrap-reverse !important;
}

.styles_flex-fill__2E0p4 {
  flex: 1 1 auto !important;
}

.styles_flex-grow-0__ox73K {
  flex-grow: 0 !important;
}

.styles_flex-grow-1__Q_0Rl {
  flex-grow: 1 !important;
}

.styles_flex-shrink-0__K9jke {
  flex-shrink: 0 !important;
}

.styles_flex-shrink-1__bybNv {
  flex-shrink: 1 !important;
}

.styles_justify-content-start__AvA0g {
  justify-content: flex-start !important;
}

.styles_justify-content-end__dF_Ea {
  justify-content: flex-end !important;
}

.styles_justify-content-center__wTGUU {
  justify-content: center !important;
}

.styles_justify-content-between__jJYGo {
  justify-content: space-between !important;
}

.styles_justify-content-around__d2cwe {
  justify-content: space-around !important;
}

.styles_align-items-start__1sHqD {
  align-items: flex-start !important;
}

.styles_align-items-end__fxgdB {
  align-items: flex-end !important;
}

.styles_align-items-center__OKdH0 {
  align-items: center !important;
}

.styles_align-items-baseline__jktRx {
  align-items: baseline !important;
}

.styles_align-items-stretch__y_5OE {
  align-items: stretch !important;
}

.styles_align-content-start__TCrk_ {
  align-content: flex-start !important;
}

.styles_align-content-end__CSphE {
  align-content: flex-end !important;
}

.styles_align-content-center__YkluO {
  align-content: center !important;
}

.styles_align-content-between__Jdgev {
  align-content: space-between !important;
}

.styles_align-content-around__Ds26U {
  align-content: space-around !important;
}

.styles_align-content-stretch__lP_kz {
  align-content: stretch !important;
}

.styles_align-self-auto___B8CC {
  align-self: auto !important;
}

.styles_align-self-start__a5Ju_ {
  align-self: flex-start !important;
}

.styles_align-self-end__QxdpR {
  align-self: flex-end !important;
}

.styles_align-self-center__a8_VD {
  align-self: center !important;
}

.styles_align-self-baseline__2DouE {
  align-self: baseline !important;
}

.styles_align-self-stretch__0HAPz {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .styles_flex-sm-row__4hVlf {
    flex-direction: row !important;
  }
  .styles_flex-sm-column__w2F76 {
    flex-direction: column !important;
  }
  .styles_flex-sm-row-reverse__Q9vM6 {
    flex-direction: row-reverse !important;
  }
  .styles_flex-sm-column-reverse__eZ348 {
    flex-direction: column-reverse !important;
  }
  .styles_flex-sm-wrap__EZSgZ {
    flex-wrap: wrap !important;
  }
  .styles_flex-sm-nowrap__LroYu {
    flex-wrap: nowrap !important;
  }
  .styles_flex-sm-wrap-reverse__gVfJT {
    flex-wrap: wrap-reverse !important;
  }
  .styles_flex-sm-fill__vbsHj {
    flex: 1 1 auto !important;
  }
  .styles_flex-sm-grow-0__a7S5p {
    flex-grow: 0 !important;
  }
  .styles_flex-sm-grow-1__0KAFT {
    flex-grow: 1 !important;
  }
  .styles_flex-sm-shrink-0__MsPsJ {
    flex-shrink: 0 !important;
  }
  .styles_flex-sm-shrink-1__P3eYT {
    flex-shrink: 1 !important;
  }
  .styles_justify-content-sm-start__oYnMZ {
    justify-content: flex-start !important;
  }
  .styles_justify-content-sm-end___9peQ {
    justify-content: flex-end !important;
  }
  .styles_justify-content-sm-center__blzCB {
    justify-content: center !important;
  }
  .styles_justify-content-sm-between__jXRiH {
    justify-content: space-between !important;
  }
  .styles_justify-content-sm-around__bnhS4 {
    justify-content: space-around !important;
  }
  .styles_align-items-sm-start__jAWbn {
    align-items: flex-start !important;
  }
  .styles_align-items-sm-end__Ipfqt {
    align-items: flex-end !important;
  }
  .styles_align-items-sm-center__W1n9J {
    align-items: center !important;
  }
  .styles_align-items-sm-baseline__3x0TE {
    align-items: baseline !important;
  }
  .styles_align-items-sm-stretch__5V418 {
    align-items: stretch !important;
  }
  .styles_align-content-sm-start__6tDxC {
    align-content: flex-start !important;
  }
  .styles_align-content-sm-end__MWfAH {
    align-content: flex-end !important;
  }
  .styles_align-content-sm-center__Ccs2N {
    align-content: center !important;
  }
  .styles_align-content-sm-between__ZIfrA {
    align-content: space-between !important;
  }
  .styles_align-content-sm-around__XtsiY {
    align-content: space-around !important;
  }
  .styles_align-content-sm-stretch__oGfkR {
    align-content: stretch !important;
  }
  .styles_align-self-sm-auto__W_gfk {
    align-self: auto !important;
  }
  .styles_align-self-sm-start__Dllny {
    align-self: flex-start !important;
  }
  .styles_align-self-sm-end__WX_AS {
    align-self: flex-end !important;
  }
  .styles_align-self-sm-center__Lmwmn {
    align-self: center !important;
  }
  .styles_align-self-sm-baseline__40WNP {
    align-self: baseline !important;
  }
  .styles_align-self-sm-stretch__s5OY3 {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .styles_flex-md-row__NwQVv {
    flex-direction: row !important;
  }
  .styles_flex-md-column__Pa90e {
    flex-direction: column !important;
  }
  .styles_flex-md-row-reverse__RPxX7 {
    flex-direction: row-reverse !important;
  }
  .styles_flex-md-column-reverse__JQY4v {
    flex-direction: column-reverse !important;
  }
  .styles_flex-md-wrap__NVBOP {
    flex-wrap: wrap !important;
  }
  .styles_flex-md-nowrap__C0bRv {
    flex-wrap: nowrap !important;
  }
  .styles_flex-md-wrap-reverse__0Fn2x {
    flex-wrap: wrap-reverse !important;
  }
  .styles_flex-md-fill__odWrG {
    flex: 1 1 auto !important;
  }
  .styles_flex-md-grow-0__HZ2Kc {
    flex-grow: 0 !important;
  }
  .styles_flex-md-grow-1__vzMNI {
    flex-grow: 1 !important;
  }
  .styles_flex-md-shrink-0__2acOd {
    flex-shrink: 0 !important;
  }
  .styles_flex-md-shrink-1__qFuZF {
    flex-shrink: 1 !important;
  }
  .styles_justify-content-md-start__pQHA9 {
    justify-content: flex-start !important;
  }
  .styles_justify-content-md-end__MZM7R {
    justify-content: flex-end !important;
  }
  .styles_justify-content-md-center__7FUne {
    justify-content: center !important;
  }
  .styles_justify-content-md-between__A9Zx4 {
    justify-content: space-between !important;
  }
  .styles_justify-content-md-around__B_0pL {
    justify-content: space-around !important;
  }
  .styles_align-items-md-start__hXfgK {
    align-items: flex-start !important;
  }
  .styles_align-items-md-end__GvP4q {
    align-items: flex-end !important;
  }
  .styles_align-items-md-center__lxIdJ {
    align-items: center !important;
  }
  .styles_align-items-md-baseline__ydAw4 {
    align-items: baseline !important;
  }
  .styles_align-items-md-stretch__UtDdF {
    align-items: stretch !important;
  }
  .styles_align-content-md-start__4j_F3 {
    align-content: flex-start !important;
  }
  .styles_align-content-md-end__cJlKv {
    align-content: flex-end !important;
  }
  .styles_align-content-md-center__E4fbU {
    align-content: center !important;
  }
  .styles_align-content-md-between__8KUux {
    align-content: space-between !important;
  }
  .styles_align-content-md-around__WJJfj {
    align-content: space-around !important;
  }
  .styles_align-content-md-stretch___lquD {
    align-content: stretch !important;
  }
  .styles_align-self-md-auto__eYtkr {
    align-self: auto !important;
  }
  .styles_align-self-md-start__j49WD {
    align-self: flex-start !important;
  }
  .styles_align-self-md-end__xPJ6H {
    align-self: flex-end !important;
  }
  .styles_align-self-md-center__OXR_v {
    align-self: center !important;
  }
  .styles_align-self-md-baseline__SP1mQ {
    align-self: baseline !important;
  }
  .styles_align-self-md-stretch__5qZ_6 {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .styles_flex-lg-row__ZhILJ {
    flex-direction: row !important;
  }
  .styles_flex-lg-column__rhuqt {
    flex-direction: column !important;
  }
  .styles_flex-lg-row-reverse__CPkAi {
    flex-direction: row-reverse !important;
  }
  .styles_flex-lg-column-reverse__wD3yi {
    flex-direction: column-reverse !important;
  }
  .styles_flex-lg-wrap__BL5my {
    flex-wrap: wrap !important;
  }
  .styles_flex-lg-nowrap__wjRRU {
    flex-wrap: nowrap !important;
  }
  .styles_flex-lg-wrap-reverse__d7cyQ {
    flex-wrap: wrap-reverse !important;
  }
  .styles_flex-lg-fill__1JFTT {
    flex: 1 1 auto !important;
  }
  .styles_flex-lg-grow-0__KzyYW {
    flex-grow: 0 !important;
  }
  .styles_flex-lg-grow-1__1vyKc {
    flex-grow: 1 !important;
  }
  .styles_flex-lg-shrink-0__YcbYw {
    flex-shrink: 0 !important;
  }
  .styles_flex-lg-shrink-1__BBEPi {
    flex-shrink: 1 !important;
  }
  .styles_justify-content-lg-start__Jx2yh {
    justify-content: flex-start !important;
  }
  .styles_justify-content-lg-end__c0aEO {
    justify-content: flex-end !important;
  }
  .styles_justify-content-lg-center__hPPb7 {
    justify-content: center !important;
  }
  .styles_justify-content-lg-between__xL5FP {
    justify-content: space-between !important;
  }
  .styles_justify-content-lg-around__na6Gf {
    justify-content: space-around !important;
  }
  .styles_align-items-lg-start__SPgbi {
    align-items: flex-start !important;
  }
  .styles_align-items-lg-end__4yXd_ {
    align-items: flex-end !important;
  }
  .styles_align-items-lg-center__LSSuS {
    align-items: center !important;
  }
  .styles_align-items-lg-baseline___i1zu {
    align-items: baseline !important;
  }
  .styles_align-items-lg-stretch__Q4IBc {
    align-items: stretch !important;
  }
  .styles_align-content-lg-start__TWzan {
    align-content: flex-start !important;
  }
  .styles_align-content-lg-end__LwDmE {
    align-content: flex-end !important;
  }
  .styles_align-content-lg-center__Kgb5c {
    align-content: center !important;
  }
  .styles_align-content-lg-between__ZrU3_ {
    align-content: space-between !important;
  }
  .styles_align-content-lg-around__g53Q9 {
    align-content: space-around !important;
  }
  .styles_align-content-lg-stretch__ug5Is {
    align-content: stretch !important;
  }
  .styles_align-self-lg-auto__18sV0 {
    align-self: auto !important;
  }
  .styles_align-self-lg-start__LA9Wr {
    align-self: flex-start !important;
  }
  .styles_align-self-lg-end__IFq_b {
    align-self: flex-end !important;
  }
  .styles_align-self-lg-center__9O5_0 {
    align-self: center !important;
  }
  .styles_align-self-lg-baseline__PiIMI {
    align-self: baseline !important;
  }
  .styles_align-self-lg-stretch__cheX_ {
    align-self: stretch !important;
  }
}

@media (min-width: 1260px) {
  .styles_flex-xl-row__9ekbw {
    flex-direction: row !important;
  }
  .styles_flex-xl-column__ma9E1 {
    flex-direction: column !important;
  }
  .styles_flex-xl-row-reverse__Nv05b {
    flex-direction: row-reverse !important;
  }
  .styles_flex-xl-column-reverse__UV9Hr {
    flex-direction: column-reverse !important;
  }
  .styles_flex-xl-wrap__v5lsF {
    flex-wrap: wrap !important;
  }
  .styles_flex-xl-nowrap__nrTGE {
    flex-wrap: nowrap !important;
  }
  .styles_flex-xl-wrap-reverse__uOakH {
    flex-wrap: wrap-reverse !important;
  }
  .styles_flex-xl-fill__bdCqA {
    flex: 1 1 auto !important;
  }
  .styles_flex-xl-grow-0__zWRiN {
    flex-grow: 0 !important;
  }
  .styles_flex-xl-grow-1__Gl9Lh {
    flex-grow: 1 !important;
  }
  .styles_flex-xl-shrink-0__nmEnz {
    flex-shrink: 0 !important;
  }
  .styles_flex-xl-shrink-1__NpmW2 {
    flex-shrink: 1 !important;
  }
  .styles_justify-content-xl-start__u7p46 {
    justify-content: flex-start !important;
  }
  .styles_justify-content-xl-end__ltQoQ {
    justify-content: flex-end !important;
  }
  .styles_justify-content-xl-center__wQ24I {
    justify-content: center !important;
  }
  .styles_justify-content-xl-between__gjqTP {
    justify-content: space-between !important;
  }
  .styles_justify-content-xl-around__0_bJK {
    justify-content: space-around !important;
  }
  .styles_align-items-xl-start__5ihk9 {
    align-items: flex-start !important;
  }
  .styles_align-items-xl-end__vD2RK {
    align-items: flex-end !important;
  }
  .styles_align-items-xl-center__HsxEi {
    align-items: center !important;
  }
  .styles_align-items-xl-baseline__LnqLD {
    align-items: baseline !important;
  }
  .styles_align-items-xl-stretch__u_TnF {
    align-items: stretch !important;
  }
  .styles_align-content-xl-start__ML868 {
    align-content: flex-start !important;
  }
  .styles_align-content-xl-end__ywjKG {
    align-content: flex-end !important;
  }
  .styles_align-content-xl-center__cG8r7 {
    align-content: center !important;
  }
  .styles_align-content-xl-between__0eNzt {
    align-content: space-between !important;
  }
  .styles_align-content-xl-around__0X5UY {
    align-content: space-around !important;
  }
  .styles_align-content-xl-stretch__Z0urx {
    align-content: stretch !important;
  }
  .styles_align-self-xl-auto__e5Bhz {
    align-self: auto !important;
  }
  .styles_align-self-xl-start__8AT5l {
    align-self: flex-start !important;
  }
  .styles_align-self-xl-end__tT_Mo {
    align-self: flex-end !important;
  }
  .styles_align-self-xl-center__S18iw {
    align-self: center !important;
  }
  .styles_align-self-xl-baseline__uDoW9 {
    align-self: baseline !important;
  }
  .styles_align-self-xl-stretch__aS2U5 {
    align-self: stretch !important;
  }
}

.styles_float-left__H0siW {
  float: left !important;
}

.styles_float-right__FM2cN {
  float: right !important;
}

.styles_float-none__71dk7 {
  float: none !important;
}

@media (min-width: 576px) {
  .styles_float-sm-left__4xHdn {
    float: left !important;
  }
  .styles_float-sm-right__t2bAw {
    float: right !important;
  }
  .styles_float-sm-none__VZ4ox {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .styles_float-md-left__ilmMq {
    float: left !important;
  }
  .styles_float-md-right__CSKOT {
    float: right !important;
  }
  .styles_float-md-none__UNiSz {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .styles_float-lg-left__poaN4 {
    float: left !important;
  }
  .styles_float-lg-right__XMGAg {
    float: right !important;
  }
  .styles_float-lg-none__NkBei {
    float: none !important;
  }
}

@media (min-width: 1260px) {
  .styles_float-xl-left__lO19X {
    float: left !important;
  }
  .styles_float-xl-right__NzHjz {
    float: right !important;
  }
  .styles_float-xl-none__kZNMm {
    float: none !important;
  }
}

.styles_user-select-all__EQox4 {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.styles_user-select-auto___N74y {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.styles_user-select-none__WC_ji {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.styles_overflow-auto__zGTap {
  overflow: auto !important;
}

.styles_overflow-hidden__uwO04 {
  overflow: hidden !important;
}

.styles_position-static__GqIJM {
  position: static !important;
}

.styles_position-relative__DU_kX {
  position: relative !important;
}

.styles_position-absolute__oh1Nx {
  position: absolute !important;
}

.styles_position-fixed__esK82 {
  position: fixed !important;
}

.styles_position-sticky__zp1zX {
  position: sticky !important;
}

.styles_fixed-top__l7aa_ {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.styles_fixed-bottom__Px_m4 {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .styles_sticky-top___Af7B {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.styles_sr-only__NOnjB {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.styles_sr-only-focusable__1Ssch:active,
.styles_sr-only-focusable__1Ssch:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.styles_shadow-sm__8hD4E {
  box-shadow: var(--lia-bs-box-shadow-sm) !important;
}

.styles_shadow__mG6mi {
  box-shadow: var(--lia-bs-box-shadow) !important;
}

.styles_shadow-lg__Q7kXQ {
  box-shadow: var(--lia-bs-box-shadow-lg) !important;
}

.styles_shadow-none__Wn3w1 {
  box-shadow: none !important;
}

.styles_w-25__0rEb2 {
  width: 25% !important;
}

.styles_w-50__aHSYB {
  width: 50% !important;
}

.styles_w-75__jiXCF {
  width: 75% !important;
}

.styles_w-100__MoeQP {
  width: 100% !important;
}

.styles_w-auto__aJC38 {
  width: auto !important;
}

.styles_h-25__dnF5I {
  height: 25% !important;
}

.styles_h-50__Pv1IF {
  height: 50% !important;
}

.styles_h-75__EXUvl {
  height: 75% !important;
}

.styles_h-100__YhTWJ {
  height: 100% !important;
}

.styles_h-auto__SoSGz {
  height: auto !important;
}

.styles_mw-100__zlS5k {
  max-width: 100% !important;
}

.styles_mh-100__5v_PH {
  max-height: 100% !important;
}

.styles_min-vw-100__VjYHb {
  min-width: 100vw !important;
}

.styles_min-vh-100__PB_2Z {
  min-height: 100vh !important;
}

.styles_vw-100__xiA76 {
  width: 100vw !important;
}

.styles_vh-100__0JxHE {
  height: 100vh !important;
}

.styles_m-0__dg3t9 {
  margin: 0 !important;
}

.styles_mt-0__33zQN,
.styles_my-0__nzL0a {
  margin-top: 0 !important;
}

.styles_mr-0__YcBev,
.styles_mx-0__aj433 {
  margin-right: 0 !important;
}

.styles_mb-0__dO7t3,
.styles_my-0__nzL0a {
  margin-bottom: 0 !important;
}

.styles_ml-0__kGTbo,
.styles_mx-0__aj433 {
  margin-left: 0 !important;
}

.styles_m-1__7ZVlz {
  margin: 0.25rem !important;
}

.styles_mt-1__j5KH7,
.styles_my-1__ZX7gr {
  margin-top: 0.25rem !important;
}

.styles_mr-1__xa2Fl,
.styles_mx-1__9Asg5 {
  margin-right: 0.25rem !important;
}

.styles_mb-1__rT04u,
.styles_my-1__ZX7gr {
  margin-bottom: 0.25rem !important;
}

.styles_ml-1__zQmgK,
.styles_mx-1__9Asg5 {
  margin-left: 0.25rem !important;
}

.styles_m-2__gGaLT {
  margin: 0.5rem !important;
}

.styles_mt-2__S3ruN,
.styles_my-2__tpTqD {
  margin-top: 0.5rem !important;
}

.styles_mr-2__HR_j9,
.styles_mx-2__SaU2g {
  margin-right: 0.5rem !important;
}

.styles_mb-2__90M0a,
.styles_my-2__tpTqD {
  margin-bottom: 0.5rem !important;
}

.styles_ml-2__o7_Zo,
.styles_mx-2__SaU2g {
  margin-left: 0.5rem !important;
}

.styles_m-3__2VXCd {
  margin: 1rem !important;
}

.styles_mt-3__pHezr,
.styles_my-3__VEHWx {
  margin-top: 1rem !important;
}

.styles_mr-3__qhC0F,
.styles_mx-3__dCbPp {
  margin-right: 1rem !important;
}

.styles_mb-3__QAhfL,
.styles_my-3__VEHWx {
  margin-bottom: 1rem !important;
}

.styles_ml-3__6JUrb,
.styles_mx-3__dCbPp {
  margin-left: 1rem !important;
}

.styles_m-4__xifRp {
  margin: 1.5rem !important;
}

.styles_mt-4__sJWmn,
.styles_my-4__aHpZl {
  margin-top: 1.5rem !important;
}

.styles_mr-4__El_Xp,
.styles_mx-4__e1PTd {
  margin-right: 1.5rem !important;
}

.styles_mb-4__Dm4ze,
.styles_my-4__aHpZl {
  margin-bottom: 1.5rem !important;
}

.styles_ml-4__21I8W,
.styles_mx-4__e1PTd {
  margin-left: 1.5rem !important;
}

.styles_m-5__K7PpW {
  margin: 3rem !important;
}

.styles_mt-5__vJCAP,
.styles_my-5__P0BKw {
  margin-top: 3rem !important;
}

.styles_mr-5__LsC0K,
.styles_mx-5__7LiZg {
  margin-right: 3rem !important;
}

.styles_mb-5__jM9uC,
.styles_my-5__P0BKw {
  margin-bottom: 3rem !important;
}

.styles_ml-5__SrHP5,
.styles_mx-5__7LiZg {
  margin-left: 3rem !important;
}

.styles_p-0__4Gynk {
  padding: 0 !important;
}

.styles_pt-0__AA7C3,
.styles_py-0__rpKQH {
  padding-top: 0 !important;
}

.styles_pr-0__E9ChK,
.styles_px-0__uUTKF {
  padding-right: 0 !important;
}

.styles_pb-0__vtia5,
.styles_py-0__rpKQH {
  padding-bottom: 0 !important;
}

.styles_pl-0__TlAn_,
.styles_px-0__uUTKF {
  padding-left: 0 !important;
}

.styles_p-1__PcGL4 {
  padding: 0.25rem !important;
}

.styles_pt-1__LnpPB,
.styles_py-1__hjZ6k {
  padding-top: 0.25rem !important;
}

.styles_pr-1__X_g79,
.styles_px-1__okSmV {
  padding-right: 0.25rem !important;
}

.styles_pb-1__9bspH,
.styles_py-1__hjZ6k {
  padding-bottom: 0.25rem !important;
}

.styles_pl-1____bXG,
.styles_px-1__okSmV {
  padding-left: 0.25rem !important;
}

.styles_p-2__cTUtU {
  padding: 0.5rem !important;
}

.styles_pt-2__2vWVJ,
.styles_py-2__O9UYC {
  padding-top: 0.5rem !important;
}

.styles_pr-2__veacf,
.styles_px-2__8Yhce {
  padding-right: 0.5rem !important;
}

.styles_pb-2__VJsWM,
.styles_py-2__O9UYC {
  padding-bottom: 0.5rem !important;
}

.styles_pl-2__PaTKS,
.styles_px-2__8Yhce {
  padding-left: 0.5rem !important;
}

.styles_p-3__VCYwJ {
  padding: 1rem !important;
}

.styles_pt-3__cjd93,
.styles_py-3__1QqVC {
  padding-top: 1rem !important;
}

.styles_pr-3__HKz8t,
.styles_px-3__2y0cc {
  padding-right: 1rem !important;
}

.styles_pb-3__N75Kf,
.styles_py-3__1QqVC {
  padding-bottom: 1rem !important;
}

.styles_pl-3__Ugc3q,
.styles_px-3__2y0cc {
  padding-left: 1rem !important;
}

.styles_p-4__clKQh {
  padding: 1.5rem !important;
}

.styles_pt-4__zAJbF,
.styles_py-4__STgtm {
  padding-top: 1.5rem !important;
}

.styles_pr-4__6f3Zp,
.styles_px-4__kdm9Y {
  padding-right: 1.5rem !important;
}

.styles_pb-4__Babzl,
.styles_py-4__STgtm {
  padding-bottom: 1.5rem !important;
}

.styles_pl-4__A3Lqw,
.styles_px-4__kdm9Y {
  padding-left: 1.5rem !important;
}

.styles_p-5__Jjcqo {
  padding: 3rem !important;
}

.styles_pt-5__hXiQ6,
.styles_py-5__azOU6 {
  padding-top: 3rem !important;
}

.styles_pr-5__X7gIQ,
.styles_px-5__Zses4 {
  padding-right: 3rem !important;
}

.styles_pb-5__RmTQ4,
.styles_py-5__azOU6 {
  padding-bottom: 3rem !important;
}

.styles_pl-5__DZUa_,
.styles_px-5__Zses4 {
  padding-left: 3rem !important;
}

.styles_m-n1__N_es9 {
  margin: -0.25rem !important;
}

.styles_mt-n1__z8uvL,
.styles_my-n1__pviu7 {
  margin-top: -0.25rem !important;
}

.styles_mr-n1__XNnHp,
.styles_mx-n1__EXBl2 {
  margin-right: -0.25rem !important;
}

.styles_mb-n1__YHg38,
.styles_my-n1__pviu7 {
  margin-bottom: -0.25rem !important;
}

.styles_ml-n1__YSjV8,
.styles_mx-n1__EXBl2 {
  margin-left: -0.25rem !important;
}

.styles_m-n2__tHXwg {
  margin: -0.5rem !important;
}

.styles_mt-n2__rT1kD,
.styles_my-n2__6kBgr {
  margin-top: -0.5rem !important;
}

.styles_mr-n2__tHv00,
.styles_mx-n2__KAsI_ {
  margin-right: -0.5rem !important;
}

.styles_mb-n2__gUNSU,
.styles_my-n2__6kBgr {
  margin-bottom: -0.5rem !important;
}

.styles_ml-n2__7gosN,
.styles_mx-n2__KAsI_ {
  margin-left: -0.5rem !important;
}

.styles_m-n3__ZlEvU {
  margin: -1rem !important;
}

.styles_mt-n3__2ciKF,
.styles_my-n3__d6Kc1 {
  margin-top: -1rem !important;
}

.styles_mr-n3__c1uYx,
.styles_mx-n3__WF_pF {
  margin-right: -1rem !important;
}

.styles_mb-n3__iGyIC,
.styles_my-n3__d6Kc1 {
  margin-bottom: -1rem !important;
}

.styles_ml-n3__TF7k1,
.styles_mx-n3__WF_pF {
  margin-left: -1rem !important;
}

.styles_m-n4__1KP_K {
  margin: -1.5rem !important;
}

.styles_mt-n4__cIpB7,
.styles_my-n4__DY2f1 {
  margin-top: -1.5rem !important;
}

.styles_mr-n4__ZZxCm,
.styles_mx-n4__N9ECZ {
  margin-right: -1.5rem !important;
}

.styles_mb-n4__xXfFX,
.styles_my-n4__DY2f1 {
  margin-bottom: -1.5rem !important;
}

.styles_ml-n4__5Pkkw,
.styles_mx-n4__N9ECZ {
  margin-left: -1.5rem !important;
}

.styles_m-n5__dLFzO {
  margin: -3rem !important;
}

.styles_mt-n5__U_4f9,
.styles_my-n5__JxqnJ {
  margin-top: -3rem !important;
}

.styles_mr-n5__GvJjD,
.styles_mx-n5__FpxZq {
  margin-right: -3rem !important;
}

.styles_mb-n5__Evce_,
.styles_my-n5__JxqnJ {
  margin-bottom: -3rem !important;
}

.styles_ml-n5__1Is63,
.styles_mx-n5__FpxZq {
  margin-left: -3rem !important;
}

.styles_m-auto__m8sBZ {
  margin: auto !important;
}

.styles_mt-auto__PuYQt,
.styles_my-auto__vr3mc {
  margin-top: auto !important;
}

.styles_mr-auto__JCcTf,
.styles_mx-auto__bgtyJ {
  margin-right: auto !important;
}

.styles_mb-auto__6adoU,
.styles_my-auto__vr3mc {
  margin-bottom: auto !important;
}

.styles_ml-auto__atS7h,
.styles_mx-auto__bgtyJ {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .styles_m-sm-0__5xzrS {
    margin: 0 !important;
  }
  .styles_mt-sm-0__N5Qmd,
  .styles_my-sm-0__t80ha {
    margin-top: 0 !important;
  }
  .styles_mr-sm-0__JRI3u,
  .styles_mx-sm-0__VIIXv {
    margin-right: 0 !important;
  }
  .styles_mb-sm-0__wl46_,
  .styles_my-sm-0__t80ha {
    margin-bottom: 0 !important;
  }
  .styles_ml-sm-0__12LXv,
  .styles_mx-sm-0__VIIXv {
    margin-left: 0 !important;
  }
  .styles_m-sm-1__TMlJx {
    margin: 0.25rem !important;
  }
  .styles_mt-sm-1__I0EPR,
  .styles_my-sm-1__GBnb6 {
    margin-top: 0.25rem !important;
  }
  .styles_mr-sm-1__wjpad,
  .styles_mx-sm-1__UIdhy {
    margin-right: 0.25rem !important;
  }
  .styles_mb-sm-1__sUTv6,
  .styles_my-sm-1__GBnb6 {
    margin-bottom: 0.25rem !important;
  }
  .styles_ml-sm-1__XaN_k,
  .styles_mx-sm-1__UIdhy {
    margin-left: 0.25rem !important;
  }
  .styles_m-sm-2___RxZi {
    margin: 0.5rem !important;
  }
  .styles_mt-sm-2__gN_bO,
  .styles_my-sm-2___mbpJ {
    margin-top: 0.5rem !important;
  }
  .styles_mr-sm-2__mu2Xl,
  .styles_mx-sm-2__Fril8 {
    margin-right: 0.5rem !important;
  }
  .styles_mb-sm-2__dD6kS,
  .styles_my-sm-2___mbpJ {
    margin-bottom: 0.5rem !important;
  }
  .styles_ml-sm-2__AZcW0,
  .styles_mx-sm-2__Fril8 {
    margin-left: 0.5rem !important;
  }
  .styles_m-sm-3__EleAo {
    margin: 1rem !important;
  }
  .styles_mt-sm-3__kQSx5,
  .styles_my-sm-3__S_GfX {
    margin-top: 1rem !important;
  }
  .styles_mr-sm-3__Ecbfb,
  .styles_mx-sm-3__z6BDJ {
    margin-right: 1rem !important;
  }
  .styles_mb-sm-3__2jInR,
  .styles_my-sm-3__S_GfX {
    margin-bottom: 1rem !important;
  }
  .styles_ml-sm-3__9KhF2,
  .styles_mx-sm-3__z6BDJ {
    margin-left: 1rem !important;
  }
  .styles_m-sm-4__easha {
    margin: 1.5rem !important;
  }
  .styles_mt-sm-4__RYVfV,
  .styles_my-sm-4__uiClJ {
    margin-top: 1.5rem !important;
  }
  .styles_mr-sm-4__OKpoK,
  .styles_mx-sm-4__y5RlM {
    margin-right: 1.5rem !important;
  }
  .styles_mb-sm-4__dQiJq,
  .styles_my-sm-4__uiClJ {
    margin-bottom: 1.5rem !important;
  }
  .styles_ml-sm-4__tW08n,
  .styles_mx-sm-4__y5RlM {
    margin-left: 1.5rem !important;
  }
  .styles_m-sm-5__2CVrt {
    margin: 3rem !important;
  }
  .styles_mt-sm-5__DUHxa,
  .styles_my-sm-5__G2bXS {
    margin-top: 3rem !important;
  }
  .styles_mr-sm-5__oAvhl,
  .styles_mx-sm-5__F3_R8 {
    margin-right: 3rem !important;
  }
  .styles_mb-sm-5__4aikh,
  .styles_my-sm-5__G2bXS {
    margin-bottom: 3rem !important;
  }
  .styles_ml-sm-5__oKZtM,
  .styles_mx-sm-5__F3_R8 {
    margin-left: 3rem !important;
  }
  .styles_p-sm-0__8qu_M {
    padding: 0 !important;
  }
  .styles_pt-sm-0__J23Mx,
  .styles_py-sm-0__anb8s {
    padding-top: 0 !important;
  }
  .styles_pr-sm-0__HZSAG,
  .styles_px-sm-0__UTcWP {
    padding-right: 0 !important;
  }
  .styles_pb-sm-0__72hF_,
  .styles_py-sm-0__anb8s {
    padding-bottom: 0 !important;
  }
  .styles_pl-sm-0__ZiAQ0,
  .styles_px-sm-0__UTcWP {
    padding-left: 0 !important;
  }
  .styles_p-sm-1__CEyt6 {
    padding: 0.25rem !important;
  }
  .styles_pt-sm-1__gWjRh,
  .styles_py-sm-1__Z60F_ {
    padding-top: 0.25rem !important;
  }
  .styles_pr-sm-1___R_VN,
  .styles_px-sm-1__SNq5a {
    padding-right: 0.25rem !important;
  }
  .styles_pb-sm-1__Dm2UN,
  .styles_py-sm-1__Z60F_ {
    padding-bottom: 0.25rem !important;
  }
  .styles_pl-sm-1__OEZ8_,
  .styles_px-sm-1__SNq5a {
    padding-left: 0.25rem !important;
  }
  .styles_p-sm-2__q2mpn {
    padding: 0.5rem !important;
  }
  .styles_pt-sm-2__hY_S6,
  .styles_py-sm-2__j9_Bj {
    padding-top: 0.5rem !important;
  }
  .styles_pr-sm-2__wL2KJ,
  .styles_px-sm-2__NiAg1 {
    padding-right: 0.5rem !important;
  }
  .styles_pb-sm-2__0_H3n,
  .styles_py-sm-2__j9_Bj {
    padding-bottom: 0.5rem !important;
  }
  .styles_pl-sm-2__AmjvL,
  .styles_px-sm-2__NiAg1 {
    padding-left: 0.5rem !important;
  }
  .styles_p-sm-3__4UmWN {
    padding: 1rem !important;
  }
  .styles_pt-sm-3__w9f6k,
  .styles_py-sm-3__VBBIz {
    padding-top: 1rem !important;
  }
  .styles_pr-sm-3__q5mmZ,
  .styles_px-sm-3__ngFZy {
    padding-right: 1rem !important;
  }
  .styles_pb-sm-3__PVlqA,
  .styles_py-sm-3__VBBIz {
    padding-bottom: 1rem !important;
  }
  .styles_pl-sm-3__XKvAN,
  .styles_px-sm-3__ngFZy {
    padding-left: 1rem !important;
  }
  .styles_p-sm-4__WJNwI {
    padding: 1.5rem !important;
  }
  .styles_pt-sm-4__FQqU_,
  .styles_py-sm-4__SFwY_ {
    padding-top: 1.5rem !important;
  }
  .styles_pr-sm-4__l0Rt_,
  .styles_px-sm-4__xUSUd {
    padding-right: 1.5rem !important;
  }
  .styles_pb-sm-4__RvDaC,
  .styles_py-sm-4__SFwY_ {
    padding-bottom: 1.5rem !important;
  }
  .styles_pl-sm-4__FW4N8,
  .styles_px-sm-4__xUSUd {
    padding-left: 1.5rem !important;
  }
  .styles_p-sm-5__HA0g7 {
    padding: 3rem !important;
  }
  .styles_pt-sm-5__TQtHz,
  .styles_py-sm-5__3lHOS {
    padding-top: 3rem !important;
  }
  .styles_pr-sm-5__7lmsu,
  .styles_px-sm-5__tiJtl {
    padding-right: 3rem !important;
  }
  .styles_pb-sm-5__W9lTx,
  .styles_py-sm-5__3lHOS {
    padding-bottom: 3rem !important;
  }
  .styles_pl-sm-5__nXR1K,
  .styles_px-sm-5__tiJtl {
    padding-left: 3rem !important;
  }
  .styles_m-sm-n1__OEDa_ {
    margin: -0.25rem !important;
  }
  .styles_mt-sm-n1__vHI3H,
  .styles_my-sm-n1__1uAht {
    margin-top: -0.25rem !important;
  }
  .styles_mr-sm-n1__IYJiZ,
  .styles_mx-sm-n1__Dmhrq {
    margin-right: -0.25rem !important;
  }
  .styles_mb-sm-n1__vcgOu,
  .styles_my-sm-n1__1uAht {
    margin-bottom: -0.25rem !important;
  }
  .styles_ml-sm-n1__61_q0,
  .styles_mx-sm-n1__Dmhrq {
    margin-left: -0.25rem !important;
  }
  .styles_m-sm-n2__Hto7y {
    margin: -0.5rem !important;
  }
  .styles_mt-sm-n2__PRmtl,
  .styles_my-sm-n2__DpErL {
    margin-top: -0.5rem !important;
  }
  .styles_mr-sm-n2__fIRVM,
  .styles_mx-sm-n2__DbRxw {
    margin-right: -0.5rem !important;
  }
  .styles_mb-sm-n2__isUHY,
  .styles_my-sm-n2__DpErL {
    margin-bottom: -0.5rem !important;
  }
  .styles_ml-sm-n2__UXLIO,
  .styles_mx-sm-n2__DbRxw {
    margin-left: -0.5rem !important;
  }
  .styles_m-sm-n3__ySOe6 {
    margin: -1rem !important;
  }
  .styles_mt-sm-n3__qQ9hr,
  .styles_my-sm-n3___8KRB {
    margin-top: -1rem !important;
  }
  .styles_mr-sm-n3___qZT_,
  .styles_mx-sm-n3__CVE1S {
    margin-right: -1rem !important;
  }
  .styles_mb-sm-n3__oRRrC,
  .styles_my-sm-n3___8KRB {
    margin-bottom: -1rem !important;
  }
  .styles_ml-sm-n3__QRyAU,
  .styles_mx-sm-n3__CVE1S {
    margin-left: -1rem !important;
  }
  .styles_m-sm-n4__Pyu8B {
    margin: -1.5rem !important;
  }
  .styles_mt-sm-n4__YpFZU,
  .styles_my-sm-n4__XkRea {
    margin-top: -1.5rem !important;
  }
  .styles_mr-sm-n4___r1bW,
  .styles_mx-sm-n4__cySsN {
    margin-right: -1.5rem !important;
  }
  .styles_mb-sm-n4__HzXw7,
  .styles_my-sm-n4__XkRea {
    margin-bottom: -1.5rem !important;
  }
  .styles_ml-sm-n4__tL2_B,
  .styles_mx-sm-n4__cySsN {
    margin-left: -1.5rem !important;
  }
  .styles_m-sm-n5__mm4g9 {
    margin: -3rem !important;
  }
  .styles_mt-sm-n5__U_wXc,
  .styles_my-sm-n5__5riOX {
    margin-top: -3rem !important;
  }
  .styles_mr-sm-n5__8MT2o,
  .styles_mx-sm-n5__jHhFP {
    margin-right: -3rem !important;
  }
  .styles_mb-sm-n5__F__8C,
  .styles_my-sm-n5__5riOX {
    margin-bottom: -3rem !important;
  }
  .styles_ml-sm-n5__7NdyO,
  .styles_mx-sm-n5__jHhFP {
    margin-left: -3rem !important;
  }
  .styles_m-sm-auto__BNCBi {
    margin: auto !important;
  }
  .styles_mt-sm-auto__KtlzR,
  .styles_my-sm-auto__szJjJ {
    margin-top: auto !important;
  }
  .styles_mr-sm-auto__AEcNE,
  .styles_mx-sm-auto__FYV7W {
    margin-right: auto !important;
  }
  .styles_mb-sm-auto__rXxEo,
  .styles_my-sm-auto__szJjJ {
    margin-bottom: auto !important;
  }
  .styles_ml-sm-auto__U9Z8K,
  .styles_mx-sm-auto__FYV7W {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .styles_m-md-0__D92zS {
    margin: 0 !important;
  }
  .styles_mt-md-0__1KL_J,
  .styles_my-md-0__QmhBP {
    margin-top: 0 !important;
  }
  .styles_mr-md-0__FSWhF,
  .styles_mx-md-0__rW1OY {
    margin-right: 0 !important;
  }
  .styles_mb-md-0__4CzqB,
  .styles_my-md-0__QmhBP {
    margin-bottom: 0 !important;
  }
  .styles_ml-md-0__LEe1d,
  .styles_mx-md-0__rW1OY {
    margin-left: 0 !important;
  }
  .styles_m-md-1__B9FzK {
    margin: 0.25rem !important;
  }
  .styles_mt-md-1__0iUs_,
  .styles_my-md-1__kJOHG {
    margin-top: 0.25rem !important;
  }
  .styles_mr-md-1__U9Mp2,
  .styles_mx-md-1__r4PIs {
    margin-right: 0.25rem !important;
  }
  .styles_mb-md-1__c28pw,
  .styles_my-md-1__kJOHG {
    margin-bottom: 0.25rem !important;
  }
  .styles_ml-md-1__dVQap,
  .styles_mx-md-1__r4PIs {
    margin-left: 0.25rem !important;
  }
  .styles_m-md-2__OPKr7 {
    margin: 0.5rem !important;
  }
  .styles_mt-md-2__onsZq,
  .styles_my-md-2__XYk05 {
    margin-top: 0.5rem !important;
  }
  .styles_mr-md-2__Km53q,
  .styles_mx-md-2__ChySF {
    margin-right: 0.5rem !important;
  }
  .styles_mb-md-2__TCKNr,
  .styles_my-md-2__XYk05 {
    margin-bottom: 0.5rem !important;
  }
  .styles_ml-md-2__braBO,
  .styles_mx-md-2__ChySF {
    margin-left: 0.5rem !important;
  }
  .styles_m-md-3____Pom {
    margin: 1rem !important;
  }
  .styles_mt-md-3__pUvJa,
  .styles_my-md-3__gLquY {
    margin-top: 1rem !important;
  }
  .styles_mr-md-3__61mQH,
  .styles_mx-md-3__yuG8N {
    margin-right: 1rem !important;
  }
  .styles_mb-md-3__KXIiq,
  .styles_my-md-3__gLquY {
    margin-bottom: 1rem !important;
  }
  .styles_ml-md-3__F1bvP,
  .styles_mx-md-3__yuG8N {
    margin-left: 1rem !important;
  }
  .styles_m-md-4__pnde0 {
    margin: 1.5rem !important;
  }
  .styles_mt-md-4__kVS7r,
  .styles_my-md-4__dcKFN {
    margin-top: 1.5rem !important;
  }
  .styles_mr-md-4__UH2k_,
  .styles_mx-md-4__sbH5B {
    margin-right: 1.5rem !important;
  }
  .styles_mb-md-4__at4ij,
  .styles_my-md-4__dcKFN {
    margin-bottom: 1.5rem !important;
  }
  .styles_ml-md-4__2vEXv,
  .styles_mx-md-4__sbH5B {
    margin-left: 1.5rem !important;
  }
  .styles_m-md-5__IrKmm {
    margin: 3rem !important;
  }
  .styles_mt-md-5__a438V,
  .styles_my-md-5__oEBHz {
    margin-top: 3rem !important;
  }
  .styles_mr-md-5__IFRUw,
  .styles_mx-md-5__P3v46 {
    margin-right: 3rem !important;
  }
  .styles_mb-md-5__GP1mw,
  .styles_my-md-5__oEBHz {
    margin-bottom: 3rem !important;
  }
  .styles_ml-md-5___F0LJ,
  .styles_mx-md-5__P3v46 {
    margin-left: 3rem !important;
  }
  .styles_p-md-0__DigbM {
    padding: 0 !important;
  }
  .styles_pt-md-0__mAnHu,
  .styles_py-md-0__74RNC {
    padding-top: 0 !important;
  }
  .styles_pr-md-0__6HAAW,
  .styles_px-md-0__Cy4ez {
    padding-right: 0 !important;
  }
  .styles_pb-md-0__a_p0b,
  .styles_py-md-0__74RNC {
    padding-bottom: 0 !important;
  }
  .styles_pl-md-0__kPttS,
  .styles_px-md-0__Cy4ez {
    padding-left: 0 !important;
  }
  .styles_p-md-1__8ZOMU {
    padding: 0.25rem !important;
  }
  .styles_pt-md-1__Z_v5j,
  .styles_py-md-1__566cV {
    padding-top: 0.25rem !important;
  }
  .styles_pr-md-1__WFpCo,
  .styles_px-md-1__gcsky {
    padding-right: 0.25rem !important;
  }
  .styles_pb-md-1__NFPtw,
  .styles_py-md-1__566cV {
    padding-bottom: 0.25rem !important;
  }
  .styles_pl-md-1__p2mpW,
  .styles_px-md-1__gcsky {
    padding-left: 0.25rem !important;
  }
  .styles_p-md-2__pnWaG {
    padding: 0.5rem !important;
  }
  .styles_pt-md-2__uS7NX,
  .styles_py-md-2__TPdtW {
    padding-top: 0.5rem !important;
  }
  .styles_pr-md-2__0l6f4,
  .styles_px-md-2__7x6W6 {
    padding-right: 0.5rem !important;
  }
  .styles_pb-md-2__dy78H,
  .styles_py-md-2__TPdtW {
    padding-bottom: 0.5rem !important;
  }
  .styles_pl-md-2__101VJ,
  .styles_px-md-2__7x6W6 {
    padding-left: 0.5rem !important;
  }
  .styles_p-md-3__5UPkT {
    padding: 1rem !important;
  }
  .styles_pt-md-3__5IVZf,
  .styles_py-md-3__J2nrt {
    padding-top: 1rem !important;
  }
  .styles_pr-md-3__6bli2,
  .styles_px-md-3___uqLm {
    padding-right: 1rem !important;
  }
  .styles_pb-md-3__6yJvK,
  .styles_py-md-3__J2nrt {
    padding-bottom: 1rem !important;
  }
  .styles_pl-md-3__KMzAP,
  .styles_px-md-3___uqLm {
    padding-left: 1rem !important;
  }
  .styles_p-md-4__d_lAE {
    padding: 1.5rem !important;
  }
  .styles_pt-md-4__o7zJo,
  .styles_py-md-4__6JWED {
    padding-top: 1.5rem !important;
  }
  .styles_pr-md-4__kVDt3,
  .styles_px-md-4__JID_u {
    padding-right: 1.5rem !important;
  }
  .styles_pb-md-4__fM1k4,
  .styles_py-md-4__6JWED {
    padding-bottom: 1.5rem !important;
  }
  .styles_pl-md-4__ZDB90,
  .styles_px-md-4__JID_u {
    padding-left: 1.5rem !important;
  }
  .styles_p-md-5__5nkEQ {
    padding: 3rem !important;
  }
  .styles_pt-md-5__lOtJj,
  .styles_py-md-5__0if5n {
    padding-top: 3rem !important;
  }
  .styles_pr-md-5__cdykv,
  .styles_px-md-5__uSBle {
    padding-right: 3rem !important;
  }
  .styles_pb-md-5__kbeQ9,
  .styles_py-md-5__0if5n {
    padding-bottom: 3rem !important;
  }
  .styles_pl-md-5__ZJIfS,
  .styles_px-md-5__uSBle {
    padding-left: 3rem !important;
  }
  .styles_m-md-n1__T3hai {
    margin: -0.25rem !important;
  }
  .styles_mt-md-n1__UQ8Uf,
  .styles_my-md-n1__HIMQH {
    margin-top: -0.25rem !important;
  }
  .styles_mr-md-n1__AfbFX,
  .styles_mx-md-n1__uY4r0 {
    margin-right: -0.25rem !important;
  }
  .styles_mb-md-n1__8o63z,
  .styles_my-md-n1__HIMQH {
    margin-bottom: -0.25rem !important;
  }
  .styles_ml-md-n1__1VpFk,
  .styles_mx-md-n1__uY4r0 {
    margin-left: -0.25rem !important;
  }
  .styles_m-md-n2__EVcsi {
    margin: -0.5rem !important;
  }
  .styles_mt-md-n2__EnTi2,
  .styles_my-md-n2__qbjjX {
    margin-top: -0.5rem !important;
  }
  .styles_mr-md-n2__j75eT,
  .styles_mx-md-n2__WTeM_ {
    margin-right: -0.5rem !important;
  }
  .styles_mb-md-n2__bqWt9,
  .styles_my-md-n2__qbjjX {
    margin-bottom: -0.5rem !important;
  }
  .styles_ml-md-n2__VgzPd,
  .styles_mx-md-n2__WTeM_ {
    margin-left: -0.5rem !important;
  }
  .styles_m-md-n3__Ke8Hw {
    margin: -1rem !important;
  }
  .styles_mt-md-n3__mpp2J,
  .styles_my-md-n3__4hGd5 {
    margin-top: -1rem !important;
  }
  .styles_mr-md-n3__WBiY0,
  .styles_mx-md-n3__eHuHD {
    margin-right: -1rem !important;
  }
  .styles_mb-md-n3__TFdLi,
  .styles_my-md-n3__4hGd5 {
    margin-bottom: -1rem !important;
  }
  .styles_ml-md-n3__YDtwn,
  .styles_mx-md-n3__eHuHD {
    margin-left: -1rem !important;
  }
  .styles_m-md-n4__VEMkx {
    margin: -1.5rem !important;
  }
  .styles_mt-md-n4__mlQ5x,
  .styles_my-md-n4__Sa2wv {
    margin-top: -1.5rem !important;
  }
  .styles_mr-md-n4__Rf9_x,
  .styles_mx-md-n4__Ih9u2 {
    margin-right: -1.5rem !important;
  }
  .styles_mb-md-n4__5Tdrt,
  .styles_my-md-n4__Sa2wv {
    margin-bottom: -1.5rem !important;
  }
  .styles_ml-md-n4__oqB7a,
  .styles_mx-md-n4__Ih9u2 {
    margin-left: -1.5rem !important;
  }
  .styles_m-md-n5__HJCLo {
    margin: -3rem !important;
  }
  .styles_mt-md-n5__P2HYD,
  .styles_my-md-n5__LzB_C {
    margin-top: -3rem !important;
  }
  .styles_mr-md-n5__ckReY,
  .styles_mx-md-n5__oYbVb {
    margin-right: -3rem !important;
  }
  .styles_mb-md-n5__kNDO_,
  .styles_my-md-n5__LzB_C {
    margin-bottom: -3rem !important;
  }
  .styles_ml-md-n5__M1cA3,
  .styles_mx-md-n5__oYbVb {
    margin-left: -3rem !important;
  }
  .styles_m-md-auto__2ukXP {
    margin: auto !important;
  }
  .styles_mt-md-auto__ZKBPG,
  .styles_my-md-auto__7wvSq {
    margin-top: auto !important;
  }
  .styles_mr-md-auto__a7gPP,
  .styles_mx-md-auto__fgAJ6 {
    margin-right: auto !important;
  }
  .styles_mb-md-auto__Mwsrg,
  .styles_my-md-auto__7wvSq {
    margin-bottom: auto !important;
  }
  .styles_ml-md-auto__U5XNy,
  .styles_mx-md-auto__fgAJ6 {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .styles_m-lg-0__u_rig {
    margin: 0 !important;
  }
  .styles_mt-lg-0__KZjpk,
  .styles_my-lg-0__xoAok {
    margin-top: 0 !important;
  }
  .styles_mr-lg-0__gSTTq,
  .styles_mx-lg-0__FDmr5 {
    margin-right: 0 !important;
  }
  .styles_mb-lg-0__PluAm,
  .styles_my-lg-0__xoAok {
    margin-bottom: 0 !important;
  }
  .styles_ml-lg-0__wV9yn,
  .styles_mx-lg-0__FDmr5 {
    margin-left: 0 !important;
  }
  .styles_m-lg-1__UBv3O {
    margin: 0.25rem !important;
  }
  .styles_mt-lg-1__6_mie,
  .styles_my-lg-1__b82j9 {
    margin-top: 0.25rem !important;
  }
  .styles_mr-lg-1__R1shT,
  .styles_mx-lg-1__6W9ev {
    margin-right: 0.25rem !important;
  }
  .styles_mb-lg-1__6nxJl,
  .styles_my-lg-1__b82j9 {
    margin-bottom: 0.25rem !important;
  }
  .styles_ml-lg-1__pE55w,
  .styles_mx-lg-1__6W9ev {
    margin-left: 0.25rem !important;
  }
  .styles_m-lg-2__ggRWQ {
    margin: 0.5rem !important;
  }
  .styles_mt-lg-2__NFATi,
  .styles_my-lg-2___v015 {
    margin-top: 0.5rem !important;
  }
  .styles_mr-lg-2__h5_xX,
  .styles_mx-lg-2__W0wgG {
    margin-right: 0.5rem !important;
  }
  .styles_mb-lg-2__QppdP,
  .styles_my-lg-2___v015 {
    margin-bottom: 0.5rem !important;
  }
  .styles_ml-lg-2__W7WQ8,
  .styles_mx-lg-2__W0wgG {
    margin-left: 0.5rem !important;
  }
  .styles_m-lg-3__Bx_kY {
    margin: 1rem !important;
  }
  .styles_mt-lg-3__eEgiq,
  .styles_my-lg-3__MqX5P {
    margin-top: 1rem !important;
  }
  .styles_mr-lg-3__hIkoa,
  .styles_mx-lg-3__vOKiW {
    margin-right: 1rem !important;
  }
  .styles_mb-lg-3__yjUsU,
  .styles_my-lg-3__MqX5P {
    margin-bottom: 1rem !important;
  }
  .styles_ml-lg-3__zUxxy,
  .styles_mx-lg-3__vOKiW {
    margin-left: 1rem !important;
  }
  .styles_m-lg-4__vBMUw {
    margin: 1.5rem !important;
  }
  .styles_mt-lg-4__EI2Ve,
  .styles_my-lg-4__EeLpj {
    margin-top: 1.5rem !important;
  }
  .styles_mr-lg-4__uIAJV,
  .styles_mx-lg-4__2AF6m {
    margin-right: 1.5rem !important;
  }
  .styles_mb-lg-4__Cium_,
  .styles_my-lg-4__EeLpj {
    margin-bottom: 1.5rem !important;
  }
  .styles_ml-lg-4__rLbPO,
  .styles_mx-lg-4__2AF6m {
    margin-left: 1.5rem !important;
  }
  .styles_m-lg-5__0W4qx {
    margin: 3rem !important;
  }
  .styles_mt-lg-5__c6yOT,
  .styles_my-lg-5__3A25q {
    margin-top: 3rem !important;
  }
  .styles_mr-lg-5__5ejUS,
  .styles_mx-lg-5__kssin {
    margin-right: 3rem !important;
  }
  .styles_mb-lg-5__dV_z1,
  .styles_my-lg-5__3A25q {
    margin-bottom: 3rem !important;
  }
  .styles_ml-lg-5__jT1ON,
  .styles_mx-lg-5__kssin {
    margin-left: 3rem !important;
  }
  .styles_p-lg-0__umWs2 {
    padding: 0 !important;
  }
  .styles_pt-lg-0__VHNOL,
  .styles_py-lg-0__34b4R {
    padding-top: 0 !important;
  }
  .styles_pr-lg-0__6Ebkg,
  .styles_px-lg-0__l6IeT {
    padding-right: 0 !important;
  }
  .styles_pb-lg-0__1RlAo,
  .styles_py-lg-0__34b4R {
    padding-bottom: 0 !important;
  }
  .styles_pl-lg-0__LKJby,
  .styles_px-lg-0__l6IeT {
    padding-left: 0 !important;
  }
  .styles_p-lg-1__s8lhG {
    padding: 0.25rem !important;
  }
  .styles_pt-lg-1__F8E3U,
  .styles_py-lg-1__QijTp {
    padding-top: 0.25rem !important;
  }
  .styles_pr-lg-1__JXAnE,
  .styles_px-lg-1__o6nFw {
    padding-right: 0.25rem !important;
  }
  .styles_pb-lg-1__CQs6L,
  .styles_py-lg-1__QijTp {
    padding-bottom: 0.25rem !important;
  }
  .styles_pl-lg-1__upJdT,
  .styles_px-lg-1__o6nFw {
    padding-left: 0.25rem !important;
  }
  .styles_p-lg-2__aqnsB {
    padding: 0.5rem !important;
  }
  .styles_pt-lg-2__48J8n,
  .styles_py-lg-2__69elE {
    padding-top: 0.5rem !important;
  }
  .styles_pr-lg-2__9Uhmk,
  .styles_px-lg-2__itkO4 {
    padding-right: 0.5rem !important;
  }
  .styles_pb-lg-2__wDCVs,
  .styles_py-lg-2__69elE {
    padding-bottom: 0.5rem !important;
  }
  .styles_pl-lg-2__0gWdY,
  .styles_px-lg-2__itkO4 {
    padding-left: 0.5rem !important;
  }
  .styles_p-lg-3__xos5r {
    padding: 1rem !important;
  }
  .styles_pt-lg-3__fohL6,
  .styles_py-lg-3__dyxiX {
    padding-top: 1rem !important;
  }
  .styles_pr-lg-3__HyRQV,
  .styles_px-lg-3__RWQBX {
    padding-right: 1rem !important;
  }
  .styles_pb-lg-3__Xo0rI,
  .styles_py-lg-3__dyxiX {
    padding-bottom: 1rem !important;
  }
  .styles_pl-lg-3__4w4Ce,
  .styles_px-lg-3__RWQBX {
    padding-left: 1rem !important;
  }
  .styles_p-lg-4__1M6yi {
    padding: 1.5rem !important;
  }
  .styles_pt-lg-4__Lxz7l,
  .styles_py-lg-4__LCtF_ {
    padding-top: 1.5rem !important;
  }
  .styles_pr-lg-4__P1FF3,
  .styles_px-lg-4__Hfbhs {
    padding-right: 1.5rem !important;
  }
  .styles_pb-lg-4__MoVhQ,
  .styles_py-lg-4__LCtF_ {
    padding-bottom: 1.5rem !important;
  }
  .styles_pl-lg-4__56dTN,
  .styles_px-lg-4__Hfbhs {
    padding-left: 1.5rem !important;
  }
  .styles_p-lg-5__YWNvy {
    padding: 3rem !important;
  }
  .styles_pt-lg-5__UuqwB,
  .styles_py-lg-5__b_9iV {
    padding-top: 3rem !important;
  }
  .styles_pr-lg-5___EKRh,
  .styles_px-lg-5__wMuqq {
    padding-right: 3rem !important;
  }
  .styles_pb-lg-5__g2RaC,
  .styles_py-lg-5__b_9iV {
    padding-bottom: 3rem !important;
  }
  .styles_pl-lg-5__WeADQ,
  .styles_px-lg-5__wMuqq {
    padding-left: 3rem !important;
  }
  .styles_m-lg-n1___H27d {
    margin: -0.25rem !important;
  }
  .styles_mt-lg-n1__9LSd6,
  .styles_my-lg-n1__Xdhh0 {
    margin-top: -0.25rem !important;
  }
  .styles_mr-lg-n1__RFpUu,
  .styles_mx-lg-n1__h1b_J {
    margin-right: -0.25rem !important;
  }
  .styles_mb-lg-n1__dHO9Y,
  .styles_my-lg-n1__Xdhh0 {
    margin-bottom: -0.25rem !important;
  }
  .styles_ml-lg-n1__GrTwe,
  .styles_mx-lg-n1__h1b_J {
    margin-left: -0.25rem !important;
  }
  .styles_m-lg-n2__MzJRn {
    margin: -0.5rem !important;
  }
  .styles_mt-lg-n2__CGg6x,
  .styles_my-lg-n2__oXuFq {
    margin-top: -0.5rem !important;
  }
  .styles_mr-lg-n2__q1SX3,
  .styles_mx-lg-n2__o0439 {
    margin-right: -0.5rem !important;
  }
  .styles_mb-lg-n2__PZbs_,
  .styles_my-lg-n2__oXuFq {
    margin-bottom: -0.5rem !important;
  }
  .styles_ml-lg-n2___9h02,
  .styles_mx-lg-n2__o0439 {
    margin-left: -0.5rem !important;
  }
  .styles_m-lg-n3__T7Z_H {
    margin: -1rem !important;
  }
  .styles_mt-lg-n3__TlmJY,
  .styles_my-lg-n3__axfog {
    margin-top: -1rem !important;
  }
  .styles_mr-lg-n3__kXTU_,
  .styles_mx-lg-n3__2q8Qr {
    margin-right: -1rem !important;
  }
  .styles_mb-lg-n3__kBO6r,
  .styles_my-lg-n3__axfog {
    margin-bottom: -1rem !important;
  }
  .styles_ml-lg-n3__i4UR1,
  .styles_mx-lg-n3__2q8Qr {
    margin-left: -1rem !important;
  }
  .styles_m-lg-n4__xh1cx {
    margin: -1.5rem !important;
  }
  .styles_mt-lg-n4__LxBZO,
  .styles_my-lg-n4__26LXE {
    margin-top: -1.5rem !important;
  }
  .styles_mr-lg-n4__dQH8o,
  .styles_mx-lg-n4__I6XfW {
    margin-right: -1.5rem !important;
  }
  .styles_mb-lg-n4__nGRME,
  .styles_my-lg-n4__26LXE {
    margin-bottom: -1.5rem !important;
  }
  .styles_ml-lg-n4__koqxr,
  .styles_mx-lg-n4__I6XfW {
    margin-left: -1.5rem !important;
  }
  .styles_m-lg-n5__hpGrH {
    margin: -3rem !important;
  }
  .styles_mt-lg-n5__GwN7m,
  .styles_my-lg-n5__31nZ4 {
    margin-top: -3rem !important;
  }
  .styles_mr-lg-n5__u05HN,
  .styles_mx-lg-n5__fdZbN {
    margin-right: -3rem !important;
  }
  .styles_mb-lg-n5__SkfvZ,
  .styles_my-lg-n5__31nZ4 {
    margin-bottom: -3rem !important;
  }
  .styles_ml-lg-n5__1oe9Z,
  .styles_mx-lg-n5__fdZbN {
    margin-left: -3rem !important;
  }
  .styles_m-lg-auto__RM0Mp {
    margin: auto !important;
  }
  .styles_mt-lg-auto__8t8nF,
  .styles_my-lg-auto__HDFVg {
    margin-top: auto !important;
  }
  .styles_mr-lg-auto__xnJcv,
  .styles_mx-lg-auto__aTqrl {
    margin-right: auto !important;
  }
  .styles_mb-lg-auto__TOHCg,
  .styles_my-lg-auto__HDFVg {
    margin-bottom: auto !important;
  }
  .styles_ml-lg-auto__CQO9h,
  .styles_mx-lg-auto__aTqrl {
    margin-left: auto !important;
  }
}

@media (min-width: 1260px) {
  .styles_m-xl-0__LNDK6 {
    margin: 0 !important;
  }
  .styles_mt-xl-0__Tncbv,
  .styles_my-xl-0__mVutV {
    margin-top: 0 !important;
  }
  .styles_mr-xl-0__FKB3c,
  .styles_mx-xl-0__c_QLK {
    margin-right: 0 !important;
  }
  .styles_mb-xl-0__Y7Ie9,
  .styles_my-xl-0__mVutV {
    margin-bottom: 0 !important;
  }
  .styles_ml-xl-0__1YdXD,
  .styles_mx-xl-0__c_QLK {
    margin-left: 0 !important;
  }
  .styles_m-xl-1__VC7bR {
    margin: 0.25rem !important;
  }
  .styles_mt-xl-1__uE5m6,
  .styles_my-xl-1__qJlhN {
    margin-top: 0.25rem !important;
  }
  .styles_mr-xl-1__sh8ck,
  .styles_mx-xl-1__LW4Bj {
    margin-right: 0.25rem !important;
  }
  .styles_mb-xl-1__mcyDX,
  .styles_my-xl-1__qJlhN {
    margin-bottom: 0.25rem !important;
  }
  .styles_ml-xl-1__QiBgl,
  .styles_mx-xl-1__LW4Bj {
    margin-left: 0.25rem !important;
  }
  .styles_m-xl-2__JdeIm {
    margin: 0.5rem !important;
  }
  .styles_mt-xl-2__NwOsZ,
  .styles_my-xl-2__3S4Ks {
    margin-top: 0.5rem !important;
  }
  .styles_mr-xl-2__iQ759,
  .styles_mx-xl-2__dgX1r {
    margin-right: 0.5rem !important;
  }
  .styles_mb-xl-2__tV6_m,
  .styles_my-xl-2__3S4Ks {
    margin-bottom: 0.5rem !important;
  }
  .styles_ml-xl-2__6kLmz,
  .styles_mx-xl-2__dgX1r {
    margin-left: 0.5rem !important;
  }
  .styles_m-xl-3__hluQ4 {
    margin: 1rem !important;
  }
  .styles_mt-xl-3__Jl3uv,
  .styles_my-xl-3__VPP5X {
    margin-top: 1rem !important;
  }
  .styles_mr-xl-3__C_2Sl,
  .styles_mx-xl-3__pLRSG {
    margin-right: 1rem !important;
  }
  .styles_mb-xl-3__4lSq6,
  .styles_my-xl-3__VPP5X {
    margin-bottom: 1rem !important;
  }
  .styles_ml-xl-3__7MNKi,
  .styles_mx-xl-3__pLRSG {
    margin-left: 1rem !important;
  }
  .styles_m-xl-4__F2eQu {
    margin: 1.5rem !important;
  }
  .styles_mt-xl-4___luxS,
  .styles_my-xl-4__NEoJ_ {
    margin-top: 1.5rem !important;
  }
  .styles_mr-xl-4__bYwFo,
  .styles_mx-xl-4__tteRH {
    margin-right: 1.5rem !important;
  }
  .styles_mb-xl-4__Jd2sH,
  .styles_my-xl-4__NEoJ_ {
    margin-bottom: 1.5rem !important;
  }
  .styles_ml-xl-4__5CaJt,
  .styles_mx-xl-4__tteRH {
    margin-left: 1.5rem !important;
  }
  .styles_m-xl-5__2oDIM {
    margin: 3rem !important;
  }
  .styles_mt-xl-5__WmnJB,
  .styles_my-xl-5__k1E_8 {
    margin-top: 3rem !important;
  }
  .styles_mr-xl-5__Yt0_d,
  .styles_mx-xl-5__bLUTb {
    margin-right: 3rem !important;
  }
  .styles_mb-xl-5__Yush2,
  .styles_my-xl-5__k1E_8 {
    margin-bottom: 3rem !important;
  }
  .styles_ml-xl-5__2z8rt,
  .styles_mx-xl-5__bLUTb {
    margin-left: 3rem !important;
  }
  .styles_p-xl-0__9mHZf {
    padding: 0 !important;
  }
  .styles_pt-xl-0__aJX_Z,
  .styles_py-xl-0__zzKS0 {
    padding-top: 0 !important;
  }
  .styles_pr-xl-0__ZELNs,
  .styles_px-xl-0__AJB_6 {
    padding-right: 0 !important;
  }
  .styles_pb-xl-0__I1wmR,
  .styles_py-xl-0__zzKS0 {
    padding-bottom: 0 !important;
  }
  .styles_pl-xl-0__8vJmV,
  .styles_px-xl-0__AJB_6 {
    padding-left: 0 !important;
  }
  .styles_p-xl-1__nt2yT {
    padding: 0.25rem !important;
  }
  .styles_pt-xl-1__CmLC8,
  .styles_py-xl-1__KSxTd {
    padding-top: 0.25rem !important;
  }
  .styles_pr-xl-1__LXz86,
  .styles_px-xl-1__UB5L6 {
    padding-right: 0.25rem !important;
  }
  .styles_pb-xl-1__opRdx,
  .styles_py-xl-1__KSxTd {
    padding-bottom: 0.25rem !important;
  }
  .styles_pl-xl-1__9_tVt,
  .styles_px-xl-1__UB5L6 {
    padding-left: 0.25rem !important;
  }
  .styles_p-xl-2__sIlTi {
    padding: 0.5rem !important;
  }
  .styles_pt-xl-2__urO6W,
  .styles_py-xl-2__jV_gp {
    padding-top: 0.5rem !important;
  }
  .styles_pr-xl-2__Mdt1_,
  .styles_px-xl-2__mIBvk {
    padding-right: 0.5rem !important;
  }
  .styles_pb-xl-2__rE_k_,
  .styles_py-xl-2__jV_gp {
    padding-bottom: 0.5rem !important;
  }
  .styles_pl-xl-2__wiGFA,
  .styles_px-xl-2__mIBvk {
    padding-left: 0.5rem !important;
  }
  .styles_p-xl-3__P1OIf {
    padding: 1rem !important;
  }
  .styles_pt-xl-3__DFmpa,
  .styles_py-xl-3__PsZPa {
    padding-top: 1rem !important;
  }
  .styles_pr-xl-3__w5P5L,
  .styles_px-xl-3__B4uhC {
    padding-right: 1rem !important;
  }
  .styles_pb-xl-3__OV9fx,
  .styles_py-xl-3__PsZPa {
    padding-bottom: 1rem !important;
  }
  .styles_pl-xl-3__JBg8t,
  .styles_px-xl-3__B4uhC {
    padding-left: 1rem !important;
  }
  .styles_p-xl-4__hApoi {
    padding: 1.5rem !important;
  }
  .styles_pt-xl-4__JFq0U,
  .styles_py-xl-4__1EthK {
    padding-top: 1.5rem !important;
  }
  .styles_pr-xl-4__hMxZl,
  .styles_px-xl-4__cFO6s {
    padding-right: 1.5rem !important;
  }
  .styles_pb-xl-4__VDITn,
  .styles_py-xl-4__1EthK {
    padding-bottom: 1.5rem !important;
  }
  .styles_pl-xl-4__lNWZP,
  .styles_px-xl-4__cFO6s {
    padding-left: 1.5rem !important;
  }
  .styles_p-xl-5__8hHFA {
    padding: 3rem !important;
  }
  .styles_pt-xl-5__y0dvu,
  .styles_py-xl-5__XrtTp {
    padding-top: 3rem !important;
  }
  .styles_pr-xl-5__n0LX7,
  .styles_px-xl-5__IKL8D {
    padding-right: 3rem !important;
  }
  .styles_pb-xl-5__U3SXv,
  .styles_py-xl-5__XrtTp {
    padding-bottom: 3rem !important;
  }
  .styles_pl-xl-5__xTzoM,
  .styles_px-xl-5__IKL8D {
    padding-left: 3rem !important;
  }
  .styles_m-xl-n1__fPOPX {
    margin: -0.25rem !important;
  }
  .styles_mt-xl-n1__GvWY2,
  .styles_my-xl-n1__UikFP {
    margin-top: -0.25rem !important;
  }
  .styles_mr-xl-n1__IWdJg,
  .styles_mx-xl-n1__5L_UN {
    margin-right: -0.25rem !important;
  }
  .styles_mb-xl-n1__ke042,
  .styles_my-xl-n1__UikFP {
    margin-bottom: -0.25rem !important;
  }
  .styles_ml-xl-n1__8EqEJ,
  .styles_mx-xl-n1__5L_UN {
    margin-left: -0.25rem !important;
  }
  .styles_m-xl-n2__yad0v {
    margin: -0.5rem !important;
  }
  .styles_mt-xl-n2__tOTy1,
  .styles_my-xl-n2__0qMDD {
    margin-top: -0.5rem !important;
  }
  .styles_mr-xl-n2__VP5b1,
  .styles_mx-xl-n2__k7zKu {
    margin-right: -0.5rem !important;
  }
  .styles_mb-xl-n2__E9W5e,
  .styles_my-xl-n2__0qMDD {
    margin-bottom: -0.5rem !important;
  }
  .styles_ml-xl-n2__rzpyr,
  .styles_mx-xl-n2__k7zKu {
    margin-left: -0.5rem !important;
  }
  .styles_m-xl-n3__huUZn {
    margin: -1rem !important;
  }
  .styles_mt-xl-n3__UrtZp,
  .styles_my-xl-n3__0HJVS {
    margin-top: -1rem !important;
  }
  .styles_mr-xl-n3__fIt53,
  .styles_mx-xl-n3__kqu0d {
    margin-right: -1rem !important;
  }
  .styles_mb-xl-n3__KDMMl,
  .styles_my-xl-n3__0HJVS {
    margin-bottom: -1rem !important;
  }
  .styles_ml-xl-n3__hfjVE,
  .styles_mx-xl-n3__kqu0d {
    margin-left: -1rem !important;
  }
  .styles_m-xl-n4__3O7j0 {
    margin: -1.5rem !important;
  }
  .styles_mt-xl-n4__jadMO,
  .styles_my-xl-n4__C_48I {
    margin-top: -1.5rem !important;
  }
  .styles_mr-xl-n4__ZlTL9,
  .styles_mx-xl-n4__tP_7u {
    margin-right: -1.5rem !important;
  }
  .styles_mb-xl-n4__rLowX,
  .styles_my-xl-n4__C_48I {
    margin-bottom: -1.5rem !important;
  }
  .styles_ml-xl-n4__R3QMX,
  .styles_mx-xl-n4__tP_7u {
    margin-left: -1.5rem !important;
  }
  .styles_m-xl-n5__9qd6j {
    margin: -3rem !important;
  }
  .styles_mt-xl-n5__O0iby,
  .styles_my-xl-n5___1s6y {
    margin-top: -3rem !important;
  }
  .styles_mr-xl-n5___k3DZ,
  .styles_mx-xl-n5__fh6O8 {
    margin-right: -3rem !important;
  }
  .styles_mb-xl-n5__bsAh3,
  .styles_my-xl-n5___1s6y {
    margin-bottom: -3rem !important;
  }
  .styles_ml-xl-n5__Iy1Ax,
  .styles_mx-xl-n5__fh6O8 {
    margin-left: -3rem !important;
  }
  .styles_m-xl-auto__ElSEB {
    margin: auto !important;
  }
  .styles_mt-xl-auto__NOZ7f,
  .styles_my-xl-auto__CrI_E {
    margin-top: auto !important;
  }
  .styles_mr-xl-auto__2UDlO,
  .styles_mx-xl-auto__ZMdnF {
    margin-right: auto !important;
  }
  .styles_mb-xl-auto__k_3kX,
  .styles_my-xl-auto__CrI_E {
    margin-bottom: auto !important;
  }
  .styles_ml-xl-auto__nD6_N,
  .styles_mx-xl-auto__ZMdnF {
    margin-left: auto !important;
  }
}

.styles_stretched-link__GWdm3::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.styles_text-monospace__oR76x {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.styles_text-justify__HrWtQ {
  text-align: justify !important;
}

.styles_text-wrap__Elr3p {
  white-space: normal !important;
}

.styles_text-nowrap__ixNsL {
  white-space: nowrap !important;
}

.styles_text-truncate__yJBD_ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles_text-left__cqKHq {
  text-align: left !important;
}

.styles_text-right__9fB_W {
  text-align: right !important;
}

.styles_text-center__Pja03 {
  text-align: center !important;
}

@media (min-width: 576px) {
  .styles_text-sm-left__EG01G {
    text-align: left !important;
  }
  .styles_text-sm-right__WEeCj {
    text-align: right !important;
  }
  .styles_text-sm-center__JRq2N {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .styles_text-md-left__mIlIo {
    text-align: left !important;
  }
  .styles_text-md-right__B1ohS {
    text-align: right !important;
  }
  .styles_text-md-center__TP5xY {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .styles_text-lg-left__DnpQZ {
    text-align: left !important;
  }
  .styles_text-lg-right__bpevx {
    text-align: right !important;
  }
  .styles_text-lg-center__XB6aS {
    text-align: center !important;
  }
}

@media (min-width: 1260px) {
  .styles_text-xl-left__nbTwS {
    text-align: left !important;
  }
  .styles_text-xl-right__Aozfg {
    text-align: right !important;
  }
  .styles_text-xl-center__7M_TC {
    text-align: center !important;
  }
}

.styles_text-lowercase__dReB9 {
  text-transform: lowercase !important;
}

.styles_text-uppercase__gQ6Zy {
  text-transform: uppercase !important;
}

.styles_text-capitalize__5z9C5 {
  text-transform: capitalize !important;
}

.styles_font-weight-light__1cO2u {
  font-weight: var(--lia-bs-font-weight-light) !important;
}

.styles_font-weight-lighter__PrdLI {
  font-weight: lighter !important;
}

.styles_font-weight-normal__e_EWp {
  font-weight: var(--lia-bs-font-weight-normal) !important;
}

.styles_font-weight-bold__3_Kc8 {
  font-weight: var(--lia-bs-font-weight-bold) !important;
}

.styles_font-weight-bolder__LE9sd {
  font-weight: bolder !important;
}

.styles_font-italic__4ZC6_ {
  font-style: italic !important;
}

.styles_text-white__sBrrF {
  color: var(--lia-bs-white) !important;
}

.styles_text-primary__OIEED {
  color: var(--lia-bs-primary) !important;
}

a.styles_text-primary__OIEED:hover,
a.styles_text-primary__OIEED:focus {
  color: hsla(
    var(--lia-bs-primary-h),
    var(--lia-bs-primary-s),
    calc(var(--lia-bs-primary-l) + -15%),
    var(--lia-bs-primary-a, 1)
  ) !important;
}

.styles_text-secondary__hWHlt {
  color: var(--lia-bs-secondary) !important;
}

a.styles_text-secondary__hWHlt:hover,
a.styles_text-secondary__hWHlt:focus {
  color: hsla(
    var(--lia-bs-secondary-h),
    var(--lia-bs-secondary-s),
    calc(var(--lia-bs-secondary-l) + -15%),
    var(--lia-bs-secondary-a, 1)
  ) !important;
}

.styles_text-success__cg6OI {
  color: var(--lia-bs-success) !important;
}

a.styles_text-success__cg6OI:hover,
a.styles_text-success__cg6OI:focus {
  color: hsla(
    var(--lia-bs-success-h),
    var(--lia-bs-success-s),
    calc(var(--lia-bs-success-l) + -15%),
    var(--lia-bs-success-a, 1)
  ) !important;
}

.styles_text-info__sUhde {
  color: var(--lia-bs-info) !important;
}

a.styles_text-info__sUhde:hover,
a.styles_text-info__sUhde:focus {
  color: hsla(
    var(--lia-bs-info-h),
    var(--lia-bs-info-s),
    calc(var(--lia-bs-info-l) + -15%),
    var(--lia-bs-info-a, 1)
  ) !important;
}

.styles_text-warning__VlNSt {
  color: var(--lia-bs-warning) !important;
}

a.styles_text-warning__VlNSt:hover,
a.styles_text-warning__VlNSt:focus {
  color: hsla(
    var(--lia-bs-warning-h),
    var(--lia-bs-warning-s),
    calc(var(--lia-bs-warning-l) + -15%),
    var(--lia-bs-warning-a, 1)
  ) !important;
}

.styles_text-danger__QA_H_ {
  color: var(--lia-bs-danger) !important;
}

a.styles_text-danger__QA_H_:hover,
a.styles_text-danger__QA_H_:focus {
  color: hsla(
    var(--lia-bs-danger-h),
    var(--lia-bs-danger-s),
    calc(var(--lia-bs-danger-l) + -15%),
    var(--lia-bs-danger-a, 1)
  ) !important;
}

.styles_text-light__Rm0bT {
  color: var(--lia-bs-light) !important;
}

a.styles_text-light__Rm0bT:hover,
a.styles_text-light__Rm0bT:focus {
  color: hsla(
    var(--lia-bs-light-h),
    var(--lia-bs-light-s),
    calc(var(--lia-bs-light-l) + -15%),
    var(--lia-bs-light-a, 1)
  ) !important;
}

.styles_text-dark__vvLSU {
  color: var(--lia-bs-dark) !important;
}

a.styles_text-dark__vvLSU:hover,
a.styles_text-dark__vvLSU:focus {
  color: hsla(
    var(--lia-bs-dark-h),
    var(--lia-bs-dark-s),
    calc(var(--lia-bs-dark-l) + -15%),
    var(--lia-bs-dark-a, 1)
  ) !important;
}

.styles_text-body__F7QRV {
  color: var(--lia-bs-body-color) !important;
}

.styles_text-muted__GiM8b {
  color: var(--lia-bs-text-muted) !important;
}

.styles_text-black-50__Alux_ {
  color: hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.5) !important;
}

.styles_text-white-50___O9Vn {
  color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.5) !important;
}

.styles_text-hide__bKhCy {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.styles_text-decoration-none__PSmZ_ {
  text-decoration: none !important;
}

.styles_text-break__Ny06M {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.styles_text-reset___E2S5 {
  color: inherit !important;
}

.styles_visible___kFia {
  visibility: visible !important;
}

.styles_invisible__w5CuQ {
  visibility: hidden !important;
}

/* Overridden to break apart the unscoped and scoped styles */

@media print {
  @page {
    size: a3;
  }
  .styles_container__6wTQq {
    min-width: 62rem !important;
  }
  .styles_navbar__aRcgS {
    display: none;
  }
  .styles_badge__ReL9K {
    border: 1px solid var(--lia-bs-black);
  }
  .styles_table__jNBXc {
    border-collapse: collapse !important;
  }
  .styles_table__jNBXc td,
  .styles_table__jNBXc th {
    background-color: var(--lia-bs-white) !important;
  }
  .styles_table-bordered__W5qJx th,
  .styles_table-bordered__W5qJx td {
    border: 1px solid var(--lia-bs-gray-300) !important;
  }
  .styles_table-dark__UB7ie {
    color: inherit;
  }
  .styles_table-dark__UB7ie th,
  .styles_table-dark__UB7ie td,
  .styles_table-dark__UB7ie thead th,
  .styles_table-dark__UB7ie tbody + tbody {
    border-color: var(--lia-bs-border-color);
  }
  .styles_table__jNBXc .styles_thead-dark__8y83q th {
    color: inherit;
    border-color: var(--lia-bs-border-color);
  }
}

/* Overridden to break apart the unscoped and scoped styles */

.styles_btn__1HFm0 {
  text-transform: var(--lia-btn-text-transform);
  font-style: var(--lia-btn-font-style);
}

.styles_btn-primary__UevYT {
  color: var(--lia-btn-primary-text-color);
  background-color: var(--lia-btn-primary-bg-color);
  border: var(--lia-btn-primary-border);
  box-shadow: none;
}

.styles_btn-primary__UevYT:hover,
.styles_btn-primary__UevYT.styles_hover__7Tzl0 {
  color: var(--lia-btn-primary-text-hover-color);
  background-color: var(--lia-btn-primary-bg-hover-color);
  border: var(--lia-btn-primary-border-hover);
  box-shadow: none;
}

.styles_btn-primary__UevYT:focus,
.styles_btn-primary__UevYT.styles_focus__Ydoo3 {
  color: var(--lia-btn-primary-text-color);
  background-color: var(--lia-btn-primary-bg-color);
  border: var(--lia-btn-primary-border-focus);
  box-shadow: var(--lia-btn-primary-box-shadow-focus);
}

.styles_btn-primary__UevYT.styles_disabled__lqbPt,
.styles_btn-primary__UevYT:disabled {
  color: var(--lia-btn-primary-text-hover-color);
  background-color: var(--lia-btn-primary-bg-hover-color);
  border: var(--lia-btn-primary-border-hover);
  box-shadow: none;
}

.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-primary__UevYT.styles_dropdown-toggle__lyIUd {
  color: var(--lia-btn-primary-text-active-color);
  background-color: var(--lia-btn-primary-bg-active-color);
  border: var(--lia-btn-primary-border-active);
  box-shadow: none;
}

.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-primary__UevYT:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-primary__UevYT.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: none;
}

.styles_btn-secondary__Ust3k {
  color: var(--lia-btn-secondary-text-color);
  background-color: var(--lia-btn-secondary-bg-color);
  border: var(--lia-btn-secondary-border);
  box-shadow: none;
}

.styles_btn-secondary__Ust3k:hover,
.styles_btn-secondary__Ust3k.styles_hover__7Tzl0 {
  color: var(--lia-btn-secondary-text-hover-color);
  background-color: var(--lia-btn-secondary-bg-hover-color);
  border: var(--lia-btn-secondary-border-hover);
  box-shadow: none;
}

.styles_btn-secondary__Ust3k:focus,
.styles_btn-secondary__Ust3k.styles_focus__Ydoo3 {
  color: var(--lia-btn-secondary-text-color);
  background-color: var(--lia-btn-secondary-bg-color);
  border: var(--lia-btn-secondary-border-focus);
  box-shadow: var(--lia-btn-secondary-box-shadow-focus);
}

.styles_btn-secondary__Ust3k.styles_disabled__lqbPt,
.styles_btn-secondary__Ust3k:disabled {
  color: var(--lia-btn-secondary-text-hover-color);
  background-color: var(--lia-btn-secondary-bg-hover-color);
  border: var(--lia-btn-secondary-border-hover);
  box-shadow: none;
}

.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-secondary__Ust3k.styles_dropdown-toggle__lyIUd {
  color: var(--lia-btn-secondary-text-active-color);
  background-color: var(--lia-btn-secondary-bg-active-color);
  border: var(--lia-btn-secondary-border-active);
  box-shadow: none;
}

.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-secondary__Ust3k:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-secondary__Ust3k.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: none;
}

.styles_btn-danger__gUe8d {
  color: var(--lia-btn-destructive-text-color);
  background-color: var(--lia-btn-destructive-bg-color);
  border: var(--lia-btn-destructive-border);
  box-shadow: none;
}

.styles_btn-danger__gUe8d:hover,
.styles_btn-danger__gUe8d.styles_hover__7Tzl0 {
  color: var(--lia-btn-destructive-text-hover-color);
  background-color: var(--lia-btn-destructive-bg-hover-color);
  border: var(--lia-btn-destructive-border-hover);
  box-shadow: none;
}

.styles_btn-danger__gUe8d:focus,
.styles_btn-danger__gUe8d.styles_focus__Ydoo3 {
  color: var(--lia-btn-destructive-text-color);
  background-color: var(--lia-btn-destructive-bg-color);
  border: var(--lia-btn-destructive-border-focus);
  box-shadow: var(--lia-btn-destructive-box-shadow-focus);
}

.styles_btn-danger__gUe8d.styles_disabled__lqbPt,
.styles_btn-danger__gUe8d:disabled {
  color: var(--lia-btn-destructive-text-hover-color);
  background-color: var(--lia-btn-destructive-bg-hover-color);
  border: var(--lia-btn-destructive-border-hover);
  box-shadow: none;
}

.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-danger__gUe8d.styles_dropdown-toggle__lyIUd {
  color: var(--lia-btn-destructive-text-active-color);
  background-color: var(--lia-btn-destructive-bg-active-color);
  border: var(--lia-btn-destructive-border-active);
  box-shadow: none;
}

.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-danger__gUe8d:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-danger__gUe8d.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: none;
}

.styles_btn-light__fMj7i {
  color: var(--lia-btn-tertiary-text-color);
  background-color: var(--lia-btn-tertiary-bg-color);
  border: var(--lia-btn-tertiary-border);
  box-shadow: none;
}

.styles_btn-light__fMj7i:hover,
.styles_btn-light__fMj7i.styles_hover__7Tzl0 {
  color: var(--lia-btn-tertiary-text-hover-color);
  background-color: var(--lia-btn-tertiary-bg-hover-color);
  border: var(--lia-btn-tertiary-border-hover);
  box-shadow: none;
}

.styles_btn-light__fMj7i:focus,
.styles_btn-light__fMj7i.styles_focus__Ydoo3 {
  color: var(--lia-btn-tertiary-text-color);
  background-color: var(--lia-btn-tertiary-bg-color);
  border: var(--lia-btn-tertiary-border-focus);
  box-shadow: var(--lia-btn-tertiary-box-shadow-focus);
}

.styles_btn-light__fMj7i.styles_disabled__lqbPt,
.styles_btn-light__fMj7i:disabled {
  color: var(--lia-btn-tertiary-text-hover-color);
  background-color: var(--lia-btn-tertiary-bg-hover-color);
  border: var(--lia-btn-tertiary-border-hover);
  box-shadow: none;
}

.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt):active,
.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN,
.styles_show__uuON9 > .styles_btn-light__fMj7i.styles_dropdown-toggle__lyIUd {
  color: var(--lia-btn-tertiary-text-active-color);
  background-color: var(--lia-btn-tertiary-bg-active-color);
  border: var(--lia-btn-tertiary-border-active);
  box-shadow: none;
}

.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt):active:focus,
.styles_btn-light__fMj7i:not(:disabled):not(.styles_disabled__lqbPt).styles_active__EuuVN:focus,
.styles_show__uuON9 > .styles_btn-light__fMj7i.styles_dropdown-toggle__lyIUd:focus {
  box-shadow: none;
}

.styles_btn__1HFm0.styles_btn-link__Qn59s:focus-visible {
  outline: var(--lia-outline-color) solid 3px;
  outline-offset: unset;
}

.styles_card__fKF_e {
  border: 0;
  box-shadow: var(--lia-box-shadow-xs);
}

.styles_dropdown-menu__gMSix {
  margin-top: 0.3125rem;
  box-shadow: var(--lia-bs-box-shadow-sm);
  max-width: 18.75rem;
  width: -moz-max-content;
  width: max-content;
}

.styles_dropdown-menu__gMSix.styles_show__uuON9 {
  animation: styles_dropdown-animate__qm5i_ var(--lia-timing-normal) var(--lia-timing-function);
}

.styles_dropdown-item__s_Yb9 {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.285714285;
  white-space: normal;
  color: var(--lia-bs-body-color);
  border-radius: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.styles_dropdown-item__s_Yb9 > svg {
  margin-right: 0.625rem;
}

.styles_dropdown-item__s_Yb9.styles_active__EuuVN:before {
  position: absolute;
  content: '';
  top: calc(50% - 0.375rem / 2);
  left: calc(var(--lia-bs-dropdown-item-padding-x) / 2 - 0.375rem / 2 + 0.0625rem);
  height: 0.375rem;
  width: 0.375rem;
  border-radius: var(--lia-border-radius-50);
  background-color: var(--lia-bs-gray-900);
}

@-moz-document url-prefix() {
  .styles_dropdown-item__s_Yb9.styles_active__EuuVN:before {
    margin-top: 0.0625rem;
  }
}

.styles_dropdown-item__s_Yb9:hover,
.styles_dropdown-item__s_Yb9:focus {
  outline: none;
}

.styles_dropdown-item__s_Yb9:hover {
  background-color: var(--lia-bs-gray-300);
}

.styles_dropdown-item__s_Yb9:focus {
  background-color: transparent;
}

.styles_dropdown-item__s_Yb9:focus-visible {
  outline: var(--lia-outline-color) solid 3px;
  outline-offset: -3px;
}

.styles_dropdown-header__9Jtdy {
  font-size: var(--lia-subheader-font-size);
  font-weight: var(--lia-subheader-font-weight);
  line-height: 1.272727272727273;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  cursor: default;
}

.styles_was-validated__KBckY .styles_form-control__h8E13:invalid,
.styles_form-control__h8E13.styles_is-invalid__03Gbr {
  background-image: none;
  padding-right: 0.625rem !important;
  border-color: var(--lia-bs-input-border-color);
}

.styles_was-validated__KBckY .styles_form-control__h8E13:invalid:focus,
.styles_form-control__h8E13.styles_is-invalid__03Gbr:focus {
  border-color: var(--lia-bs-input-focus-border-color);
  box-shadow: var(--lia-bs-input-focus-box-shadow);
}

.styles_was-validated__KBckY .styles_form-check-input__ACRJO:invalid ~ .styles_form-check-label__6JMbk,
.styles_form-check-input__ACRJO.styles_is-invalid__03Gbr ~ .styles_form-check-label__6JMbk {
  color: var(--lia-bs-body-color);
}

.styles_form-label__u6RHk,
.styles_form-check-label__6JMbk,
.styles_custom-control-label__98j17 {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  display: inline-block;
  overflow-wrap: break-word;
  max-width: 100%;
}

.styles_form-text__IaYLU {
  margin-top: var(--lia-bs-form-text-margin-top);
  font-size: var(--lia-font-size-xs);
}

.styles_form-label__u6RHk + .styles_form-text__IaYLU {
  margin: calc((var(--lia-bs-label-margin-bottom) / 2) * -1) 0 0.3125rem;
}

.styles_form-label__u6RHk + .styles_form-check-inline__PbQ1x:before {
  display: table;
  content: '';
}

.styles_form-check__K9Uz9,
.styles_custom-switch__LRKoq {
  padding: 0;
  display: grid;
  grid-template-areas: 'input label' '. subtext';
  grid-template-columns: auto 1fr;
  -moz-column-gap: 0.625rem;
       column-gap: 0.625rem;
}

.styles_form-check__K9Uz9.styles_form-check-inline__PbQ1x,
.styles_custom-switch__LRKoq.styles_form-check-inline__PbQ1x {
  display: inline-grid;
  margin-right: 0;
}

.styles_form-check__K9Uz9.styles_form-check-inline__PbQ1x:not(:last-child),
.styles_custom-switch__LRKoq.styles_form-check-inline__PbQ1x:not(:last-child) {
  margin-right: 0.625rem;
}

.styles_form-check__K9Uz9 .styles_form-text__IaYLU,
.styles_custom-switch__LRKoq .styles_form-text__IaYLU {
  grid-area: subtext;
  margin-top: var(--lia-check-label-margin-bottom);
}

.styles_form-check__K9Uz9 + .styles_form-check__K9Uz9:not(.styles_form-check-inline__PbQ1x),
.styles_custom-switch__LRKoq + .styles_form-check__K9Uz9:not(.styles_form-check-inline__PbQ1x) {
  margin-top: 0.625rem;
}

.styles_form-check-input__ACRJO,
.styles_custom-control-input___8VFl {
  grid-area: input;
  align-self: center;
  position: relative;
  margin: 0 0 auto;
}

.styles_form-check-input__ACRJO[type='radio'],
.styles_form-check-input__ACRJO[type='checkbox'] {
  display: grid;
  place-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 1rem;
  height: 1rem;
  margin: 0;
  border: 1px solid var(--lia-bs-gray-600);
  background-color: var(--lia-bs-white);
  transition: var(--lia-timing-fast) box-shadow var(--lia-timing-function),
    var(--lia-timing-fast) border-color var(--lia-timing-function),
    var(--lia-timing-fast) background-color var(--lia-timing-function);
}

.styles_form-check-input__ACRJO[type='radio']:before,
.styles_form-check-input__ACRJO[type='checkbox']:before {
  content: '';
  background-color: var(--lia-bs-white);
}

.styles_form-check-input__ACRJO[type='radio']:checked,
.styles_form-check-input__ACRJO[type='checkbox']:checked {
  background-color: var(--lia-input-active-fill-color);
  border-color: var(--lia-input-active-fill-color);
}

.styles_form-check-input__ACRJO[type='radio']:focus,
.styles_form-check-input__ACRJO[type='radio']:focus-visible,
.styles_form-check-input__ACRJO[type='checkbox']:focus,
.styles_form-check-input__ACRJO[type='checkbox']:focus-visible {
  border-color: var(--lia-bs-primary);
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.25);
  outline: unset;
}

.styles_form-check-input__ACRJO[type='radio'] {
  border-radius: 50%;
}

.styles_form-check-input__ACRJO[type='radio']:before {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  transform: scale(0);
  transition: var(--lia-timing-fast) transform var(--lia-timing-function);
  box-shadow: 0 1px 2px
    hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.25);
}

.styles_form-check-input__ACRJO[type='radio']:checked:before {
  transform: scale(1);
}

.styles_form-check-input__ACRJO[type='checkbox'] {
  border-radius: var(--lia-checkbox-border-radius);
}

.styles_form-check-input__ACRJO[type='checkbox']:before {
  width: 0.625rem;
  height: 0.5625rem;
  opacity: 0;
  transition: var(--lia-timing-fast) opacity var(--lia-timing-function);
  -webkit-mask-size: 10px 9px;
          mask-size: 10px 9px;
  -webkit-mask-image: var(--lia-checkmark-input-icon);
          mask-image: var(--lia-checkmark-input-icon);
}

.styles_form-check-input__ACRJO[type='checkbox']:checked:before {
  opacity: 1;
}

.styles_form-check-input__ACRJO[disabled],
.styles_form-check-input__ACRJO:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.styles_form-check-input__ACRJO[disabled] ~ .styles_form-check-label__6JMbk,
.styles_form-check-input__ACRJO:disabled ~ .styles_form-check-label__6JMbk {
  color: var(--lia-bs-input-disabled-color);
  cursor: not-allowed;
}

.styles_form-check-label__6JMbk,
.styles_custom-control-label__98j17 {
  grid-area: label;
}

.styles_form-control__h8E13::-moz-placeholder {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-base);
}

.styles_form-control__h8E13::placeholder {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-base);
}

.styles_form-control__h8E13:disabled {
  color: var(--lia-bs-input-disabled-color);
}

select.styles_form-control__h8E13 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: var(--lia-select-arrow-icon);
  background-repeat: no-repeat;
  background-size: 16px 17px;
  background-position: calc(100% - 10px);
  padding-right: 1.875rem;
}

textarea.styles_form-control__h8E13 {
  border-radius: var(--lia-textarea-border-radius);
}

.styles_custom-file-label__yrR5T {
  font-size: var(--lia-bs-font-size-sm);
  color: var(--lia-bs-gray-600);
}

.styles_custom-file-label__yrR5T:after {
  font-weight: var(--lia-bs-font-weight-bold);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.styles_custom-file__sgTM_ {
  height: auto;
}

.styles_custom-file-input__jsy3I {
  font-size: var(--lia-bs-font-size-sm);
}

.styles_custom-file-input__jsy3I[disabled] ~ .styles_custom-file-label__yrR5T,
.styles_custom-file-input__jsy3I:disabled ~ .styles_custom-file-label__yrR5T {
  color: var(--lia-bs-text-muted);
  border-color: var(--lia-bs-gray-300);
}

.styles_custom-file-input__jsy3I[disabled] ~ .styles_custom-file-label__yrR5T:after,
.styles_custom-file-input__jsy3I:disabled ~ .styles_custom-file-label__yrR5T:after {
  color: var(--lia-bs-text-muted);
}

.styles_custom-switch__LRKoq {
  min-height: auto;
}

.styles_custom-switch__LRKoq .styles_custom-control-label__98j17:before,
.styles_custom-switch__LRKoq .styles_custom-control-label__98j17:after {
  content: none;
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl {
  display: grid;
  place-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: static;
  width: 2.25rem;
  height: 1.25rem;
  border-radius: 100vw;
  background-color: var(--lia-bs-gray-600);
  border: 1px solid var(--lia-bs-gray-600);
  opacity: 1;
  transition: var(--lia-timing-fast) box-shadow var(--lia-timing-function),
    var(--lia-timing-fast) border-color var(--lia-timing-function),
    var(--lia-timing-fast) background-color var(--lia-timing-function);
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:before {
  content: '';
  background-color: var(--lia-bs-white);
  box-shadow: var(--lia-box-shadow-xs);
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  transform: translateX(-0.5rem);
  transition: var(--lia-timing-fast) transform var(--lia-timing-function);
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:checked {
  background-color: var(--lia-input-active-fill-color);
  border-color: var(--lia-input-active-fill-color);
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:checked:before {
  transform: translateX(0.5rem);
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:focus,
.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:focus-visible {
  border-color: var(--lia-bs-primary);
  box-shadow: 0 0 0 3px
    hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.25);
  outline: unset;
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl[disabled],
.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl[disabled] ~ .styles_custom-control-label__98j17,
.styles_custom-switch__LRKoq .styles_custom-control-input___8VFl:disabled ~ .styles_custom-control-label__98j17 {
  color: var(--lia-bs-input-disabled-color);
  cursor: not-allowed;
}

.styles_form-check__K9Uz9,
.styles_form-check-input__ACRJO,
.styles_form-control-range__T18W4 {
  accent-color: var(--lia-input-active-fill-color);
}

@media (min-width: 768px) {
  .styles_list-group-item__c_btC {
    padding: var(--lia-bs-list-group-item-padding-y)
      calc(var(--lia-bs-list-group-item-padding-x) + 0.625rem);
  }
}

.styles_modal-header__rgSBQ .styles_modal-title__5BmJZ {
  font-weight: var(--lia-bs-modal-header-font-weight);
  margin-right: 0.9375rem;
}

.styles_modal-header__rgSBQ .styles_close__bRXON {
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  margin: 0 -0.4375rem 0 0;
  border-radius: var(--lia-border-radius-50);
  opacity: 1;
  font-size: 0;
  transition: background-color var(--lia-timing-normal),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);
}

.styles_modal-header__rgSBQ .styles_close__bRXON:hover {
  background-color: var(--lia-bs-gray-200);
}

.styles_modal-header__rgSBQ .styles_close__bRXON:focus-visible {
  box-shadow: var(--lia-bs-input-focus-box-shadow), 0 0 0 1px var(--lia-bs-input-focus-border-color);
  outline: unset;
}

.styles_modal-header__rgSBQ .styles_close__bRXON:not(:disabled):not(.styles_disabled__lqbPt):hover,
.styles_modal-header__rgSBQ .styles_close__bRXON:not(:disabled):not(.styles_disabled__lqbPt):focus {
  opacity: 1;
}

.styles_modal-header__rgSBQ .styles_close__bRXON span:first-child {
  display: block;
  margin: 0 auto;
  width: 1rem;
  height: 1rem;
  background-color: var(--lia-bs-gray-900);
  -webkit-mask-size: 16px;
          mask-size: 16px;
  -webkit-mask-image: var(--lia-close-icon);
          mask-image: var(--lia-close-icon);
}

.styles_modal-content__73xuK {
  box-shadow: var(--lia-bs-box-shadow-lg);
}

.styles_modal-body__0fyal {
  padding: 0 1.875rem 1.875rem;
}

.styles_modal-footer__XSxmz {
  margin-top: -1.875rem;
  background-color: var(--lia-bs-modal-content-bg);
  border-radius: 0 0 var(--lia-bs-border-radius-lg) var(--lia-bs-border-radius-lg);
  padding: 1.875rem 1.875rem 1.25rem;
  z-index: 2;
}

.styles_modal-footer__XSxmz > * {
  margin: 0;
}

@media (max-width: 767.98px) {
  .styles_modal-header__rgSBQ {
    padding: 1.25rem 1.25rem 0.625rem;
  }
  .styles_modal-header__rgSBQ .styles_close__bRXON {
    margin: -0.125rem -0.4375rem 0 0;
  }
  .styles_modal-title__5BmJZ {
    font-size: var(--lia-bs-h5-font-size);
  }
  .styles_modal-body__0fyal {
    padding: 0 1.25rem 1.25rem;
  }
  .styles_modal-footer__XSxmz {
    margin-top: -0.625rem;
    padding: 0.625rem 1.25rem 1.25rem;
  }
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-header__rgSBQ {
  border-bottom: 1px solid var(--lia-bs-border-color);
  z-index: 2;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal {
  margin-top: -0.0625rem;
  margin-bottom: -0.0625rem;
  overflow-x: clip;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal:before,
.styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal:after {
  position: relative;
  display: block;
  content: '';
  height: 0.0625rem;
  transform: translateX(-1.875rem);
  width: calc(100% + 3.75rem);
  background-color: var(--lia-bs-white);
  z-index: 10;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal:before {
  top: 0;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal:after {
  bottom: 0;
}

.styles_modal-dialog-scrollable__oS5LP .styles_modal-footer__XSxmz {
  border-top: 1px solid var(--lia-bs-border-color);
  z-index: 2;
}

@media (max-width: 767.98px) {
  .styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal {
    padding-bottom: 0.625rem;
  }
  .styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal:before,
  .styles_modal-dialog-scrollable__oS5LP .styles_modal-body__0fyal:after {
    transform: translateX(-1.25rem);
    width: calc(100% + 2.5rem);
  }
}

/* size xl (fullscreen) styles */

.styles_modal-xl__WJuHU {
  margin: 1.5625rem;
  max-height: 100%;
}

.styles_modal-xl__WJuHU .styles_modal-content__73xuK {
  height: calc(100dvh - 3.125rem);
  background-color: var(--lia-img-preview-content-bg-color);
}

.styles_modal-xl__WJuHU .styles_modal-dialog-scrollable__oS5LP {
  max-height: 100%;
}

.styles_modal-xl__WJuHU .styles_modal-header__rgSBQ .styles_modal-title__5BmJZ {
  color: var(--lia-img-preview-title-color);
}

.styles_modal-xl__WJuHU .styles_modal-header__rgSBQ .styles_close__bRXON:hover {
  background-color: var(--lia-img-preview-control-bg-color);
}

.styles_modal-xl__WJuHU .styles_modal-header__rgSBQ .styles_close__bRXON span:first-child {
  background-color: var(--lia-img-preview-control-color);
}

@media (max-width: 767.98px) {
  .styles_modal-xl__WJuHU {
    margin: 0.3125rem;
  }
  .styles_modal-xl__WJuHU .styles_modal-content__73xuK {
    width: calc(100vw - 0.625rem);
    height: calc(100dvh - 0.625rem);
  }
}

.styles_pagination__zfkl1 {
  margin-bottom: 0;
}

.styles_page-link__wCtCZ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_page-item__rQMne.styles_disabled__lqbPt {
  cursor: not-allowed;
}

.styles_page-item__rQMne.styles_disabled__lqbPt .styles_page-link__wCtCZ svg {
  fill: var(--lia-bs-gray-600);
}

.styles_popover__01_WA {
  padding: 0.8125rem 0.9375rem;
  min-width: var(--lia-bs-popover-min-width);
  height: auto;
  box-shadow: var(--lia-bs-popover-box-shadow);
}

.styles_popover-header__2H7nK {
  font-weight: var(--lia-bs-font-weight-bold);
  font-size: var(--lia-bs-font-size-sm);
  margin: 0 0 0.5rem;
  padding: 0 0 0.5rem;
}

.styles_bs-popover-bottom__Fn92k,
.styles_bs-popover-auto__AG2j1[x-placement^='bottom'] {
  margin-top: calc(var(--lia-bs-popover-arrow-height) + 0.625rem);
}

.styles_bs-popover-top__u59kA,
.styles_bs-popover-auto__AG2j1[x-placement^='top'] {
  margin-bottom: calc(var(--lia-bs-popover-arrow-height) + 0.625rem);
}

.styles_bs-popover-right__LYBkG,
.styles_bs-popover-auto__AG2j1[x-placement^='right'] {
  margin-left: calc(var(--lia-bs-popover-arrow-height) + 0.625rem);
}

.styles_bs-popover-left__ANXbi,
.styles_bs-popover-auto__AG2j1[x-placement^='left'] {
  margin-right: calc(var(--lia-bs-popover-arrow-height) + 0.625rem);
}

.styles_table__jNBXc {
  margin-bottom: 0;
}

.styles_rounded-circle__Sl6qD {
  border-radius: var(--lia-border-radius-50) !important;
}

/* Globals */

/**
 * The transition timings used here need to match 1:1 to the timings
 * set in `AnimationHelper.FadeTransitionTimeouts`.
 */

.styles_lia-g-csst-fade-enter__Umb8x,
    .styles_lia-g-csst-fade-appear__75giw {
      opacity: 0;
      pointer-events: none;
    }

.styles_lia-g-csst-fade-enter-active__s8twX, .styles_lia-g-csst-fade-appear-active__WBmqx {
        opacity: 1;
        transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
      }

.styles_lia-g-csst-fade-exit__2vGFd {
      opacity: 1;
      pointer-events: none;
    }

.styles_lia-g-csst-fade-exit-active__3esw4 {
        opacity: 0;
        transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
      }

.styles_lia-g-csst-fade-slow-enter__IaJrt,
    .styles_lia-g-csst-fade-slow-appear__KO1fK {
      opacity: 0;
    }

.styles_lia-g-csst-fade-slow-enter-active__tlgg9, .styles_lia-g-csst-fade-slow-appear-active__Q0BUv {
        opacity: 1;
        transition: opacity var(--lia-timing-slow) var(--lia-timing-function);
      }

.styles_lia-g-csst-fade-slow-exit__ASLLF {
      opacity: 1;
    }

.styles_lia-g-csst-fade-slow-exit-active__ITwF_ {
        opacity: 0;
        transition: opacity var(--lia-timing-slow) var(--lia-timing-function);
      }

/* AnimationHelper.ZoomFadeTransitionTimeouts */

.styles_lia-g-csst-zoom-fade-enter__fWRz8,
    .styles_lia-g-csst-zoom-fade-appear__odXpe {
      transform: scale(0.9);
      opacity: 0;
    }

.styles_lia-g-csst-zoom-fade-enter-active__LWhkt, .styles_lia-g-csst-zoom-fade-appear-active__d6nn_ {
        opacity: 1;
        transform: scale(1);
        transition: all var(--lia-timing-normal) var(--lia-timing-function);
        transition-property: opacity, transform;
      }

.styles_lia-g-csst-zoom-fade-exit__hSLvP {
      transform: scale(1);
      opacity: 1;
    }

.styles_lia-g-csst-zoom-fade-exit-active__J5Wlo {
        transform: scale(0.9);
        opacity: 0;
        transition: all var(--lia-timing-normal) var(--lia-timing-function);
        transition-property: opacity, transform;
      }

.styles_lia-g-csst-move-left-enter__wrAkk,
    .styles_lia-g-csst-move-left-appear__i79HD {
      transform: translateX(100vw);
    }

.styles_lia-g-csst-move-left-enter-active__W0lP3, .styles_lia-g-csst-move-left-appear-active__owKb6 {
        transform: translateX(0);
        transition: transform var(--lia-timing-normal);
      }

.styles_lia-g-csst-move-left-exit__naT5x {
      transform: translateX(0);
    }

.styles_lia-g-csst-move-left-exit-active__dWGDd {
        transform: translateX(100vw);
        transition: transform var(--lia-timing-normal);
      }

.styles_lia-g-csst-move-right-enter__XjCWD,
    .styles_lia-g-csst-move-right-appear__MwJJR {
      transform: translateX(-100vw);
    }

.styles_lia-g-csst-move-right-enter-active__w5r_y, .styles_lia-g-csst-move-right-appear-active__t_3zn {
        transform: translateX(0);
        transition: transform var(--lia-timing-normal);
      }

.styles_lia-g-csst-move-right-exit__m_4pg {
      transform: translateX(0);
    }

.styles_lia-g-csst-move-right-exit-active__F1EFA {
        transform: translateX(-100vw);
        transition: transform var(--lia-timing-normal);
      }

.styles_lia-g-csst-slide-in-enter__zZv5L {
      opacity: 0;
      transform: scale(0.9) translateX(6.25rem);
    }

.styles_lia-g-csst-slide-in-enter-active__v3mGg {
        opacity: 1;
        transform: scale(1) translateX(0);
        transition: all var(--lia-timing-normal) cubic-bezier(0.24, 0.94, 0.62, 1);
        transition-property: opacity, transform;
      }

.styles_lia-g-csst-slide-in-exit__bxLum {
      opacity: 1;
    }

.styles_lia-g-csst-slide-in-exit-active__KH2SK {
        transition: opacity var(--lia-timing-normal) cubic-bezier(0.24, 0.94, 0.62, 1);
        opacity: 0;
      }

.styles_lia-g-csst-slide-up-enter__vojvr {
      opacity: 0;
      transform: scale(0.9) translateY(2.1875rem);
    }

.styles_lia-g-csst-slide-up-enter-active__9n8O2 {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: all var(--lia-timing-slow) cubic-bezier(0.26, 1.44, 0.45, 0.84);
        transition-property: opacity, transform;
      }

.styles_lia-g-csst-slide-up-exit__6E0bH {
      opacity: 1;
    }

.styles_lia-g-csst-slide-up-exit-active__0hmwl {
        transition: opacity var(--lia-timing-slow) cubic-bezier(0.24, 0.94, 0.62, 1);
        opacity: 0;
      }

.styles_lia-g-csst-slide-up-exit-done__avokK {
        opacity: 0;
      }

.styles_lia-g-csst-squish-enter__SHi_K {
      transform: rotate(0deg) scale(1);
    }

.styles_lia-g-csst-squish-enter-active__Oh4Hi {
        transform: rotate(25deg) scale(0.7);
        transition: transform var(--lia-timing-slow) var(--lia-timing-function);
      }

.styles_lia-g-csst-squish-exit__9JvAO {
      transform: rotate(0deg) scale(1.3);
    }

.styles_lia-g-csst-squish-exit-done__T2RRo {
        transform: rotate(0deg) scale(1);
      }

.styles_lia-g-will-load__A1v8t {
  transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
}

.styles_lia-g-will-load__A1v8t.styles_lia-g-is-loaded__javfn {
    opacity: 1;
  }

.styles_lia-g-will-load__A1v8t.styles_lia-g-is-reloading___Zr7g {
    opacity: 0.5;
  }

.styles_lia-g-bounce-forwards__5h5C1 {
  animation: styles_liaBounceForward__ANngu var(--lia-timing-normal) var(--lia-timing-function);
}

@keyframes styles_liaBounceForward__ANngu {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}

.styles_lia-g-auth-wrapper__xKYe1 {
  align-items: center;
}

.styles_lia-g-auth-panel__dOBs3.styles_lia-g-auth-panel__dOBs3 {
  width: 100%;
  margin: 6.25rem auto auto;
  max-width: 28.125rem;
}

.styles_lia-g-auth-panel__dOBs3.styles_lia-g-auth-panel__dOBs3 .styles_lia-g-auth-panel-header__m2S12 {
    padding: 1.875rem 2.5rem 1.25rem;
    text-align: center;
  }

.styles_lia-g-auth-panel__dOBs3.styles_lia-g-auth-panel__dOBs3 .styles_lia-g-auth-panel-body__Fzf12 {
    padding: 0 2.5rem 2.5rem;
  }

@media (max-width: 575.98px) {

.styles_lia-g-auth-panel__dOBs3.styles_lia-g-auth-panel__dOBs3 {
    margin-top: 0;
    max-width: auto;
    border-radius: 0
}

    .styles_lia-g-auth-panel__dOBs3.styles_lia-g-auth-panel__dOBs3 .styles_lia-g-auth-panel-header__m2S12 {
      padding: 1.25rem;
    }
    .styles_lia-g-auth-panel__dOBs3.styles_lia-g-auth-panel__dOBs3 .styles_lia-g-auth-panel-body__Fzf12 {
      padding: 0 1.25rem 1.25rem;
    }
  }

.styles_lia-g-auth-nav-section__ODiXl {
  margin-top: 1.875rem;
  font-size: var(--lia-bs-font-size-sm);
  text-align: center;
}

.styles_lia-g-auth-nav-section__ODiXl + .styles_lia-g-auth-nav-section__ODiXl {
    margin-top: 0.3125rem;
  }

.styles_lia-g-auth-panel-h1__SQkZ0 {
  font-size: var(--lia-bs-h3-font-size);
  line-height: var(--lia-bs-headings-line-height);
  margin-bottom: 0;
}

.styles_lia-g-bg-center__cJNG6 {
    background-position: center;
  }

.styles_lia-g-bg-cover___Omk_ {
    background-size: cover;
  }

.styles_lia-g-solved-border__f_lJd {
  border-left: 5px solid var(--lia-forum-solved-color) !important;
}

/**
 * Default outline styles.
 */

/**
 * Default input-style box-shadow.
 */

/**
 * Default focus-visible outline styles
 */

/**
 * Default focus-visible styles for button
 * or button-like elements.
 */

.styles_lia-g-unstyled-btn__Sglpm {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
}

.styles_lia-g-action-btn__70w24 {
  display: flex;
  padding: 0.25rem 0.1875rem;
  line-height: 1.2;
  font-size: var(--lia-bs-font-size-sm);
  transition: background-color var(--lia-timing-normal);
}

.styles_lia-g-action-btn__70w24 > span {
    color: var(--lia-bs-gray-700);
    font-weight: var(--lia-font-weight-md);
    transition: color var(--lia-timing-normal);
  }

.styles_lia-g-action-btn__70w24 > svg {
    transition: fill var(--lia-timing-normal);
    fill: var(--lia-bs-gray-600);
  }

.styles_lia-g-action-btn__70w24:hover {
    background-color: var(--lia-bs-gray-200);
  }

.styles_lia-g-action-btn__70w24:hover,
  .styles_lia-g-action-btn__70w24:focus {
    text-decoration: none;
  }

.styles_lia-g-action-btn__70w24:disabled {
    opacity: 1;
  }

.styles_lia-g-action-btn__70w24:disabled > span {
      color: var(--lia-bs-gray-600);
    }

.styles_lia-g-action-btn__70w24:disabled > svg {
      fill: var(--lia-bs-gray-500);
    }

.styles_lia-g-icon-btn__fJGFW {
  display: flex;
  padding: 0.25rem;
  background-color: transparent;
  border: none;
  border-radius: var(--lia-border-radius-50);
}

.styles_lia-g-icon-btn__fJGFW.styles_dropdown-toggle__lyIUd:after {
    display: none;
  }

.styles_lia-g-icon-btn__fJGFW:hover {
    background-color: hsla(
      var(--lia-bs-black-h),
      var(--lia-bs-black-s),
      var(--lia-bs-black-l),
      0.04
    );
    box-shadow: none;
  }

.styles_lia-g-icon-btn__fJGFW:focus {
    outline: unset;
    box-shadow: unset;
  }

.styles_lia-g-icon-btn__fJGFW:focus-visible {
  box-shadow: var(--lia-bs-input-focus-box-shadow), 0 0 0 1px var(--lia-bs-input-focus-border-color);
  outline: unset;
  }

.styles_lia-g-icon-btn__fJGFW:active {
    background-color: hsla(
      var(--lia-bs-black-h),
      var(--lia-bs-black-s),
      var(--lia-bs-black-l),
      0.08
    );
  }

.styles_lia-g-icon-btn__fJGFW:disabled {
    opacity: var(--lia-bs-btn-disabled-opacity);
  }

.styles_lia-g-icon-btn__fJGFW.styles_lia-g-in-modal-header__iaWXC {
    padding: 0.5rem;
    margin: 0 0.625rem 0 auto;
  }

.styles_lia-g-btn-has-loading-animation__WjjTI:disabled {
  opacity: 1;
}

.styles_lia-g-link-btn__y3Rq_ {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  color: var(--lia-bs-link-color);
  text-decoration: none;
  font-family: var(--lia-bs-font-family-base);
  line-height: var(--lia-bs-line-height-base);
  font-weight: var(--lia-bs-font-weight-base);
  vertical-align: unset;
}

.styles_lia-g-link-btn__y3Rq_:hover {
    color: var(--lia-bs-link-hover-color);
    -webkit-text-decoration: var(--lia-bs-link-hover-decoration);
            text-decoration: var(--lia-bs-link-hover-decoration);
  }

.styles_lia-g-link-btn__y3Rq_::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

.styles_lia-g-link-btn__y3Rq_:focus {
    box-shadow: unset;
  }

.styles_lia-g-link-btn__y3Rq_:focus-visible {
  outline: var(--lia-outline-color) solid 3px !important;
  outline-offset: unset;
  }

.styles_lia-g-text-btn__ubWbh,
.styles_lia-g-text-btn-wrap__0DmhQ > button {
  line-height: 1.2;
  color: var(--lia-bs-body-color);
  padding: 0;
  border: 0;
}

.styles_lia-g-loader-btn__hVmpn {
  color: var(--lia-load-text-color);
  font-weight: var(--lia-load-text-font-weight);
  font-size: var(--lia-load-text-font-size);
  padding: 0;
  border: 0;
}

.styles_lia-g-loader-btn__hVmpn:hover,
  .styles_lia-g-loader-btn__hVmpn:focus {
    color: var(--lia-load-text-color);
    text-decoration: none;
  }

.styles_lia-g-editor-btn__j0iJS {
  background-color: var(--lia-bs-white);
  border-color: var(--lia-bs-border-color);
  line-height: 0;
  padding: 0.5625rem;
}

.styles_lia-g-editor-btn__j0iJS:hover,
  .styles_lia-g-editor-btn__j0iJS:focus {
    background-color: var(--lia-bs-light);
    box-shadow: none;
  }

.styles_lia-g-card__y_snR {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  word-break: break-word;
}

.styles_lia-g-card__y_snR .styles_lia-g-card-header__0BOHm.styles_lia-g-card-header__0BOHm {
    margin-bottom: 1.5625rem;
    width: 100%;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-body__rOqpK.styles_lia-g-card-body__rOqpK {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 1.25rem;
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-base);
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-body-is-clickable__TNLCy {
    position: relative;
    cursor: pointer;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-item___XH9P:not(:last-child) {
    margin-bottom: 0.625rem;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-avatar__0S3Gd {
    border-radius: var(--lia-bs-border-radius);
    margin-bottom: 1.25rem;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-avatar__0S3Gd.styles_lia-is-circle__E3GTj {
      border-radius: var(--lia-border-radius-50);
    }

.styles_lia-g-card__y_snR .styles_lia-g-card-title__PI4qH {
    display: block;
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-lg);
    font-weight: var(--lia-font-weight-md);
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-title__PI4qH:hover {
      color: var(--lia-bs-body-color);
      text-decoration: underline;
    }

.styles_lia-g-card__y_snR .styles_lia-g-card-subtitle__n8gq2 {
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-base);
    margin-bottom: 0;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-meta__lbtZc {
    display: block;
    color: var(--lia-bs-text-muted);
    font-size: var(--lia-bs-font-size-sm);
    white-space: normal;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-count__nDn9j {
    color: var(--lia-bs-gray-700);
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-footer__NUmfQ.styles_lia-g-card-footer__NUmfQ {
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%;
    color: var(--lia-bs-gray-700);
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-footer__NUmfQ.styles_lia-g-card-footer__NUmfQ .styles_lia-g-card-count__nDn9j:not(:first-child) {
      margin-left: 1.25rem;
    }

.styles_lia-g-card__y_snR .styles_lia-g-card-footer-unstyled__8p1Ft.styles_lia-g-card-footer-unstyled__8p1Ft {
    border: none;
    padding-top: 0;
    margin-top: 0;
  }

.styles_lia-g-card__y_snR.styles_lia-g-card-sm__ItOMo .styles_lia-g-card-title__PI4qH, .styles_lia-g-card__y_snR.styles_lia-g-card-xs__6cGk3 .styles_lia-g-card-title__PI4qH {
      font-size: var(--lia-bs-font-size-base);
    }

.styles_lia-g-card__y_snR.styles_lia-g-card-sm__ItOMo .styles_lia-g-card-subtitle__n8gq2, .styles_lia-g-card__y_snR.styles_lia-g-card-xs__6cGk3 .styles_lia-g-card-subtitle__n8gq2 {
      font-size: var(--lia-bs-font-size-sm);
    }

.styles_lia-g-card__y_snR.styles_lia-g-card-xs__6cGk3 .styles_lia-g-card-item___XH9P:not(:last-child) {
      margin-bottom: 0.3125rem;
    }

.styles_lia-g-card__y_snR.styles_lia-g-card-xs__6cGk3 .styles_lia-g-card-avatar__0S3Gd {
      margin-bottom: 0.9375rem;
    }

.styles_lia-g-card__y_snR .styles_lia-g-card-left__GBjZ1 {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: left;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-center__fMhEM {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

.styles_lia-g-card__y_snR .styles_lia-g-card-right__OGmaI {
    align-items: flex-end !important;
    text-align: right;
    justify-content: flex-end !important;
  }

.styles_lia-g-hovercard__4UARF {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  width: 100%;
  max-width: 20rem;
  background-color: var(--lia-bs-white);
  box-shadow: var(--lia-bs-box-shadow);
  border-radius: var(--lia-bs-border-radius);
  border: 1px solid var(--lia-bs-border-color);
  z-index: 1050;
}

.styles_lia-g-hovercard-link__niY0Q:hover,
  .styles_lia-g-hovercard-link__niY0Q:focus {
    text-decoration: none;
  }

.styles_lia-g-hovercard-icon__ZpJLZ {
  margin-bottom: 1rem;
}

.styles_lia-g-hovercard-title__SRMTx {
  font-weight: var(--lia-bs-font-weight-bold);
  color: var(--lia-bs-body-color);
  margin-bottom: 0;
}

a.styles_lia-g-hovercard-title__SRMTx:hover,
  a.styles_lia-g-hovercard-title__SRMTx:focus {
    color: var(--lia-bs-body-color);
    text-decoration: none;
  }

.styles_lia-g-hovercard-info__oAgCJ {
  display: block;
  font-size: var(--lia-bs-font-size-sm);
  margin-top: 0.875rem;
  text-align: left;
}

.styles_lia-g-hovercard-meta-text__Y6vdS {
  display: block;
  font-size: var(--lia-font-size-xs);
  color: var(--lia-bs-text-muted);
}

.styles_lia-g-hovercard-divided-text__awV5x {
  display: flex;
  align-items: center;
}

.styles_lia-g-hovercard-badges__b8Zns {
  width: 100%;
  margin-top: 1.25rem;
  justify-content: center;
}

.styles_lia-g-hovercard-badges__b8Zns + .styles_lia-g-hovercard-data__wSAgE {
    margin-top: 1.125rem;
  }

.styles_lia-g-hovercard-data__wSAgE {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0.75rem 0.625rem 0;
}

.styles_lia-g-hovercard-data-item__TAFQN {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    min-width: 25%;
  }

.styles_lia-g-hovercard-data-number__EIT7g {
    font-weight: var(--lia-bs-font-weight-bold);
    color: var(--lia-bs-gray-900);
    margin: 0;
  }

.styles_lia-g-hovercard-data-title__K49wW {
    font-weight: var(--lia-bs-font-weight-normal);
    font-size: var(--lia-font-size-xs);
    color: var(--lia-bs-gray-700);
  }

.styles_lia-g-hovercard-btn__xymJ5 {
  margin-top: 1.25rem;
  width: 100%;
}

.styles_lia-g-clamp__dg3Sg {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.styles_lia-g-clamp-1__lzYG5 {
    -webkit-line-clamp: 1;
  }

.styles_lia-g-clamp-2__53ACH {
    -webkit-line-clamp: 2;
  }

.styles_lia-g-clamp-3__hQHtO {
    -webkit-line-clamp: 3;
  }

.styles_lia-g-clamp-4__3fIg4 {
    -webkit-line-clamp: 4;
  }

.styles_lia-g-clamp-5__NDmQz {
    -webkit-line-clamp: 5;
  }

.styles_lia-g-clamp-6__PokyO {
    -webkit-line-clamp: 6;
  }

.styles_lia-g-clamp-7__E84Bb {
    -webkit-line-clamp: 7;
  }

.styles_lia-g-clamp-8__3O8R5 {
    -webkit-line-clamp: 8;
  }

.styles_lia-g-clamp-9__297uc {
    -webkit-line-clamp: 9;
  }

.styles_lia-g-clamp-10__aNYTg {
    -webkit-line-clamp: 10;
  }

.styles_lia-g-clamp-11__jEBFa {
    -webkit-line-clamp: 11;
  }

.styles_lia-g-clamp-12__HLcOn {
    -webkit-line-clamp: 12;
  }

.styles_lia-g-clamp-13__BTWV7 {
    -webkit-line-clamp: 13;
  }

.styles_lia-g-clamp-14__vCCZk {
    -webkit-line-clamp: 14;
  }

.styles_lia-g-clamp-15__GW5LS {
    -webkit-line-clamp: 15;
  }

.styles_lia-g-clamp-16__RRECR {
    -webkit-line-clamp: 16;
  }

.styles_lia-g-clamp-17__5wDfj {
    -webkit-line-clamp: 17;
  }

.styles_lia-g-clamp-18__wvlSI {
    -webkit-line-clamp: 18;
  }

.styles_lia-g-clamp-19__di2Zx {
    -webkit-line-clamp: 19;
  }

.styles_lia-g-clamp-20__mtUtj {
    -webkit-line-clamp: 20;
  }

.styles_lia-g-svg-current-color__yJ0uQ {
  fill: currentColor;
}

/**
 * Default panel styles
 */

.styles_lia-g-message-box__arKXm {
  padding: 1.5625rem 0.9375rem;
  background-color: var(--lia-panel-bg-color);
  box-shadow: var(--lia-panel-box-shadow);
  border: var(--lia-content-item-border);
  border-radius: var(--lia-panel-border-radius);
}

@media (min-width: 768px) {

.styles_lia-g-message-box__arKXm {
    padding: 1.25rem 1.5625rem
}
  }

@media (max-width: 575.98px) {

.styles_lia-g-message-box__arKXm {
    border-radius: 0
}
  }

.styles_lia-g-page-full-height__U2VNi {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.styles_lia-g-popper-right___y0Jr {
  right: 0 !important;
  left: auto !important;
}

.styles_lia-g-pseudo-body___wI2Y {
  font-family: var(--lia-bs-font-family-base);
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-body-bg);
}

.styles_lia-g-pseudo-body-inner__enF_K {
    margin: var(--lia-body-margin);
    max-width: var(--lia-body-max-width);
    background-color: var(--lia-bs-body-bg);
  }

.styles_lia-g-admin-width__nR0H4,
  .styles_lia-g-admin-width__nR0H4 body {
    min-width: 69.375rem;
  }

.styles_lia-g-theme-styleguide-box__qgdLZ {
  padding: 1.5625rem;
  box-shadow: var(--lia-bs-box-shadow-sm);
  border: 1px solid var(--lia-bs-border-color);
  background-color: var(--lia-bs-white);
  border-radius: var(--lia-bs-border-radius);
}

/* Adds vertical spacing between section widgets in the same column */

.styles_lia-g-section-widget__aHMgk:not(:last-of-type):not(:empty) {
  margin-bottom: 1.875rem;
}

.styles_lia-g-admin-sections-wrap__mjq0k {
  display: flex;
  flex-direction: column;
  margin-left: 17.5rem;
}

.styles_lia-g-admin-scroll-wrap__NqitB {
  display: grid;
  grid-template-columns: 250px 1fr;
  -moz-column-gap: 1.875rem;
       column-gap: 1.875rem;
}

.styles_lia-g-admin-scroll-wrap__NqitB .styles_lia-g-admin-scroll-nav__U92DG {
    position: sticky;
    top: calc((3.25rem * 2) + 2.5rem);
  }

.styles_lia-g-admin-scroll-wrap__NqitB .styles_lia-g-admin-scroll-nav__U92DG.styles_lia-g-admin-has-solo-nav__70SnW {
      top: calc(3.25rem + 2.5rem);
    }

.styles_lia-g-admin-scroll-wrap__NqitB .styles_lia-g-admin-scroll-content__zpNqq {
    display: flex;
    flex-direction: column;
    margin-bottom: 100%;
    row-gap: 1.875rem;
  }

.styles_lia-g-admin-panel__T5cio {
  padding: 1.25rem 1.875rem 1.875rem;
  background-color: var(--lia-panel-bg-color);
  border-radius: var(--lia-panel-border-radius);
}

.styles_lia-g-count-wrap___e35P {
  display: inline-flex;
  align-items: center;
  color: var(--lia-bs-text-muted);
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-sm);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.styles_lia-g-count-wrap___e35P:not(.styles_lia-g-count-use-text__n9Kma) {
    gap: 0.3125rem;
  }

.styles_lia-g-count-text__Dw9O9 + .styles_lia-g-count-number__s_gWt:before,
.styles_lia-g-count-number__s_gWt + .styles_lia-g-count-text__Dw9O9:before {
  content: ' ';
  white-space: pre;
}

.styles_lia-g-dropdown-toggle__aM2yM {
  display: flex;
  align-items: center;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-body-color);
  border-color: var(--lia-bs-gray-500);
  border-radius: var(--lia-bs-border-radius);
  background-color: var(--lia-bs-white);
  padding-left: 0.625rem;
  padding-right: 0.5rem;
}

.styles_lia-g-dropdown-toggle__aM2yM:not(.styles_lia-g-no-arrow__PEegZ):after {
    display: inline-block;
    content: '';
    margin-left: 0.3125rem;
    width: var(--lia-icon-size-14);
    height: var(--lia-icon-size-14);
    background-color: var(--lia-bs-gray-900);
    -webkit-mask-image: var(--lia-chevron-down-icon);
            mask-image: var(--lia-chevron-down-icon);
    -webkit-mask-size: 14px;
            mask-size: 14px;
    background-size: cover;
    vertical-align: middle;
  }

.styles_lia-g-dropdown-toggle__aM2yM.styles_lia-g-no-arrow__PEegZ {
    padding-right: 0.625rem;
  }

.styles_lia-g-dropdown-toggle__aM2yM.styles_btn-link__Qn59s {
      padding: 0;
      border: none;
      background-color: inherit;
    }

.styles_lia-g-dropdown-toggle__aM2yM.styles_btn-link__Qn59s:hover,
      .styles_lia-g-dropdown-toggle__aM2yM.styles_btn-link__Qn59s:focus {
        color: var(--lia-bs-body-color);
      }

.styles_lia-g-dropdown-toggle__aM2yM.styles_btn-lg__MJujA {
      font-size: var(--lia-bs-font-size-base);
    }

.styles_lia-g-dropdown-toggle__aM2yM:hover {
    box-shadow: none;
  }

.styles_lia-g-dropdown-toggle__aM2yM:hover,
  .styles_lia-g-dropdown-toggle__aM2yM:focus {
    text-decoration: none;
  }

.styles_lia-g-dropdown-toggle__aM2yM:focus {
    outline: unset;
    box-shadow: unset;
  }

.styles_lia-g-dropdown-toggle__aM2yM:focus-visible {
  box-shadow: var(--lia-bs-input-focus-box-shadow), 0 0 0 1px var(--lia-bs-input-focus-border-color);
  outline: unset;
  }

.styles_lia-g-faux-input__7UVsm {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.4375rem 0.625rem;
  text-align: left;
  font-size: var(--lia-bs-input-btn-font-size);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: 1.7142857;
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
  background-clip: padding-box;
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-bs-input-border-radius);
  transition: border-color var(--lia-timing-fast) var(--lia-timing-function),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);
}

.styles_lia-g-faux-input__7UVsm:focus,
  .styles_lia-g-faux-input__7UVsm:focus-visible {
    border-color: var(--lia-bs-input-focus-border-color);
    outline: 0;
    box-shadow: var(--lia-bs-input-focus-box-shadow);
  }

.styles_lia-g-faux-input__7UVsm.styles_lia-g-faux-select__IInNa:after {
    display: block;
    content: '';
    margin-left: auto;
    width: 1rem;
    height: 1.0625rem;
    background-color: var(--lia-bs-gray-900);
    -webkit-mask-image: var(--lia-select-arrow-icon);
            mask-image: var(--lia-select-arrow-icon);
    -webkit-mask-size: 16px 17px;
            mask-size: 16px 17px;
    background-size: cover;
  }

.styles_lia-g-faux-select-list__Lj6IL {
  min-width: 100%;
}

@keyframes styles_dropdown-animate__qm5i_ {
  from {
    opacity: 0;
    transform: scale(0.97) translateY(-0.1875rem);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.styles_lia-g-editor-toolbar___iEG6 {
  width: 100%;
}

.styles_lia-g-editor-toolbar___iEG6 .tox-tinymce-inline {
      visibility: inherit !important;
      display: inherit !important;
    }

.styles_lia-g-editor-toolbar___iEG6 .tox-autocompleter {
      display: none !important;
    }

.styles_lia-g-editor-toolbar___iEG6 .tox.tox-tinymce {
        border-radius: var(--lia-bs-border-radius-sm);
        overflow: hidden;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox.tox-tinymce--toolbar-bottom .tox-editor-header,
      .styles_lia-g-editor-toolbar___iEG6 .tox.tox-tinymce-inline .tox-editor-header {
        border: 0;
        margin: 0;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar,
      .styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar__overflow,
      .styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar__primary {
        background: none;
        background-color: var(--lia-bs-gray-200);
        padding: 0;
        margin: 0 -0.0625rem;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar__group {
        margin: 0.4375rem 0;
        padding: 0 0.25rem;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling {
        border-radius: var(--lia-bs-border-radius-sm);
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling:before,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling:after {
          position: absolute;
          content: '';
          width: 0.375rem;
          top: 0;
          bottom: 0;
          z-index: 4;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling:before {
          left: 0;
          background: linear-gradient(
            90deg,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.12) 0%,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0) 100%
          );
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling:after {
          right: 0;
          background: linear-gradient(
            90deg,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0) 0%,
            hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.12) 100%
          );
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling .tox-toolbar__group:last-child {
            flex-grow: 1;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling .tox-toolbar__group:first-child:before,
          .styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling .tox-toolbar__group:last-child:after {
            display: block;
            content: '';
            height: calc(100% + 0.875rem);
            width: 0.4375rem;
            background-color: var(--lia-bs-gray-200);
            z-index: 5;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling .tox-toolbar__group:first-child:before {
            margin-left: -0.25rem;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-toolbar.tox-toolbar--scrolling .tox-toolbar__group:last-child:after {
            flex-grow: 1;
            margin-right: -0.25rem;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
        border-color: var(--lia-bs-gray-300);
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-menu {
        margin: 0.3125rem 0;
        border: 1px solid var(--lia-bs-border-color);
        border-radius: var(--lia-bs-border-radius-sm);
        box-shadow: var(--lia-bs-box-shadow);
        background-color: var(--lia-bs-white);
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection--list .tox-collection__item--active {
          background-color: var(--lia-bs-gray-300);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
            color: inherit;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection--list .tox-collection__item--enabled {
          background-color: var(--lia-bs-gray-300);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn--select,
      .styles_lia-g-editor-toolbar___iEG6 .tox .tox-split-button,
      .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn {
        margin: 0;
        padding: 0;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn {
        color: var(--lia-bs-dark);
        background-color: transparent;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn:hover,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn--enabled,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn--enabled:hover,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
          color: var(--lia-bs-dark);
          background-color: var(--lia-bs-gray-300);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn:hover svg, .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn--enabled svg, .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn--enabled:hover svg, .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
            fill: var(--lia-bs-dark);
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn.tox-tbtn--select {
          min-width: 2.6875rem;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn.tox-tbtn--select .tox-icon.tox-tbtn__icon-wrap {
            display: flex;
            justify-content: center;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn .tox-icon svg > path, .styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn .tox-tbtn__select-chevron svg > path {
            fill: var(--lia-bs-dark);
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn.tox-tbtn--disabled .tox-icon svg > path {
              fill: var(--lia-bs-btn-disabled-opacity);
            }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn svg {
          fill: var(--lia-bs-dark);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn[aria-label='Update'] {
        margin: -0.4375rem -0.25rem;
        height: 2.25rem;
        width: 2.25rem;
        box-shadow: var(--lia-box-shadow-xs);
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop:before,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop:after {
          content: none;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop.tox-pop--top {
          margin-top: -0.3125rem;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop.tox-pop--bottom {
          margin-bottom: -0.3125rem;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog {
        border-radius: var(--lia-bs-border-radius-sm);
        box-shadow: var(--lia-box-shadow-xs);
        background-color: var(--lia-bs-white);
        border: 0;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar {
          margin: 0;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group {
            margin: 0;
            padding: 0;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn {
              padding: 0.0625rem;
            }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn:hover,
              .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn:focus {
                background-color: var(--lia-bs-light);
              }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--enabled,
              .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--enabled:hover {
                background-color: var(--lia-bs-gray-300);
              }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--disabled,
              .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--disabled:hover,
              .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--disabled:focus {
                background-color: var(--lia-bs-white);
              }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--disabled svg, .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--disabled:hover svg, .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar__group .tox-tbtn--disabled:focus svg {
                  fill: var(--lia-bs-gray-500);
                }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar--scrolling {
          overflow-x: hidden;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar--scrolling:before,
          .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar--scrolling:after {
            background: none;
            content: none;
          }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar--scrolling .tox-toolbar__group:first-child:before,
            .styles_lia-g-editor-toolbar___iEG6 .tox .tox-pop__dialog .tox-toolbar--scrolling .tox-toolbar__group:last-child:after {
              content: none;
            }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn__select-label {
          width: auto;
          margin: 0 0 0 0.4375rem;
          color: var(--lia-bs-body-color);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-tbtn__select-chevron {
          margin: 0 0.0625rem;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-split-button:hover {
          box-shadow: none;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-split-button__chevron {
          width: 1.125rem;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-split-button__chevron svg > path {
            fill: var(--lia-bs-dark);
          }

.styles_lia-g-editor-toolbar___iEG6 .tox.tox-tinymce-aux {
        z-index: 1000;
        display: block !important;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox.tox-tinymce-aux .tox-toolbar__overflow {
          border: 0;
          margin-top: 0.5625rem;
          box-shadow: var(--lia-box-shadow-xs);
          border-radius: var(--lia-bs-border-radius-sm);
          background-color: var(--lia-bs-gray-200);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection--list .tox-collection__item {
        padding: 0.3125rem 0.53125rem;
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection__item-label {
          font-size: inherit;
          line-height: inherit;
          white-space: nowrap;
          color: var(--lia-bs-dark);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection__item-checkmark svg,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection__item-icon svg,
        .styles_lia-g-editor-toolbar___iEG6 .tox .tox-collection__item-caret svg {
          fill: var(--lia-bs-dark);
        }

.styles_lia-g-editor-toolbar___iEG6 .tox:not([dir='rtl']) .tox-collection--list .tox-collection__item-label:first-child {
          margin: 0;
        }

.styles_lia-g-editor-toolbar___iEG6 .tox:not([dir='rtl']) .tox-collection--list .tox-collection__item > :not(:first-child) {
          margin: 0 0 0 0.53125rem;
        }

/* TODO: Remove this after modal work has been done. */

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-dialog-wrap__backdrop {
        background-color: hsla(
          var(--lia-bs-black-h),
          var(--lia-bs-black-s),
          var(--lia-bs-black-l),
          0.3
        );
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-dialog {
        border: none;
        background-color: var(--lia-bs-white);
        border-radius: var(--lia-bs-border-radius-lg);
        box-shadow: var(--lia-bs-box-shadow-lg);
        max-width: var(--lia-bs-modal-sm);
      }

.styles_lia-g-editor-toolbar___iEG6 .tox .tox-insert-table-picker__label {
        color: var(--lia-bs-text-muted);
      }

.styles_lia-g-form-field-no-border__QQ_wp > input,
  .styles_lia-g-form-field-no-border__QQ_wp > textarea {
    border: 0;
    padding: 0;
    border-radius: 0;
  }

.styles_lia-g-form-field-no-border__QQ_wp > input:focus, .styles_lia-g-form-field-no-border__QQ_wp > textarea:focus {
      border: 0;
      box-shadow: none !important;
    }

.styles_lia-g-form-field-no-resize__CqzRn > textarea {
  resize: none;
}

.styles_lia-g-validation-error__NLJa4 {
  display: flex !important; /* Override Bootstrap */
  color: var(--lia-bs-danger);
  font-size: var(--lia-font-size-xs);
  margin-top: 0.5rem;
}

.styles_lia-g-validation-error__NLJa4 .styles_lia-g-validation-error-icon__cz63_ {
    margin-right: 0.3125rem;
    flex-shrink: 0;
  }

.styles_lia-g-validation-alert__MnpOi {
  padding: 0.625rem 0 0 0.9375rem;
}

.styles_lia-g-validation-alert-heading__faywT {
    font-size: var(--lia-bs-font-size-sm);
    padding-top: 0.3125rem;
  }

.styles_lia-g-disabled__TbO1x {
  opacity: var(--lia-bs-btn-disabled-opacity);
}

.styles_lia-g-legend-as-label__DWpZj.styles_lia-g-legend-as-label__DWpZj {
  display: inline-block;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  line-height: var(--lia-bs-line-height-base);
  margin-bottom: 0.625rem;
}

.styles_lia-g-field-divider__1kcql {
  border-bottom: 1px solid var(--lia-bs-border-color);
  margin-bottom: 1.25rem;
  padding-bottom: 1.5625rem;
}

.styles_lia-g-setting-form-field-divider__gUzm5:not(:last-child) {
    margin-bottom: 0.9375rem !important;
    padding-bottom: 1.5625rem;
    border-bottom: 1px solid var(--lia-bs-border-color);
  }

.styles_lia-g-divider__X68T2 + .styles_lia-g-divider__X68T2 {
    margin-left: var(--lia-divider-ml);
  }

.styles_lia-g-divider__X68T2 + .styles_lia-g-divider__X68T2:before {
      content: '';
      display: inline-block;
      background-color: var(--lia-divider-bg-color);
      margin-right: var(--lia-divider-mr);
      height: var(--lia-divider-size);
      width: var(--lia-divider-size);
      border-radius: var(--lia-divider-border-radius);
      vertical-align: middle;
    }

.styles_lia-g-divider__X68T2.styles_lia-g-is-color-mimic__T2gJA + .styles_lia-g-is-color-mimic__T2gJA:before {
    background-color: currentColor;
  }

.styles_lia-g-divider__X68T2.styles_active__EuuVN {
    background-color: var(--lia-divider-bg-color-active);
  }

.styles_lia-g-divider-element__SJ_Lw {
  display: block;
  background-color: var(--lia-divider-bg-color);
  margin: 0 var(--lia-divider-mr) 0 var(--lia-divider-ml);
  height: var(--lia-divider-size);
  width: var(--lia-divider-size);
  border-radius: var(--lia-divider-border-radius);
}

.styles_lia-g-divider-element__SJ_Lw.styles_lia-g-is-color-mimic__T2gJA {
    background-color: currentColor;
  }

.styles_lia-g-list-item-pr__FsMSd {
  padding-right: var(--lia-bs-list-group-item-padding-x);
}

.styles_lia-g-list-item-pl__evHcy {
  padding-left: var(--lia-bs-list-group-item-padding-x);
}

.styles_lia-g-list-item-px__CqMf4 {
  padding-right: var(--lia-bs-list-group-item-padding-x);
  padding-left: var(--lia-bs-list-group-item-padding-x);
}

/* Replacement for the standard UL with similar styles. */

.styles_lia-g-better-ul__puZ5p {
  display: table;
  list-style: none;
  margin: 0;
  padding: 0;
}

.styles_lia-g-better-ul__puZ5p > li {
    display: table-row;
  }

.styles_lia-g-better-ul__puZ5p > li:before {
      display: table-cell;
      content: '•';
      padding-right: 0.3125rem;
    }

.styles_lia-g-object-fit-cover__p45as {
  -o-object-fit: cover;
     object-fit: cover;
}

.styles_lia-g-object-fit-contain__OzjaP {
  -o-object-fit: contain;
     object-fit: contain;
}

.styles_lia-g-inline-icon-link__NBHs0 {
  display: inline-flex;
  align-items: center;
}

.styles_lia-g-inline-icon-link__NBHs0 .styles_lia-g-inline-icon__ZfOom {
    margin-left: 0.25rem;
    fill: var(--lia-bs-link-color);
  }

.styles_lia-g-inline-icon-link__NBHs0:hover .styles_lia-g-inline-icon__ZfOom {
    fill: var(--lia-bs-link-hover-color);
  }

.styles_lia-g-icon-size-10__29RN6 {
      width: var(--lia-icon-size-10);
      height: var(--lia-icon-size-10);
      min-width: var(--lia-icon-size-10);
      min-height: var(--lia-icon-size-10);
    }

.styles_lia-g-icon-size-12__fO_66 {
      width: var(--lia-icon-size-12);
      height: var(--lia-icon-size-12);
      min-width: var(--lia-icon-size-12);
      min-height: var(--lia-icon-size-12);
    }

.styles_lia-g-icon-size-14__x7dQA {
      width: var(--lia-icon-size-14);
      height: var(--lia-icon-size-14);
      min-width: var(--lia-icon-size-14);
      min-height: var(--lia-icon-size-14);
    }

.styles_lia-g-icon-size-16__V9Va_ {
      width: var(--lia-icon-size-16);
      height: var(--lia-icon-size-16);
      min-width: var(--lia-icon-size-16);
      min-height: var(--lia-icon-size-16);
    }

.styles_lia-g-icon-size-20__oBHJy {
      width: var(--lia-icon-size-20);
      height: var(--lia-icon-size-20);
      min-width: var(--lia-icon-size-20);
      min-height: var(--lia-icon-size-20);
    }

.styles_lia-g-icon-size-24__A6gB_ {
      width: var(--lia-icon-size-24);
      height: var(--lia-icon-size-24);
      min-width: var(--lia-icon-size-24);
      min-height: var(--lia-icon-size-24);
    }

.styles_lia-g-icon-size-30__3OgzM {
      width: var(--lia-icon-size-30);
      height: var(--lia-icon-size-30);
      min-width: var(--lia-icon-size-30);
      min-height: var(--lia-icon-size-30);
    }

.styles_lia-g-icon-size-40__s3x_4 {
      width: var(--lia-icon-size-40);
      height: var(--lia-icon-size-40);
      min-width: var(--lia-icon-size-40);
      min-height: var(--lia-icon-size-40);
    }

.styles_lia-g-icon-size-50__HExtr {
      width: var(--lia-icon-size-50);
      height: var(--lia-icon-size-50);
      min-width: var(--lia-icon-size-50);
      min-height: var(--lia-icon-size-50);
    }

.styles_lia-g-icon-size-60__w0yzb {
      width: var(--lia-icon-size-60);
      height: var(--lia-icon-size-60);
      min-width: var(--lia-icon-size-60);
      min-height: var(--lia-icon-size-60);
    }

.styles_lia-g-icon-size-80__f0WeA {
      width: var(--lia-icon-size-80);
      height: var(--lia-icon-size-80);
      min-width: var(--lia-icon-size-80);
      min-height: var(--lia-icon-size-80);
    }

.styles_lia-g-icon-size-120__xV6F8 {
      width: var(--lia-icon-size-120);
      height: var(--lia-icon-size-120);
      min-width: var(--lia-icon-size-120);
      min-height: var(--lia-icon-size-120);
    }

.styles_lia-g-icon-size-160__zVTlh {
      width: var(--lia-icon-size-160);
      height: var(--lia-icon-size-160);
      min-width: var(--lia-icon-size-160);
      min-height: var(--lia-icon-size-160);
    }

/*
 * Default highlight text styles.
 */

.styles_lia-g-message-body__LkV7_ {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.styles_lia-g-message-body__LkV7_ h1,
  .styles_lia-g-message-body__LkV7_ h2,
  .styles_lia-g-message-body__LkV7_ h3,
  .styles_lia-g-message-body__LkV7_ h4,
  .styles_lia-g-message-body__LkV7_ h5,
  .styles_lia-g-message-body__LkV7_ h6 {
    margin-top: var(--lia-rte-default-message-header-mt);
    margin-bottom: var(--lia-rte-default-message-header-mb);
  }

.styles_lia-g-message-body__LkV7_ a {
    word-break: break-word;
  }

.styles_lia-g-message-body__LkV7_ p,
  .styles_lia-g-message-body__LkV7_ pre {
    margin-top: var(--lia-rte-default-message-item-mt);
    margin-bottom: var(--lia-rte-default-message-item-mb);
  }

.styles_lia-g-message-body__LkV7_ pre {
    clear: both;
    white-space: pre-wrap;
  }

.styles_lia-g-message-body__LkV7_ img {
    max-width: 100%;
    border-radius: var(--lia-bs-border-radius-sm);
  }

.styles_lia-g-message-body__LkV7_ figcaption:empty {
    display: none;
  }

.styles_lia-g-message-body__LkV7_ table {
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: normal;
  }

.styles_lia-g-message-body__LkV7_ blockquote {
    padding: 1.25rem 1.25rem 1.25rem 1.875rem;
    background-color: var(--lia-bs-gray-200);
    border-radius: var(--lia-bs-border-radius-sm);
    border-left: 5px solid var(--lia-bs-primary);
    margin-top: var(--lia-rte-default-message-item-mt);
    margin-bottom: var(--lia-rte-default-message-item-mb);
    clear: both;
  }

.styles_lia-g-message-body__LkV7_ blockquote > p:last-of-type,
    .styles_lia-g-message-body__LkV7_ blockquote:last-child {
      margin-bottom: 0;
    }

.styles_lia-g-message-body__LkV7_ blockquote:first-child {
      margin-top: 0;
    }

.styles_lia-g-message-body__LkV7_ blockquote .styles_lia-blockquote-author-title___Eu8R {
      font-weight: var(--lia-bs-font-weight-bold);
      font-size: var(--lia-bs-font-size-sm);
    }

.styles_lia-g-message-body__LkV7_ blockquote .styles_lia-blockquote-author-text__IfLRZ {
      font-size: var(--lia-bs-font-size-sm);
    }

.styles_lia-g-message-body__LkV7_ .styles_lia-table-wrapper__h6Xo9 {
    margin-top: var(--lia-rte-default-message-item-mt);
    margin-bottom: var(--lia-rte-default-message-item-mb);
  }

.styles_lia-g-message-body__LkV7_ > *:first-child {
    margin-top: 0 !important;
  }

.styles_lia-g-message-body__LkV7_ > *:last-child {
    margin-bottom: 0 !important;
  }

.styles_lia-g-message-body__LkV7_ .mce-content-body > *:first-child {
        margin-top: 0 !important;
      }

.styles_lia-g-message-body__LkV7_ .mce-content-body > *:last-child {
        margin-bottom: 0 !important;
      }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:has(+ .lia-linked-item:hover),
      .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:has(+ .lia-linked-item:focus) {
        opacity: 0.5;
      }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:has(+ .lia-linked-item:hover):hover,
        .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:has(+ .lia-linked-item:hover):focus,
        .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:has(+ .lia-linked-item:focus):hover,
        .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:has(+ .lia-linked-item:focus):focus {
          opacity: 1;
        }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item {
        position: relative;
      }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item:hover .lia-anchor,
        .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item:focus .lia-anchor {
          opacity: 0.5;
        }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item:hover .lia-anchor:hover,
          .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item:hover .lia-anchor:focus,
          .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item:focus .lia-anchor:hover,
          .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-linked-item:focus .lia-anchor:focus {
            opacity: 1;
          }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor {
        visibility: visible;
        position: relative;
        transition: opacity var(--lia-timing-normal) var(--lia-timing-function);
        z-index: 2;
      }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:after,
        .styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          min-height: 1.375rem;
          min-width: 1.375rem;
          transform: translate(-0.625rem, -0.6875rem);
        }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:after {
          border-radius: var(--lia-bs-border-radius-sm);
          box-shadow: var(--lia-box-shadow-xs);
          background-color: var(--lia-bs-white);
        }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor:before {
          -webkit-mask-image: var(--lia-anchor-icon);
                  mask-image: var(--lia-anchor-icon);
          -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
          -webkit-mask-position: center;
                  mask-position: center;
          background-color: var(--lia-bs-gray-700);
          z-index: 2;
        }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor[data-mce-selected]:after {
          box-shadow: 0 0 0 2px var(--lia-outline-color);
        }

.styles_lia-g-message-body__LkV7_ .mce-content-body .lia-anchor[contenteditable='false'][data-mce-selected] {
          outline: none;
        }

.styles_lia-g-message-body__LkV7_ .lia-anchor {
      visibility: hidden;
    }

.styles_lia-g-message-body__LkV7_ .lia-media-object > a {
        display: inline-block;
      }

.styles_lia-g-message-body__LkV7_ .lia-media-object img {
        pointer-events: none;
        width: auto;
        max-width: 100%;
        height: auto;
      }

.styles_lia-g-message-body__LkV7_ .lia-media-unstyled-btn {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      width: 100%;
    }

.styles_lia-g-message-body__LkV7_ .lia-media-is-inline {
      display: inline-block;
    }

@media (min-width: 576px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-small {
        max-width: 80%
    }
      }

@media (min-width: 768px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-small {
        max-width: 60%
    }
      }

@media (min-width: 992px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-small {
        max-width: 40%
    }
      }

@media (min-width: 1260px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-small {
        max-width: 30%
    }
      }

@media (min-width: 576px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-medium {
        max-width: 90%
    }
      }

@media (min-width: 768px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-medium {
        max-width: 80%
    }
      }

@media (min-width: 992px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-medium {
        max-width: 70%
    }
      }

@media (min-width: 1260px) {

.styles_lia-g-message-body__LkV7_ .lia-media-size-medium {
        max-width: 60%
    }
      }

.styles_lia-g-message-body__LkV7_ .lia-media-size-large {
      max-width: 100%;
    }

.styles_lia-g-message-body__LkV7_ .lia-media-is-left {
      display: table;
      margin: 0.3125rem 1.875rem 1.25rem 0;
      float: left;
    }

@media (max-width: 767.98px) {

.styles_lia-g-message-body__LkV7_ .lia-media-is-left {
        margin-left: auto;
        margin-right: auto;
        float: none
    }
      }

.styles_lia-g-message-body__LkV7_ .lia-media-is-right {
      display: table;
      margin: 0.3125rem 0 1.25rem 1.875rem;
      float: right;
    }

@media (max-width: 767.98px) {

.styles_lia-g-message-body__LkV7_ .lia-media-is-right {
        margin-left: auto;
        margin-right: auto;
        float: none
    }
      }

.styles_lia-g-message-body__LkV7_ .lia-media-is-center {
      display: table;
      margin: 0.3125rem auto 1.25rem;
      float: none;
      clear: both;
    }

.styles_lia-g-message-body__LkV7_ .lia-media-is-no-align {
      display: table;
      margin: 0.3125rem 0 1.25rem;
      clear: both;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-container {
      position: relative;
      display: flex;
      flex-direction: column;
      aspect-ratio: 16/9;
      width: 100%;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-player {
      background-color: var(--lia-bs-gray-300);
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-display-wrapper {
      background-color: var(--lia-bs-gray-200);
    }

.styles_lia-g-message-body__LkV7_ .lia-video-processing-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--lia-bs-gray-300);
      opacity: 0.8;
      color: var(--lia-bs-gray-900);
      font-size: var(--lia-bs-font-size-sm);
      height: 2.5rem;
    }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .lia-video-processing-indicator {
        font-size: var(--lia-font-size-xs);
        height: 1.875rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .lia-video-player-icon {
        margin-top: 0.9375rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .lia-video-player-icon,
      .styles_lia-g-message-body__LkV7_ .lia-media-size-small .vjs-big-play-button {
        width: 3.125rem;
        height: 3.125rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .vjs-big-play-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        transform: translate(-50%, -50%);
      }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .vjs-big-play-button .vjs-icon-placeholder {
          display: none;
        }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .vjs-big-play-button:after {
          content: '';
          -webkit-mask-image: var(--lia-video-play-active-icon);
                  mask-image: var(--lia-video-play-active-icon);
          background-color: var(--lia-bs-white);
          width: 1.875rem;
          height: 1.875rem;
          -webkit-mask-size: 30px;
                  mask-size: 30px;
        }

.styles_lia-g-message-body__LkV7_ .lia-media-size-small .vjs-error .vjs-big-play-button,
      .styles_lia-g-message-body__LkV7_ .lia-media-size-small .vjs-has-started .vjs-big-play-button {
        display: none;
      }

.styles_lia-g-message-body__LkV7_ .lia-video-upload-finalise-indicator {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-player-icon {
      background-color: var(--lia-bs-gray-300);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      opacity: 0.8;
      top: 50%;
      left: 50%;
      margin-top: 1.25rem;
      width: 6.25rem;
      height: 6.25rem;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-player-icon:after {
        content: '';
        -webkit-mask-image: var(--lia-video-play-disabled-icon);
                mask-image: var(--lia-video-play-disabled-icon);
        background-color: var(--lia-bs-gray-600);
        width: 1.875rem;
        height: 1.875rem;
        -webkit-mask-size: 30px;
                mask-size: 30px;
      }

.styles_lia-g-message-body__LkV7_ .lia-media-caption-text {
      color: var(--lia-bs-gray-700);
      font-size: var(--lia-font-size-xs);
      padding: 0.625rem 0;
      max-width: -moz-min-content;
      max-width: min-content;
      min-width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      display: block;
    }

.styles_lia-g-message-body__LkV7_ .lia-iframe-embeded {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      border-radius: var(--lia-bs-border-radius-sm);
    }

.styles_lia-g-message-body__LkV7_ .lia-embeded-content {
      width: 100%;
      clear: both;
      margin-bottom: var(--lia-rte-default-message-item-mb);
    }

.styles_lia-g-message-body__LkV7_ .lia-embeded-content .lia-anchor::after,
        .styles_lia-g-message-body__LkV7_ .lia-embeded-content .lia-anchor::before {
          display: none;
        }

.styles_lia-g-message-body__LkV7_ .lia-embeded-content .mce-item-table td {
        border: none;
      }

/* Media query for Firefox */

@supports (-moz-appearance: none) {
      .styles_lia-g-message-body__LkV7_ .lia-media-caption-text {
        width: -moz-available;
      }
    }

.styles_lia-g-message-body__LkV7_ .lia-internal-url:before, .styles_lia-g-message-body__LkV7_ .lia-mention:before {
        content: '';
        display: inline-block;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-size: 12px;
                mask-size: 12px;
        margin-right: 0.25rem;
        width: 0.75rem;
        height: 0.75rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-user:before {
        -webkit-mask-image: var(--lia-user-icon);
                mask-image: var(--lia-user-icon);
        background-color: var(--lia-bs-primary);
      }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-forum:before {
          -webkit-mask-image: var(--lia-content-discussion-icon);
                  mask-image: var(--lia-content-discussion-icon);
          background-color: var(--lia-forum-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-blog:before {
          -webkit-mask-image: var(--lia-content-blog-icon);
                  mask-image: var(--lia-content-blog-icon);
          background-color: var(--lia-blog-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-tkb:before {
          -webkit-mask-image: var(--lia-content-knowledge-icon);
                  mask-image: var(--lia-content-knowledge-icon);
          background-color: var(--lia-tkb-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-contest:before {
          -webkit-mask-image: var(--lia-content-contest-icon);
                  mask-image: var(--lia-content-contest-icon);
          background-color: var(--lia-contest-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-qanda:before {
          -webkit-mask-image: var(--lia-content-discussion-question-icon);
                  mask-image: var(--lia-content-discussion-question-icon);
          background-color: var(--lia-qanda-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-occasion:before {
          -webkit-mask-image: var(--lia-content-occasion-icon);
                  mask-image: var(--lia-content-occasion-icon);
          background-color: var(--lia-occasion-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-idea:before {
          -webkit-mask-image: var(--lia-content-idea-icon);
                  mask-image: var(--lia-content-idea-icon);
          background-color: var(--lia-idea-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-grouphub:before {
          -webkit-mask-image: var(--lia-content-group-icon);
                  mask-image: var(--lia-content-group-icon);
          background-color: var(--lia-grouphub-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-internal-url-content-type-category:before {
          -webkit-mask-image: var(--lia-content-category-icon);
                  mask-image: var(--lia-content-category-icon);
          background-color: var(--lia-category-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-user:before {
        -webkit-mask-image: var(--lia-user-mention-icon);
                mask-image: var(--lia-user-mention-icon);
        background-color: var(--lia-bs-primary);
      }

.styles_lia-g-message-body__LkV7_ .lia-mention-node-type-grouphub:before {
          -webkit-mask-image: var(--lia-content-group-icon);
                  mask-image: var(--lia-content-group-icon);
          background-color: var(--lia-grouphub-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-node-type-category:before {
          -webkit-mask-image: var(--lia-content-category-icon);
                  mask-image: var(--lia-content-category-icon);
          background-color: var(--lia-category-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-FORUM:before {
          -webkit-mask-image: var(--lia-content-discussion-icon);
                  mask-image: var(--lia-content-discussion-icon);
          background-color: var(--lia-forum-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-BLOG:before {
          -webkit-mask-image: var(--lia-content-blog-icon);
                  mask-image: var(--lia-content-blog-icon);
          background-color: var(--lia-blog-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-TKB:before {
          -webkit-mask-image: var(--lia-content-knowledge-icon);
                  mask-image: var(--lia-content-knowledge-icon);
          background-color: var(--lia-tkb-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-CONTEST:before {
          -webkit-mask-image: var(--lia-content-contest-icon);
                  mask-image: var(--lia-content-contest-icon);
          background-color: var(--lia-contest-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-QANDA:before {
          -webkit-mask-image: var(--lia-content-discussion-question-icon);
                  mask-image: var(--lia-content-discussion-question-icon);
          background-color: var(--lia-qanda-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-OCCASION:before {
          -webkit-mask-image: var(--lia-content-occasion-icon);
                  mask-image: var(--lia-content-occasion-icon);
          background-color: var(--lia-occasion-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-mention-content-type-IDEA:before {
          -webkit-mask-image: var(--lia-content-idea-icon);
                  mask-image: var(--lia-content-idea-icon);
          background-color: var(--lia-idea-color);
        }

.styles_lia-g-message-body__LkV7_ .lia-external-url:after {
      display: inline-block;
      content: '';
      -webkit-mask-size: 12px;
              mask-size: 12px;
      width: 0.75rem;
      height: 0.75rem;
      top: 0.1875rem;
      margin-left: 0.25rem;
      -webkit-mask-image: var(--lia-external-link-icon);
              mask-image: var(--lia-external-link-icon);
      background-color: var(--lia-bs-primary);
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
    }

.styles_lia-g-message-body__LkV7_ .lia-video-container img {
        aspect-ratio: 16/9;
        width: 100%;
      }

.styles_lia-g-message-body__LkV7_ .lia-video-container > iframe {
        border-radius: var(--lia-bs-border-radius-sm);
        border: none;
        height: 100%;
      }

.styles_lia-g-message-body__LkV7_ .video-js {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-big-play-button {
        background-color: var(--lia-bs-primary);
        opacity: 0.8;
      }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-play-progress.vjs-slider-bar,
      .styles_lia-g-message-body__LkV7_ .video-js .vjs-volume-panel .vjs-volume-level {
        background-color: var(--lia-bs-primary);
      }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-dock-text {
        display: none;
      }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-error-display:before {
          content: none;
        }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-errors-dialog {
        border: none;
        background: none;
        position: unset;
      }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-errors-dialog .vjs-errors-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-errors-dialog .vjs-errors-headline {
          margin: 0;
          color: var(--lia-bs-gray-300);
        }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-errors-dialog .vjs-errors-code {
          display: none;
        }

.styles_lia-g-message-body__LkV7_ .video-js .vjs-errors-dialog .vjs-errors-ref-id {
          display: none;
        }

.styles_lia-g-message-body-blog__TUnqH blockquote, .styles_lia-g-message-body-tkb__SZmO_ blockquote {
      margin-top: var(--lia-rte-special-message-item-mt);
      margin-bottom: var(--lia-rte-special-message-item-mb);
    }

.styles_lia-g-message-body-blog__TUnqH h1,
    .styles_lia-g-message-body-blog__TUnqH h2,
    .styles_lia-g-message-body-blog__TUnqH h3,
    .styles_lia-g-message-body-blog__TUnqH h4,
    .styles_lia-g-message-body-blog__TUnqH h5,
    .styles_lia-g-message-body-blog__TUnqH h6,
    .styles_lia-g-message-body-tkb__SZmO_ h1,
    .styles_lia-g-message-body-tkb__SZmO_ h2,
    .styles_lia-g-message-body-tkb__SZmO_ h3,
    .styles_lia-g-message-body-tkb__SZmO_ h4,
    .styles_lia-g-message-body-tkb__SZmO_ h5,
    .styles_lia-g-message-body-tkb__SZmO_ h6 {
      margin-top: var(--lia-rte-special-message-header-mt);
      margin-bottom: var(--lia-rte-special-message-header-mb);
    }

.styles_lia-g-message-body-blog__TUnqH p,
    .styles_lia-g-message-body-blog__TUnqH pre,
    .styles_lia-g-message-body-tkb__SZmO_ p,
    .styles_lia-g-message-body-tkb__SZmO_ pre {
      margin-top: var(--lia-rte-special-message-item-mt);
      margin-bottom: var(--lia-rte-special-message-item-mb);
    }

.styles_lia-g-message-body-blog__TUnqH *:last-child, .styles_lia-g-message-body-tkb__SZmO_ *:last-child {
      margin-bottom: 0;
    }

.styles_lia-g-message-body-blog__TUnqH .lia-code-sample, .styles_lia-g-message-body-tkb__SZmO_ .lia-code-sample {
        margin-top: var(--lia-rte-special-message-item-mt);
        margin-bottom: var(--lia-rte-special-message-item-mb);
      }

.styles_lia-g-message-body-blog__TUnqH .mce-toc, .styles_lia-g-message-body-tkb__SZmO_ .mce-toc {
        margin-bottom: var(--lia-rte-special-message-header-mb);
      }

.styles_lia-g-message-body-blog__TUnqH .lia-spoiler-container, .styles_lia-g-message-body-tkb__SZmO_ .lia-spoiler-container {
  margin-top: var(--lia-rte-special-message-item-mt);
  margin-bottom: var(--lia-rte-special-message-item-mb);
      }

.styles_lia-g-message-body-blog__TUnqH .lia-spoiler-container:first-child,
  [data-mce-caret]:first-child + .styles_lia-g-message-body-blog__TUnqH .lia-spoiler-container,
  .styles_lia-g-message-body-tkb__SZmO_ .lia-spoiler-container:first-child,
  [data-mce-caret]:first-child + .styles_lia-g-message-body-tkb__SZmO_ .lia-spoiler-container {
    margin-top: 0;
  }

.styles_lia-g-message-body-blog__TUnqH .lia-spoiler-container:last-child, .styles_lia-g-message-body-tkb__SZmO_ .lia-spoiler-container:last-child {
    margin-bottom: 0;
  }

.styles_lia-g-message-body-blog__TUnqH .styles_lia-g-spoiler-editor__lurGM, .styles_lia-g-message-body-tkb__SZmO_ .styles_lia-g-spoiler-editor__lurGM {
  margin-top: var(--lia-rte-special-message-item-mt);
  margin-bottom: var(--lia-rte-special-message-item-mb);
    }

.styles_lia-g-message-body-blog__TUnqH .styles_lia-g-spoiler-editor__lurGM:first-child,
  [data-mce-caret]:first-child + .styles_lia-g-message-body-blog__TUnqH .styles_lia-g-spoiler-editor__lurGM,
  .styles_lia-g-message-body-tkb__SZmO_ .styles_lia-g-spoiler-editor__lurGM:first-child,
  [data-mce-caret]:first-child + .styles_lia-g-message-body-tkb__SZmO_ .styles_lia-g-spoiler-editor__lurGM {
    margin-top: 0;
  }

.styles_lia-g-message-body-blog__TUnqH .styles_lia-g-spoiler-editor__lurGM:last-child, .styles_lia-g-message-body-tkb__SZmO_ .styles_lia-g-spoiler-editor__lurGM:last-child {
    margin-bottom: 0;
  }

.styles_lia-g-message-body-forum__p9J5_ {
      font-family: var(--lia-forum-font-family);
    }

.styles_lia-g-message-body-forum__p9J5_ p {
        font-weight: var(--lia-forum-font-weight);
        font-style: var(--lia-forum-font-style);
        line-height: var(--lia-forum-line-height);
      }

.styles_lia-g-message-body-forum__p9J5_ a {
        color: var(--lia-forum-message-link-color);
        font-style: var(--lia-forum-message-link-font-style);
        font-weight: var(--lia-forum-message-link-font-weight);
        -webkit-text-decoration: var(--lia-forum-message-link-decoration);
                text-decoration: var(--lia-forum-message-link-decoration);
      }

.styles_lia-g-message-body-forum__p9J5_ a:hover {
          color: var(--lia-forum-message-link-color);
          -webkit-text-decoration: var(--lia-forum-message-link-decoration);
                  text-decoration: var(--lia-forum-message-link-decoration);
        }

.styles_lia-g-message-body-blog__TUnqH {
      font-family: var(--lia-blog-font-family);
    }

.styles_lia-g-message-body-blog__TUnqH p {
        font-weight: var(--lia-blog-font-weight);
        font-style: var(--lia-blog-font-style);
        line-height: var(--lia-blog-line-height);
      }

.styles_lia-g-message-body-blog__TUnqH a {
        color: var(--lia-blog-message-link-color);
        font-style: var(--lia-blog-message-link-font-style);
        font-weight: var(--lia-blog-message-link-font-weight);
        -webkit-text-decoration: var(--lia-blog-message-link-decoration);
                text-decoration: var(--lia-blog-message-link-decoration);
      }

.styles_lia-g-message-body-blog__TUnqH a:hover {
          color: var(--lia-blog-message-link-color);
          -webkit-text-decoration: var(--lia-blog-message-link-decoration);
                  text-decoration: var(--lia-blog-message-link-decoration);
        }

.styles_lia-g-message-body-tkb__SZmO_ {
      font-family: var(--lia-tkb-font-family);
    }

.styles_lia-g-message-body-tkb__SZmO_ p {
        font-weight: var(--lia-tkb-font-weight);
        font-style: var(--lia-tkb-font-style);
        line-height: var(--lia-tkb-line-height);
      }

.styles_lia-g-message-body-tkb__SZmO_ a {
        color: var(--lia-tkb-message-link-color);
        font-style: var(--lia-tkb-message-link-font-style);
        font-weight: var(--lia-tkb-message-link-font-weight);
        -webkit-text-decoration: var(--lia-tkb-message-link-decoration);
                text-decoration: var(--lia-tkb-message-link-decoration);
      }

.styles_lia-g-message-body-tkb__SZmO_ a:hover {
          color: var(--lia-tkb-message-link-color);
          -webkit-text-decoration: var(--lia-tkb-message-link-decoration);
                  text-decoration: var(--lia-tkb-message-link-decoration);
        }

.styles_lia-g-message-body-qanda__nD46z {
      font-family: var(--lia-qanda-font-family);
    }

.styles_lia-g-message-body-qanda__nD46z p {
        font-weight: var(--lia-qanda-font-weight);
        font-style: var(--lia-qanda-font-style);
        line-height: var(--lia-qanda-line-height);
      }

.styles_lia-g-message-body-qanda__nD46z a {
        color: var(--lia-qanda-message-link-color);
        font-style: var(--lia-qanda-message-link-font-style);
        font-weight: var(--lia-qanda-message-link-font-weight);
        -webkit-text-decoration: var(--lia-qanda-message-link-decoration);
                text-decoration: var(--lia-qanda-message-link-decoration);
      }

.styles_lia-g-message-body-qanda__nD46z a:hover {
          color: var(--lia-qanda-message-link-color);
          -webkit-text-decoration: var(--lia-qanda-message-link-decoration);
                  text-decoration: var(--lia-qanda-message-link-decoration);
        }

.styles_lia-g-message-body-idea__MnWxG {
      font-family: var(--lia-idea-font-family);
    }

.styles_lia-g-message-body-idea__MnWxG p {
        font-weight: var(--lia-idea-font-weight);
        font-style: var(--lia-idea-font-style);
        line-height: var(--lia-idea-line-height);
      }

.styles_lia-g-message-body-idea__MnWxG a {
        color: var(--lia-idea-message-link-color);
        font-style: var(--lia-idea-message-link-font-style);
        font-weight: var(--lia-idea-message-link-font-weight);
        -webkit-text-decoration: var(--lia-idea-message-link-decoration);
                text-decoration: var(--lia-idea-message-link-decoration);
      }

.styles_lia-g-message-body-idea__MnWxG a:hover {
          color: var(--lia-idea-message-link-color);
          -webkit-text-decoration: var(--lia-idea-message-link-decoration);
                  text-decoration: var(--lia-idea-message-link-decoration);
        }

.styles_lia-g-message-body-content__5RonU {
      font-family: var(--lia-content-font-family);
    }

.styles_lia-g-message-body-content__5RonU p {
        font-weight: var(--lia-content-font-weight);
        font-style: var(--lia-content-font-style);
        line-height: var(--lia-content-line-height);
      }

.styles_lia-g-message-body-content__5RonU a {
        color: var(--lia-content-message-link-color);
        font-style: var(--lia-content-message-link-font-style);
        font-weight: var(--lia-content-message-link-font-weight);
        -webkit-text-decoration: var(--lia-content-message-link-decoration);
                text-decoration: var(--lia-content-message-link-decoration);
      }

.styles_lia-g-message-body-content__5RonU a:hover {
          color: var(--lia-content-message-link-color);
          -webkit-text-decoration: var(--lia-content-message-link-decoration);
                  text-decoration: var(--lia-content-message-link-decoration);
        }

.styles_lia-g-message-body-occasion__fp1q1 {
      font-family: var(--lia-occasion-font-family);
    }

.styles_lia-g-message-body-occasion__fp1q1 p {
        font-weight: var(--lia-occasion-font-weight);
        font-style: var(--lia-occasion-font-style);
        line-height: var(--lia-occasion-line-height);
      }

.styles_lia-g-message-body-occasion__fp1q1 a {
        color: var(--lia-occasion-message-link-color);
        font-style: var(--lia-occasion-message-link-font-style);
        font-weight: var(--lia-occasion-message-link-font-weight);
        -webkit-text-decoration: var(--lia-occasion-message-link-decoration);
                text-decoration: var(--lia-occasion-message-link-decoration);
      }

.styles_lia-g-message-body-occasion__fp1q1 a:hover {
          color: var(--lia-occasion-message-link-color);
          -webkit-text-decoration: var(--lia-occasion-message-link-decoration);
                  text-decoration: var(--lia-occasion-message-link-decoration);
        }

.styles_lia-g-message-body__LkV7_ .mce-toc {
      margin-bottom: var(--lia-rte-default-message-header-mb);
    }

.styles_lia-g-message-body__LkV7_ .mce-toc h2 {
        color: var(--lia-bs-body-color);
        font-weight: var(--lia-bs-font-weight-bold);
        font-size: var(--lia-bs-font-size-sm);
        border-bottom: 1px solid var(--lia-bs-border-color);
        padding-bottom: 0.3125rem;
        margin: 0;
      }

.styles_lia-g-message-body__LkV7_ .mce-toc ul {
        padding-left: 0.9375rem;
      }

.styles_lia-g-message-body__LkV7_ .mce-toc > ul {
        padding-left: 0;
      }

.styles_lia-g-message-body__LkV7_ .mce-toc li {
        list-style: none;
        margin-top: 0.3125rem;
      }

.styles_lia-g-message-body__LkV7_ .mce-toc li a:not(:empty):before {
          content: '-';
          margin-right: 0.625rem;
          color: var(--lia-bs-gray-600);
          text-decoration: none;
          display: inline-block;
        }

.styles_lia-g-message-body__LkV7_ .lia-align-right {
        text-align: right;
      }

.styles_lia-g-message-body__LkV7_ .lia-align-left {
        text-align: left;
      }

.styles_lia-g-message-body__LkV7_ .lia-align-center {
        text-align: center;
      }

.styles_lia-g-message-body__LkV7_ .lia-align-justify {
        text-align: justify;
      }

.styles_lia-g-message-body__LkV7_ .lia-vertical-align-top {
        vertical-align: top;
      }

.styles_lia-g-message-body__LkV7_ .lia-vertical-align-middle {
        vertical-align: middle;
      }

.styles_lia-g-message-body__LkV7_ .lia-vertical-align-bottom {
        vertical-align: bottom;
      }

.styles_lia-g-message-body__LkV7_ .lia-search-match-lithium {
  background-color: var(--lia-highlight-color);
  box-shadow: 0 0 0 2px var(--lia-highlight-color);
  border-radius: var(--lia-bs-border-radius-sm);
    }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-0px {
          /* prettier-ignore */
          padding-left: 0;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-0px {
          /* prettier-ignore */
          padding-right: 0;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-0px {
          /* prettier-ignore */
          margin-left: 0;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-0px {
          /* prettier-ignore */
          margin-right: 0;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-30px {
          /* prettier-ignore */
          padding-left: 1.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-30px {
          /* prettier-ignore */
          padding-right: 1.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-30px {
          /* prettier-ignore */
          margin-left: 1.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-30px {
          /* prettier-ignore */
          margin-right: 1.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-60px {
          /* prettier-ignore */
          padding-left: 3.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-60px {
          /* prettier-ignore */
          padding-right: 3.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-60px {
          /* prettier-ignore */
          margin-left: 3.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-60px {
          /* prettier-ignore */
          margin-right: 3.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-90px {
          /* prettier-ignore */
          padding-left: 5.625rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-90px {
          /* prettier-ignore */
          padding-right: 5.625rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-90px {
          /* prettier-ignore */
          margin-left: 5.625rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-90px {
          /* prettier-ignore */
          margin-right: 5.625rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-120px {
          /* prettier-ignore */
          padding-left: 7.5rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-120px {
          /* prettier-ignore */
          padding-right: 7.5rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-120px {
          /* prettier-ignore */
          margin-left: 7.5rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-120px {
          /* prettier-ignore */
          margin-right: 7.5rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-150px {
          /* prettier-ignore */
          padding-left: 9.375rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-150px {
          /* prettier-ignore */
          padding-right: 9.375rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-150px {
          /* prettier-ignore */
          margin-left: 9.375rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-150px {
          /* prettier-ignore */
          margin-right: 9.375rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-180px {
          /* prettier-ignore */
          padding-left: 11.25rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-180px {
          /* prettier-ignore */
          padding-right: 11.25rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-180px {
          /* prettier-ignore */
          margin-left: 11.25rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-180px {
          /* prettier-ignore */
          margin-right: 11.25rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-210px {
          /* prettier-ignore */
          padding-left: 13.125rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-210px {
          /* prettier-ignore */
          padding-right: 13.125rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-210px {
          /* prettier-ignore */
          margin-left: 13.125rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-210px {
          /* prettier-ignore */
          margin-right: 13.125rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-240px {
          /* prettier-ignore */
          padding-left: 15rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-240px {
          /* prettier-ignore */
          padding-right: 15rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-240px {
          /* prettier-ignore */
          margin-left: 15rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-240px {
          /* prettier-ignore */
          margin-right: 15rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-270px {
          /* prettier-ignore */
          padding-left: 16.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-270px {
          /* prettier-ignore */
          padding-right: 16.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-270px {
          /* prettier-ignore */
          margin-left: 16.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-270px {
          /* prettier-ignore */
          margin-right: 16.875rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-300px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-300px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-300px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-300px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-330px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-330px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-330px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-330px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-360px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-360px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-360px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-360px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-390px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-390px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-390px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-390px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-420px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-420px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-420px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-420px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-450px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-450px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-450px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-450px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-480px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-480px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-480px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-480px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-510px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-510px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-510px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-510px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-540px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-540px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-540px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-540px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-570px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-570px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-570px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-570px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-600px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-600px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-600px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-600px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-630px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-630px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-630px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-630px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-660px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-660px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-660px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-660px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-690px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-690px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-690px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-690px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-720px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-720px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-720px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-720px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-left-750px {
          /* prettier-ignore */
          padding-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-padding-right-750px {
          /* prettier-ignore */
          padding-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-left-750px {
          /* prettier-ignore */
          margin-left: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-indent-margin-right-750px {
          /* prettier-ignore */
          margin-right: 18.75rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container {
  margin-top: var(--lia-rte-default-message-item-mt);
  margin-bottom: var(--lia-rte-default-message-item-mb);
    }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container:first-child,
  [data-mce-caret]:first-child + .styles_lia-g-message-body__LkV7_ .lia-spoiler-container {
    margin-top: 0;
  }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container:last-child {
    margin-bottom: 0;
  }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container {
  display: block;
  background-color: var(--lia-bs-gray-200);
  border-radius: var(--lia-bs-border-radius-sm);
      padding: 1.25rem;
      clear: both;
}

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--lia-bs-body-color);
        padding: 1.25rem;
        margin: -1.25rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-link:hover,
        .styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-link:focus {
          color: var(--lia-bs-body-color);
          text-decoration: none;
        }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-link:after {
          display: block;
          content: '';
          width: 1rem;
          height: 1rem;
          background-size: cover;
          background-color: var(--lia-bs-gray-900);
          -webkit-mask-size: 16px;
                  mask-size: 16px;
          -webkit-mask-image: var(--lia-chevron-down-icon);
                  mask-image: var(--lia-chevron-down-icon);
          transform: rotate(-90deg);
          transition: transform var(--lia-timing-normal);
          padding-left: 1rem;
        }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-border {
        display: none;
        border-top: 1px solid var(--lia-bs-border-color);
        margin-top: 1.25rem;
        padding-top: 1.25rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-content:after {
          content: '';
          display: table;
          clear: both;
        }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container .lia-spoiler-content > *:last-child {
          margin-bottom: 0 !important;
        }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container.lia-spoiler-show .lia-spoiler-border {
          display: block;
        }

.styles_lia-g-message-body__LkV7_ .lia-spoiler-container.lia-spoiler-show .lia-spoiler-link:after {
          transform: rotate(0deg);
        }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card {
      clear: both;
    }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-container {
      display: flex;
      flex-direction: row;
      border: 1px solid var(--lia-bs-border-color);
      border-radius: 0.3125rem;
      clear: both;
    }

@media (max-width: 767.98px) {

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-container {
        flex-direction: column
    }
      }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-container:hover,
      .styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-container:focus {
        text-decoration: none;
      }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-content {
      display: block;
      padding: 1.25rem;
    }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-header {
      display: flex;
      align-items: center;
      -moz-column-gap: 0.3125rem;
           column-gap: 0.3125rem;
      margin-bottom: 0.625rem;
    }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-header:after {
        display: block;
        flex-shrink: 0;
        content: '';
        -webkit-mask-size: 12px;
                mask-size: 12px;
        width: 0.75rem;
        height: 0.75rem;
        -webkit-mask-image: var(--lia-external-link-icon);
                mask-image: var(--lia-external-link-icon);
        background-color: var(--lia-bs-gray-900);
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
      }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-header h5 {
        margin: 0;
        color: var(--lia-bs-gray-900);
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-image {
      width: 9.75rem;
      height: 9.75rem;
      border-radius: 0;
      -o-object-fit: cover;
         object-fit: cover;
    }

@media (max-width: 767.98px) {

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-image {
        width: 100%
    }
      }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-description {
      line-height: 1.4;
      color: var(--lia-bs-gray-900);
      margin-bottom: 0.625rem;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-provider-name {
      font-size: var(--lia-bs-font-size-sm);
      color: var(--lia-bs-gray-700);
    }

.styles_lia-g-message-body__LkV7_ .lia-rich-preview-card-provider-name img {
        margin-right: 0.3125rem;
        width: 1rem;
        height: 1rem;
      }

.styles_lia-g-message-body__LkV7_ .lia-clear-both {
      clear: both;
    }

.styles_lia-g-message-body__LkV7_ .lia-text-color-1,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-1 a {
        color: var(--lia-rte-custom-color-1);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-1 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-1 a :after {
          background-color: var(--lia-rte-custom-color-1);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-1 {
        background-color: var(--lia-rte-custom-color-1);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-1 {
        border-color: var(--lia-rte-custom-color-1);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-2,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-2 a {
        color: var(--lia-rte-custom-color-2);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-2 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-2 a :after {
          background-color: var(--lia-rte-custom-color-2);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-2 {
        background-color: var(--lia-rte-custom-color-2);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-2 {
        border-color: var(--lia-rte-custom-color-2);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-3,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-3 a {
        color: var(--lia-rte-custom-color-3);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-3 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-3 a :after {
          background-color: var(--lia-rte-custom-color-3);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-3 {
        background-color: var(--lia-rte-custom-color-3);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-3 {
        border-color: var(--lia-rte-custom-color-3);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-4,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-4 a {
        color: var(--lia-rte-custom-color-4);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-4 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-4 a :after {
          background-color: var(--lia-rte-custom-color-4);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-4 {
        background-color: var(--lia-rte-custom-color-4);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-4 {
        border-color: var(--lia-rte-custom-color-4);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-5,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-5 a {
        color: var(--lia-rte-custom-color-5);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-5 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-5 a :after {
          background-color: var(--lia-rte-custom-color-5);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-5 {
        background-color: var(--lia-rte-custom-color-5);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-5 {
        border-color: var(--lia-rte-custom-color-5);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-6,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-6 a {
        color: var(--lia-rte-custom-color-6);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-6 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-6 a :after {
          background-color: var(--lia-rte-custom-color-6);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-6 {
        background-color: var(--lia-rte-custom-color-6);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-6 {
        border-color: var(--lia-rte-custom-color-6);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-7,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-7 a {
        color: var(--lia-rte-custom-color-7);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-7 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-7 a :after {
          background-color: var(--lia-rte-custom-color-7);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-7 {
        background-color: var(--lia-rte-custom-color-7);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-7 {
        border-color: var(--lia-rte-custom-color-7);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-8,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-8 a {
        color: var(--lia-rte-custom-color-8);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-8 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-8 a :after {
          background-color: var(--lia-rte-custom-color-8);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-8 {
        background-color: var(--lia-rte-custom-color-8);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-8 {
        border-color: var(--lia-rte-custom-color-8);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-9,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-9 a {
        color: var(--lia-rte-custom-color-9);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-9 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-9 a :after {
          background-color: var(--lia-rte-custom-color-9);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-9 {
        background-color: var(--lia-rte-custom-color-9);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-9 {
        border-color: var(--lia-rte-custom-color-9);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-10,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-10 a {
        color: var(--lia-rte-custom-color-10);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-10 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-10 a :after {
          background-color: var(--lia-rte-custom-color-10);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-10 {
        background-color: var(--lia-rte-custom-color-10);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-10 {
        border-color: var(--lia-rte-custom-color-10);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-11,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-11 a {
        color: var(--lia-rte-custom-color-11);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-11 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-11 a :after {
          background-color: var(--lia-rte-custom-color-11);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-11 {
        background-color: var(--lia-rte-custom-color-11);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-11 {
        border-color: var(--lia-rte-custom-color-11);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-12,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-12 a {
        color: var(--lia-rte-custom-color-12);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-12 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-12 a :after {
          background-color: var(--lia-rte-custom-color-12);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-12 {
        background-color: var(--lia-rte-custom-color-12);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-12 {
        border-color: var(--lia-rte-custom-color-12);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-13,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-13 a {
        color: var(--lia-rte-custom-color-13);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-13 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-13 a :after {
          background-color: var(--lia-rte-custom-color-13);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-13 {
        background-color: var(--lia-rte-custom-color-13);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-13 {
        border-color: var(--lia-rte-custom-color-13);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-14,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-14 a {
        color: var(--lia-rte-custom-color-14);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-14 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-14 a :after {
          background-color: var(--lia-rte-custom-color-14);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-14 {
        background-color: var(--lia-rte-custom-color-14);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-14 {
        border-color: var(--lia-rte-custom-color-14);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-15,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-15 a {
        color: var(--lia-rte-custom-color-15);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-15 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-15 a :after {
          background-color: var(--lia-rte-custom-color-15);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-15 {
        background-color: var(--lia-rte-custom-color-15);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-15 {
        border-color: var(--lia-rte-custom-color-15);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-16,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-16 a {
        color: var(--lia-rte-custom-color-16);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-16 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-16 a :after {
          background-color: var(--lia-rte-custom-color-16);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-16 {
        background-color: var(--lia-rte-custom-color-16);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-16 {
        border-color: var(--lia-rte-custom-color-16);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-17,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-17 a {
        color: var(--lia-rte-custom-color-17);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-17 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-17 a :after {
          background-color: var(--lia-rte-custom-color-17);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-17 {
        background-color: var(--lia-rte-custom-color-17);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-17 {
        border-color: var(--lia-rte-custom-color-17);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-18,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-18 a {
        color: var(--lia-rte-custom-color-18);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-18 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-18 a :after {
          background-color: var(--lia-rte-custom-color-18);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-18 {
        background-color: var(--lia-rte-custom-color-18);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-18 {
        border-color: var(--lia-rte-custom-color-18);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-19,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-19 a {
        color: var(--lia-rte-custom-color-19);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-19 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-19 a :after {
          background-color: var(--lia-rte-custom-color-19);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-19 {
        background-color: var(--lia-rte-custom-color-19);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-19 {
        border-color: var(--lia-rte-custom-color-19);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-20,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-20 a {
        color: var(--lia-rte-custom-color-20);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-20 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-20 a :after {
          background-color: var(--lia-rte-custom-color-20);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-20 {
        background-color: var(--lia-rte-custom-color-20);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-20 {
        border-color: var(--lia-rte-custom-color-20);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-21,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-21 a {
        color: var(--lia-rte-custom-color-21);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-21 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-21 a :after {
          background-color: var(--lia-rte-custom-color-21);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-21 {
        background-color: var(--lia-rte-custom-color-21);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-21 {
        border-color: var(--lia-rte-custom-color-21);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-22,
      .styles_lia-g-message-body__LkV7_ .lia-text-color-22 a {
        color: var(--lia-rte-custom-color-22);
      }

.styles_lia-g-message-body__LkV7_ .lia-text-color-22 :after, .styles_lia-g-message-body__LkV7_ .lia-text-color-22 a :after {
          background-color: var(--lia-rte-custom-color-22);
        }

.styles_lia-g-message-body__LkV7_ .lia-background-color-22 {
        background-color: var(--lia-rte-custom-color-22);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-color-22 {
        border-color: var(--lia-rte-custom-color-22);
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-hidden {
        /* prettier-ignore */
        border-style: hidden;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-dotted {
        /* prettier-ignore */
        border-style: dotted;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-dashed {
        /* prettier-ignore */
        border-style: dashed;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-solid {
        /* prettier-ignore */
        border-style: solid;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-double {
        /* prettier-ignore */
        border-style: double;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-groove {
        /* prettier-ignore */
        border-style: groove;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-ridge {
        /* prettier-ignore */
        border-style: ridge;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-inset {
        /* prettier-ignore */
        border-style: inset;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-outset {
        /* prettier-ignore */
        border-style: outset;
      }

.styles_lia-g-message-body__LkV7_ .lia-border-style-none {
        /* prettier-ignore */
        border-style: none;
      }

iframe.styles_lia-iframe-with-script__YkhWf {
  display: none;
}

.styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-dialog__M3NL6,
  .styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-content__scjnA {
    max-height: calc(100dvh - 3.625rem);
  }

.styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-content__scjnA {
    position: relative;
  }

.styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-header__mp22c {
    position: relative;
    border-bottom: 1px solid var(--lia-bs-border-color);
    z-index: 2;
  }

.styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-body__idonn {
    position: static;
    overflow-y: auto;
    overflow-x: clip;
    padding-bottom: 0;
    margin-top: -0.0625rem;
  }

.styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-body__idonn:before {
      position: relative;
      display: block;
      content: '';
      height: 0.0625rem;
      transform: translateX(-1.875rem);
      width: calc(100% + 3.75rem);
      background-color: var(--lia-bs-white);
      z-index: 10;
      top: 0;
    }

.styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-footer__c2EHS {
    position: sticky;
    bottom: 0;
    margin: 1.25rem -1.875rem 0;
    padding: 1.25rem 1.875rem 1.875rem;
    background-color: var(--lia-bs-white);
    border-top: 1px solid var(--lia-bs-border-color);
    border-radius: 0 0 var(--lia-bs-border-radius-lg) var(--lia-bs-border-radius-lg);
    z-index: 12;
  }

@media (max-width: 767.98px) {
    .styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-body__idonn:before {
      transform: translateX(-1.25rem);
      width: calc(100% + 2.5rem);
    }
    .styles_lia-g-locked-modal__OUWMT .styles_lia-g-locked-modal-footer__c2EHS {
      padding: 0.625rem 1.25rem 1.25rem;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }
  }

.styles_lia-g-modal-buttons-wrap__VAurc {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  -moz-column-gap: 0.625rem;
       column-gap: 0.625rem;
}

/* Adds control for dictating the scrollable <Modal.Body> overflow, which otherwise could expand to view port sizes */

.styles_lia-g-infinite-scroll-modal__DPuDx {
  max-height: 50rem;
}

.styles_lia-g-subtext-modal-header__cbf18 {
  display: grid;
  grid-template-areas:
    'title close'
    'subtitle subtitle';
  grid-template-columns: 1fr auto;
}

.styles_lia-g-subtext-modal-header__cbf18 > [type='button'] {
    grid-area: close;
  }

.styles_lia-g-subtext-modal-title__APR7d {
  grid-area: title;
}

.styles_lia-g-subtext-modal-subtext__QnJeq {
  grid-area: subtitle;
  margin-top: 0.3125rem;
  font-size: var(--lia-bs-font-size-sm);
}

.styles_lia-g-navbar-link___5V8S {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: var(--lia-nav-brand-logo-height);
  padding: var(--lia-nav-link-py) var(--lia-nav-link-px);
  color: var(--lia-nav-link-color);
  transition: color var(--lia-timing-normal), background-color var(--lia-timing-normal),
    border-color var(--lia-timing-normal), box-shadow var(--lia-timing-normal);
  background-color: var(--lia-nav-link-bg-color);
  border: var(--lia-nav-link-border);
  border-radius: var(--lia-nav-link-border-radius);
  box-shadow: var(--lia-nav-link-box-shadow);
  font-size: var(--lia-nav-link-font-size);
  font-weight: var(--lia-nav-link-font-weight);
  font-style: var(--lia-nav-link-font-style);
  text-transform: var(--lia-nav-link-text-transform);
  letter-spacing: var(--lia-nav-link-letter-spacing);
  line-height: 1.428571428571429;
  cursor: pointer;
}

.styles_lia-g-navbar-link___5V8S:hover,
  .styles_lia-g-navbar-link___5V8S:focus {
    text-decoration: none;
    color: var(--lia-nav-link-hover-color);
    background-color: var(--lia-nav-link-bg-hover-color);
    border: var(--lia-nav-link-border-hover);
    box-shadow: var(--lia-nav-link-box-shadow-hover);
    outline: none;
  }

.styles_lia-g-navbar-link___5V8S:hover .styles_lia-g-navbar-link-text__hNCPc:after, .styles_lia-g-navbar-link___5V8S:focus .styles_lia-g-navbar-link-text__hNCPc:after {
      border-bottom: var(--lia-nav-link-text-border-bottom-hover);
    }

.styles_lia-g-navbar-link___5V8S:hover .styles_lia-g-navbar-dropdown-icon__MBB_c, .styles_lia-g-navbar-link___5V8S:focus .styles_lia-g-navbar-dropdown-icon__MBB_c {
      fill: var(--lia-nav-link-hover-color);
    }

.styles_lia-g-navbar-link___5V8S:focus-visible {
    outline: unset !important;
  }

.styles_lia-g-navbar-link-text__hNCPc {
  display: inline-block;
  position: relative;
  overflow-wrap: break-word;
  word-break: break-word;
}

.styles_lia-g-navbar-link-text__hNCPc:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    bottom: calc(-1 * var(--lia-nav-link-py));
    border-bottom: var(--lia-nav-link-text-border-bottom);
  }

.styles_lia-g-navbar-dropdown-menu__Tugwv {
  min-width: 10.9375rem;
  background-color: var(--lia-nav-bg-color);
  padding: var(--lia-nav-dropdown-pt) var(--lia-nav-dropdown-px) var(--lia-nav-dropdown-pb);
  max-height: calc(100dvh - var(--lia-d-navbar-height));
  overflow-y: auto;
}

.styles_lia-g-navbar-dropdown-menu__Tugwv .styles_lia-g-navbar-link___5V8S {
    padding: var(--lia-nav-link-dropdown-py) var(--lia-nav-link-dropdown-px);
    min-height: unset;
  }

.styles_lia-g-navbar-dropdown-menu__Tugwv .styles_lia-g-navbar-link___5V8S .styles_lia-g-navbar-link-text__hNCPc:after {
      bottom: calc(-1 * var(--lia-nav-link-dropdown-py));
    }

@media (min-width: 992px) {

.styles_lia-g-navbar-dropdown-menu__Tugwv {
    margin: 0 calc(-1 * var(--lia-nav-dropdown-px)) !important
}
  }

.styles_lia-g-navbar-dropdown-divider__nP_ld {
  border-top-color: var(--lia-nav-dropdown-border-color);
  margin: var(--lia-nav-dropdown-divider-mt) calc(-1 * var(--lia-nav-dropdown-px))
    var(--lia-nav-dropdown-divider-mb);
}

.styles_lia-g-navbar-dropdown-icon__MBB_c {
  fill: var(--lia-nav-link-color);
  margin-left: 0.1875rem;
  width: var(--lia-nav-link-font-size);
  height: var(--lia-nav-link-font-size);
}

@media (max-width: 991.98px) {

.styles_lia-g-navbar-dropdown-icon__MBB_c {
    margin-left: auto
}
  }

/**
 * Based on Prism.js default by Lea Verou
 */

.styles_lia-g-message-body__LkV7_ .lia-code-sample {
      color: var(--lia-prism-color);
      background-color: var(--lia-prism-bg-color);
      font-family: var(--lia-prism-font-family);
      font-size: var(--lia-prism-font-size);
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      word-wrap: normal;
      line-height: var(--lia-bs-line-height-base);
      border: 1px solid var(--lia-bs-border-color);

      -moz-tab-size: var(--lia-prism-tab-size);
      -o-tab-size: var(--lia-prism-tab-size);
      tab-size: var(--lia-prism-tab-size);

      -webkit-hyphens: none;
      hyphens: none;
    }

.styles_lia-g-message-body__LkV7_ .lia-code-sample::-moz-selection {
        text-shadow: none;
        background: var(--lia-prism-highlight-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample::-moz-selection,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample::selection {
        text-shadow: none;
        background: var(--lia-prism-highlight-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.comment,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.prolog,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.doctype,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.cdata {
        color: var(--lia-prism-comment-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.punctuation {
        color: var(--lia-prism-punctuation-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.namespace {
        opacity: var(--lia-prism-namespace-opacity);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.property,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.tag,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.boolean,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.number,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.constant,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.symbol,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.deleted {
        color: var(--lia-prism-prop-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.selector,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.attr-name,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.string,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.char,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.builtin,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.inserted {
        color: var(--lia-prism-selector-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.operator,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.entity,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.url,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample.language-css .token.string,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample.style .token.string {
        color: var(--lia-prism-operator-color);
        background: var(--lia-prism-operator-bg-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.atrule,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.attr-value,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.keyword {
        color: var(--lia-prism-keyword-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.function,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.class-name {
        color: var(--lia-prism-function-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.regex,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.important,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.variable {
        color: var(--lia-prism-variable-color);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.important,
      .styles_lia-g-message-body__LkV7_ .lia-code-sample .token.bold {
        font-weight: var(--lia-prism-font-weight-bold);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.italic {
        font-style: var(--lia-prism-font-style-italic);
      }

.styles_lia-g-message-body__LkV7_ .lia-code-sample .token.entity {
        cursor: help;
      }

/* Code Blocks */

.styles_lia-g-message-body__LkV7_ pre.lia-code-sample {
      margin-top: var(--lia-rte-special-message-item-mt);
      margin-bottom: var(--lia-rte-special-message-item-mb);
      padding: 1.25rem;
      border-radius: var(--lia-bs-border-radius-sm);
      white-space: pre;
      overflow: auto;
      line-height: 1.4;
    }

/* Inline Code */

.styles_lia-g-message-body__LkV7_ code.lia-code-sample {
      padding: 0.1em;
      border-radius: 0.3em;
      white-space: normal;
    }

.styles_lia-g-message-body__LkV7_.styles_lia-g-message-body-forum__p9J5_ pre.lia-code-sample {
        margin-top: var(--lia-rte-default-message-item-mt);
        margin-bottom: var(--lia-rte-default-message-item-mb);
      }

.styles_lia-g-box-shadow-xs__YflbT {
  box-shadow: var(--lia-box-shadow-xs);
}

.styles_lia-g-list-li-mb-none__dx5ad,
  .styles_lia-g-list-li-mb-sm__gWJpp,
  .styles_lia-g-list-li-mb-md__sM0P3,
  .styles_lia-g-list-li-mb-lg__XKL_Z,
  .styles_lia-g-list-li-mb-xl__aRbIa,
  .styles_lia-g-list-li-mb-xxl__bDdLX {
    margin-bottom: 0;
  }

.styles_lia-g-list-li-mb-none__dx5ad > li:not(:last-of-type) {
    margin-bottom: 0;
  }

.styles_lia-g-list-li-mb-sm__gWJpp > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-sm);
  }

.styles_lia-g-list-li-mb-md__sM0P3 > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-md);
  }

.styles_lia-g-list-li-mb-lg__XKL_Z > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-lg);
  }

.styles_lia-g-list-li-mb-xl__aRbIa > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-xl);
  }

.styles_lia-g-list-li-mb-xxl__bDdLX > li:not(:last-of-type) {
    margin-bottom: var(--lia-list-li-mb-xxl);
  }

.styles_lia-g-m-0__0pEj1 {
      /* prettier-ignore */
      margin: 0 !important;
  }

.styles_lia-g-mt-0__OfJCm,
  .styles_lia-g-my-0__lzVcy {
      /* prettier-ignore */
      margin-top: 0 !important;
  }

.styles_lia-g-mr-0__4W_Sg,
  .styles_lia-g-mx-0__AsJGq {
      /* prettier-ignore */
      margin-right: 0 !important;
  }

.styles_lia-g-mb-0__AIhaX,
  .styles_lia-g-my-0__lzVcy {
      /* prettier-ignore */
      margin-bottom: 0 !important;
  }

.styles_lia-g-ml-0__r9K1I,
  .styles_lia-g-mx-0__AsJGq {
      /* prettier-ignore */
      margin-left: 0 !important;
  }

.styles_lia-g-p-0__7qfSG {
      /* prettier-ignore */
      padding: 0 !important;
  }

.styles_lia-g-pt-0__eAFqY,
  .styles_lia-g-py-0___GJtc {
      /* prettier-ignore */
      padding-top: 0 !important;
  }

.styles_lia-g-pr-0__frxKA,
  .styles_lia-g-px-0__xNbri {
      /* prettier-ignore */
      padding-right: 0 !important;
  }

.styles_lia-g-pb-0__FRmU1,
  .styles_lia-g-py-0___GJtc {
      /* prettier-ignore */
      padding-bottom: 0 !important;
  }

.styles_lia-g-pl-0__7VTV4,
  .styles_lia-g-px-0__xNbri {
      /* prettier-ignore */
      padding-left: 0 !important;
  }

.styles_lia-g-m-5__RyFBH {
      /* prettier-ignore */
      margin: 0.3125rem !important;
  }

.styles_lia-g-mt-5__Gn6_v,
  .styles_lia-g-my-5__5lx4k {
      /* prettier-ignore */
      margin-top: 0.3125rem !important;
  }

.styles_lia-g-mr-5__qkf3_,
  .styles_lia-g-mx-5__5Nle2 {
      /* prettier-ignore */
      margin-right: 0.3125rem !important;
  }

.styles_lia-g-mb-5__AmH7J,
  .styles_lia-g-my-5__5lx4k {
      /* prettier-ignore */
      margin-bottom: 0.3125rem !important;
  }

.styles_lia-g-ml-5__SlnKE,
  .styles_lia-g-mx-5__5Nle2 {
      /* prettier-ignore */
      margin-left: 0.3125rem !important;
  }

.styles_lia-g-p-5__4IgZA {
      /* prettier-ignore */
      padding: 0.3125rem !important;
  }

.styles_lia-g-pt-5__ISAdQ,
  .styles_lia-g-py-5__tNuAU {
      /* prettier-ignore */
      padding-top: 0.3125rem !important;
  }

.styles_lia-g-pr-5__sqBF0,
  .styles_lia-g-px-5__le2Ev {
      /* prettier-ignore */
      padding-right: 0.3125rem !important;
  }

.styles_lia-g-pb-5__mJAxM,
  .styles_lia-g-py-5__tNuAU {
      /* prettier-ignore */
      padding-bottom: 0.3125rem !important;
  }

.styles_lia-g-pl-5__pyKwu,
  .styles_lia-g-px-5__le2Ev {
      /* prettier-ignore */
      padding-left: 0.3125rem !important;
  }

.styles_lia-g-m-10__Q1pbU {
      /* prettier-ignore */
      margin: 0.625rem !important;
  }

.styles_lia-g-mt-10__ZA5JU,
  .styles_lia-g-my-10__i06iw {
      /* prettier-ignore */
      margin-top: 0.625rem !important;
  }

.styles_lia-g-mr-10__IIyjn,
  .styles_lia-g-mx-10__F7ia5 {
      /* prettier-ignore */
      margin-right: 0.625rem !important;
  }

.styles_lia-g-mb-10__DT6cb,
  .styles_lia-g-my-10__i06iw {
      /* prettier-ignore */
      margin-bottom: 0.625rem !important;
  }

.styles_lia-g-ml-10__JmJZF,
  .styles_lia-g-mx-10__F7ia5 {
      /* prettier-ignore */
      margin-left: 0.625rem !important;
  }

.styles_lia-g-p-10__xV4Ng {
      /* prettier-ignore */
      padding: 0.625rem !important;
  }

.styles_lia-g-pt-10__n8J9M,
  .styles_lia-g-py-10__oBh_5 {
      /* prettier-ignore */
      padding-top: 0.625rem !important;
  }

.styles_lia-g-pr-10__zWTkd,
  .styles_lia-g-px-10__xE_5R {
      /* prettier-ignore */
      padding-right: 0.625rem !important;
  }

.styles_lia-g-pb-10__CdDKw,
  .styles_lia-g-py-10__oBh_5 {
      /* prettier-ignore */
      padding-bottom: 0.625rem !important;
  }

.styles_lia-g-pl-10__TlYkN,
  .styles_lia-g-px-10__xE_5R {
      /* prettier-ignore */
      padding-left: 0.625rem !important;
  }

.styles_lia-g-m-15__B8TZS {
      /* prettier-ignore */
      margin: 0.9375rem !important;
  }

.styles_lia-g-mt-15__Eikdl,
  .styles_lia-g-my-15__yLAL9 {
      /* prettier-ignore */
      margin-top: 0.9375rem !important;
  }

.styles_lia-g-mr-15__M0BSU,
  .styles_lia-g-mx-15__Fj5SF {
      /* prettier-ignore */
      margin-right: 0.9375rem !important;
  }

.styles_lia-g-mb-15__YNrGd,
  .styles_lia-g-my-15__yLAL9 {
      /* prettier-ignore */
      margin-bottom: 0.9375rem !important;
  }

.styles_lia-g-ml-15__ebszb,
  .styles_lia-g-mx-15__Fj5SF {
      /* prettier-ignore */
      margin-left: 0.9375rem !important;
  }

.styles_lia-g-p-15__UGLOq {
      /* prettier-ignore */
      padding: 0.9375rem !important;
  }

.styles_lia-g-pt-15__653_x,
  .styles_lia-g-py-15__rqY0D {
      /* prettier-ignore */
      padding-top: 0.9375rem !important;
  }

.styles_lia-g-pr-15__HPDel,
  .styles_lia-g-px-15__xoC15 {
      /* prettier-ignore */
      padding-right: 0.9375rem !important;
  }

.styles_lia-g-pb-15__q8Vd8,
  .styles_lia-g-py-15__rqY0D {
      /* prettier-ignore */
      padding-bottom: 0.9375rem !important;
  }

.styles_lia-g-pl-15__K4Si9,
  .styles_lia-g-px-15__xoC15 {
      /* prettier-ignore */
      padding-left: 0.9375rem !important;
  }

.styles_lia-g-m-20__EjzqV {
      /* prettier-ignore */
      margin: 1.25rem !important;
  }

.styles_lia-g-mt-20__fcHyD,
  .styles_lia-g-my-20__438P8 {
      /* prettier-ignore */
      margin-top: 1.25rem !important;
  }

.styles_lia-g-mr-20__8WLMh,
  .styles_lia-g-mx-20__iBkhH {
      /* prettier-ignore */
      margin-right: 1.25rem !important;
  }

.styles_lia-g-mb-20__4UA0V,
  .styles_lia-g-my-20__438P8 {
      /* prettier-ignore */
      margin-bottom: 1.25rem !important;
  }

.styles_lia-g-ml-20__NjUrn,
  .styles_lia-g-mx-20__iBkhH {
      /* prettier-ignore */
      margin-left: 1.25rem !important;
  }

.styles_lia-g-p-20__PAvDd {
      /* prettier-ignore */
      padding: 1.25rem !important;
  }

.styles_lia-g-pt-20__ex1jx,
  .styles_lia-g-py-20__PP8_d {
      /* prettier-ignore */
      padding-top: 1.25rem !important;
  }

.styles_lia-g-pr-20__riWuF,
  .styles_lia-g-px-20__pZkQD {
      /* prettier-ignore */
      padding-right: 1.25rem !important;
  }

.styles_lia-g-pb-20__lk63U,
  .styles_lia-g-py-20__PP8_d {
      /* prettier-ignore */
      padding-bottom: 1.25rem !important;
  }

.styles_lia-g-pl-20__l3G_m,
  .styles_lia-g-px-20__pZkQD {
      /* prettier-ignore */
      padding-left: 1.25rem !important;
  }

.styles_lia-g-m-25__2nOjc {
      /* prettier-ignore */
      margin: 1.5625rem !important;
  }

.styles_lia-g-mt-25__b_Zpr,
  .styles_lia-g-my-25__NQjrN {
      /* prettier-ignore */
      margin-top: 1.5625rem !important;
  }

.styles_lia-g-mr-25__WBFwt,
  .styles_lia-g-mx-25__Br1Ug {
      /* prettier-ignore */
      margin-right: 1.5625rem !important;
  }

.styles_lia-g-mb-25__5lrHe,
  .styles_lia-g-my-25__NQjrN {
      /* prettier-ignore */
      margin-bottom: 1.5625rem !important;
  }

.styles_lia-g-ml-25__j3n4m,
  .styles_lia-g-mx-25__Br1Ug {
      /* prettier-ignore */
      margin-left: 1.5625rem !important;
  }

.styles_lia-g-p-25__DSqDf {
      /* prettier-ignore */
      padding: 1.5625rem !important;
  }

.styles_lia-g-pt-25__hLVVM,
  .styles_lia-g-py-25__setCd {
      /* prettier-ignore */
      padding-top: 1.5625rem !important;
  }

.styles_lia-g-pr-25__rs3Gu,
  .styles_lia-g-px-25__YLSKe {
      /* prettier-ignore */
      padding-right: 1.5625rem !important;
  }

.styles_lia-g-pb-25__kh2a2,
  .styles_lia-g-py-25__setCd {
      /* prettier-ignore */
      padding-bottom: 1.5625rem !important;
  }

.styles_lia-g-pl-25__3mqfN,
  .styles_lia-g-px-25__YLSKe {
      /* prettier-ignore */
      padding-left: 1.5625rem !important;
  }

.styles_lia-g-m-30__fBNOG {
      /* prettier-ignore */
      margin: 1.875rem !important;
  }

.styles_lia-g-mt-30__8ZzNw,
  .styles_lia-g-my-30__jG1GZ {
      /* prettier-ignore */
      margin-top: 1.875rem !important;
  }

.styles_lia-g-mr-30__5Gf3G,
  .styles_lia-g-mx-30__1bqNR {
      /* prettier-ignore */
      margin-right: 1.875rem !important;
  }

.styles_lia-g-mb-30__v2cqJ,
  .styles_lia-g-my-30__jG1GZ {
      /* prettier-ignore */
      margin-bottom: 1.875rem !important;
  }

.styles_lia-g-ml-30__4jPwY,
  .styles_lia-g-mx-30__1bqNR {
      /* prettier-ignore */
      margin-left: 1.875rem !important;
  }

.styles_lia-g-p-30__YFL1j {
      /* prettier-ignore */
      padding: 1.875rem !important;
  }

.styles_lia-g-pt-30__v2Kzc,
  .styles_lia-g-py-30__KQJ14 {
      /* prettier-ignore */
      padding-top: 1.875rem !important;
  }

.styles_lia-g-pr-30__o1BdL,
  .styles_lia-g-px-30__YSgUW {
      /* prettier-ignore */
      padding-right: 1.875rem !important;
  }

.styles_lia-g-pb-30__ikftQ,
  .styles_lia-g-py-30__KQJ14 {
      /* prettier-ignore */
      padding-bottom: 1.875rem !important;
  }

.styles_lia-g-pl-30__4V2ec,
  .styles_lia-g-px-30__YSgUW {
      /* prettier-ignore */
      padding-left: 1.875rem !important;
  }

.styles_lia-g-m-35__hioJP {
      /* prettier-ignore */
      margin: 2.1875rem !important;
  }

.styles_lia-g-mt-35__L0X19,
  .styles_lia-g-my-35__UR6zs {
      /* prettier-ignore */
      margin-top: 2.1875rem !important;
  }

.styles_lia-g-mr-35__Ky3gv,
  .styles_lia-g-mx-35__uxJ54 {
      /* prettier-ignore */
      margin-right: 2.1875rem !important;
  }

.styles_lia-g-mb-35__wQRvb,
  .styles_lia-g-my-35__UR6zs {
      /* prettier-ignore */
      margin-bottom: 2.1875rem !important;
  }

.styles_lia-g-ml-35__cLkbH,
  .styles_lia-g-mx-35__uxJ54 {
      /* prettier-ignore */
      margin-left: 2.1875rem !important;
  }

.styles_lia-g-p-35__NFMns {
      /* prettier-ignore */
      padding: 2.1875rem !important;
  }

.styles_lia-g-pt-35__uKxT_,
  .styles_lia-g-py-35__dST07 {
      /* prettier-ignore */
      padding-top: 2.1875rem !important;
  }

.styles_lia-g-pr-35__5eM95,
  .styles_lia-g-px-35__mTmY2 {
      /* prettier-ignore */
      padding-right: 2.1875rem !important;
  }

.styles_lia-g-pb-35__HsS_q,
  .styles_lia-g-py-35__dST07 {
      /* prettier-ignore */
      padding-bottom: 2.1875rem !important;
  }

.styles_lia-g-pl-35__FFFX5,
  .styles_lia-g-px-35__mTmY2 {
      /* prettier-ignore */
      padding-left: 2.1875rem !important;
  }

.styles_lia-g-m-40__T9C7b {
      /* prettier-ignore */
      margin: 2.5rem !important;
  }

.styles_lia-g-mt-40__tOweX,
  .styles_lia-g-my-40__mhqul {
      /* prettier-ignore */
      margin-top: 2.5rem !important;
  }

.styles_lia-g-mr-40__p61z9,
  .styles_lia-g-mx-40__uKE8l {
      /* prettier-ignore */
      margin-right: 2.5rem !important;
  }

.styles_lia-g-mb-40__LUQba,
  .styles_lia-g-my-40__mhqul {
      /* prettier-ignore */
      margin-bottom: 2.5rem !important;
  }

.styles_lia-g-ml-40__IZxqX,
  .styles_lia-g-mx-40__uKE8l {
      /* prettier-ignore */
      margin-left: 2.5rem !important;
  }

.styles_lia-g-p-40__Ymqnu {
      /* prettier-ignore */
      padding: 2.5rem !important;
  }

.styles_lia-g-pt-40__xEeMn,
  .styles_lia-g-py-40__JFHFt {
      /* prettier-ignore */
      padding-top: 2.5rem !important;
  }

.styles_lia-g-pr-40__LoPit,
  .styles_lia-g-px-40__qgDHd {
      /* prettier-ignore */
      padding-right: 2.5rem !important;
  }

.styles_lia-g-pb-40__QcG5S,
  .styles_lia-g-py-40__JFHFt {
      /* prettier-ignore */
      padding-bottom: 2.5rem !important;
  }

.styles_lia-g-pl-40__l9squ,
  .styles_lia-g-px-40__qgDHd {
      /* prettier-ignore */
      padding-left: 2.5rem !important;
  }

.styles_lia-g-m-50__l84aG {
      /* prettier-ignore */
      margin: 3.125rem !important;
  }

.styles_lia-g-mt-50__3ZocW,
  .styles_lia-g-my-50__EM4xq {
      /* prettier-ignore */
      margin-top: 3.125rem !important;
  }

.styles_lia-g-mr-50__3vHCr,
  .styles_lia-g-mx-50__LakQQ {
      /* prettier-ignore */
      margin-right: 3.125rem !important;
  }

.styles_lia-g-mb-50__eVf_H,
  .styles_lia-g-my-50__EM4xq {
      /* prettier-ignore */
      margin-bottom: 3.125rem !important;
  }

.styles_lia-g-ml-50__gtWrg,
  .styles_lia-g-mx-50__LakQQ {
      /* prettier-ignore */
      margin-left: 3.125rem !important;
  }

.styles_lia-g-p-50__2Tqqx {
      /* prettier-ignore */
      padding: 3.125rem !important;
  }

.styles_lia-g-pt-50__s8yFg,
  .styles_lia-g-py-50__q21EU {
      /* prettier-ignore */
      padding-top: 3.125rem !important;
  }

.styles_lia-g-pr-50__oz4oG,
  .styles_lia-g-px-50__dUcT0 {
      /* prettier-ignore */
      padding-right: 3.125rem !important;
  }

.styles_lia-g-pb-50__wwvT2,
  .styles_lia-g-py-50__q21EU {
      /* prettier-ignore */
      padding-bottom: 3.125rem !important;
  }

.styles_lia-g-pl-50__VchoL,
  .styles_lia-g-px-50__dUcT0 {
      /* prettier-ignore */
      padding-left: 3.125rem !important;
  }

.styles_lia-g-m-100__iSt2h {
      /* prettier-ignore */
      margin: 6.25rem !important;
  }

.styles_lia-g-mt-100___Leff,
  .styles_lia-g-my-100__Sdxmt {
      /* prettier-ignore */
      margin-top: 6.25rem !important;
  }

.styles_lia-g-mr-100__yjWsk,
  .styles_lia-g-mx-100__qn7nR {
      /* prettier-ignore */
      margin-right: 6.25rem !important;
  }

.styles_lia-g-mb-100__WN5pa,
  .styles_lia-g-my-100__Sdxmt {
      /* prettier-ignore */
      margin-bottom: 6.25rem !important;
  }

.styles_lia-g-ml-100__2bIKR,
  .styles_lia-g-mx-100__qn7nR {
      /* prettier-ignore */
      margin-left: 6.25rem !important;
  }

.styles_lia-g-p-100__gdeTd {
      /* prettier-ignore */
      padding: 6.25rem !important;
  }

.styles_lia-g-pt-100__mPuhr,
  .styles_lia-g-py-100__VRvDa {
      /* prettier-ignore */
      padding-top: 6.25rem !important;
  }

.styles_lia-g-pr-100__TdPTi,
  .styles_lia-g-px-100__1R0kC {
      /* prettier-ignore */
      padding-right: 6.25rem !important;
  }

.styles_lia-g-pb-100__FEaWP,
  .styles_lia-g-py-100__VRvDa {
      /* prettier-ignore */
      padding-bottom: 6.25rem !important;
  }

.styles_lia-g-pl-100__fsvDp,
  .styles_lia-g-px-100__1R0kC {
      /* prettier-ignore */
      padding-left: 6.25rem !important;
  }

@media (min-width: 576px) {
  .styles_lia-g-m-sm-0__zcNL7 {
      /* prettier-ignore */
      margin: 0 !important;
  }
  .styles_lia-g-mt-sm-0__IQHcb,
  .styles_lia-g-my-sm-0__O6FWn {
      /* prettier-ignore */
      margin-top: 0 !important;
  }
  .styles_lia-g-mr-sm-0__mQahu,
  .styles_lia-g-mx-sm-0__ODJ0L {
      /* prettier-ignore */
      margin-right: 0 !important;
  }
  .styles_lia-g-mb-sm-0__0JryU,
  .styles_lia-g-my-sm-0__O6FWn {
      /* prettier-ignore */
      margin-bottom: 0 !important;
  }
  .styles_lia-g-ml-sm-0__g_ExY,
  .styles_lia-g-mx-sm-0__ODJ0L {
      /* prettier-ignore */
      margin-left: 0 !important;
  }
  .styles_lia-g-p-sm-0__Mr0JR {
      /* prettier-ignore */
      padding: 0 !important;
  }
  .styles_lia-g-pt-sm-0__0_Nlx,
  .styles_lia-g-py-sm-0__wiXk4 {
      /* prettier-ignore */
      padding-top: 0 !important;
  }
  .styles_lia-g-pr-sm-0__i_up5,
  .styles_lia-g-px-sm-0__uBccf {
      /* prettier-ignore */
      padding-right: 0 !important;
  }
  .styles_lia-g-pb-sm-0__Jydfp,
  .styles_lia-g-py-sm-0__wiXk4 {
      /* prettier-ignore */
      padding-bottom: 0 !important;
  }
  .styles_lia-g-pl-sm-0__t5LoD,
  .styles_lia-g-px-sm-0__uBccf {
      /* prettier-ignore */
      padding-left: 0 !important;
  }
  .styles_lia-g-m-sm-5__URcJC {
      /* prettier-ignore */
      margin: 0.3125rem !important;
  }
  .styles_lia-g-mt-sm-5__IXb4b,
  .styles_lia-g-my-sm-5__q9_mk {
      /* prettier-ignore */
      margin-top: 0.3125rem !important;
  }
  .styles_lia-g-mr-sm-5__wXEdX,
  .styles_lia-g-mx-sm-5__YH04S {
      /* prettier-ignore */
      margin-right: 0.3125rem !important;
  }
  .styles_lia-g-mb-sm-5__gWrn6,
  .styles_lia-g-my-sm-5__q9_mk {
      /* prettier-ignore */
      margin-bottom: 0.3125rem !important;
  }
  .styles_lia-g-ml-sm-5__1xmZH,
  .styles_lia-g-mx-sm-5__YH04S {
      /* prettier-ignore */
      margin-left: 0.3125rem !important;
  }
  .styles_lia-g-p-sm-5__GBrKU {
      /* prettier-ignore */
      padding: 0.3125rem !important;
  }
  .styles_lia-g-pt-sm-5__nIzs6,
  .styles_lia-g-py-sm-5__w1EJO {
      /* prettier-ignore */
      padding-top: 0.3125rem !important;
  }
  .styles_lia-g-pr-sm-5__K6ljK,
  .styles_lia-g-px-sm-5__RexPJ {
      /* prettier-ignore */
      padding-right: 0.3125rem !important;
  }
  .styles_lia-g-pb-sm-5__Yvcd6,
  .styles_lia-g-py-sm-5__w1EJO {
      /* prettier-ignore */
      padding-bottom: 0.3125rem !important;
  }
  .styles_lia-g-pl-sm-5__I9zQA,
  .styles_lia-g-px-sm-5__RexPJ {
      /* prettier-ignore */
      padding-left: 0.3125rem !important;
  }
  .styles_lia-g-m-sm-10__eM552 {
      /* prettier-ignore */
      margin: 0.625rem !important;
  }
  .styles_lia-g-mt-sm-10__yuwm1,
  .styles_lia-g-my-sm-10__Hfeiy {
      /* prettier-ignore */
      margin-top: 0.625rem !important;
  }
  .styles_lia-g-mr-sm-10__ZSph1,
  .styles_lia-g-mx-sm-10__XXaO1 {
      /* prettier-ignore */
      margin-right: 0.625rem !important;
  }
  .styles_lia-g-mb-sm-10__i8VBm,
  .styles_lia-g-my-sm-10__Hfeiy {
      /* prettier-ignore */
      margin-bottom: 0.625rem !important;
  }
  .styles_lia-g-ml-sm-10__Z7yu6,
  .styles_lia-g-mx-sm-10__XXaO1 {
      /* prettier-ignore */
      margin-left: 0.625rem !important;
  }
  .styles_lia-g-p-sm-10__1zS8u {
      /* prettier-ignore */
      padding: 0.625rem !important;
  }
  .styles_lia-g-pt-sm-10__OP_XG,
  .styles_lia-g-py-sm-10__kwVbV {
      /* prettier-ignore */
      padding-top: 0.625rem !important;
  }
  .styles_lia-g-pr-sm-10__CBP_q,
  .styles_lia-g-px-sm-10__7_pDo {
      /* prettier-ignore */
      padding-right: 0.625rem !important;
  }
  .styles_lia-g-pb-sm-10__bMkXN,
  .styles_lia-g-py-sm-10__kwVbV {
      /* prettier-ignore */
      padding-bottom: 0.625rem !important;
  }
  .styles_lia-g-pl-sm-10__ZVTAu,
  .styles_lia-g-px-sm-10__7_pDo {
      /* prettier-ignore */
      padding-left: 0.625rem !important;
  }
  .styles_lia-g-m-sm-15__Fvh_b {
      /* prettier-ignore */
      margin: 0.9375rem !important;
  }
  .styles_lia-g-mt-sm-15__y_a8C,
  .styles_lia-g-my-sm-15__EbpN3 {
      /* prettier-ignore */
      margin-top: 0.9375rem !important;
  }
  .styles_lia-g-mr-sm-15__O784p,
  .styles_lia-g-mx-sm-15__I8mB2 {
      /* prettier-ignore */
      margin-right: 0.9375rem !important;
  }
  .styles_lia-g-mb-sm-15__pQc3n,
  .styles_lia-g-my-sm-15__EbpN3 {
      /* prettier-ignore */
      margin-bottom: 0.9375rem !important;
  }
  .styles_lia-g-ml-sm-15__cC9QI,
  .styles_lia-g-mx-sm-15__I8mB2 {
      /* prettier-ignore */
      margin-left: 0.9375rem !important;
  }
  .styles_lia-g-p-sm-15__Zc_Dq {
      /* prettier-ignore */
      padding: 0.9375rem !important;
  }
  .styles_lia-g-pt-sm-15__pFEiK,
  .styles_lia-g-py-sm-15__EZ1Yu {
      /* prettier-ignore */
      padding-top: 0.9375rem !important;
  }
  .styles_lia-g-pr-sm-15__Ty_13,
  .styles_lia-g-px-sm-15__1gPux {
      /* prettier-ignore */
      padding-right: 0.9375rem !important;
  }
  .styles_lia-g-pb-sm-15__qqlqx,
  .styles_lia-g-py-sm-15__EZ1Yu {
      /* prettier-ignore */
      padding-bottom: 0.9375rem !important;
  }
  .styles_lia-g-pl-sm-15__BU6ul,
  .styles_lia-g-px-sm-15__1gPux {
      /* prettier-ignore */
      padding-left: 0.9375rem !important;
  }
  .styles_lia-g-m-sm-20__aY5DY {
      /* prettier-ignore */
      margin: 1.25rem !important;
  }
  .styles_lia-g-mt-sm-20__7YvF_,
  .styles_lia-g-my-sm-20___Y1Em {
      /* prettier-ignore */
      margin-top: 1.25rem !important;
  }
  .styles_lia-g-mr-sm-20__UBxdy,
  .styles_lia-g-mx-sm-20__0C0dN {
      /* prettier-ignore */
      margin-right: 1.25rem !important;
  }
  .styles_lia-g-mb-sm-20__WdxS9,
  .styles_lia-g-my-sm-20___Y1Em {
      /* prettier-ignore */
      margin-bottom: 1.25rem !important;
  }
  .styles_lia-g-ml-sm-20__pI8yc,
  .styles_lia-g-mx-sm-20__0C0dN {
      /* prettier-ignore */
      margin-left: 1.25rem !important;
  }
  .styles_lia-g-p-sm-20__p8HVc {
      /* prettier-ignore */
      padding: 1.25rem !important;
  }
  .styles_lia-g-pt-sm-20__Y4KyN,
  .styles_lia-g-py-sm-20__j8f3_ {
      /* prettier-ignore */
      padding-top: 1.25rem !important;
  }
  .styles_lia-g-pr-sm-20__HJu5W,
  .styles_lia-g-px-sm-20__g6spK {
      /* prettier-ignore */
      padding-right: 1.25rem !important;
  }
  .styles_lia-g-pb-sm-20__xsUAh,
  .styles_lia-g-py-sm-20__j8f3_ {
      /* prettier-ignore */
      padding-bottom: 1.25rem !important;
  }
  .styles_lia-g-pl-sm-20__Hp9SE,
  .styles_lia-g-px-sm-20__g6spK {
      /* prettier-ignore */
      padding-left: 1.25rem !important;
  }
  .styles_lia-g-m-sm-25__VfU2m {
      /* prettier-ignore */
      margin: 1.5625rem !important;
  }
  .styles_lia-g-mt-sm-25__hoMZL,
  .styles_lia-g-my-sm-25__f7kXu {
      /* prettier-ignore */
      margin-top: 1.5625rem !important;
  }
  .styles_lia-g-mr-sm-25__xWNIk,
  .styles_lia-g-mx-sm-25__ryyDs {
      /* prettier-ignore */
      margin-right: 1.5625rem !important;
  }
  .styles_lia-g-mb-sm-25__JBeFJ,
  .styles_lia-g-my-sm-25__f7kXu {
      /* prettier-ignore */
      margin-bottom: 1.5625rem !important;
  }
  .styles_lia-g-ml-sm-25__sx6tf,
  .styles_lia-g-mx-sm-25__ryyDs {
      /* prettier-ignore */
      margin-left: 1.5625rem !important;
  }
  .styles_lia-g-p-sm-25__UvkeB {
      /* prettier-ignore */
      padding: 1.5625rem !important;
  }
  .styles_lia-g-pt-sm-25__9lGbg,
  .styles_lia-g-py-sm-25__ZlILS {
      /* prettier-ignore */
      padding-top: 1.5625rem !important;
  }
  .styles_lia-g-pr-sm-25__1WmUu,
  .styles_lia-g-px-sm-25__xVuWl {
      /* prettier-ignore */
      padding-right: 1.5625rem !important;
  }
  .styles_lia-g-pb-sm-25__bfP5L,
  .styles_lia-g-py-sm-25__ZlILS {
      /* prettier-ignore */
      padding-bottom: 1.5625rem !important;
  }
  .styles_lia-g-pl-sm-25__H60_w,
  .styles_lia-g-px-sm-25__xVuWl {
      /* prettier-ignore */
      padding-left: 1.5625rem !important;
  }
  .styles_lia-g-m-sm-30__8exTT {
      /* prettier-ignore */
      margin: 1.875rem !important;
  }
  .styles_lia-g-mt-sm-30__zU6UY,
  .styles_lia-g-my-sm-30__gbhw8 {
      /* prettier-ignore */
      margin-top: 1.875rem !important;
  }
  .styles_lia-g-mr-sm-30__gDcJT,
  .styles_lia-g-mx-sm-30__Le9ug {
      /* prettier-ignore */
      margin-right: 1.875rem !important;
  }
  .styles_lia-g-mb-sm-30__exERN,
  .styles_lia-g-my-sm-30__gbhw8 {
      /* prettier-ignore */
      margin-bottom: 1.875rem !important;
  }
  .styles_lia-g-ml-sm-30__8tlM9,
  .styles_lia-g-mx-sm-30__Le9ug {
      /* prettier-ignore */
      margin-left: 1.875rem !important;
  }
  .styles_lia-g-p-sm-30__N8kVP {
      /* prettier-ignore */
      padding: 1.875rem !important;
  }
  .styles_lia-g-pt-sm-30__jhH1I,
  .styles_lia-g-py-sm-30__hBla0 {
      /* prettier-ignore */
      padding-top: 1.875rem !important;
  }
  .styles_lia-g-pr-sm-30__64Tlc,
  .styles_lia-g-px-sm-30__c4X8X {
      /* prettier-ignore */
      padding-right: 1.875rem !important;
  }
  .styles_lia-g-pb-sm-30__34t6_,
  .styles_lia-g-py-sm-30__hBla0 {
      /* prettier-ignore */
      padding-bottom: 1.875rem !important;
  }
  .styles_lia-g-pl-sm-30__XTf0H,
  .styles_lia-g-px-sm-30__c4X8X {
      /* prettier-ignore */
      padding-left: 1.875rem !important;
  }
  .styles_lia-g-m-sm-35__HDqto {
      /* prettier-ignore */
      margin: 2.1875rem !important;
  }
  .styles_lia-g-mt-sm-35__j_k1P,
  .styles_lia-g-my-sm-35__m1ju0 {
      /* prettier-ignore */
      margin-top: 2.1875rem !important;
  }
  .styles_lia-g-mr-sm-35__3lLfO,
  .styles_lia-g-mx-sm-35___jUq3 {
      /* prettier-ignore */
      margin-right: 2.1875rem !important;
  }
  .styles_lia-g-mb-sm-35__mvtnA,
  .styles_lia-g-my-sm-35__m1ju0 {
      /* prettier-ignore */
      margin-bottom: 2.1875rem !important;
  }
  .styles_lia-g-ml-sm-35__tT1n3,
  .styles_lia-g-mx-sm-35___jUq3 {
      /* prettier-ignore */
      margin-left: 2.1875rem !important;
  }
  .styles_lia-g-p-sm-35__4bKQG {
      /* prettier-ignore */
      padding: 2.1875rem !important;
  }
  .styles_lia-g-pt-sm-35__gQ8D8,
  .styles_lia-g-py-sm-35___hmmi {
      /* prettier-ignore */
      padding-top: 2.1875rem !important;
  }
  .styles_lia-g-pr-sm-35__rPGQN,
  .styles_lia-g-px-sm-35__4z4GV {
      /* prettier-ignore */
      padding-right: 2.1875rem !important;
  }
  .styles_lia-g-pb-sm-35__RYURa,
  .styles_lia-g-py-sm-35___hmmi {
      /* prettier-ignore */
      padding-bottom: 2.1875rem !important;
  }
  .styles_lia-g-pl-sm-35__rPWPy,
  .styles_lia-g-px-sm-35__4z4GV {
      /* prettier-ignore */
      padding-left: 2.1875rem !important;
  }
  .styles_lia-g-m-sm-40__YpEoy {
      /* prettier-ignore */
      margin: 2.5rem !important;
  }
  .styles_lia-g-mt-sm-40__d6yBg,
  .styles_lia-g-my-sm-40__GnBTX {
      /* prettier-ignore */
      margin-top: 2.5rem !important;
  }
  .styles_lia-g-mr-sm-40__B0l0B,
  .styles_lia-g-mx-sm-40__vIo56 {
      /* prettier-ignore */
      margin-right: 2.5rem !important;
  }
  .styles_lia-g-mb-sm-40__zsaUx,
  .styles_lia-g-my-sm-40__GnBTX {
      /* prettier-ignore */
      margin-bottom: 2.5rem !important;
  }
  .styles_lia-g-ml-sm-40__HkWG8,
  .styles_lia-g-mx-sm-40__vIo56 {
      /* prettier-ignore */
      margin-left: 2.5rem !important;
  }
  .styles_lia-g-p-sm-40__cMbTN {
      /* prettier-ignore */
      padding: 2.5rem !important;
  }
  .styles_lia-g-pt-sm-40__Xv_0_,
  .styles_lia-g-py-sm-40__lUHeZ {
      /* prettier-ignore */
      padding-top: 2.5rem !important;
  }
  .styles_lia-g-pr-sm-40__x_TL7,
  .styles_lia-g-px-sm-40__r9VDR {
      /* prettier-ignore */
      padding-right: 2.5rem !important;
  }
  .styles_lia-g-pb-sm-40__OB9Qm,
  .styles_lia-g-py-sm-40__lUHeZ {
      /* prettier-ignore */
      padding-bottom: 2.5rem !important;
  }
  .styles_lia-g-pl-sm-40__mcjox,
  .styles_lia-g-px-sm-40__r9VDR {
      /* prettier-ignore */
      padding-left: 2.5rem !important;
  }
  .styles_lia-g-m-sm-50__dUcLR {
      /* prettier-ignore */
      margin: 3.125rem !important;
  }
  .styles_lia-g-mt-sm-50__ZZApy,
  .styles_lia-g-my-sm-50__e13La {
      /* prettier-ignore */
      margin-top: 3.125rem !important;
  }
  .styles_lia-g-mr-sm-50__Lvvkq,
  .styles_lia-g-mx-sm-50__sp5k3 {
      /* prettier-ignore */
      margin-right: 3.125rem !important;
  }
  .styles_lia-g-mb-sm-50__SNSCI,
  .styles_lia-g-my-sm-50__e13La {
      /* prettier-ignore */
      margin-bottom: 3.125rem !important;
  }
  .styles_lia-g-ml-sm-50__0GiQS,
  .styles_lia-g-mx-sm-50__sp5k3 {
      /* prettier-ignore */
      margin-left: 3.125rem !important;
  }
  .styles_lia-g-p-sm-50__7TVcz {
      /* prettier-ignore */
      padding: 3.125rem !important;
  }
  .styles_lia-g-pt-sm-50__3sSdR,
  .styles_lia-g-py-sm-50__x1CKa {
      /* prettier-ignore */
      padding-top: 3.125rem !important;
  }
  .styles_lia-g-pr-sm-50__arEsI,
  .styles_lia-g-px-sm-50__ErK0f {
      /* prettier-ignore */
      padding-right: 3.125rem !important;
  }
  .styles_lia-g-pb-sm-50__2JR95,
  .styles_lia-g-py-sm-50__x1CKa {
      /* prettier-ignore */
      padding-bottom: 3.125rem !important;
  }
  .styles_lia-g-pl-sm-50__jDI3W,
  .styles_lia-g-px-sm-50__ErK0f {
      /* prettier-ignore */
      padding-left: 3.125rem !important;
  }
  .styles_lia-g-m-sm-100__kGqb3 {
      /* prettier-ignore */
      margin: 6.25rem !important;
  }
  .styles_lia-g-mt-sm-100__fb9wP,
  .styles_lia-g-my-sm-100__FkJ_z {
      /* prettier-ignore */
      margin-top: 6.25rem !important;
  }
  .styles_lia-g-mr-sm-100__xGUUn,
  .styles_lia-g-mx-sm-100__liXfq {
      /* prettier-ignore */
      margin-right: 6.25rem !important;
  }
  .styles_lia-g-mb-sm-100__rZhfP,
  .styles_lia-g-my-sm-100__FkJ_z {
      /* prettier-ignore */
      margin-bottom: 6.25rem !important;
  }
  .styles_lia-g-ml-sm-100__1ldTB,
  .styles_lia-g-mx-sm-100__liXfq {
      /* prettier-ignore */
      margin-left: 6.25rem !important;
  }
  .styles_lia-g-p-sm-100__QB8qL {
      /* prettier-ignore */
      padding: 6.25rem !important;
  }
  .styles_lia-g-pt-sm-100__eyIYU,
  .styles_lia-g-py-sm-100__22HqD {
      /* prettier-ignore */
      padding-top: 6.25rem !important;
  }
  .styles_lia-g-pr-sm-100__oYGON,
  .styles_lia-g-px-sm-100__oqJk1 {
      /* prettier-ignore */
      padding-right: 6.25rem !important;
  }
  .styles_lia-g-pb-sm-100__WeeyG,
  .styles_lia-g-py-sm-100__22HqD {
      /* prettier-ignore */
      padding-bottom: 6.25rem !important;
  }
  .styles_lia-g-pl-sm-100__KfJvo,
  .styles_lia-g-px-sm-100__oqJk1 {
      /* prettier-ignore */
      padding-left: 6.25rem !important;
  }
    }

@media (min-width: 768px) {
  .styles_lia-g-m-md-0__Q9iLV {
      /* prettier-ignore */
      margin: 0 !important;
  }
  .styles_lia-g-mt-md-0__bpZZP,
  .styles_lia-g-my-md-0__IrEes {
      /* prettier-ignore */
      margin-top: 0 !important;
  }
  .styles_lia-g-mr-md-0__gPuCk,
  .styles_lia-g-mx-md-0__TCIWS {
      /* prettier-ignore */
      margin-right: 0 !important;
  }
  .styles_lia-g-mb-md-0__ZV0jg,
  .styles_lia-g-my-md-0__IrEes {
      /* prettier-ignore */
      margin-bottom: 0 !important;
  }
  .styles_lia-g-ml-md-0__oPqVt,
  .styles_lia-g-mx-md-0__TCIWS {
      /* prettier-ignore */
      margin-left: 0 !important;
  }
  .styles_lia-g-p-md-0__C5gKl {
      /* prettier-ignore */
      padding: 0 !important;
  }
  .styles_lia-g-pt-md-0__B9lD8,
  .styles_lia-g-py-md-0__FLxnz {
      /* prettier-ignore */
      padding-top: 0 !important;
  }
  .styles_lia-g-pr-md-0__XEA1j,
  .styles_lia-g-px-md-0__YX94C {
      /* prettier-ignore */
      padding-right: 0 !important;
  }
  .styles_lia-g-pb-md-0__r8qCC,
  .styles_lia-g-py-md-0__FLxnz {
      /* prettier-ignore */
      padding-bottom: 0 !important;
  }
  .styles_lia-g-pl-md-0__rG7Xr,
  .styles_lia-g-px-md-0__YX94C {
      /* prettier-ignore */
      padding-left: 0 !important;
  }
  .styles_lia-g-m-md-5__kOGpk {
      /* prettier-ignore */
      margin: 0.3125rem !important;
  }
  .styles_lia-g-mt-md-5__3AflD,
  .styles_lia-g-my-md-5__1EMIa {
      /* prettier-ignore */
      margin-top: 0.3125rem !important;
  }
  .styles_lia-g-mr-md-5__DVH67,
  .styles_lia-g-mx-md-5__KFa1q {
      /* prettier-ignore */
      margin-right: 0.3125rem !important;
  }
  .styles_lia-g-mb-md-5__FyMgx,
  .styles_lia-g-my-md-5__1EMIa {
      /* prettier-ignore */
      margin-bottom: 0.3125rem !important;
  }
  .styles_lia-g-ml-md-5__xPnab,
  .styles_lia-g-mx-md-5__KFa1q {
      /* prettier-ignore */
      margin-left: 0.3125rem !important;
  }
  .styles_lia-g-p-md-5__3Iwe_ {
      /* prettier-ignore */
      padding: 0.3125rem !important;
  }
  .styles_lia-g-pt-md-5__anLR7,
  .styles_lia-g-py-md-5__ExiQV {
      /* prettier-ignore */
      padding-top: 0.3125rem !important;
  }
  .styles_lia-g-pr-md-5__aqv2G,
  .styles_lia-g-px-md-5__ostzO {
      /* prettier-ignore */
      padding-right: 0.3125rem !important;
  }
  .styles_lia-g-pb-md-5__qlyxT,
  .styles_lia-g-py-md-5__ExiQV {
      /* prettier-ignore */
      padding-bottom: 0.3125rem !important;
  }
  .styles_lia-g-pl-md-5__2cOGe,
  .styles_lia-g-px-md-5__ostzO {
      /* prettier-ignore */
      padding-left: 0.3125rem !important;
  }
  .styles_lia-g-m-md-10__o4lf4 {
      /* prettier-ignore */
      margin: 0.625rem !important;
  }
  .styles_lia-g-mt-md-10__qAvl2,
  .styles_lia-g-my-md-10__bhoXF {
      /* prettier-ignore */
      margin-top: 0.625rem !important;
  }
  .styles_lia-g-mr-md-10__pw4mM,
  .styles_lia-g-mx-md-10__Usc86 {
      /* prettier-ignore */
      margin-right: 0.625rem !important;
  }
  .styles_lia-g-mb-md-10__im4vq,
  .styles_lia-g-my-md-10__bhoXF {
      /* prettier-ignore */
      margin-bottom: 0.625rem !important;
  }
  .styles_lia-g-ml-md-10__5sXt2,
  .styles_lia-g-mx-md-10__Usc86 {
      /* prettier-ignore */
      margin-left: 0.625rem !important;
  }
  .styles_lia-g-p-md-10__owTlh {
      /* prettier-ignore */
      padding: 0.625rem !important;
  }
  .styles_lia-g-pt-md-10__6XiJ5,
  .styles_lia-g-py-md-10__Uw7Z6 {
      /* prettier-ignore */
      padding-top: 0.625rem !important;
  }
  .styles_lia-g-pr-md-10__dhbdA,
  .styles_lia-g-px-md-10__pSkuy {
      /* prettier-ignore */
      padding-right: 0.625rem !important;
  }
  .styles_lia-g-pb-md-10__Pz4QN,
  .styles_lia-g-py-md-10__Uw7Z6 {
      /* prettier-ignore */
      padding-bottom: 0.625rem !important;
  }
  .styles_lia-g-pl-md-10__1y4VA,
  .styles_lia-g-px-md-10__pSkuy {
      /* prettier-ignore */
      padding-left: 0.625rem !important;
  }
  .styles_lia-g-m-md-15__KJeD7 {
      /* prettier-ignore */
      margin: 0.9375rem !important;
  }
  .styles_lia-g-mt-md-15__6l8Cr,
  .styles_lia-g-my-md-15__SutkL {
      /* prettier-ignore */
      margin-top: 0.9375rem !important;
  }
  .styles_lia-g-mr-md-15__zx13W,
  .styles_lia-g-mx-md-15__zmkvp {
      /* prettier-ignore */
      margin-right: 0.9375rem !important;
  }
  .styles_lia-g-mb-md-15__4U3_k,
  .styles_lia-g-my-md-15__SutkL {
      /* prettier-ignore */
      margin-bottom: 0.9375rem !important;
  }
  .styles_lia-g-ml-md-15__jK1Ji,
  .styles_lia-g-mx-md-15__zmkvp {
      /* prettier-ignore */
      margin-left: 0.9375rem !important;
  }
  .styles_lia-g-p-md-15__2__lv {
      /* prettier-ignore */
      padding: 0.9375rem !important;
  }
  .styles_lia-g-pt-md-15__S_a6_,
  .styles_lia-g-py-md-15__LYKB_ {
      /* prettier-ignore */
      padding-top: 0.9375rem !important;
  }
  .styles_lia-g-pr-md-15__SdrPo,
  .styles_lia-g-px-md-15__7g1On {
      /* prettier-ignore */
      padding-right: 0.9375rem !important;
  }
  .styles_lia-g-pb-md-15__rodcZ,
  .styles_lia-g-py-md-15__LYKB_ {
      /* prettier-ignore */
      padding-bottom: 0.9375rem !important;
  }
  .styles_lia-g-pl-md-15__AeAy_,
  .styles_lia-g-px-md-15__7g1On {
      /* prettier-ignore */
      padding-left: 0.9375rem !important;
  }
  .styles_lia-g-m-md-20__fTGWo {
      /* prettier-ignore */
      margin: 1.25rem !important;
  }
  .styles_lia-g-mt-md-20__ip7mC,
  .styles_lia-g-my-md-20__fX_Vx {
      /* prettier-ignore */
      margin-top: 1.25rem !important;
  }
  .styles_lia-g-mr-md-20__ruEuc,
  .styles_lia-g-mx-md-20__1psbZ {
      /* prettier-ignore */
      margin-right: 1.25rem !important;
  }
  .styles_lia-g-mb-md-20__595p3,
  .styles_lia-g-my-md-20__fX_Vx {
      /* prettier-ignore */
      margin-bottom: 1.25rem !important;
  }
  .styles_lia-g-ml-md-20__FQRWK,
  .styles_lia-g-mx-md-20__1psbZ {
      /* prettier-ignore */
      margin-left: 1.25rem !important;
  }
  .styles_lia-g-p-md-20__v5_y1 {
      /* prettier-ignore */
      padding: 1.25rem !important;
  }
  .styles_lia-g-pt-md-20__no1XC,
  .styles_lia-g-py-md-20__FUJ9d {
      /* prettier-ignore */
      padding-top: 1.25rem !important;
  }
  .styles_lia-g-pr-md-20__xcmpT,
  .styles_lia-g-px-md-20___4riz {
      /* prettier-ignore */
      padding-right: 1.25rem !important;
  }
  .styles_lia-g-pb-md-20__pS603,
  .styles_lia-g-py-md-20__FUJ9d {
      /* prettier-ignore */
      padding-bottom: 1.25rem !important;
  }
  .styles_lia-g-pl-md-20__ibcDa,
  .styles_lia-g-px-md-20___4riz {
      /* prettier-ignore */
      padding-left: 1.25rem !important;
  }
  .styles_lia-g-m-md-25__Rfven {
      /* prettier-ignore */
      margin: 1.5625rem !important;
  }
  .styles_lia-g-mt-md-25__519ne,
  .styles_lia-g-my-md-25___dKQy {
      /* prettier-ignore */
      margin-top: 1.5625rem !important;
  }
  .styles_lia-g-mr-md-25__2ZsiV,
  .styles_lia-g-mx-md-25__EvrYu {
      /* prettier-ignore */
      margin-right: 1.5625rem !important;
  }
  .styles_lia-g-mb-md-25__1T46o,
  .styles_lia-g-my-md-25___dKQy {
      /* prettier-ignore */
      margin-bottom: 1.5625rem !important;
  }
  .styles_lia-g-ml-md-25__yKwpr,
  .styles_lia-g-mx-md-25__EvrYu {
      /* prettier-ignore */
      margin-left: 1.5625rem !important;
  }
  .styles_lia-g-p-md-25__5Ca9Y {
      /* prettier-ignore */
      padding: 1.5625rem !important;
  }
  .styles_lia-g-pt-md-25__2KcD2,
  .styles_lia-g-py-md-25__sYrYe {
      /* prettier-ignore */
      padding-top: 1.5625rem !important;
  }
  .styles_lia-g-pr-md-25__aVI6_,
  .styles_lia-g-px-md-25__Hraft {
      /* prettier-ignore */
      padding-right: 1.5625rem !important;
  }
  .styles_lia-g-pb-md-25__ECp4g,
  .styles_lia-g-py-md-25__sYrYe {
      /* prettier-ignore */
      padding-bottom: 1.5625rem !important;
  }
  .styles_lia-g-pl-md-25__Ogs0v,
  .styles_lia-g-px-md-25__Hraft {
      /* prettier-ignore */
      padding-left: 1.5625rem !important;
  }
  .styles_lia-g-m-md-30__LRBJS {
      /* prettier-ignore */
      margin: 1.875rem !important;
  }
  .styles_lia-g-mt-md-30__fXzwv,
  .styles_lia-g-my-md-30__gmOTb {
      /* prettier-ignore */
      margin-top: 1.875rem !important;
  }
  .styles_lia-g-mr-md-30__RWeHP,
  .styles_lia-g-mx-md-30__7UABU {
      /* prettier-ignore */
      margin-right: 1.875rem !important;
  }
  .styles_lia-g-mb-md-30__S8AEi,
  .styles_lia-g-my-md-30__gmOTb {
      /* prettier-ignore */
      margin-bottom: 1.875rem !important;
  }
  .styles_lia-g-ml-md-30__EcSY0,
  .styles_lia-g-mx-md-30__7UABU {
      /* prettier-ignore */
      margin-left: 1.875rem !important;
  }
  .styles_lia-g-p-md-30__r4qZS {
      /* prettier-ignore */
      padding: 1.875rem !important;
  }
  .styles_lia-g-pt-md-30__7C2HR,
  .styles_lia-g-py-md-30__W_Uif {
      /* prettier-ignore */
      padding-top: 1.875rem !important;
  }
  .styles_lia-g-pr-md-30__At9ns,
  .styles_lia-g-px-md-30__TNOA5 {
      /* prettier-ignore */
      padding-right: 1.875rem !important;
  }
  .styles_lia-g-pb-md-30__R6BUG,
  .styles_lia-g-py-md-30__W_Uif {
      /* prettier-ignore */
      padding-bottom: 1.875rem !important;
  }
  .styles_lia-g-pl-md-30__Y8hMA,
  .styles_lia-g-px-md-30__TNOA5 {
      /* prettier-ignore */
      padding-left: 1.875rem !important;
  }
  .styles_lia-g-m-md-35__KOELz {
      /* prettier-ignore */
      margin: 2.1875rem !important;
  }
  .styles_lia-g-mt-md-35__JIZzN,
  .styles_lia-g-my-md-35__hPixr {
      /* prettier-ignore */
      margin-top: 2.1875rem !important;
  }
  .styles_lia-g-mr-md-35__iUMlN,
  .styles_lia-g-mx-md-35__zpRrP {
      /* prettier-ignore */
      margin-right: 2.1875rem !important;
  }
  .styles_lia-g-mb-md-35__GU3GA,
  .styles_lia-g-my-md-35__hPixr {
      /* prettier-ignore */
      margin-bottom: 2.1875rem !important;
  }
  .styles_lia-g-ml-md-35__PBwk_,
  .styles_lia-g-mx-md-35__zpRrP {
      /* prettier-ignore */
      margin-left: 2.1875rem !important;
  }
  .styles_lia-g-p-md-35__cq5SJ {
      /* prettier-ignore */
      padding: 2.1875rem !important;
  }
  .styles_lia-g-pt-md-35__QbCy4,
  .styles_lia-g-py-md-35__YPqWC {
      /* prettier-ignore */
      padding-top: 2.1875rem !important;
  }
  .styles_lia-g-pr-md-35__iK5Oc,
  .styles_lia-g-px-md-35__wq_My {
      /* prettier-ignore */
      padding-right: 2.1875rem !important;
  }
  .styles_lia-g-pb-md-35__BGgar,
  .styles_lia-g-py-md-35__YPqWC {
      /* prettier-ignore */
      padding-bottom: 2.1875rem !important;
  }
  .styles_lia-g-pl-md-35__NqWJj,
  .styles_lia-g-px-md-35__wq_My {
      /* prettier-ignore */
      padding-left: 2.1875rem !important;
  }
  .styles_lia-g-m-md-40__dy0s1 {
      /* prettier-ignore */
      margin: 2.5rem !important;
  }
  .styles_lia-g-mt-md-40__ay9nx,
  .styles_lia-g-my-md-40__2tH_N {
      /* prettier-ignore */
      margin-top: 2.5rem !important;
  }
  .styles_lia-g-mr-md-40__jS2Pe,
  .styles_lia-g-mx-md-40__Dneyh {
      /* prettier-ignore */
      margin-right: 2.5rem !important;
  }
  .styles_lia-g-mb-md-40__nIpdm,
  .styles_lia-g-my-md-40__2tH_N {
      /* prettier-ignore */
      margin-bottom: 2.5rem !important;
  }
  .styles_lia-g-ml-md-40__4bKJl,
  .styles_lia-g-mx-md-40__Dneyh {
      /* prettier-ignore */
      margin-left: 2.5rem !important;
  }
  .styles_lia-g-p-md-40__woAHG {
      /* prettier-ignore */
      padding: 2.5rem !important;
  }
  .styles_lia-g-pt-md-40__A1awL,
  .styles_lia-g-py-md-40__FaL8I {
      /* prettier-ignore */
      padding-top: 2.5rem !important;
  }
  .styles_lia-g-pr-md-40__c0WFN,
  .styles_lia-g-px-md-40__yT88y {
      /* prettier-ignore */
      padding-right: 2.5rem !important;
  }
  .styles_lia-g-pb-md-40__Ojxgf,
  .styles_lia-g-py-md-40__FaL8I {
      /* prettier-ignore */
      padding-bottom: 2.5rem !important;
  }
  .styles_lia-g-pl-md-40__gf8de,
  .styles_lia-g-px-md-40__yT88y {
      /* prettier-ignore */
      padding-left: 2.5rem !important;
  }
  .styles_lia-g-m-md-50__3lo6d {
      /* prettier-ignore */
      margin: 3.125rem !important;
  }
  .styles_lia-g-mt-md-50__djhJA,
  .styles_lia-g-my-md-50__2Z4j2 {
      /* prettier-ignore */
      margin-top: 3.125rem !important;
  }
  .styles_lia-g-mr-md-50__3JTNP,
  .styles_lia-g-mx-md-50__b3k4b {
      /* prettier-ignore */
      margin-right: 3.125rem !important;
  }
  .styles_lia-g-mb-md-50__20WxO,
  .styles_lia-g-my-md-50__2Z4j2 {
      /* prettier-ignore */
      margin-bottom: 3.125rem !important;
  }
  .styles_lia-g-ml-md-50__CQwYI,
  .styles_lia-g-mx-md-50__b3k4b {
      /* prettier-ignore */
      margin-left: 3.125rem !important;
  }
  .styles_lia-g-p-md-50__ooaQx {
      /* prettier-ignore */
      padding: 3.125rem !important;
  }
  .styles_lia-g-pt-md-50__wJ7pl,
  .styles_lia-g-py-md-50__JL7Dm {
      /* prettier-ignore */
      padding-top: 3.125rem !important;
  }
  .styles_lia-g-pr-md-50__sgDnC,
  .styles_lia-g-px-md-50__1Qj6J {
      /* prettier-ignore */
      padding-right: 3.125rem !important;
  }
  .styles_lia-g-pb-md-50__WSWFE,
  .styles_lia-g-py-md-50__JL7Dm {
      /* prettier-ignore */
      padding-bottom: 3.125rem !important;
  }
  .styles_lia-g-pl-md-50__B2BYt,
  .styles_lia-g-px-md-50__1Qj6J {
      /* prettier-ignore */
      padding-left: 3.125rem !important;
  }
  .styles_lia-g-m-md-100__O_1EE {
      /* prettier-ignore */
      margin: 6.25rem !important;
  }
  .styles_lia-g-mt-md-100__5S2RF,
  .styles_lia-g-my-md-100__zpZcy {
      /* prettier-ignore */
      margin-top: 6.25rem !important;
  }
  .styles_lia-g-mr-md-100__QtX1_,
  .styles_lia-g-mx-md-100__rKrlO {
      /* prettier-ignore */
      margin-right: 6.25rem !important;
  }
  .styles_lia-g-mb-md-100__nnmHD,
  .styles_lia-g-my-md-100__zpZcy {
      /* prettier-ignore */
      margin-bottom: 6.25rem !important;
  }
  .styles_lia-g-ml-md-100__M60BK,
  .styles_lia-g-mx-md-100__rKrlO {
      /* prettier-ignore */
      margin-left: 6.25rem !important;
  }
  .styles_lia-g-p-md-100__9v34f {
      /* prettier-ignore */
      padding: 6.25rem !important;
  }
  .styles_lia-g-pt-md-100__jkfHR,
  .styles_lia-g-py-md-100__xuPdu {
      /* prettier-ignore */
      padding-top: 6.25rem !important;
  }
  .styles_lia-g-pr-md-100__akYyU,
  .styles_lia-g-px-md-100__ZhCO3 {
      /* prettier-ignore */
      padding-right: 6.25rem !important;
  }
  .styles_lia-g-pb-md-100__0ytFP,
  .styles_lia-g-py-md-100__xuPdu {
      /* prettier-ignore */
      padding-bottom: 6.25rem !important;
  }
  .styles_lia-g-pl-md-100__n7zFc,
  .styles_lia-g-px-md-100__ZhCO3 {
      /* prettier-ignore */
      padding-left: 6.25rem !important;
  }
    }

@media (min-width: 992px) {
  .styles_lia-g-m-lg-0__agcoH {
      /* prettier-ignore */
      margin: 0 !important;
  }
  .styles_lia-g-mt-lg-0__ZT8tW,
  .styles_lia-g-my-lg-0__Ul5q7 {
      /* prettier-ignore */
      margin-top: 0 !important;
  }
  .styles_lia-g-mr-lg-0__B9aiv,
  .styles_lia-g-mx-lg-0__8nZaf {
      /* prettier-ignore */
      margin-right: 0 !important;
  }
  .styles_lia-g-mb-lg-0__eRw5w,
  .styles_lia-g-my-lg-0__Ul5q7 {
      /* prettier-ignore */
      margin-bottom: 0 !important;
  }
  .styles_lia-g-ml-lg-0__fuQsD,
  .styles_lia-g-mx-lg-0__8nZaf {
      /* prettier-ignore */
      margin-left: 0 !important;
  }
  .styles_lia-g-p-lg-0__eJwRd {
      /* prettier-ignore */
      padding: 0 !important;
  }
  .styles_lia-g-pt-lg-0__cHNOq,
  .styles_lia-g-py-lg-0__wyVJn {
      /* prettier-ignore */
      padding-top: 0 !important;
  }
  .styles_lia-g-pr-lg-0__m_zcj,
  .styles_lia-g-px-lg-0__x_lfg {
      /* prettier-ignore */
      padding-right: 0 !important;
  }
  .styles_lia-g-pb-lg-0__LRolW,
  .styles_lia-g-py-lg-0__wyVJn {
      /* prettier-ignore */
      padding-bottom: 0 !important;
  }
  .styles_lia-g-pl-lg-0__jYMeM,
  .styles_lia-g-px-lg-0__x_lfg {
      /* prettier-ignore */
      padding-left: 0 !important;
  }
  .styles_lia-g-m-lg-5__QU4Go {
      /* prettier-ignore */
      margin: 0.3125rem !important;
  }
  .styles_lia-g-mt-lg-5__kHFLA,
  .styles_lia-g-my-lg-5__zm11c {
      /* prettier-ignore */
      margin-top: 0.3125rem !important;
  }
  .styles_lia-g-mr-lg-5__AuPyW,
  .styles_lia-g-mx-lg-5__RT8HD {
      /* prettier-ignore */
      margin-right: 0.3125rem !important;
  }
  .styles_lia-g-mb-lg-5__9ysId,
  .styles_lia-g-my-lg-5__zm11c {
      /* prettier-ignore */
      margin-bottom: 0.3125rem !important;
  }
  .styles_lia-g-ml-lg-5__8gbT1,
  .styles_lia-g-mx-lg-5__RT8HD {
      /* prettier-ignore */
      margin-left: 0.3125rem !important;
  }
  .styles_lia-g-p-lg-5___QG5k {
      /* prettier-ignore */
      padding: 0.3125rem !important;
  }
  .styles_lia-g-pt-lg-5__XW6b9,
  .styles_lia-g-py-lg-5__vCS4U {
      /* prettier-ignore */
      padding-top: 0.3125rem !important;
  }
  .styles_lia-g-pr-lg-5__NmDAP,
  .styles_lia-g-px-lg-5___bCIX {
      /* prettier-ignore */
      padding-right: 0.3125rem !important;
  }
  .styles_lia-g-pb-lg-5__44rhX,
  .styles_lia-g-py-lg-5__vCS4U {
      /* prettier-ignore */
      padding-bottom: 0.3125rem !important;
  }
  .styles_lia-g-pl-lg-5__N88XY,
  .styles_lia-g-px-lg-5___bCIX {
      /* prettier-ignore */
      padding-left: 0.3125rem !important;
  }
  .styles_lia-g-m-lg-10__MGzDI {
      /* prettier-ignore */
      margin: 0.625rem !important;
  }
  .styles_lia-g-mt-lg-10__s_MU9,
  .styles_lia-g-my-lg-10__vHwja {
      /* prettier-ignore */
      margin-top: 0.625rem !important;
  }
  .styles_lia-g-mr-lg-10__N7_ia,
  .styles_lia-g-mx-lg-10__hdvHW {
      /* prettier-ignore */
      margin-right: 0.625rem !important;
  }
  .styles_lia-g-mb-lg-10__QYojA,
  .styles_lia-g-my-lg-10__vHwja {
      /* prettier-ignore */
      margin-bottom: 0.625rem !important;
  }
  .styles_lia-g-ml-lg-10__DIbqE,
  .styles_lia-g-mx-lg-10__hdvHW {
      /* prettier-ignore */
      margin-left: 0.625rem !important;
  }
  .styles_lia-g-p-lg-10__r8fyH {
      /* prettier-ignore */
      padding: 0.625rem !important;
  }
  .styles_lia-g-pt-lg-10__LvfAu,
  .styles_lia-g-py-lg-10__ogJRF {
      /* prettier-ignore */
      padding-top: 0.625rem !important;
  }
  .styles_lia-g-pr-lg-10__B9luZ,
  .styles_lia-g-px-lg-10__uCbzZ {
      /* prettier-ignore */
      padding-right: 0.625rem !important;
  }
  .styles_lia-g-pb-lg-10__Kw4wR,
  .styles_lia-g-py-lg-10__ogJRF {
      /* prettier-ignore */
      padding-bottom: 0.625rem !important;
  }
  .styles_lia-g-pl-lg-10__droxQ,
  .styles_lia-g-px-lg-10__uCbzZ {
      /* prettier-ignore */
      padding-left: 0.625rem !important;
  }
  .styles_lia-g-m-lg-15__ofi_0 {
      /* prettier-ignore */
      margin: 0.9375rem !important;
  }
  .styles_lia-g-mt-lg-15__Rgv9C,
  .styles_lia-g-my-lg-15__Qwme6 {
      /* prettier-ignore */
      margin-top: 0.9375rem !important;
  }
  .styles_lia-g-mr-lg-15__foQP_,
  .styles_lia-g-mx-lg-15__Pybr7 {
      /* prettier-ignore */
      margin-right: 0.9375rem !important;
  }
  .styles_lia-g-mb-lg-15__n_yhC,
  .styles_lia-g-my-lg-15__Qwme6 {
      /* prettier-ignore */
      margin-bottom: 0.9375rem !important;
  }
  .styles_lia-g-ml-lg-15__h3XuN,
  .styles_lia-g-mx-lg-15__Pybr7 {
      /* prettier-ignore */
      margin-left: 0.9375rem !important;
  }
  .styles_lia-g-p-lg-15__Hqtly {
      /* prettier-ignore */
      padding: 0.9375rem !important;
  }
  .styles_lia-g-pt-lg-15__nLOZH,
  .styles_lia-g-py-lg-15__hFf2X {
      /* prettier-ignore */
      padding-top: 0.9375rem !important;
  }
  .styles_lia-g-pr-lg-15__x5z4r,
  .styles_lia-g-px-lg-15__njKP1 {
      /* prettier-ignore */
      padding-right: 0.9375rem !important;
  }
  .styles_lia-g-pb-lg-15__8tGan,
  .styles_lia-g-py-lg-15__hFf2X {
      /* prettier-ignore */
      padding-bottom: 0.9375rem !important;
  }
  .styles_lia-g-pl-lg-15__hAj0R,
  .styles_lia-g-px-lg-15__njKP1 {
      /* prettier-ignore */
      padding-left: 0.9375rem !important;
  }
  .styles_lia-g-m-lg-20__LkyG6 {
      /* prettier-ignore */
      margin: 1.25rem !important;
  }
  .styles_lia-g-mt-lg-20__i4vpu,
  .styles_lia-g-my-lg-20__hKgIZ {
      /* prettier-ignore */
      margin-top: 1.25rem !important;
  }
  .styles_lia-g-mr-lg-20__ORfIl,
  .styles_lia-g-mx-lg-20__VOS43 {
      /* prettier-ignore */
      margin-right: 1.25rem !important;
  }
  .styles_lia-g-mb-lg-20__aJLkj,
  .styles_lia-g-my-lg-20__hKgIZ {
      /* prettier-ignore */
      margin-bottom: 1.25rem !important;
  }
  .styles_lia-g-ml-lg-20__X6alQ,
  .styles_lia-g-mx-lg-20__VOS43 {
      /* prettier-ignore */
      margin-left: 1.25rem !important;
  }
  .styles_lia-g-p-lg-20__3w8_7 {
      /* prettier-ignore */
      padding: 1.25rem !important;
  }
  .styles_lia-g-pt-lg-20__pG_vA,
  .styles_lia-g-py-lg-20__LvsxZ {
      /* prettier-ignore */
      padding-top: 1.25rem !important;
  }
  .styles_lia-g-pr-lg-20__SXcR_,
  .styles_lia-g-px-lg-20__O2Y6B {
      /* prettier-ignore */
      padding-right: 1.25rem !important;
  }
  .styles_lia-g-pb-lg-20__dUBWy,
  .styles_lia-g-py-lg-20__LvsxZ {
      /* prettier-ignore */
      padding-bottom: 1.25rem !important;
  }
  .styles_lia-g-pl-lg-20__0dY9V,
  .styles_lia-g-px-lg-20__O2Y6B {
      /* prettier-ignore */
      padding-left: 1.25rem !important;
  }
  .styles_lia-g-m-lg-25__5LF9C {
      /* prettier-ignore */
      margin: 1.5625rem !important;
  }
  .styles_lia-g-mt-lg-25__VX1L_,
  .styles_lia-g-my-lg-25__mdJtF {
      /* prettier-ignore */
      margin-top: 1.5625rem !important;
  }
  .styles_lia-g-mr-lg-25__0oa9O,
  .styles_lia-g-mx-lg-25__bT3eq {
      /* prettier-ignore */
      margin-right: 1.5625rem !important;
  }
  .styles_lia-g-mb-lg-25__6M1RT,
  .styles_lia-g-my-lg-25__mdJtF {
      /* prettier-ignore */
      margin-bottom: 1.5625rem !important;
  }
  .styles_lia-g-ml-lg-25__mKxGn,
  .styles_lia-g-mx-lg-25__bT3eq {
      /* prettier-ignore */
      margin-left: 1.5625rem !important;
  }
  .styles_lia-g-p-lg-25__vc2tz {
      /* prettier-ignore */
      padding: 1.5625rem !important;
  }
  .styles_lia-g-pt-lg-25__AttDT,
  .styles_lia-g-py-lg-25__hNMQ8 {
      /* prettier-ignore */
      padding-top: 1.5625rem !important;
  }
  .styles_lia-g-pr-lg-25__iKHWl,
  .styles_lia-g-px-lg-25__2B5TC {
      /* prettier-ignore */
      padding-right: 1.5625rem !important;
  }
  .styles_lia-g-pb-lg-25__mdOgx,
  .styles_lia-g-py-lg-25__hNMQ8 {
      /* prettier-ignore */
      padding-bottom: 1.5625rem !important;
  }
  .styles_lia-g-pl-lg-25__g77D7,
  .styles_lia-g-px-lg-25__2B5TC {
      /* prettier-ignore */
      padding-left: 1.5625rem !important;
  }
  .styles_lia-g-m-lg-30__LznGe {
      /* prettier-ignore */
      margin: 1.875rem !important;
  }
  .styles_lia-g-mt-lg-30__UPM2t,
  .styles_lia-g-my-lg-30__W83KL {
      /* prettier-ignore */
      margin-top: 1.875rem !important;
  }
  .styles_lia-g-mr-lg-30__2ZXEE,
  .styles_lia-g-mx-lg-30__JlmVT {
      /* prettier-ignore */
      margin-right: 1.875rem !important;
  }
  .styles_lia-g-mb-lg-30__XDI0_,
  .styles_lia-g-my-lg-30__W83KL {
      /* prettier-ignore */
      margin-bottom: 1.875rem !important;
  }
  .styles_lia-g-ml-lg-30__Awkwv,
  .styles_lia-g-mx-lg-30__JlmVT {
      /* prettier-ignore */
      margin-left: 1.875rem !important;
  }
  .styles_lia-g-p-lg-30__1AgXj {
      /* prettier-ignore */
      padding: 1.875rem !important;
  }
  .styles_lia-g-pt-lg-30__8SO8W,
  .styles_lia-g-py-lg-30__SguH0 {
      /* prettier-ignore */
      padding-top: 1.875rem !important;
  }
  .styles_lia-g-pr-lg-30__gIo0L,
  .styles_lia-g-px-lg-30__oNnQX {
      /* prettier-ignore */
      padding-right: 1.875rem !important;
  }
  .styles_lia-g-pb-lg-30__EPC2v,
  .styles_lia-g-py-lg-30__SguH0 {
      /* prettier-ignore */
      padding-bottom: 1.875rem !important;
  }
  .styles_lia-g-pl-lg-30__XefiM,
  .styles_lia-g-px-lg-30__oNnQX {
      /* prettier-ignore */
      padding-left: 1.875rem !important;
  }
  .styles_lia-g-m-lg-35__vDQAB {
      /* prettier-ignore */
      margin: 2.1875rem !important;
  }
  .styles_lia-g-mt-lg-35__xODcj,
  .styles_lia-g-my-lg-35__CcREq {
      /* prettier-ignore */
      margin-top: 2.1875rem !important;
  }
  .styles_lia-g-mr-lg-35__UTLBJ,
  .styles_lia-g-mx-lg-35__31k8o {
      /* prettier-ignore */
      margin-right: 2.1875rem !important;
  }
  .styles_lia-g-mb-lg-35__bnU15,
  .styles_lia-g-my-lg-35__CcREq {
      /* prettier-ignore */
      margin-bottom: 2.1875rem !important;
  }
  .styles_lia-g-ml-lg-35__zbnJy,
  .styles_lia-g-mx-lg-35__31k8o {
      /* prettier-ignore */
      margin-left: 2.1875rem !important;
  }
  .styles_lia-g-p-lg-35__YYmi8 {
      /* prettier-ignore */
      padding: 2.1875rem !important;
  }
  .styles_lia-g-pt-lg-35__L4b__,
  .styles_lia-g-py-lg-35__rleh2 {
      /* prettier-ignore */
      padding-top: 2.1875rem !important;
  }
  .styles_lia-g-pr-lg-35__IIh5W,
  .styles_lia-g-px-lg-35__zUNj_ {
      /* prettier-ignore */
      padding-right: 2.1875rem !important;
  }
  .styles_lia-g-pb-lg-35__YMZIs,
  .styles_lia-g-py-lg-35__rleh2 {
      /* prettier-ignore */
      padding-bottom: 2.1875rem !important;
  }
  .styles_lia-g-pl-lg-35__uDSe7,
  .styles_lia-g-px-lg-35__zUNj_ {
      /* prettier-ignore */
      padding-left: 2.1875rem !important;
  }
  .styles_lia-g-m-lg-40__2e6D7 {
      /* prettier-ignore */
      margin: 2.5rem !important;
  }
  .styles_lia-g-mt-lg-40__Oya56,
  .styles_lia-g-my-lg-40__4QmX_ {
      /* prettier-ignore */
      margin-top: 2.5rem !important;
  }
  .styles_lia-g-mr-lg-40__nsDmj,
  .styles_lia-g-mx-lg-40__V2Kaj {
      /* prettier-ignore */
      margin-right: 2.5rem !important;
  }
  .styles_lia-g-mb-lg-40__VqTPC,
  .styles_lia-g-my-lg-40__4QmX_ {
      /* prettier-ignore */
      margin-bottom: 2.5rem !important;
  }
  .styles_lia-g-ml-lg-40__shnX4,
  .styles_lia-g-mx-lg-40__V2Kaj {
      /* prettier-ignore */
      margin-left: 2.5rem !important;
  }
  .styles_lia-g-p-lg-40__x5wzJ {
      /* prettier-ignore */
      padding: 2.5rem !important;
  }
  .styles_lia-g-pt-lg-40__SbFW0,
  .styles_lia-g-py-lg-40__oHWZ8 {
      /* prettier-ignore */
      padding-top: 2.5rem !important;
  }
  .styles_lia-g-pr-lg-40__xkOFX,
  .styles_lia-g-px-lg-40__5gsnW {
      /* prettier-ignore */
      padding-right: 2.5rem !important;
  }
  .styles_lia-g-pb-lg-40__8nGIo,
  .styles_lia-g-py-lg-40__oHWZ8 {
      /* prettier-ignore */
      padding-bottom: 2.5rem !important;
  }
  .styles_lia-g-pl-lg-40__b7Jes,
  .styles_lia-g-px-lg-40__5gsnW {
      /* prettier-ignore */
      padding-left: 2.5rem !important;
  }
  .styles_lia-g-m-lg-50___PYiC {
      /* prettier-ignore */
      margin: 3.125rem !important;
  }
  .styles_lia-g-mt-lg-50__P7d_X,
  .styles_lia-g-my-lg-50__QNh5W {
      /* prettier-ignore */
      margin-top: 3.125rem !important;
  }
  .styles_lia-g-mr-lg-50__5773l,
  .styles_lia-g-mx-lg-50__TgaDa {
      /* prettier-ignore */
      margin-right: 3.125rem !important;
  }
  .styles_lia-g-mb-lg-50__e16gk,
  .styles_lia-g-my-lg-50__QNh5W {
      /* prettier-ignore */
      margin-bottom: 3.125rem !important;
  }
  .styles_lia-g-ml-lg-50__3CTW1,
  .styles_lia-g-mx-lg-50__TgaDa {
      /* prettier-ignore */
      margin-left: 3.125rem !important;
  }
  .styles_lia-g-p-lg-50__v_noq {
      /* prettier-ignore */
      padding: 3.125rem !important;
  }
  .styles_lia-g-pt-lg-50__yI_pq,
  .styles_lia-g-py-lg-50__6tdsp {
      /* prettier-ignore */
      padding-top: 3.125rem !important;
  }
  .styles_lia-g-pr-lg-50__VxRhn,
  .styles_lia-g-px-lg-50__JbXTx {
      /* prettier-ignore */
      padding-right: 3.125rem !important;
  }
  .styles_lia-g-pb-lg-50___g5YZ,
  .styles_lia-g-py-lg-50__6tdsp {
      /* prettier-ignore */
      padding-bottom: 3.125rem !important;
  }
  .styles_lia-g-pl-lg-50__2Wp_U,
  .styles_lia-g-px-lg-50__JbXTx {
      /* prettier-ignore */
      padding-left: 3.125rem !important;
  }
  .styles_lia-g-m-lg-100__Hgl87 {
      /* prettier-ignore */
      margin: 6.25rem !important;
  }
  .styles_lia-g-mt-lg-100__ENbLm,
  .styles_lia-g-my-lg-100__t_DY_ {
      /* prettier-ignore */
      margin-top: 6.25rem !important;
  }
  .styles_lia-g-mr-lg-100__aOoMi,
  .styles_lia-g-mx-lg-100__d8lu0 {
      /* prettier-ignore */
      margin-right: 6.25rem !important;
  }
  .styles_lia-g-mb-lg-100__oq4pP,
  .styles_lia-g-my-lg-100__t_DY_ {
      /* prettier-ignore */
      margin-bottom: 6.25rem !important;
  }
  .styles_lia-g-ml-lg-100__LBvEZ,
  .styles_lia-g-mx-lg-100__d8lu0 {
      /* prettier-ignore */
      margin-left: 6.25rem !important;
  }
  .styles_lia-g-p-lg-100__QD4o2 {
      /* prettier-ignore */
      padding: 6.25rem !important;
  }
  .styles_lia-g-pt-lg-100__fiLJb,
  .styles_lia-g-py-lg-100__Lp0OD {
      /* prettier-ignore */
      padding-top: 6.25rem !important;
  }
  .styles_lia-g-pr-lg-100__7qqwX,
  .styles_lia-g-px-lg-100__CVUEb {
      /* prettier-ignore */
      padding-right: 6.25rem !important;
  }
  .styles_lia-g-pb-lg-100__5ckOJ,
  .styles_lia-g-py-lg-100__Lp0OD {
      /* prettier-ignore */
      padding-bottom: 6.25rem !important;
  }
  .styles_lia-g-pl-lg-100__jg9vN,
  .styles_lia-g-px-lg-100__CVUEb {
      /* prettier-ignore */
      padding-left: 6.25rem !important;
  }
    }

@media (min-width: 1260px) {
  .styles_lia-g-m-xl-0__13GsU {
      /* prettier-ignore */
      margin: 0 !important;
  }
  .styles_lia-g-mt-xl-0__XoXmE,
  .styles_lia-g-my-xl-0___O09w {
      /* prettier-ignore */
      margin-top: 0 !important;
  }
  .styles_lia-g-mr-xl-0__XqUvi,
  .styles_lia-g-mx-xl-0__y5K_p {
      /* prettier-ignore */
      margin-right: 0 !important;
  }
  .styles_lia-g-mb-xl-0__Ex93v,
  .styles_lia-g-my-xl-0___O09w {
      /* prettier-ignore */
      margin-bottom: 0 !important;
  }
  .styles_lia-g-ml-xl-0__3jgaL,
  .styles_lia-g-mx-xl-0__y5K_p {
      /* prettier-ignore */
      margin-left: 0 !important;
  }
  .styles_lia-g-p-xl-0__Xn_hR {
      /* prettier-ignore */
      padding: 0 !important;
  }
  .styles_lia-g-pt-xl-0___brOx,
  .styles_lia-g-py-xl-0__8hn_v {
      /* prettier-ignore */
      padding-top: 0 !important;
  }
  .styles_lia-g-pr-xl-0__fWy30,
  .styles_lia-g-px-xl-0__GHyxs {
      /* prettier-ignore */
      padding-right: 0 !important;
  }
  .styles_lia-g-pb-xl-0__pIky4,
  .styles_lia-g-py-xl-0__8hn_v {
      /* prettier-ignore */
      padding-bottom: 0 !important;
  }
  .styles_lia-g-pl-xl-0__yz8Lg,
  .styles_lia-g-px-xl-0__GHyxs {
      /* prettier-ignore */
      padding-left: 0 !important;
  }
  .styles_lia-g-m-xl-5__V6EXR {
      /* prettier-ignore */
      margin: 0.3125rem !important;
  }
  .styles_lia-g-mt-xl-5__3xZWs,
  .styles_lia-g-my-xl-5__nSuz_ {
      /* prettier-ignore */
      margin-top: 0.3125rem !important;
  }
  .styles_lia-g-mr-xl-5__uVZZx,
  .styles_lia-g-mx-xl-5__RyrkH {
      /* prettier-ignore */
      margin-right: 0.3125rem !important;
  }
  .styles_lia-g-mb-xl-5__f4NIv,
  .styles_lia-g-my-xl-5__nSuz_ {
      /* prettier-ignore */
      margin-bottom: 0.3125rem !important;
  }
  .styles_lia-g-ml-xl-5__sR_Jm,
  .styles_lia-g-mx-xl-5__RyrkH {
      /* prettier-ignore */
      margin-left: 0.3125rem !important;
  }
  .styles_lia-g-p-xl-5__FAp5o {
      /* prettier-ignore */
      padding: 0.3125rem !important;
  }
  .styles_lia-g-pt-xl-5__oYbPM,
  .styles_lia-g-py-xl-5__0G6ET {
      /* prettier-ignore */
      padding-top: 0.3125rem !important;
  }
  .styles_lia-g-pr-xl-5__hJJKg,
  .styles_lia-g-px-xl-5__ufZc6 {
      /* prettier-ignore */
      padding-right: 0.3125rem !important;
  }
  .styles_lia-g-pb-xl-5__pa9ao,
  .styles_lia-g-py-xl-5__0G6ET {
      /* prettier-ignore */
      padding-bottom: 0.3125rem !important;
  }
  .styles_lia-g-pl-xl-5__36by3,
  .styles_lia-g-px-xl-5__ufZc6 {
      /* prettier-ignore */
      padding-left: 0.3125rem !important;
  }
  .styles_lia-g-m-xl-10__aEjQk {
      /* prettier-ignore */
      margin: 0.625rem !important;
  }
  .styles_lia-g-mt-xl-10__0s6Tu,
  .styles_lia-g-my-xl-10__Xygzl {
      /* prettier-ignore */
      margin-top: 0.625rem !important;
  }
  .styles_lia-g-mr-xl-10__xrhco,
  .styles_lia-g-mx-xl-10__pmNrB {
      /* prettier-ignore */
      margin-right: 0.625rem !important;
  }
  .styles_lia-g-mb-xl-10__74g0R,
  .styles_lia-g-my-xl-10__Xygzl {
      /* prettier-ignore */
      margin-bottom: 0.625rem !important;
  }
  .styles_lia-g-ml-xl-10__9lKCc,
  .styles_lia-g-mx-xl-10__pmNrB {
      /* prettier-ignore */
      margin-left: 0.625rem !important;
  }
  .styles_lia-g-p-xl-10___NTaJ {
      /* prettier-ignore */
      padding: 0.625rem !important;
  }
  .styles_lia-g-pt-xl-10__3cQP9,
  .styles_lia-g-py-xl-10__51UsY {
      /* prettier-ignore */
      padding-top: 0.625rem !important;
  }
  .styles_lia-g-pr-xl-10__3GrF5,
  .styles_lia-g-px-xl-10__Mwv7z {
      /* prettier-ignore */
      padding-right: 0.625rem !important;
  }
  .styles_lia-g-pb-xl-10__SbJu4,
  .styles_lia-g-py-xl-10__51UsY {
      /* prettier-ignore */
      padding-bottom: 0.625rem !important;
  }
  .styles_lia-g-pl-xl-10__pF_W_,
  .styles_lia-g-px-xl-10__Mwv7z {
      /* prettier-ignore */
      padding-left: 0.625rem !important;
  }
  .styles_lia-g-m-xl-15__Vp1NA {
      /* prettier-ignore */
      margin: 0.9375rem !important;
  }
  .styles_lia-g-mt-xl-15__z04FB,
  .styles_lia-g-my-xl-15__vtdER {
      /* prettier-ignore */
      margin-top: 0.9375rem !important;
  }
  .styles_lia-g-mr-xl-15__3t1Nw,
  .styles_lia-g-mx-xl-15__XVn9h {
      /* prettier-ignore */
      margin-right: 0.9375rem !important;
  }
  .styles_lia-g-mb-xl-15__dWCNv,
  .styles_lia-g-my-xl-15__vtdER {
      /* prettier-ignore */
      margin-bottom: 0.9375rem !important;
  }
  .styles_lia-g-ml-xl-15__RAOam,
  .styles_lia-g-mx-xl-15__XVn9h {
      /* prettier-ignore */
      margin-left: 0.9375rem !important;
  }
  .styles_lia-g-p-xl-15__ij675 {
      /* prettier-ignore */
      padding: 0.9375rem !important;
  }
  .styles_lia-g-pt-xl-15__5kP4h,
  .styles_lia-g-py-xl-15__y5rw4 {
      /* prettier-ignore */
      padding-top: 0.9375rem !important;
  }
  .styles_lia-g-pr-xl-15__G9rym,
  .styles_lia-g-px-xl-15__iIxFh {
      /* prettier-ignore */
      padding-right: 0.9375rem !important;
  }
  .styles_lia-g-pb-xl-15__4y0qo,
  .styles_lia-g-py-xl-15__y5rw4 {
      /* prettier-ignore */
      padding-bottom: 0.9375rem !important;
  }
  .styles_lia-g-pl-xl-15__MF6Al,
  .styles_lia-g-px-xl-15__iIxFh {
      /* prettier-ignore */
      padding-left: 0.9375rem !important;
  }
  .styles_lia-g-m-xl-20__8qT4X {
      /* prettier-ignore */
      margin: 1.25rem !important;
  }
  .styles_lia-g-mt-xl-20__B78_y,
  .styles_lia-g-my-xl-20__Qa3yY {
      /* prettier-ignore */
      margin-top: 1.25rem !important;
  }
  .styles_lia-g-mr-xl-20__6hEt9,
  .styles_lia-g-mx-xl-20__QxHhj {
      /* prettier-ignore */
      margin-right: 1.25rem !important;
  }
  .styles_lia-g-mb-xl-20__GXX_Q,
  .styles_lia-g-my-xl-20__Qa3yY {
      /* prettier-ignore */
      margin-bottom: 1.25rem !important;
  }
  .styles_lia-g-ml-xl-20__HpQSR,
  .styles_lia-g-mx-xl-20__QxHhj {
      /* prettier-ignore */
      margin-left: 1.25rem !important;
  }
  .styles_lia-g-p-xl-20__maJya {
      /* prettier-ignore */
      padding: 1.25rem !important;
  }
  .styles_lia-g-pt-xl-20__3hoiE,
  .styles_lia-g-py-xl-20__Yfjll {
      /* prettier-ignore */
      padding-top: 1.25rem !important;
  }
  .styles_lia-g-pr-xl-20__0fYGb,
  .styles_lia-g-px-xl-20__AMFnK {
      /* prettier-ignore */
      padding-right: 1.25rem !important;
  }
  .styles_lia-g-pb-xl-20__0TGje,
  .styles_lia-g-py-xl-20__Yfjll {
      /* prettier-ignore */
      padding-bottom: 1.25rem !important;
  }
  .styles_lia-g-pl-xl-20__Cjumq,
  .styles_lia-g-px-xl-20__AMFnK {
      /* prettier-ignore */
      padding-left: 1.25rem !important;
  }
  .styles_lia-g-m-xl-25__c6BCi {
      /* prettier-ignore */
      margin: 1.5625rem !important;
  }
  .styles_lia-g-mt-xl-25__1ePU9,
  .styles_lia-g-my-xl-25__ZRuw8 {
      /* prettier-ignore */
      margin-top: 1.5625rem !important;
  }
  .styles_lia-g-mr-xl-25__tpIpZ,
  .styles_lia-g-mx-xl-25__nSZwU {
      /* prettier-ignore */
      margin-right: 1.5625rem !important;
  }
  .styles_lia-g-mb-xl-25__gm1GK,
  .styles_lia-g-my-xl-25__ZRuw8 {
      /* prettier-ignore */
      margin-bottom: 1.5625rem !important;
  }
  .styles_lia-g-ml-xl-25__Y7DTB,
  .styles_lia-g-mx-xl-25__nSZwU {
      /* prettier-ignore */
      margin-left: 1.5625rem !important;
  }
  .styles_lia-g-p-xl-25__AxKhQ {
      /* prettier-ignore */
      padding: 1.5625rem !important;
  }
  .styles_lia-g-pt-xl-25__Jehtc,
  .styles_lia-g-py-xl-25__oXFHQ {
      /* prettier-ignore */
      padding-top: 1.5625rem !important;
  }
  .styles_lia-g-pr-xl-25__28C9x,
  .styles_lia-g-px-xl-25__KjHPF {
      /* prettier-ignore */
      padding-right: 1.5625rem !important;
  }
  .styles_lia-g-pb-xl-25__HgcHh,
  .styles_lia-g-py-xl-25__oXFHQ {
      /* prettier-ignore */
      padding-bottom: 1.5625rem !important;
  }
  .styles_lia-g-pl-xl-25__ZgnHa,
  .styles_lia-g-px-xl-25__KjHPF {
      /* prettier-ignore */
      padding-left: 1.5625rem !important;
  }
  .styles_lia-g-m-xl-30__EDDN2 {
      /* prettier-ignore */
      margin: 1.875rem !important;
  }
  .styles_lia-g-mt-xl-30__nm1i7,
  .styles_lia-g-my-xl-30__UlZjc {
      /* prettier-ignore */
      margin-top: 1.875rem !important;
  }
  .styles_lia-g-mr-xl-30__7mDcc,
  .styles_lia-g-mx-xl-30__fmlj4 {
      /* prettier-ignore */
      margin-right: 1.875rem !important;
  }
  .styles_lia-g-mb-xl-30__IPMuS,
  .styles_lia-g-my-xl-30__UlZjc {
      /* prettier-ignore */
      margin-bottom: 1.875rem !important;
  }
  .styles_lia-g-ml-xl-30__2jdaB,
  .styles_lia-g-mx-xl-30__fmlj4 {
      /* prettier-ignore */
      margin-left: 1.875rem !important;
  }
  .styles_lia-g-p-xl-30__DtsAp {
      /* prettier-ignore */
      padding: 1.875rem !important;
  }
  .styles_lia-g-pt-xl-30__SXG_Q,
  .styles_lia-g-py-xl-30__P5pGQ {
      /* prettier-ignore */
      padding-top: 1.875rem !important;
  }
  .styles_lia-g-pr-xl-30__KciJd,
  .styles_lia-g-px-xl-30__jGbTX {
      /* prettier-ignore */
      padding-right: 1.875rem !important;
  }
  .styles_lia-g-pb-xl-30__SWUr0,
  .styles_lia-g-py-xl-30__P5pGQ {
      /* prettier-ignore */
      padding-bottom: 1.875rem !important;
  }
  .styles_lia-g-pl-xl-30__db74i,
  .styles_lia-g-px-xl-30__jGbTX {
      /* prettier-ignore */
      padding-left: 1.875rem !important;
  }
  .styles_lia-g-m-xl-35__nKMUP {
      /* prettier-ignore */
      margin: 2.1875rem !important;
  }
  .styles_lia-g-mt-xl-35__HMlJb,
  .styles_lia-g-my-xl-35__ZLCde {
      /* prettier-ignore */
      margin-top: 2.1875rem !important;
  }
  .styles_lia-g-mr-xl-35__FOcwq,
  .styles_lia-g-mx-xl-35___31Pw {
      /* prettier-ignore */
      margin-right: 2.1875rem !important;
  }
  .styles_lia-g-mb-xl-35__Gi6EO,
  .styles_lia-g-my-xl-35__ZLCde {
      /* prettier-ignore */
      margin-bottom: 2.1875rem !important;
  }
  .styles_lia-g-ml-xl-35__pkV_Y,
  .styles_lia-g-mx-xl-35___31Pw {
      /* prettier-ignore */
      margin-left: 2.1875rem !important;
  }
  .styles_lia-g-p-xl-35__W0x4X {
      /* prettier-ignore */
      padding: 2.1875rem !important;
  }
  .styles_lia-g-pt-xl-35__Bwz_H,
  .styles_lia-g-py-xl-35__U5KFX {
      /* prettier-ignore */
      padding-top: 2.1875rem !important;
  }
  .styles_lia-g-pr-xl-35__dCngP,
  .styles_lia-g-px-xl-35__aGrH7 {
      /* prettier-ignore */
      padding-right: 2.1875rem !important;
  }
  .styles_lia-g-pb-xl-35__HdvpN,
  .styles_lia-g-py-xl-35__U5KFX {
      /* prettier-ignore */
      padding-bottom: 2.1875rem !important;
  }
  .styles_lia-g-pl-xl-35__VerS_,
  .styles_lia-g-px-xl-35__aGrH7 {
      /* prettier-ignore */
      padding-left: 2.1875rem !important;
  }
  .styles_lia-g-m-xl-40__SZ_ax {
      /* prettier-ignore */
      margin: 2.5rem !important;
  }
  .styles_lia-g-mt-xl-40__ftiqi,
  .styles_lia-g-my-xl-40__TW___ {
      /* prettier-ignore */
      margin-top: 2.5rem !important;
  }
  .styles_lia-g-mr-xl-40__SDUQS,
  .styles_lia-g-mx-xl-40__1R1Ad {
      /* prettier-ignore */
      margin-right: 2.5rem !important;
  }
  .styles_lia-g-mb-xl-40__sC6xT,
  .styles_lia-g-my-xl-40__TW___ {
      /* prettier-ignore */
      margin-bottom: 2.5rem !important;
  }
  .styles_lia-g-ml-xl-40__5HeqT,
  .styles_lia-g-mx-xl-40__1R1Ad {
      /* prettier-ignore */
      margin-left: 2.5rem !important;
  }
  .styles_lia-g-p-xl-40__x_plq {
      /* prettier-ignore */
      padding: 2.5rem !important;
  }
  .styles_lia-g-pt-xl-40__QTEgk,
  .styles_lia-g-py-xl-40__teSio {
      /* prettier-ignore */
      padding-top: 2.5rem !important;
  }
  .styles_lia-g-pr-xl-40__V4A57,
  .styles_lia-g-px-xl-40__nOtip {
      /* prettier-ignore */
      padding-right: 2.5rem !important;
  }
  .styles_lia-g-pb-xl-40__Kv8id,
  .styles_lia-g-py-xl-40__teSio {
      /* prettier-ignore */
      padding-bottom: 2.5rem !important;
  }
  .styles_lia-g-pl-xl-40__awjNC,
  .styles_lia-g-px-xl-40__nOtip {
      /* prettier-ignore */
      padding-left: 2.5rem !important;
  }
  .styles_lia-g-m-xl-50__myMdn {
      /* prettier-ignore */
      margin: 3.125rem !important;
  }
  .styles_lia-g-mt-xl-50___hu6t,
  .styles_lia-g-my-xl-50__w_baB {
      /* prettier-ignore */
      margin-top: 3.125rem !important;
  }
  .styles_lia-g-mr-xl-50__TR0w9,
  .styles_lia-g-mx-xl-50__om8x8 {
      /* prettier-ignore */
      margin-right: 3.125rem !important;
  }
  .styles_lia-g-mb-xl-50__Nf3r9,
  .styles_lia-g-my-xl-50__w_baB {
      /* prettier-ignore */
      margin-bottom: 3.125rem !important;
  }
  .styles_lia-g-ml-xl-50__9NNF2,
  .styles_lia-g-mx-xl-50__om8x8 {
      /* prettier-ignore */
      margin-left: 3.125rem !important;
  }
  .styles_lia-g-p-xl-50__usQWr {
      /* prettier-ignore */
      padding: 3.125rem !important;
  }
  .styles_lia-g-pt-xl-50__rKIXu,
  .styles_lia-g-py-xl-50__cpnCg {
      /* prettier-ignore */
      padding-top: 3.125rem !important;
  }
  .styles_lia-g-pr-xl-50__bwZ5V,
  .styles_lia-g-px-xl-50__e4HAh {
      /* prettier-ignore */
      padding-right: 3.125rem !important;
  }
  .styles_lia-g-pb-xl-50__cp3xG,
  .styles_lia-g-py-xl-50__cpnCg {
      /* prettier-ignore */
      padding-bottom: 3.125rem !important;
  }
  .styles_lia-g-pl-xl-50__Yo80o,
  .styles_lia-g-px-xl-50__e4HAh {
      /* prettier-ignore */
      padding-left: 3.125rem !important;
  }
  .styles_lia-g-m-xl-100__QR00m {
      /* prettier-ignore */
      margin: 6.25rem !important;
  }
  .styles_lia-g-mt-xl-100__PNZdO,
  .styles_lia-g-my-xl-100___Hqs2 {
      /* prettier-ignore */
      margin-top: 6.25rem !important;
  }
  .styles_lia-g-mr-xl-100__hxE4f,
  .styles_lia-g-mx-xl-100__N8UOj {
      /* prettier-ignore */
      margin-right: 6.25rem !important;
  }
  .styles_lia-g-mb-xl-100__5E5kB,
  .styles_lia-g-my-xl-100___Hqs2 {
      /* prettier-ignore */
      margin-bottom: 6.25rem !important;
  }
  .styles_lia-g-ml-xl-100__Czgs_,
  .styles_lia-g-mx-xl-100__N8UOj {
      /* prettier-ignore */
      margin-left: 6.25rem !important;
  }
  .styles_lia-g-p-xl-100__9DUM9 {
      /* prettier-ignore */
      padding: 6.25rem !important;
  }
  .styles_lia-g-pt-xl-100__DsnpL,
  .styles_lia-g-py-xl-100__vCw44 {
      /* prettier-ignore */
      padding-top: 6.25rem !important;
  }
  .styles_lia-g-pr-xl-100__zH9gF,
  .styles_lia-g-px-xl-100__DiNBH {
      /* prettier-ignore */
      padding-right: 6.25rem !important;
  }
  .styles_lia-g-pb-xl-100__HjgkZ,
  .styles_lia-g-py-xl-100__vCw44 {
      /* prettier-ignore */
      padding-bottom: 6.25rem !important;
  }
  .styles_lia-g-pl-xl-100__7d5ve,
  .styles_lia-g-px-xl-100__DiNBH {
      /* prettier-ignore */
      padding-left: 6.25rem !important;
  }
    }

.styles_lia-g-gap-5__j26XD {
    /* prettier-ignore */
    gap: 0.3125rem !important;
  }

.styles_lia-g-column-gap-5__chL1y {
    /* prettier-ignore */
    -moz-column-gap: 0.3125rem !important;
         column-gap: 0.3125rem !important;
  }

.styles_lia-g-row-gap-5__PTQQ8 {
    /* prettier-ignore */
    row-gap: 0.3125rem !important;
  }

.styles_lia-g-gap-10__LZ1Q9 {
    /* prettier-ignore */
    gap: 0.625rem !important;
  }

.styles_lia-g-column-gap-10__9ZIV1 {
    /* prettier-ignore */
    -moz-column-gap: 0.625rem !important;
         column-gap: 0.625rem !important;
  }

.styles_lia-g-row-gap-10__3jQm5 {
    /* prettier-ignore */
    row-gap: 0.625rem !important;
  }

.styles_lia-g-gap-15__9MgIz {
    /* prettier-ignore */
    gap: 0.9375rem !important;
  }

.styles_lia-g-column-gap-15__i5qPW {
    /* prettier-ignore */
    -moz-column-gap: 0.9375rem !important;
         column-gap: 0.9375rem !important;
  }

.styles_lia-g-row-gap-15__BovgT {
    /* prettier-ignore */
    row-gap: 0.9375rem !important;
  }

.styles_lia-g-gap-20__9OiUe {
    /* prettier-ignore */
    gap: 1.25rem !important;
  }

.styles_lia-g-column-gap-20__IrtCS {
    /* prettier-ignore */
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

.styles_lia-g-row-gap-20__SenyS {
    /* prettier-ignore */
    row-gap: 1.25rem !important;
  }

.styles_lia-g-gap-25__ReMJV {
    /* prettier-ignore */
    gap: 1.5625rem !important;
  }

.styles_lia-g-column-gap-25__Nb9N5 {
    /* prettier-ignore */
    -moz-column-gap: 1.5625rem !important;
         column-gap: 1.5625rem !important;
  }

.styles_lia-g-row-gap-25__78uwh {
    /* prettier-ignore */
    row-gap: 1.5625rem !important;
  }

.styles_lia-g-td-snap__n_YHn {
  white-space: nowrap;
}

@media (min-width: 768px) {

.styles_lia-g-td-snap__n_YHn {
    width: 1%
}
  }

.styles_lia-g-tr-hover__fiMt6:hover td a:not(.styles_dropdown-item__s_Yb9),
    .styles_lia-g-tr-hover__fiMt6:hover td button:not(.styles_dropdown-item__s_Yb9) {
      -webkit-text-decoration: var(--lia-bs-link-hover-decoration) !important;
              text-decoration: var(--lia-bs-link-hover-decoration) !important;
    }

.styles_lia-g-tr-hover__fiMt6 td a:not(.styles_dropdown-item__s_Yb9),
    .styles_lia-g-tr-hover__fiMt6 td button:not(.styles_dropdown-item__s_Yb9) {
      color: var(--lia-bs-gray-900) !important;
    }

.styles_lia-g-tr-hover__fiMt6 td a:not(.styles_dropdown-item__s_Yb9):hover, .styles_lia-g-tr-hover__fiMt6 td button:not(.styles_dropdown-item__s_Yb9):hover {
        color: var(--lia-bs-link-hover-color) !important;
        -webkit-text-decoration: var(--lia-bs-link-hover-decoration) !important;
                text-decoration: var(--lia-bs-link-hover-decoration) !important;
      }

.styles_lia-g-table-list__tyjlF {
  border-collapse: separate;
  border-spacing: 0;
  background-color: var(--lia-bs-white);
}

.styles_lia-g-table-list__tyjlF thead th {
    padding: 0.625rem;
    vertical-align: middle;
    border: 0;
    font-size: var(--lia-bs-font-size-sm);
    font-weight: var(--lia-font-weight-md);
    color: var(--lia-bs-headings-font-weight);
  }

.styles_lia-g-table-list__tyjlF tbody td {
    padding: 0.9375rem 0.625rem;
    vertical-align: middle;
    font-size: var(--lia-bs-font-size-sm);
  }

.styles_lia-g-is-number__Uwcn9 {
  font-variant-numeric: tabular-nums;
}

.styles_lia-g-text-lg__y9kWj {
    font-size: var(--lia-font-size-xl);
  }

.styles_lia-g-text-md__zxd_R {
    font-size: var(--lia-bs-font-size-base);
  }

.styles_lia-g-text-sm__VsXOr {
    font-size: var(--lia-bs-font-size-sm);
    letter-spacing: var(--lia-letter-spacing-sm);
  }

.styles_lia-g-text-xs__xMXX5 {
    font-size: var(--lia-font-size-xs);
    letter-spacing: var(--lia-letter-spacing-xs);
  }

.styles_lia-g-text-xxs__y5_zy {
    font-size: var(--lia-font-size-xxs);
  }

.styles_lia-g-text-black__ImyR6 {
    color: var(--lia-bs-black) !important;
  }

.styles_lia-g-text-link__tEAHV {
    color: var(--lia-bs-link-color) !important;
  }

.styles_lia-g-link__vfYWt {
  color: var(--lia-bs-link-color);
  -webkit-text-decoration: var(--lia-bs-link-decoration);
          text-decoration: var(--lia-bs-link-decoration);
}

.styles_lia-g-link__vfYWt:hover {
    color: var(--lia-bs-link-hover-color);
    -webkit-text-decoration: var(--lia-bs-link-hover-decoration);
            text-decoration: var(--lia-bs-link-hover-decoration);
  }

.styles_lia-g-subheading__MYxBV {
  letter-spacing: var(--lia-letter-spacing-subheading);
  font-size: var(--lia-subheader-font-size);
  font-weight: var(--lia-subheader-font-weight);
  text-transform: uppercase;
}

.styles_lia-g-underline__FXlmg {
  text-decoration: underline;
}

.styles_lia-g-code-block__2eRGq {
  background-color: var(--lia-bs-gray-200);
  padding: 1.25rem;
  font-family: var(--font-family-monospace);
  font-size: var(--lia-bs-font-size-sm);
  line-height: 1.6;
  max-width: 100%;
  overflow: auto;
  word-wrap: break-word;
  border: 1px solid var(--lia-bs-border-color);
  border-radius: var(--lia-bs-border-radius-sm);
  margin: 0;
}

.styles_lia-g-body-text__GXFLl {
  font-family: var(--lia-bs-font-family-base);
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
}

.styles_lia-g-font-weight-medium__K_eqF {
  font-weight: var(--lia-font-weight-md);
}

/* Fonts */

@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: url('/static/fonts/Atkinson-Hyperlegible-Font-Print-and-Web-2020-0514/Web Fonts/WOFF/Atkinson-Hyperlegible-Regular-102.woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: url('/static/fonts/Atkinson-Hyperlegible-Font-Print-and-Web-2020-0514/Web Fonts/WOFF/Atkinson-Hyperlegible-Bold-102.woff');
  font-weight: bold;
}

/*
 Unscoped styles are styles that do not have class name scoping and
 will be applied directly to root elements (div, span, h1, etc).
 Most of the unscoped styles are provided by Bootstrap.

 These are separated out in order to work properly with NextJs's
 out-of-the-box support for CSS Modules, which when used in "pure"
 mode do not allow unscoped CSS to be included. To workaround this
 issue, we include these unscoped styles directly in _add.tsx without
 the `.module.` qualifier, which bypasses CSS Modules and exposes these
 styles as global styles.

 We refer to these as "unscoped" as to not collide with the similarly
 named "globals", which are CSS rules that use class names (or ID's),
 and continue to work with CSS Modules.

 https://github.com/zeit/next.js/issues/10142
*/

:root {
  /* Chart Colors Variants */
  --lia-chart-bias-neutral: hsl(0, 0%, 44%);
  --lia-chart-bias-positive: hsl(150, 83%, 42%);
  --lia-chart-bias-negative: hsl(350, 93%, 52%);

  --lia-chart-contrast-teal: hsl(187, 66%, 49%);
  --lia-chart-contrast-pink: hsl(340, 75%, 59%);
  --lia-chart-contrast-yellow: hsl(54, 87%, 65%);
  --lia-chart-contrast-slate: hsl(200, 18%, 46%);
  --lia-chart-contrast-purple: hsl(291, 46%, 44%);
  --lia-chart-contrast-lime: hsl(68, 65%, 43%);
  --lia-chart-contrast-violet: hsl(258, 58%, 63%);
  --lia-chart-contrast-orange: hsl(15, 87%, 57%);
  --lia-chart-contrast-blue: hsl(205, 100%, 50%);

  --lia-chart-shade-blue-1: hsl(205, 100%, 30%);
  --lia-chart-shade-blue-2: hsl(205, 100%, 40%);
  --lia-chart-shade-blue-3: hsl(205, 100%, 50%);
  --lia-chart-shade-blue-4: hsl(205, 100%, 60%);
  --lia-chart-shade-blue-5: hsl(205, 100%, 70%);
  --lia-chart-shade-blue-6: hsl(205, 100%, 80%);

  --lia-chart-shade-orange-1: hsl(14, 77%, 51%);
  --lia-chart-shade-orange-2: hsl(14, 100%, 60%);
  --lia-chart-shade-orange-3: hsl(18, 87%, 73%);
  --lia-chart-shade-orange-4: hsl(17, 100%, 87%);
  --lia-chart-shade-orange-5: hsl(18, 87%, 94%);

  --lia-page-editor-danger: hsla(347, 70%, 54%, 0.6);
  --lia-page-editor-dragging: hsla(231, 58%, 50%, 0.05);
  --lia-page-editor-primary-h: 231;
  --lia-page-editor-primary-s: 58%;
  --lia-page-editor-primary-l: 50%;
  --lia-page-editor-primary: hsl(
    var(--lia-page-editor-primary-h),
    var(--lia-page-editor-primary-s),
    var(--lia-page-editor-primary-l)
  );
}

:root {
  --lia-d-navbar-height: '0';
  --lia-d-navbar-height-sticky: '0';
  --lia-d-header-height: '0';
  --lia-bs-grid-gutter-width: var(--lia-grid-gutter-width-md);
}

@media (max-width: 575.98px) {

:root {
    --lia-bs-grid-gutter-width: var(--lia-grid-gutter-width-xs)
}
  }

html,
body {
  min-height: 100dvh;
}

#__next {
  display: var(--lia-vars-loaded, none);
  flex-direction: column;
  min-height: 100dvh;
}

#__next > main:not([data-main-override]) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

#__next > nav,
  #__next > footer {
    flex-shrink: 0;
  }

html > body {
  margin: var(--lia-body-margin);
  max-width: var(--lia-body-max-width);
}

*:focus-visible:not(button):not(input) {
  outline: var(--lia-outline-color) solid 3px;
  outline-offset: 0;
}

:target {
  scroll-margin-top: var(--lia-d-navbar-height-sticky);
}

/**
 * Hide the Google ReCaptcha badge. Additional information on hiding the ReCaptcha badge:
 *
 * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
 */

.grecaptcha-badge {
  visibility: hidden;
}

/**
  Taken from https://gist.github.com/johanlef/518a511b2b2f6b96c4f429b3af2f169a
 */

/* Gray Scale */

/* Main Colors */

/* Link Color */

/* Body Background */

/* Gutters */

/* Font Family */

/* Font Weight */

/* Line Height */

/* Font Size */

/* Headings */

/* Header Font Size */

/* Lead */

/* Text Muted */

/* Blockquote Font Size */

/* Border */

/* Box Shadows */

/* List Group */

/* Grids Breakpoints */

/* Container Max Widths */

/* Modals */

/* Popover */

/* Inputs */

/* Button Sizes */

/* Button Other */

/* Toasts */

/* Dropdowns */

/* Pagination */

/* Card */

/* List Groups */

/* Nav */

/* Progress Bars */

/* Close */

/* Table */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: var(--lia-bs-white);
  --gray: var(--lia-bs-gray-600);
  --gray-dark: var(--lia-bs-gray-800);
  --primary: var(--lia-bs-primary);
  --secondary: var(--lia-bs-secondary);
  --success: var(--lia-bs-success);
  --info: var(--lia-bs-info);
  --warning: var(--lia-bs-warning);
  --danger: var(--lia-bs-danger);
  --light: var(--lia-bs-light);
  --dark: var(--lia-bs-dark);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1260px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: hsla(
    var(--lia-bs-black-h),
    var(--lia-bs-black-s),
    var(--lia-bs-black-l),
    0
  );
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: var(--lia-bs-font-family-base);
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
  text-align: left;
  background-color: var(--lia-bs-body-bg);
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: var(--lia-bs-font-weight-bold);
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--lia-bs-link-color);
  -webkit-text-decoration: var(--lia-bs-link-decoration);
          text-decoration: var(--lia-bs-link-decoration);
  background-color: transparent;
}

a:hover {
  color: var(--lia-bs-link-hover-color);
  -webkit-text-decoration: var(--lia-bs-link-hover-decoration);
          text-decoration: var(--lia-bs-link-hover-decoration);
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: var(--lia-bs-text-muted);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: var(--lia-bs-label-margin-bottom);
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: var(--lia-bs-headings-font-family);
  font-weight: var(--lia-bs-headings-font-weight);
  line-height: var(--lia-bs-headings-line-height);
  font-style: var(--lia-headings-font-style);
  color: var(--lia-bs-headings-color);
}

h1 {
  font-size: var(--lia-bs-h1-font-size);
  letter-spacing: var(--lia-h1-letter-spacing);
  font-weight: var(--lia-h1-font-weight);
}

h2 {
  font-size: var(--lia-bs-h2-font-size);
  letter-spacing: var(--lia-h2-letter-spacing);
  font-weight: var(--lia-h2-font-weight);
}

h3 {
  font-size: var(--lia-bs-h3-font-size);
  letter-spacing: var(--lia-h3-letter-spacing);
  font-weight: var(--lia-h3-font-weight);
}

h4 {
  font-size: var(--lia-bs-h4-font-size);
  letter-spacing: var(--lia-h4-letter-spacing);
  font-weight: var(--lia-h4-font-weight);
}

h5 {
  font-size: var(--lia-bs-h5-font-size);
  letter-spacing: var(--lia-h5-letter-spacing);
  font-weight: var(--lia-h5-font-weight);
}

h6 {
  font-size: var(--lia-bs-h6-font-size);
  letter-spacing: var(--lia-h6-letter-spacing);
  font-weight: var(--lia-h6-font-weight);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid
    hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.1);
}

small {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-base);
  letter-spacing: var(--lia-letter-spacing-sm);
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: var(--lia-bs-white);
  background-color: var(--lia-bs-gray-900);
  border-radius: var(--lia-bs-border-radius-sm);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: var(--lia-bs-font-weight-bold);
}

pre {
  display: block;
  font-size: 87.5%;
  color: var(--lia-bs-gray-900);
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid var(--lia-bs-gray-500);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

body {
  font-style: var(--lia-font-style-base);
  -moz-osx-font-smoothing: grayscale;
}

a,
button {
  touch-action: manipulation;
}

.SystemAlert_lia-box__yzo17 {
  height: 100%;
  padding: 0.9375rem 1.25rem;
  word-break: break-word;
  border: 1px solid var(--lia-alert-system-color);
  background-color: hsl(var(--lia-alert-system-color-h), var(--lia-alert-system-color-s), 98%);
  border-radius: var(--lia-bs-border-radius-lg);
  box-shadow: inset 0 0 0 3px
    hsla(
      var(--lia-alert-system-color-h),
      var(--lia-alert-system-color-s),
      var(--lia-alert-system-color-l),
      0.2
    );
}

.SystemAlert_lia-title__HXatT,
.SystemAlert_lia-message__EKfhi {
  display: block;
  color: var(--lia-alert-system-color);
}

.SystemAlert_lia-title__HXatT:not(.SystemAlert_lia-is-component__BVDVh) {
  line-height: var(--lia-bs-headings-line-height);
  font-weight: var(--lia-bs-headings-font-weight);
  font-size: var(--lia-bs-h5-font-size);
  letter-spacing: var(--lia-h5-letter-spacing);
}

.SystemAlert_lia-message__EKfhi:not(.SystemAlert_lia-is-component__BVDVh) {
  font-size: var(--lia-bs-font-size-sm);
}

.SystemAlert_lia-title__HXatT + .SystemAlert_lia-message__EKfhi {
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 3px solid
    hsla(
      var(--lia-alert-system-color-h),
      var(--lia-alert-system-color-s),
      var(--lia-alert-system-color-l),
      0.2
    );
}

/* Media breakpoints */
/* Breakpoint Down */
/* Breakpoint Up */
/* Panels */
/* Admin */
/* Search */
/* Indents */
/* Quilts */
.Icon_lia-icon__on1Gb {
  display: inline-block;
  fill: var(--lia-local-icon-color);
}
.Icon_lia-icon__on1Gb > image {
    height: 100%;
    width: 100%;
  }
.Icon_lia-frame__Que5I {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--lia-bs-border-radius-sm);
  background-color: hsla(
    var(--lia-local-icon-color-h),
    var(--lia-local-icon-color-s),
    var(--lia-local-icon-color-l),
    0.1
  );
}
.Icon_lia-frame__Que5I.Icon_lia-size-10__TURN9 .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-12__l8N3M .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-14__XOl7p .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-16__D8_m2 .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-20__qVZab .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-24__o23et .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-30__sne9W .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-40__xfOj3 .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-50__PduO6 .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-60__JktZu .Icon_lia-icon__on1Gb {
        height: 50%;
        width: 50%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-80__uWzC0 .Icon_lia-icon__on1Gb {
        height: 40%;
        width: 40%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-120__KAAz6 .Icon_lia-icon__on1Gb {
        height: 40%;
        width: 40%;
      }
.Icon_lia-frame__Que5I.Icon_lia-size-160__ZOOwZ .Icon_lia-icon__on1Gb {
        height: 40%;
        width: 40%;
      }

.CustomValidationChecklist_lia-item__0ahUP {
  margin-bottom: 0.125rem;
}

/**
 * Default outline styles.
 */
/**
 * Default input-style box-shadow.
 */
/**
 * Default focus-visible outline styles
 */
/**
 * Default focus-visible styles for button
 * or button-like elements.
 */
.InfoPopover_lia-info-btn__Kn7ne.InfoPopover_lia-info-btn__Kn7ne {
  display: inline-flex;
  -moz-column-gap: 0.3125rem;
       column-gap: 0.3125rem;
  align-items: center;
  border-radius: var(--lia-border-radius-50);
  cursor: help !important;
}
.InfoPopover_lia-info-btn__Kn7ne.InfoPopover_lia-info-btn__Kn7ne:focus {
    box-shadow: unset;
  }
.InfoPopover_lia-info-btn__Kn7ne.InfoPopover_lia-info-btn__Kn7ne:focus-visible {
  outline: var(--lia-outline-color) solid 3px !important;
  outline-offset: unset;
  }
.InfoPopover_lia-info-btn__Kn7ne.InfoPopover_lia-info-btn__Kn7ne.InfoPopover_lia-with-label__hzqCK {
    border-radius: var(--lia-bs-border-radius);
  }
.InfoPopover_lia-info-btn__Kn7ne.InfoPopover_lia-info-btn__Kn7ne .InfoPopover_lia-label__Y2Upw {
    white-space: pre-wrap;
    line-height: 1;
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-local-icon-color, var(--lia-bs-body-color));
  }

.Button_lia-load-size__a_Dgl {
  min-height: calc(var(--lia-bs-line-height-base) * var(--lia-bs-font-size-sm));
}

  .Button_lia-load-size-sm__0jdgE {
    min-height: calc(1.2 * var(--lia-font-size-xs));
  }

  .Button_lia-load-size-lg__IfagQ {
    min-height: calc(var(--lia-bs-line-height-base) * var(--lia-bs-font-size-base));
  }

  .Button_lia-load-size__a_Dgl .Button_lia-loading-color__ah2tP {
    background-color: currentColor;
    opacity: 0.75;
  }

.FormField_lia-label-wrap__eFxhW {
  display: inline-flex;
  align-items: center;
  -moz-column-gap: 0.3125rem;
       column-gap: 0.3125rem;
  margin-bottom: var(--lia-bs-label-margin-bottom);
}

  .FormField_lia-label-wrap__eFxhW .FormField_lia-label__TOMHO {
    margin-bottom: 0;
  }

.FormField_lia-form-error__GJbOo {
  word-break: break-word;
}

.PasswordField_lia-password-wrap__yC2Ft {
  position: relative;
}

  /** In addition to !important we also need a double selector here to ensure we win the specificity war no matter what order sheets are loaded in */
  .PasswordField_lia-password-wrap__yC2Ft .PasswordField_lia-input-field__BTTE3.PasswordField_lia-input-field__BTTE3 {
    /** Disclaimer: !important is bad and wrong, but it is better than potentially breaking things by removing it from _forms.scss */
    padding-right: 2.375rem !important;
  }
  .PasswordField_lia-password-wrap__yC2Ft .PasswordField_lia-show-password__9Wv_e {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2.375rem;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

.PasswordField_lia-input-field__BTTE3::-ms-reveal {
  display: none;
}

.NewPasswordField_lia-popover__psj_4 {
  padding: 0.625rem 0.9375rem 0.9375rem;
}

  .NewPasswordField_lia-popover-header__OkP7q {
    color: var(--lia-bs-gray-900);
    padding-bottom: 0.625rem;
    border-bottom: 1px solid var(--lia-bs-border-color);
    margin-bottom: 0.625rem;
    font-weight: var(--lia-bs-font-weight-bold);
  }

.FloatingControlBox_lia-box__24IwU {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 1.5625rem;
  left: 1.5625rem;
  padding: 1.25rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: var(--lia-bs-white);
  border: 1px solid var(--lia-bs-border-color);
  border-radius: var(--lia-bs-border-radius);
  box-shadow: var(--lia-bs-box-shadow);
  color: var(--lia-bs-body-color);
  z-index: 1200;
}

.FloatingControlBox_lia-dropdown-item__LCwA5 {
  white-space: break-spaces;
}

.ToggleTextKeysFloatingBox_lia-description__KgOVM {
  display: flex;
  flex-direction: column;
  margin-left: 0.625rem;
  font-size: var(--lia-bs-font-size-sm);
}

.MaintenanceFloatingBox_lia-maintenance-message__spEYp {
  display: flex;
  flex-direction: column;
  max-width: 12.5rem;
  margin-left: 0.625rem;
  font-size: var(--lia-bs-font-size-sm);
}
.MaintenanceFloatingBox_lia-maintenance-icon__sfiPX {
  display: flex;
  position: relative;
  padding: 0.3125rem;
  justify-content: center;
  align-items: center;
}
.MaintenanceFloatingBox_lia-danger-icon__Dd3M3 {
  display: inline-block;
  margin-top: -0.125rem;
}
.MaintenanceFloatingBox_lia-pulse__ZK0VY:after {
  position: absolute;
  content: '';
  top: 0;
  display: block;
  border-radius: var(--lia-avatar-border-radius);
  height: 100%;
  width: 100%;
  animation: MaintenanceFloatingBox_lia-switch-pulse__aLQan 2s infinite;
}
@keyframes MaintenanceFloatingBox_lia-switch-pulse__aLQan {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 var(--lia-bs-primary);
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  90% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    opacity: 0;
  }
}

