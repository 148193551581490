.lia-label-wrap {
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: var(--lia-bs-label-margin-bottom);

  .lia-label {
    margin-bottom: 0;
  }
}

.lia-form-error {
  word-break: break-word;
}
